import {Component, Input} from '@angular/core';
import {ConsumptionDataCompanyPartner} from "../consumption-data-model";
import {TreeNode} from "../../material-components/filter-tree/filter-tree.component";
import {ChartDataMapper} from "../chart-data-mapper";
import {MatIconComponent} from "../../material-components/mat-icon-component/mat-icon.component";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'charts-wrapper',
  templateUrl: './charts-wrapper.component.html',
  styleUrls: ['./charts-wrapper.component.scss']
})
export class ChartsWrapperComponent extends MatIconComponent {

  _consumptionData?: ConsumptionDataCompanyPartner;

  @Input() opened: boolean = true;

  @Input()
  set consumptionData(value: ConsumptionDataCompanyPartner | undefined) {
    this._consumptionData = value;
    if(value != undefined) {
      this.filterTree = ChartDataMapper.toFilterTree(this._consumptionData);
      this.minimalYear = ChartDataMapper.getMinYear(value);
    }
  }

  filterTree?: TreeNode[];
  mpFilter: {mpid: string, vnb: string, bp: string}[] | undefined = undefined;
  today = new Date();
  dateToDisplay = new Date(this.today.getFullYear(), this.today.getMonth(), 0);
  currentYear = this.dateToDisplay.getFullYear();
  yearToDisplay = this.currentYear;
  minimalYear = this.currentYear;
  monthToDisplay: number = this.dateToDisplay.getMonth();

  constructor(protected matIconRegistry: MatIconRegistry,
              protected domSanitizer: DomSanitizer) {
    super(matIconRegistry, domSanitizer);
  }

  onPreviousYearClick() {
    this.yearToDisplay --;
  }

  onNextYearClick() {
    this.yearToDisplay ++;
  }

  onPreviousMonthClick() {
    if(this.monthToDisplay == 0) {
      this.monthToDisplay = 11;
      this.yearToDisplay --;
    } else {
      this.monthToDisplay!--;
    }
  }

  onNextMonthClick() {
    if(this.monthToDisplay == 11) {
      this.monthToDisplay = 0;
      this.yearToDisplay ++;
    } else {
      this.monthToDisplay!++;
    }
  }

  onNodesSelected($event: {mpid: string, vnb: string, bp: string}[]) {
    this.mpFilter = $event;
  }

  onMonthSelected($event: number) {
    this.monthToDisplay = $event;
  }
}
