/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const authentication = $root.authentication = (() => {

    /**
     * Namespace authentication.
     * @exports authentication
     * @namespace
     */
    const authentication = {};

    authentication.AuthenticationService = (function() {

        /**
         * Constructs a new AuthenticationService service.
         * @memberof authentication
         * @classdesc Represents an AuthenticationService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function AuthenticationService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (AuthenticationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AuthenticationService;

        /**
         * Callback as used by {@link authentication.AuthenticationService#login1Password}.
         * @memberof authentication.AuthenticationService
         * @typedef Login1PasswordCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {authentication.OtpAuthentication} [response] OtpAuthentication
         */

        /**
         * Calls Login1Password.
         * @function login1Password
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {authentication.IUsernamePasswordRequest} request UsernamePasswordRequest message or plain object
         * @param {authentication.AuthenticationService.Login1PasswordCallback} callback Node-style callback called with the error, if any, and OtpAuthentication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AuthenticationService.prototype.login1Password = function login1Password(request, callback) {
            return this.rpcCall(login1Password, $root.authentication.UsernamePasswordRequest, $root.authentication.OtpAuthentication, request, callback);
        }, "name", { value: "Login1Password" });

        /**
         * Calls Login1Password.
         * @function login1Password
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {authentication.IUsernamePasswordRequest} request UsernamePasswordRequest message or plain object
         * @returns {Promise<authentication.OtpAuthentication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link authentication.AuthenticationService#login2Otp}.
         * @memberof authentication.AuthenticationService
         * @typedef Login2OtpCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {authentication.Authentication} [response] Authentication
         */

        /**
         * Calls Login2Otp.
         * @function login2Otp
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {authentication.IOtpRequest} request OtpRequest message or plain object
         * @param {authentication.AuthenticationService.Login2OtpCallback} callback Node-style callback called with the error, if any, and Authentication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AuthenticationService.prototype.login2Otp = function login2Otp(request, callback) {
            return this.rpcCall(login2Otp, $root.authentication.OtpRequest, $root.authentication.Authentication, request, callback);
        }, "name", { value: "Login2Otp" });

        /**
         * Calls Login2Otp.
         * @function login2Otp
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {authentication.IOtpRequest} request OtpRequest message or plain object
         * @returns {Promise<authentication.Authentication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link authentication.AuthenticationService#logout}.
         * @memberof authentication.AuthenticationService
         * @typedef LogoutCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls Logout.
         * @function logout
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {authentication.AuthenticationService.LogoutCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AuthenticationService.prototype.logout = function logout(request, callback) {
            return this.rpcCall(logout, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "Logout" });

        /**
         * Calls Logout.
         * @function logout
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link authentication.AuthenticationService#changePartner}.
         * @memberof authentication.AuthenticationService
         * @typedef ChangePartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {authentication.Authentication} [response] Authentication
         */

        /**
         * Calls ChangePartner.
         * @function changePartner
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {authentication.IChangePartnerRequest} request ChangePartnerRequest message or plain object
         * @param {authentication.AuthenticationService.ChangePartnerCallback} callback Node-style callback called with the error, if any, and Authentication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AuthenticationService.prototype.changePartner = function changePartner(request, callback) {
            return this.rpcCall(changePartner, $root.authentication.ChangePartnerRequest, $root.authentication.Authentication, request, callback);
        }, "name", { value: "ChangePartner" });

        /**
         * Calls ChangePartner.
         * @function changePartner
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {authentication.IChangePartnerRequest} request ChangePartnerRequest message or plain object
         * @returns {Promise<authentication.Authentication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link authentication.AuthenticationService#refreshToken}.
         * @memberof authentication.AuthenticationService
         * @typedef RefreshTokenCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {authentication.Authentication} [response] Authentication
         */

        /**
         * Calls RefreshToken.
         * @function refreshToken
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {authentication.AuthenticationService.RefreshTokenCallback} callback Node-style callback called with the error, if any, and Authentication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AuthenticationService.prototype.refreshToken = function refreshToken(request, callback) {
            return this.rpcCall(refreshToken, $root.google.protobuf.Empty, $root.authentication.Authentication, request, callback);
        }, "name", { value: "RefreshToken" });

        /**
         * Calls RefreshToken.
         * @function refreshToken
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<authentication.Authentication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link authentication.AuthenticationService#getMPDirAdminToken}.
         * @memberof authentication.AuthenticationService
         * @typedef GetMPDirAdminTokenCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {authentication.MPDirAdminAuthentication} [response] MPDirAdminAuthentication
         */

        /**
         * Calls GetMPDirAdminToken.
         * @function getMPDirAdminToken
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {authentication.IMPDirAdminRequest} request MPDirAdminRequest message or plain object
         * @param {authentication.AuthenticationService.GetMPDirAdminTokenCallback} callback Node-style callback called with the error, if any, and MPDirAdminAuthentication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AuthenticationService.prototype.getMPDirAdminToken = function getMPDirAdminToken(request, callback) {
            return this.rpcCall(getMPDirAdminToken, $root.authentication.MPDirAdminRequest, $root.authentication.MPDirAdminAuthentication, request, callback);
        }, "name", { value: "GetMPDirAdminToken" });

        /**
         * Calls GetMPDirAdminToken.
         * @function getMPDirAdminToken
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {authentication.IMPDirAdminRequest} request MPDirAdminRequest message or plain object
         * @returns {Promise<authentication.MPDirAdminAuthentication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link authentication.AuthenticationService#ftpLogin}.
         * @memberof authentication.AuthenticationService
         * @typedef FtpLoginCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {authentication.Authentication} [response] Authentication
         */

        /**
         * Calls FtpLogin.
         * @function ftpLogin
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {authentication.IUsernamePasswordRequest} request UsernamePasswordRequest message or plain object
         * @param {authentication.AuthenticationService.FtpLoginCallback} callback Node-style callback called with the error, if any, and Authentication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(AuthenticationService.prototype.ftpLogin = function ftpLogin(request, callback) {
            return this.rpcCall(ftpLogin, $root.authentication.UsernamePasswordRequest, $root.authentication.Authentication, request, callback);
        }, "name", { value: "FtpLogin" });

        /**
         * Calls FtpLogin.
         * @function ftpLogin
         * @memberof authentication.AuthenticationService
         * @instance
         * @param {authentication.IUsernamePasswordRequest} request UsernamePasswordRequest message or plain object
         * @returns {Promise<authentication.Authentication>} Promise
         * @variation 2
         */

        return AuthenticationService;
    })();

    authentication.UsernamePasswordRequest = (function() {

        /**
         * Properties of a UsernamePasswordRequest.
         * @memberof authentication
         * @interface IUsernamePasswordRequest
         * @property {google.protobuf.IStringValue|null} [username] UsernamePasswordRequest username
         * @property {google.protobuf.IStringValue|null} [password] UsernamePasswordRequest password
         */

        /**
         * Constructs a new UsernamePasswordRequest.
         * @memberof authentication
         * @classdesc Represents a UsernamePasswordRequest.
         * @implements IUsernamePasswordRequest
         * @constructor
         * @param {authentication.IUsernamePasswordRequest=} [properties] Properties to set
         */
        function UsernamePasswordRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UsernamePasswordRequest username.
         * @member {google.protobuf.IStringValue|null|undefined} username
         * @memberof authentication.UsernamePasswordRequest
         * @instance
         */
        UsernamePasswordRequest.prototype.username = null;

        /**
         * UsernamePasswordRequest password.
         * @member {google.protobuf.IStringValue|null|undefined} password
         * @memberof authentication.UsernamePasswordRequest
         * @instance
         */
        UsernamePasswordRequest.prototype.password = null;

        /**
         * Gets the default type url for UsernamePasswordRequest
         * @function getTypeUrl
         * @memberof authentication.UsernamePasswordRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UsernamePasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.UsernamePasswordRequest";
        };

        return UsernamePasswordRequest;
    })();

    authentication.OtpRequest = (function() {

        /**
         * Properties of an OtpRequest.
         * @memberof authentication
         * @interface IOtpRequest
         * @property {google.protobuf.IStringValue|null} [otp] OtpRequest otp
         */

        /**
         * Constructs a new OtpRequest.
         * @memberof authentication
         * @classdesc Represents an OtpRequest.
         * @implements IOtpRequest
         * @constructor
         * @param {authentication.IOtpRequest=} [properties] Properties to set
         */
        function OtpRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OtpRequest otp.
         * @member {google.protobuf.IStringValue|null|undefined} otp
         * @memberof authentication.OtpRequest
         * @instance
         */
        OtpRequest.prototype.otp = null;

        /**
         * Gets the default type url for OtpRequest
         * @function getTypeUrl
         * @memberof authentication.OtpRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OtpRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.OtpRequest";
        };

        return OtpRequest;
    })();

    authentication.ChangePartnerRequest = (function() {

        /**
         * Properties of a ChangePartnerRequest.
         * @memberof authentication
         * @interface IChangePartnerRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] ChangePartnerRequest partnerId
         */

        /**
         * Constructs a new ChangePartnerRequest.
         * @memberof authentication
         * @classdesc Represents a ChangePartnerRequest.
         * @implements IChangePartnerRequest
         * @constructor
         * @param {authentication.IChangePartnerRequest=} [properties] Properties to set
         */
        function ChangePartnerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangePartnerRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof authentication.ChangePartnerRequest
         * @instance
         */
        ChangePartnerRequest.prototype.partnerId = null;

        /**
         * Gets the default type url for ChangePartnerRequest
         * @function getTypeUrl
         * @memberof authentication.ChangePartnerRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangePartnerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.ChangePartnerRequest";
        };

        return ChangePartnerRequest;
    })();

    authentication.Authentication = (function() {

        /**
         * Properties of an Authentication.
         * @memberof authentication
         * @interface IAuthentication
         * @property {google.protobuf.IStringValue|null} [accessToken] Authentication accessToken
         * @property {google.protobuf.IStringValue|null} [refreshToken] Authentication refreshToken
         */

        /**
         * Constructs a new Authentication.
         * @memberof authentication
         * @classdesc Represents an Authentication.
         * @implements IAuthentication
         * @constructor
         * @param {authentication.IAuthentication=} [properties] Properties to set
         */
        function Authentication(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Authentication accessToken.
         * @member {google.protobuf.IStringValue|null|undefined} accessToken
         * @memberof authentication.Authentication
         * @instance
         */
        Authentication.prototype.accessToken = null;

        /**
         * Authentication refreshToken.
         * @member {google.protobuf.IStringValue|null|undefined} refreshToken
         * @memberof authentication.Authentication
         * @instance
         */
        Authentication.prototype.refreshToken = null;

        /**
         * Gets the default type url for Authentication
         * @function getTypeUrl
         * @memberof authentication.Authentication
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Authentication.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.Authentication";
        };

        return Authentication;
    })();

    authentication.OtpAuthentication = (function() {

        /**
         * Properties of an OtpAuthentication.
         * @memberof authentication
         * @interface IOtpAuthentication
         * @property {google.protobuf.IStringValue|null} [otpToken] OtpAuthentication otpToken
         */

        /**
         * Constructs a new OtpAuthentication.
         * @memberof authentication
         * @classdesc Represents an OtpAuthentication.
         * @implements IOtpAuthentication
         * @constructor
         * @param {authentication.IOtpAuthentication=} [properties] Properties to set
         */
        function OtpAuthentication(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OtpAuthentication otpToken.
         * @member {google.protobuf.IStringValue|null|undefined} otpToken
         * @memberof authentication.OtpAuthentication
         * @instance
         */
        OtpAuthentication.prototype.otpToken = null;

        /**
         * Gets the default type url for OtpAuthentication
         * @function getTypeUrl
         * @memberof authentication.OtpAuthentication
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OtpAuthentication.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.OtpAuthentication";
        };

        return OtpAuthentication;
    })();

    authentication.MPDirAdminRequest = (function() {

        /**
         * Properties of a MPDirAdminRequest.
         * @memberof authentication
         * @interface IMPDirAdminRequest
         * @property {google.protobuf.IStringValue|null} [password] MPDirAdminRequest password
         */

        /**
         * Constructs a new MPDirAdminRequest.
         * @memberof authentication
         * @classdesc Represents a MPDirAdminRequest.
         * @implements IMPDirAdminRequest
         * @constructor
         * @param {authentication.IMPDirAdminRequest=} [properties] Properties to set
         */
        function MPDirAdminRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MPDirAdminRequest password.
         * @member {google.protobuf.IStringValue|null|undefined} password
         * @memberof authentication.MPDirAdminRequest
         * @instance
         */
        MPDirAdminRequest.prototype.password = null;

        /**
         * Gets the default type url for MPDirAdminRequest
         * @function getTypeUrl
         * @memberof authentication.MPDirAdminRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MPDirAdminRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.MPDirAdminRequest";
        };

        return MPDirAdminRequest;
    })();

    authentication.MPDirAdminAuthentication = (function() {

        /**
         * Properties of a MPDirAdminAuthentication.
         * @memberof authentication
         * @interface IMPDirAdminAuthentication
         * @property {google.protobuf.IStringValue|null} [mpdirAdminToken] MPDirAdminAuthentication mpdirAdminToken
         */

        /**
         * Constructs a new MPDirAdminAuthentication.
         * @memberof authentication
         * @classdesc Represents a MPDirAdminAuthentication.
         * @implements IMPDirAdminAuthentication
         * @constructor
         * @param {authentication.IMPDirAdminAuthentication=} [properties] Properties to set
         */
        function MPDirAdminAuthentication(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MPDirAdminAuthentication mpdirAdminToken.
         * @member {google.protobuf.IStringValue|null|undefined} mpdirAdminToken
         * @memberof authentication.MPDirAdminAuthentication
         * @instance
         */
        MPDirAdminAuthentication.prototype.mpdirAdminToken = null;

        /**
         * Gets the default type url for MPDirAdminAuthentication
         * @function getTypeUrl
         * @memberof authentication.MPDirAdminAuthentication
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MPDirAdminAuthentication.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.MPDirAdminAuthentication";
        };

        return MPDirAdminAuthentication;
    })();

    return authentication;
})();

export const base = $root.base = (() => {

    /**
     * Namespace base.
     * @exports base
     * @namespace
     */
    const base = {};

    base.LocalizedMessage = (function() {

        /**
         * Properties of a LocalizedMessage.
         * @memberof base
         * @interface ILocalizedMessage
         * @property {google.protobuf.IStringValue|null} [de] LocalizedMessage de
         * @property {google.protobuf.IStringValue|null} [it] LocalizedMessage it
         * @property {google.protobuf.IStringValue|null} [fr] LocalizedMessage fr
         */

        /**
         * Constructs a new LocalizedMessage.
         * @memberof base
         * @classdesc Represents a LocalizedMessage.
         * @implements ILocalizedMessage
         * @constructor
         * @param {base.ILocalizedMessage=} [properties] Properties to set
         */
        function LocalizedMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocalizedMessage de.
         * @member {google.protobuf.IStringValue|null|undefined} de
         * @memberof base.LocalizedMessage
         * @instance
         */
        LocalizedMessage.prototype.de = null;

        /**
         * LocalizedMessage it.
         * @member {google.protobuf.IStringValue|null|undefined} it
         * @memberof base.LocalizedMessage
         * @instance
         */
        LocalizedMessage.prototype.it = null;

        /**
         * LocalizedMessage fr.
         * @member {google.protobuf.IStringValue|null|undefined} fr
         * @memberof base.LocalizedMessage
         * @instance
         */
        LocalizedMessage.prototype.fr = null;

        /**
         * Gets the default type url for LocalizedMessage
         * @function getTypeUrl
         * @memberof base.LocalizedMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocalizedMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.LocalizedMessage";
        };

        return LocalizedMessage;
    })();

    /**
     * Status enum.
     * @name base.Status
     * @enum {number}
     * @property {number} STATUS_UNSPECIFIED=0 STATUS_UNSPECIFIED value
     * @property {number} SUCCESS=1 SUCCESS value
     * @property {number} ERROR=2 ERROR value
     */
    base.Status = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STATUS_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SUCCESS"] = 1;
        values[valuesById[2] = "ERROR"] = 2;
        return values;
    })();

    base.LocalizedResult = (function() {

        /**
         * Properties of a LocalizedResult.
         * @memberof base
         * @interface ILocalizedResult
         * @property {base.Status|null} [status] LocalizedResult status
         * @property {Array.<base.ILocalizedMessage>|null} [errorMessages] LocalizedResult errorMessages
         * @property {Array.<base.ILocalizedMessage>|null} [warningMessages] LocalizedResult warningMessages
         */

        /**
         * Constructs a new LocalizedResult.
         * @memberof base
         * @classdesc Represents a LocalizedResult.
         * @implements ILocalizedResult
         * @constructor
         * @param {base.ILocalizedResult=} [properties] Properties to set
         */
        function LocalizedResult(properties) {
            this.errorMessages = [];
            this.warningMessages = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocalizedResult status.
         * @member {base.Status} status
         * @memberof base.LocalizedResult
         * @instance
         */
        LocalizedResult.prototype.status = 0;

        /**
         * LocalizedResult errorMessages.
         * @member {Array.<base.ILocalizedMessage>} errorMessages
         * @memberof base.LocalizedResult
         * @instance
         */
        LocalizedResult.prototype.errorMessages = $util.emptyArray;

        /**
         * LocalizedResult warningMessages.
         * @member {Array.<base.ILocalizedMessage>} warningMessages
         * @memberof base.LocalizedResult
         * @instance
         */
        LocalizedResult.prototype.warningMessages = $util.emptyArray;

        /**
         * Gets the default type url for LocalizedResult
         * @function getTypeUrl
         * @memberof base.LocalizedResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocalizedResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.LocalizedResult";
        };

        return LocalizedResult;
    })();

    base.DownloadFileResponse = (function() {

        /**
         * Properties of a DownloadFileResponse.
         * @memberof base
         * @interface IDownloadFileResponse
         * @property {google.protobuf.IStringValue|null} [filename] DownloadFileResponse filename
         * @property {Uint8Array|null} [data] DownloadFileResponse data
         */

        /**
         * Constructs a new DownloadFileResponse.
         * @memberof base
         * @classdesc Represents a DownloadFileResponse.
         * @implements IDownloadFileResponse
         * @constructor
         * @param {base.IDownloadFileResponse=} [properties] Properties to set
         */
        function DownloadFileResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DownloadFileResponse filename.
         * @member {google.protobuf.IStringValue|null|undefined} filename
         * @memberof base.DownloadFileResponse
         * @instance
         */
        DownloadFileResponse.prototype.filename = null;

        /**
         * DownloadFileResponse data.
         * @member {Uint8Array} data
         * @memberof base.DownloadFileResponse
         * @instance
         */
        DownloadFileResponse.prototype.data = $util.newBuffer([]);

        /**
         * Gets the default type url for DownloadFileResponse
         * @function getTypeUrl
         * @memberof base.DownloadFileResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DownloadFileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.DownloadFileResponse";
        };

        return DownloadFileResponse;
    })();

    base.Chunk = (function() {

        /**
         * Properties of a Chunk.
         * @memberof base
         * @interface IChunk
         * @property {Uint8Array|null} [data] Chunk data
         */

        /**
         * Constructs a new Chunk.
         * @memberof base
         * @classdesc Represents a Chunk.
         * @implements IChunk
         * @constructor
         * @param {base.IChunk=} [properties] Properties to set
         */
        function Chunk(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Chunk data.
         * @member {Uint8Array} data
         * @memberof base.Chunk
         * @instance
         */
        Chunk.prototype.data = $util.newBuffer([]);

        /**
         * Gets the default type url for Chunk
         * @function getTypeUrl
         * @memberof base.Chunk
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Chunk.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.Chunk";
        };

        return Chunk;
    })();

    base.MessagePart = (function() {

        /**
         * Properties of a MessagePart.
         * @memberof base
         * @interface IMessagePart
         * @property {base.IChunkWithCounter|null} [chunk] MessagePart chunk
         */

        /**
         * Constructs a new MessagePart.
         * @memberof base
         * @classdesc Represents a MessagePart.
         * @implements IMessagePart
         * @constructor
         * @param {base.IMessagePart=} [properties] Properties to set
         */
        function MessagePart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessagePart chunk.
         * @member {base.IChunkWithCounter|null|undefined} chunk
         * @memberof base.MessagePart
         * @instance
         */
        MessagePart.prototype.chunk = null;

        /**
         * Gets the default type url for MessagePart
         * @function getTypeUrl
         * @memberof base.MessagePart
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessagePart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.MessagePart";
        };

        return MessagePart;
    })();

    base.ChunkWithCounter = (function() {

        /**
         * Properties of a ChunkWithCounter.
         * @memberof base
         * @interface IChunkWithCounter
         * @property {Uint8Array|null} [data] ChunkWithCounter data
         * @property {number|null} [counter] ChunkWithCounter counter
         */

        /**
         * Constructs a new ChunkWithCounter.
         * @memberof base
         * @classdesc Represents a ChunkWithCounter.
         * @implements IChunkWithCounter
         * @constructor
         * @param {base.IChunkWithCounter=} [properties] Properties to set
         */
        function ChunkWithCounter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChunkWithCounter data.
         * @member {Uint8Array} data
         * @memberof base.ChunkWithCounter
         * @instance
         */
        ChunkWithCounter.prototype.data = $util.newBuffer([]);

        /**
         * ChunkWithCounter counter.
         * @member {number} counter
         * @memberof base.ChunkWithCounter
         * @instance
         */
        ChunkWithCounter.prototype.counter = 0;

        /**
         * Gets the default type url for ChunkWithCounter
         * @function getTypeUrl
         * @memberof base.ChunkWithCounter
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChunkWithCounter.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.ChunkWithCounter";
        };

        return ChunkWithCounter;
    })();

    /**
     * Language enum.
     * @name base.Language
     * @enum {number}
     * @property {number} DE=0 DE value
     * @property {number} FR=1 FR value
     * @property {number} IT=2 IT value
     */
    base.Language = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DE"] = 0;
        values[valuesById[1] = "FR"] = 1;
        values[valuesById[2] = "IT"] = 2;
        return values;
    })();

    base.MeteringPointSelector = (function() {

        /**
         * Properties of a MeteringPointSelector.
         * @memberof base
         * @interface IMeteringPointSelector
         * @property {google.protobuf.IStringValue|null} [id] MeteringPointSelector id
         * @property {google.protobuf.IStringValue|null} [administratorEic] MeteringPointSelector administratorEic
         */

        /**
         * Constructs a new MeteringPointSelector.
         * @memberof base
         * @classdesc Represents a MeteringPointSelector.
         * @implements IMeteringPointSelector
         * @constructor
         * @param {base.IMeteringPointSelector=} [properties] Properties to set
         */
        function MeteringPointSelector(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointSelector id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof base.MeteringPointSelector
         * @instance
         */
        MeteringPointSelector.prototype.id = null;

        /**
         * MeteringPointSelector administratorEic.
         * @member {google.protobuf.IStringValue|null|undefined} administratorEic
         * @memberof base.MeteringPointSelector
         * @instance
         */
        MeteringPointSelector.prototype.administratorEic = null;

        /**
         * Gets the default type url for MeteringPointSelector
         * @function getTypeUrl
         * @memberof base.MeteringPointSelector
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointSelector.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.MeteringPointSelector";
        };

        return MeteringPointSelector;
    })();

    /**
     * Role enum.
     * @name base.Role
     * @enum {number}
     * @property {number} ROLE_UNSPECIFIED=0 ROLE_UNSPECIFIED value
     * @property {number} UNB=1 UNB value
     * @property {number} VNB=2 VNB value
     * @property {number} LF=3 LF value
     * @property {number} BGV=4 BGV value
     * @property {number} SDV=5 SDV value
     * @property {number} EV=6 EV value
     */
    base.Role = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ROLE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "UNB"] = 1;
        values[valuesById[2] = "VNB"] = 2;
        values[valuesById[3] = "LF"] = 3;
        values[valuesById[4] = "BGV"] = 4;
        values[valuesById[5] = "SDV"] = 5;
        values[valuesById[6] = "EV"] = 6;
        return values;
    })();

    base.PartnerRequest = (function() {

        /**
         * Properties of a PartnerRequest.
         * @memberof base
         * @interface IPartnerRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] PartnerRequest partnerId
         */

        /**
         * Constructs a new PartnerRequest.
         * @memberof base
         * @classdesc Represents a PartnerRequest.
         * @implements IPartnerRequest
         * @constructor
         * @param {base.IPartnerRequest=} [properties] Properties to set
         */
        function PartnerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PartnerRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof base.PartnerRequest
         * @instance
         */
        PartnerRequest.prototype.partnerId = null;

        /**
         * Gets the default type url for PartnerRequest
         * @function getTypeUrl
         * @memberof base.PartnerRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PartnerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.PartnerRequest";
        };

        return PartnerRequest;
    })();

    base.PartnerIdResponse = (function() {

        /**
         * Properties of a PartnerIdResponse.
         * @memberof base
         * @interface IPartnerIdResponse
         * @property {google.protobuf.IInt32Value|null} [partnerId] PartnerIdResponse partnerId
         */

        /**
         * Constructs a new PartnerIdResponse.
         * @memberof base
         * @classdesc Represents a PartnerIdResponse.
         * @implements IPartnerIdResponse
         * @constructor
         * @param {base.IPartnerIdResponse=} [properties] Properties to set
         */
        function PartnerIdResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PartnerIdResponse partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof base.PartnerIdResponse
         * @instance
         */
        PartnerIdResponse.prototype.partnerId = null;

        /**
         * Gets the default type url for PartnerIdResponse
         * @function getTypeUrl
         * @memberof base.PartnerIdResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PartnerIdResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.PartnerIdResponse";
        };

        return PartnerIdResponse;
    })();

    base.ByMessageUuidAndPartnerIdRequest = (function() {

        /**
         * Properties of a ByMessageUuidAndPartnerIdRequest.
         * @memberof base
         * @interface IByMessageUuidAndPartnerIdRequest
         * @property {google.protobuf.IStringValue|null} [messageUuid] ByMessageUuidAndPartnerIdRequest messageUuid
         * @property {google.protobuf.IInt32Value|null} [partnerId] ByMessageUuidAndPartnerIdRequest partnerId
         */

        /**
         * Constructs a new ByMessageUuidAndPartnerIdRequest.
         * @memberof base
         * @classdesc Represents a ByMessageUuidAndPartnerIdRequest.
         * @implements IByMessageUuidAndPartnerIdRequest
         * @constructor
         * @param {base.IByMessageUuidAndPartnerIdRequest=} [properties] Properties to set
         */
        function ByMessageUuidAndPartnerIdRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ByMessageUuidAndPartnerIdRequest messageUuid.
         * @member {google.protobuf.IStringValue|null|undefined} messageUuid
         * @memberof base.ByMessageUuidAndPartnerIdRequest
         * @instance
         */
        ByMessageUuidAndPartnerIdRequest.prototype.messageUuid = null;

        /**
         * ByMessageUuidAndPartnerIdRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof base.ByMessageUuidAndPartnerIdRequest
         * @instance
         */
        ByMessageUuidAndPartnerIdRequest.prototype.partnerId = null;

        /**
         * Gets the default type url for ByMessageUuidAndPartnerIdRequest
         * @function getTypeUrl
         * @memberof base.ByMessageUuidAndPartnerIdRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ByMessageUuidAndPartnerIdRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.ByMessageUuidAndPartnerIdRequest";
        };

        return ByMessageUuidAndPartnerIdRequest;
    })();

    base.UuidAndPartnerId = (function() {

        /**
         * Properties of an UuidAndPartnerId.
         * @memberof base
         * @interface IUuidAndPartnerId
         * @property {google.protobuf.IStringValue|null} [uuid] UuidAndPartnerId uuid
         * @property {google.protobuf.IInt32Value|null} [partnerId] UuidAndPartnerId partnerId
         */

        /**
         * Constructs a new UuidAndPartnerId.
         * @memberof base
         * @classdesc Represents an UuidAndPartnerId.
         * @implements IUuidAndPartnerId
         * @constructor
         * @param {base.IUuidAndPartnerId=} [properties] Properties to set
         */
        function UuidAndPartnerId(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UuidAndPartnerId uuid.
         * @member {google.protobuf.IStringValue|null|undefined} uuid
         * @memberof base.UuidAndPartnerId
         * @instance
         */
        UuidAndPartnerId.prototype.uuid = null;

        /**
         * UuidAndPartnerId partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof base.UuidAndPartnerId
         * @instance
         */
        UuidAndPartnerId.prototype.partnerId = null;

        /**
         * Gets the default type url for UuidAndPartnerId
         * @function getTypeUrl
         * @memberof base.UuidAndPartnerId
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UuidAndPartnerId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.UuidAndPartnerId";
        };

        return UuidAndPartnerId;
    })();

    base.PaginatedPartnerListRequest = (function() {

        /**
         * Properties of a PaginatedPartnerListRequest.
         * @memberof base
         * @interface IPaginatedPartnerListRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] PaginatedPartnerListRequest partnerId
         * @property {query.IQuery|null} [query] PaginatedPartnerListRequest query
         */

        /**
         * Constructs a new PaginatedPartnerListRequest.
         * @memberof base
         * @classdesc Represents a PaginatedPartnerListRequest.
         * @implements IPaginatedPartnerListRequest
         * @constructor
         * @param {base.IPaginatedPartnerListRequest=} [properties] Properties to set
         */
        function PaginatedPartnerListRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginatedPartnerListRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof base.PaginatedPartnerListRequest
         * @instance
         */
        PaginatedPartnerListRequest.prototype.partnerId = null;

        /**
         * PaginatedPartnerListRequest query.
         * @member {query.IQuery|null|undefined} query
         * @memberof base.PaginatedPartnerListRequest
         * @instance
         */
        PaginatedPartnerListRequest.prototype.query = null;

        /**
         * Gets the default type url for PaginatedPartnerListRequest
         * @function getTypeUrl
         * @memberof base.PaginatedPartnerListRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaginatedPartnerListRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.PaginatedPartnerListRequest";
        };

        return PaginatedPartnerListRequest;
    })();

    /**
     * Direction enum.
     * @name base.Direction
     * @enum {number}
     * @property {number} DIRECTION_UNSPECIFIED=0 DIRECTION_UNSPECIFIED value
     * @property {number} OUTGOING=1 OUTGOING value
     * @property {number} INCOMING=2 INCOMING value
     */
    base.Direction = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DIRECTION_UNSPECIFIED"] = 0;
        values[valuesById[1] = "OUTGOING"] = 1;
        values[valuesById[2] = "INCOMING"] = 2;
        return values;
    })();

    base.UUID = (function() {

        /**
         * Properties of a UUID.
         * @memberof base
         * @interface IUUID
         * @property {google.protobuf.IStringValue|null} [uuid] UUID uuid
         */

        /**
         * Constructs a new UUID.
         * @memberof base
         * @classdesc Represents a UUID.
         * @implements IUUID
         * @constructor
         * @param {base.IUUID=} [properties] Properties to set
         */
        function UUID(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UUID uuid.
         * @member {google.protobuf.IStringValue|null|undefined} uuid
         * @memberof base.UUID
         * @instance
         */
        UUID.prototype.uuid = null;

        /**
         * Gets the default type url for UUID
         * @function getTypeUrl
         * @memberof base.UUID
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UUID.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/base.UUID";
        };

        return UUID;
    })();

    return base;
})();

export const query = $root.query = (() => {

    /**
     * Namespace query.
     * @exports query
     * @namespace
     */
    const query = {};

    query.Query = (function() {

        /**
         * Properties of a Query.
         * @memberof query
         * @interface IQuery
         * @property {Array.<query.IConditions>|null} [andConditions] Query andConditions
         * @property {Array.<query.IOrderBy>|null} [sortOrder] Query sortOrder
         * @property {query.IPageRequest|null} [pageRequest] Query pageRequest
         */

        /**
         * Constructs a new Query.
         * @memberof query
         * @classdesc Represents a Query.
         * @implements IQuery
         * @constructor
         * @param {query.IQuery=} [properties] Properties to set
         */
        function Query(properties) {
            this.andConditions = [];
            this.sortOrder = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Query andConditions.
         * @member {Array.<query.IConditions>} andConditions
         * @memberof query.Query
         * @instance
         */
        Query.prototype.andConditions = $util.emptyArray;

        /**
         * Query sortOrder.
         * @member {Array.<query.IOrderBy>} sortOrder
         * @memberof query.Query
         * @instance
         */
        Query.prototype.sortOrder = $util.emptyArray;

        /**
         * Query pageRequest.
         * @member {query.IPageRequest|null|undefined} pageRequest
         * @memberof query.Query
         * @instance
         */
        Query.prototype.pageRequest = null;

        /**
         * Gets the default type url for Query
         * @function getTypeUrl
         * @memberof query.Query
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Query.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.Query";
        };

        return Query;
    })();

    query.Conditions = (function() {

        /**
         * Properties of a Conditions.
         * @memberof query
         * @interface IConditions
         * @property {Array.<query.ICondition>|null} [orConditions] Conditions orConditions
         */

        /**
         * Constructs a new Conditions.
         * @memberof query
         * @classdesc Represents a Conditions.
         * @implements IConditions
         * @constructor
         * @param {query.IConditions=} [properties] Properties to set
         */
        function Conditions(properties) {
            this.orConditions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Conditions orConditions.
         * @member {Array.<query.ICondition>} orConditions
         * @memberof query.Conditions
         * @instance
         */
        Conditions.prototype.orConditions = $util.emptyArray;

        /**
         * Gets the default type url for Conditions
         * @function getTypeUrl
         * @memberof query.Conditions
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Conditions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.Conditions";
        };

        return Conditions;
    })();

    query.Condition = (function() {

        /**
         * Properties of a Condition.
         * @memberof query
         * @interface ICondition
         * @property {google.protobuf.IStringValue|null} [columnName] Condition columnName
         * @property {query.Operator|null} [operator] Condition operator
         * @property {Array.<google.protobuf.IStringValue>|null} [values] Condition values
         */

        /**
         * Constructs a new Condition.
         * @memberof query
         * @classdesc Represents a Condition.
         * @implements ICondition
         * @constructor
         * @param {query.ICondition=} [properties] Properties to set
         */
        function Condition(properties) {
            this.values = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Condition columnName.
         * @member {google.protobuf.IStringValue|null|undefined} columnName
         * @memberof query.Condition
         * @instance
         */
        Condition.prototype.columnName = null;

        /**
         * Condition operator.
         * @member {query.Operator} operator
         * @memberof query.Condition
         * @instance
         */
        Condition.prototype.operator = 0;

        /**
         * Condition values.
         * @member {Array.<google.protobuf.IStringValue>} values
         * @memberof query.Condition
         * @instance
         */
        Condition.prototype.values = $util.emptyArray;

        /**
         * Gets the default type url for Condition
         * @function getTypeUrl
         * @memberof query.Condition
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Condition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.Condition";
        };

        return Condition;
    })();

    /**
     * Operator enum.
     * @name query.Operator
     * @enum {number}
     * @property {number} OPERATOR_UNSPECIFIED=0 OPERATOR_UNSPECIFIED value
     * @property {number} GT=1 GT value
     * @property {number} LT=2 LT value
     * @property {number} EQ=3 EQ value
     * @property {number} LIKE=4 LIKE value
     * @property {number} IN=5 IN value
     * @property {number} GE=6 GE value
     * @property {number} LE=7 LE value
     * @property {number} NE=8 NE value
     */
    query.Operator = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OPERATOR_UNSPECIFIED"] = 0;
        values[valuesById[1] = "GT"] = 1;
        values[valuesById[2] = "LT"] = 2;
        values[valuesById[3] = "EQ"] = 3;
        values[valuesById[4] = "LIKE"] = 4;
        values[valuesById[5] = "IN"] = 5;
        values[valuesById[6] = "GE"] = 6;
        values[valuesById[7] = "LE"] = 7;
        values[valuesById[8] = "NE"] = 8;
        return values;
    })();

    query.OrderBy = (function() {

        /**
         * Properties of an OrderBy.
         * @memberof query
         * @interface IOrderBy
         * @property {google.protobuf.IStringValue|null} [columnName] OrderBy columnName
         * @property {query.SortOrder|null} [order] OrderBy order
         */

        /**
         * Constructs a new OrderBy.
         * @memberof query
         * @classdesc Represents an OrderBy.
         * @implements IOrderBy
         * @constructor
         * @param {query.IOrderBy=} [properties] Properties to set
         */
        function OrderBy(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderBy columnName.
         * @member {google.protobuf.IStringValue|null|undefined} columnName
         * @memberof query.OrderBy
         * @instance
         */
        OrderBy.prototype.columnName = null;

        /**
         * OrderBy order.
         * @member {query.SortOrder} order
         * @memberof query.OrderBy
         * @instance
         */
        OrderBy.prototype.order = 0;

        /**
         * Gets the default type url for OrderBy
         * @function getTypeUrl
         * @memberof query.OrderBy
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OrderBy.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.OrderBy";
        };

        return OrderBy;
    })();

    /**
     * SortOrder enum.
     * @name query.SortOrder
     * @enum {number}
     * @property {number} ASC=0 ASC value
     * @property {number} DESC=1 DESC value
     */
    query.SortOrder = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ASC"] = 0;
        values[valuesById[1] = "DESC"] = 1;
        return values;
    })();

    query.PageRequest = (function() {

        /**
         * Properties of a PageRequest.
         * @memberof query
         * @interface IPageRequest
         * @property {google.protobuf.IInt32Value|null} [pageIndex] PageRequest pageIndex
         * @property {google.protobuf.IInt32Value|null} [pageSize] PageRequest pageSize
         */

        /**
         * Constructs a new PageRequest.
         * @memberof query
         * @classdesc Represents a PageRequest.
         * @implements IPageRequest
         * @constructor
         * @param {query.IPageRequest=} [properties] Properties to set
         */
        function PageRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PageRequest pageIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} pageIndex
         * @memberof query.PageRequest
         * @instance
         */
        PageRequest.prototype.pageIndex = null;

        /**
         * PageRequest pageSize.
         * @member {google.protobuf.IInt32Value|null|undefined} pageSize
         * @memberof query.PageRequest
         * @instance
         */
        PageRequest.prototype.pageSize = null;

        /**
         * Gets the default type url for PageRequest
         * @function getTypeUrl
         * @memberof query.PageRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PageRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.PageRequest";
        };

        return PageRequest;
    })();

    query.PageInfo = (function() {

        /**
         * Properties of a PageInfo.
         * @memberof query
         * @interface IPageInfo
         * @property {google.protobuf.IInt32Value|null} [pageIndex] PageInfo pageIndex
         * @property {google.protobuf.IInt32Value|null} [totalPages] PageInfo totalPages
         * @property {google.protobuf.IInt32Value|null} [pageElements] PageInfo pageElements
         * @property {google.protobuf.IInt32Value|null} [totalElements] PageInfo totalElements
         */

        /**
         * Constructs a new PageInfo.
         * @memberof query
         * @classdesc Represents a PageInfo.
         * @implements IPageInfo
         * @constructor
         * @param {query.IPageInfo=} [properties] Properties to set
         */
        function PageInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PageInfo pageIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} pageIndex
         * @memberof query.PageInfo
         * @instance
         */
        PageInfo.prototype.pageIndex = null;

        /**
         * PageInfo totalPages.
         * @member {google.protobuf.IInt32Value|null|undefined} totalPages
         * @memberof query.PageInfo
         * @instance
         */
        PageInfo.prototype.totalPages = null;

        /**
         * PageInfo pageElements.
         * @member {google.protobuf.IInt32Value|null|undefined} pageElements
         * @memberof query.PageInfo
         * @instance
         */
        PageInfo.prototype.pageElements = null;

        /**
         * PageInfo totalElements.
         * @member {google.protobuf.IInt32Value|null|undefined} totalElements
         * @memberof query.PageInfo
         * @instance
         */
        PageInfo.prototype.totalElements = null;

        /**
         * Gets the default type url for PageInfo
         * @function getTypeUrl
         * @memberof query.PageInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PageInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/query.PageInfo";
        };

        return PageInfo;
    })();

    return query;
})();

export const common = $root.common = (() => {

    /**
     * Namespace common.
     * @exports common
     * @namespace
     */
    const common = {};

    /**
     * SupportedCipherAlgorithm enum.
     * @name common.SupportedCipherAlgorithm
     * @enum {number}
     * @property {number} SUPPORTED_CIPHER_ALGORITHM_UNSPECIFIED=0 SUPPORTED_CIPHER_ALGORITHM_UNSPECIFIED value
     * @property {number} AES_GCM_256=1 AES_GCM_256 value
     * @property {number} AES_CTR_256=2 AES_CTR_256 value
     */
    common.SupportedCipherAlgorithm = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SUPPORTED_CIPHER_ALGORITHM_UNSPECIFIED"] = 0;
        values[valuesById[1] = "AES_GCM_256"] = 1;
        values[valuesById[2] = "AES_CTR_256"] = 2;
        return values;
    })();

    common.CiphertextData = (function() {

        /**
         * Properties of a CiphertextData.
         * @memberof common
         * @interface ICiphertextData
         * @property {common.SupportedCipherAlgorithm|null} [algorithm] CiphertextData algorithm
         * @property {google.protobuf.IBytesValue|null} [ciphertext] CiphertextData ciphertext
         * @property {google.protobuf.IBytesValue|null} [initializationVector] CiphertextData initializationVector
         */

        /**
         * Constructs a new CiphertextData.
         * @memberof common
         * @classdesc Represents a CiphertextData.
         * @implements ICiphertextData
         * @constructor
         * @param {common.ICiphertextData=} [properties] Properties to set
         */
        function CiphertextData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CiphertextData algorithm.
         * @member {common.SupportedCipherAlgorithm} algorithm
         * @memberof common.CiphertextData
         * @instance
         */
        CiphertextData.prototype.algorithm = 0;

        /**
         * CiphertextData ciphertext.
         * @member {google.protobuf.IBytesValue|null|undefined} ciphertext
         * @memberof common.CiphertextData
         * @instance
         */
        CiphertextData.prototype.ciphertext = null;

        /**
         * CiphertextData initializationVector.
         * @member {google.protobuf.IBytesValue|null|undefined} initializationVector
         * @memberof common.CiphertextData
         * @instance
         */
        CiphertextData.prototype.initializationVector = null;

        /**
         * Gets the default type url for CiphertextData
         * @function getTypeUrl
         * @memberof common.CiphertextData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CiphertextData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.CiphertextData";
        };

        return CiphertextData;
    })();

    common.Credentials = (function() {

        /**
         * Properties of a Credentials.
         * @memberof common
         * @interface ICredentials
         * @property {google.protobuf.IStringValue|null} [username] Credentials username
         * @property {common.ICiphertextData|null} [encryptedPassword] Credentials encryptedPassword
         * @property {google.protobuf.IStringValue|null} [cleartextPassword] Credentials cleartextPassword
         */

        /**
         * Constructs a new Credentials.
         * @memberof common
         * @classdesc Represents a Credentials.
         * @implements ICredentials
         * @constructor
         * @param {common.ICredentials=} [properties] Properties to set
         */
        function Credentials(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Credentials username.
         * @member {google.protobuf.IStringValue|null|undefined} username
         * @memberof common.Credentials
         * @instance
         */
        Credentials.prototype.username = null;

        /**
         * Credentials encryptedPassword.
         * @member {common.ICiphertextData|null|undefined} encryptedPassword
         * @memberof common.Credentials
         * @instance
         */
        Credentials.prototype.encryptedPassword = null;

        /**
         * Credentials cleartextPassword.
         * @member {google.protobuf.IStringValue|null|undefined} cleartextPassword
         * @memberof common.Credentials
         * @instance
         */
        Credentials.prototype.cleartextPassword = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Credentials password.
         * @member {"encryptedPassword"|"cleartextPassword"|undefined} password
         * @memberof common.Credentials
         * @instance
         */
        Object.defineProperty(Credentials.prototype, "password", {
            get: $util.oneOfGetter($oneOfFields = ["encryptedPassword", "cleartextPassword"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for Credentials
         * @function getTypeUrl
         * @memberof common.Credentials
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Credentials.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.Credentials";
        };

        return Credentials;
    })();

    common.FtpAccount = (function() {

        /**
         * Properties of a FtpAccount.
         * @memberof common
         * @interface IFtpAccount
         * @property {google.protobuf.IStringValue|null} [hostname] FtpAccount hostname
         * @property {common.ICredentials|null} [credentials] FtpAccount credentials
         * @property {google.protobuf.IStringValue|null} [directory] FtpAccount directory
         */

        /**
         * Constructs a new FtpAccount.
         * @memberof common
         * @classdesc Represents a FtpAccount.
         * @implements IFtpAccount
         * @constructor
         * @param {common.IFtpAccount=} [properties] Properties to set
         */
        function FtpAccount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FtpAccount hostname.
         * @member {google.protobuf.IStringValue|null|undefined} hostname
         * @memberof common.FtpAccount
         * @instance
         */
        FtpAccount.prototype.hostname = null;

        /**
         * FtpAccount credentials.
         * @member {common.ICredentials|null|undefined} credentials
         * @memberof common.FtpAccount
         * @instance
         */
        FtpAccount.prototype.credentials = null;

        /**
         * FtpAccount directory.
         * @member {google.protobuf.IStringValue|null|undefined} directory
         * @memberof common.FtpAccount
         * @instance
         */
        FtpAccount.prototype.directory = null;

        /**
         * Gets the default type url for FtpAccount
         * @function getTypeUrl
         * @memberof common.FtpAccount
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FtpAccount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.FtpAccount";
        };

        return FtpAccount;
    })();

    common.ExternalFtpAccount = (function() {

        /**
         * Properties of an ExternalFtpAccount.
         * @memberof common
         * @interface IExternalFtpAccount
         * @property {google.protobuf.IInt32Value|null} [id] ExternalFtpAccount id
         * @property {google.protobuf.IStringValue|null} [senderEic] ExternalFtpAccount senderEic
         * @property {base.Role|null} [senderRole] ExternalFtpAccount senderRole
         * @property {google.protobuf.IStringValue|null} [hostname] ExternalFtpAccount hostname
         * @property {google.protobuf.IStringValue|null} [directory] ExternalFtpAccount directory
         * @property {google.protobuf.IStringValue|null} [username] ExternalFtpAccount username
         * @property {google.protobuf.IStringValue|null} [password] ExternalFtpAccount password
         */

        /**
         * Constructs a new ExternalFtpAccount.
         * @memberof common
         * @classdesc Represents an ExternalFtpAccount.
         * @implements IExternalFtpAccount
         * @constructor
         * @param {common.IExternalFtpAccount=} [properties] Properties to set
         */
        function ExternalFtpAccount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExternalFtpAccount id.
         * @member {google.protobuf.IInt32Value|null|undefined} id
         * @memberof common.ExternalFtpAccount
         * @instance
         */
        ExternalFtpAccount.prototype.id = null;

        /**
         * ExternalFtpAccount senderEic.
         * @member {google.protobuf.IStringValue|null|undefined} senderEic
         * @memberof common.ExternalFtpAccount
         * @instance
         */
        ExternalFtpAccount.prototype.senderEic = null;

        /**
         * ExternalFtpAccount senderRole.
         * @member {base.Role} senderRole
         * @memberof common.ExternalFtpAccount
         * @instance
         */
        ExternalFtpAccount.prototype.senderRole = 0;

        /**
         * ExternalFtpAccount hostname.
         * @member {google.protobuf.IStringValue|null|undefined} hostname
         * @memberof common.ExternalFtpAccount
         * @instance
         */
        ExternalFtpAccount.prototype.hostname = null;

        /**
         * ExternalFtpAccount directory.
         * @member {google.protobuf.IStringValue|null|undefined} directory
         * @memberof common.ExternalFtpAccount
         * @instance
         */
        ExternalFtpAccount.prototype.directory = null;

        /**
         * ExternalFtpAccount username.
         * @member {google.protobuf.IStringValue|null|undefined} username
         * @memberof common.ExternalFtpAccount
         * @instance
         */
        ExternalFtpAccount.prototype.username = null;

        /**
         * ExternalFtpAccount password.
         * @member {google.protobuf.IStringValue|null|undefined} password
         * @memberof common.ExternalFtpAccount
         * @instance
         */
        ExternalFtpAccount.prototype.password = null;

        /**
         * Gets the default type url for ExternalFtpAccount
         * @function getTypeUrl
         * @memberof common.ExternalFtpAccount
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExternalFtpAccount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.ExternalFtpAccount";
        };

        return ExternalFtpAccount;
    })();

    common.MetadataMessage = (function() {

        /**
         * Properties of a MetadataMessage.
         * @memberof common
         * @interface IMetadataMessage
         * @property {google.protobuf.IStringValue|null} [uuid] MetadataMessage uuid
         * @property {google.protobuf.IInt64Value|null} [timestamp] MetadataMessage timestamp
         * @property {google.protobuf.IStringValue|null} [sender] MetadataMessage sender
         * @property {google.protobuf.IStringValue|null} [originJwt] MetadataMessage originJwt
         * @property {google.protobuf.IStringValue|null} [recipient] MetadataMessage recipient
         * @property {google.protobuf.IStringValue|null} [originalFilename] MetadataMessage originalFilename
         * @property {google.protobuf.IStringValue|null} [encryptionMode] MetadataMessage encryptionMode
         * @property {google.protobuf.IBytesValue|null} [key] MetadataMessage key
         * @property {google.protobuf.IBytesValue|null} [iv] MetadataMessage iv
         */

        /**
         * Constructs a new MetadataMessage.
         * @memberof common
         * @classdesc Represents a MetadataMessage.
         * @implements IMetadataMessage
         * @constructor
         * @param {common.IMetadataMessage=} [properties] Properties to set
         */
        function MetadataMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MetadataMessage uuid.
         * @member {google.protobuf.IStringValue|null|undefined} uuid
         * @memberof common.MetadataMessage
         * @instance
         */
        MetadataMessage.prototype.uuid = null;

        /**
         * MetadataMessage timestamp.
         * @member {google.protobuf.IInt64Value|null|undefined} timestamp
         * @memberof common.MetadataMessage
         * @instance
         */
        MetadataMessage.prototype.timestamp = null;

        /**
         * MetadataMessage sender.
         * @member {google.protobuf.IStringValue|null|undefined} sender
         * @memberof common.MetadataMessage
         * @instance
         */
        MetadataMessage.prototype.sender = null;

        /**
         * MetadataMessage originJwt.
         * @member {google.protobuf.IStringValue|null|undefined} originJwt
         * @memberof common.MetadataMessage
         * @instance
         */
        MetadataMessage.prototype.originJwt = null;

        /**
         * MetadataMessage recipient.
         * @member {google.protobuf.IStringValue|null|undefined} recipient
         * @memberof common.MetadataMessage
         * @instance
         */
        MetadataMessage.prototype.recipient = null;

        /**
         * MetadataMessage originalFilename.
         * @member {google.protobuf.IStringValue|null|undefined} originalFilename
         * @memberof common.MetadataMessage
         * @instance
         */
        MetadataMessage.prototype.originalFilename = null;

        /**
         * MetadataMessage encryptionMode.
         * @member {google.protobuf.IStringValue|null|undefined} encryptionMode
         * @memberof common.MetadataMessage
         * @instance
         */
        MetadataMessage.prototype.encryptionMode = null;

        /**
         * MetadataMessage key.
         * @member {google.protobuf.IBytesValue|null|undefined} key
         * @memberof common.MetadataMessage
         * @instance
         */
        MetadataMessage.prototype.key = null;

        /**
         * MetadataMessage iv.
         * @member {google.protobuf.IBytesValue|null|undefined} iv
         * @memberof common.MetadataMessage
         * @instance
         */
        MetadataMessage.prototype.iv = null;

        /**
         * Gets the default type url for MetadataMessage
         * @function getTypeUrl
         * @memberof common.MetadataMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MetadataMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/common.MetadataMessage";
        };

        return MetadataMessage;
    })();

    return common;
})();

export const sdat = $root.sdat = (() => {

    /**
     * Namespace sdat.
     * @exports sdat
     * @namespace
     */
    const sdat = {};

    /**
     * SdatMessageType enum.
     * @name sdat.SdatMessageType
     * @enum {number}
     * @property {number} SDAT_MESSAGE_TYPE_UNSPECIFIED=0 SDAT_MESSAGE_TYPE_UNSPECIFIED value
     * @property {number} ACKNOWLEDGEMENT_OF_ACCEPTANCE=1 ACKNOWLEDGEMENT_OF_ACCEPTANCE value
     * @property {number} MODEL_ERROR_REPORT=2 MODEL_ERROR_REPORT value
     * @property {number} MP_ROLE_CHANGE_REQUEST=3 MP_ROLE_CHANGE_REQUEST value
     * @property {number} MP_ROLE_CHANGE_RESPONSE=4 MP_ROLE_CHANGE_RESPONSE value
     * @property {number} NOTIFICATION_FROM_IMBALANCE_SETTLEMENT_RESPONSIBLE=5 NOTIFICATION_FROM_IMBALANCE_SETTLEMENT_RESPONSIBLE value
     * @property {number} NOTIFICATION_FROM_METERED_DATA_AGGREGATOR=6 NOTIFICATION_FROM_METERED_DATA_AGGREGATOR value
     * @property {number} QUERY_INFORMATION=7 QUERY_INFORMATION value
     * @property {number} MASTERDATA_SUPPLY_CONTRACT=8 MASTERDATA_SUPPLY_CONTRACT value
     * @property {number} E_INVOICE_INVOICE=9 E_INVOICE_INVOICE value
     * @property {number} E_INVOICE_CORRECTION=10 E_INVOICE_CORRECTION value
     * @property {number} E_INVOICE_ACCEPTANCE=11 E_INVOICE_ACCEPTANCE value
     * @property {number} E_INVOICE_REJECTION=12 E_INVOICE_REJECTION value
     * @property {number} MASTERDATA_METERING_POINT=13 MASTERDATA_METERING_POINT value
     * @property {number} MASTERDATA_PARTY_CONNECTED_TO_THE_GRID=14 MASTERDATA_PARTY_CONNECTED_TO_THE_GRID value
     * @property {number} AGGREGATED_METERED_DATA=15 AGGREGATED_METERED_DATA value
     * @property {number} MP_ROLE_CHANGE_NOTIFICATION=16 MP_ROLE_CHANGE_NOTIFICATION value
     * @property {number} VALIDATED_METERED_DATA=17 VALIDATED_METERED_DATA value
     * @property {number} PROCESS_CANCELLATION_REQUEST=18 PROCESS_CANCELLATION_REQUEST value
     * @property {number} PROCESS_CANCELLATION_RESPONSE=19 PROCESS_CANCELLATION_RESPONSE value
     * @property {number} MP_REGISTRATION_OR_DEREGISTRATION=20 MP_REGISTRATION_OR_DEREGISTRATION value
     * @property {number} MP_ROLE_CHANGE_DATAHUB_NOTIFICATION=21 MP_ROLE_CHANGE_DATAHUB_NOTIFICATION value
     * @property {number} NON_SDAT=99 NON_SDAT value
     */
    sdat.SdatMessageType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SDAT_MESSAGE_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "ACKNOWLEDGEMENT_OF_ACCEPTANCE"] = 1;
        values[valuesById[2] = "MODEL_ERROR_REPORT"] = 2;
        values[valuesById[3] = "MP_ROLE_CHANGE_REQUEST"] = 3;
        values[valuesById[4] = "MP_ROLE_CHANGE_RESPONSE"] = 4;
        values[valuesById[5] = "NOTIFICATION_FROM_IMBALANCE_SETTLEMENT_RESPONSIBLE"] = 5;
        values[valuesById[6] = "NOTIFICATION_FROM_METERED_DATA_AGGREGATOR"] = 6;
        values[valuesById[7] = "QUERY_INFORMATION"] = 7;
        values[valuesById[8] = "MASTERDATA_SUPPLY_CONTRACT"] = 8;
        values[valuesById[9] = "E_INVOICE_INVOICE"] = 9;
        values[valuesById[10] = "E_INVOICE_CORRECTION"] = 10;
        values[valuesById[11] = "E_INVOICE_ACCEPTANCE"] = 11;
        values[valuesById[12] = "E_INVOICE_REJECTION"] = 12;
        values[valuesById[13] = "MASTERDATA_METERING_POINT"] = 13;
        values[valuesById[14] = "MASTERDATA_PARTY_CONNECTED_TO_THE_GRID"] = 14;
        values[valuesById[15] = "AGGREGATED_METERED_DATA"] = 15;
        values[valuesById[16] = "MP_ROLE_CHANGE_NOTIFICATION"] = 16;
        values[valuesById[17] = "VALIDATED_METERED_DATA"] = 17;
        values[valuesById[18] = "PROCESS_CANCELLATION_REQUEST"] = 18;
        values[valuesById[19] = "PROCESS_CANCELLATION_RESPONSE"] = 19;
        values[valuesById[20] = "MP_REGISTRATION_OR_DEREGISTRATION"] = 20;
        values[valuesById[21] = "MP_ROLE_CHANGE_DATAHUB_NOTIFICATION"] = 21;
        values[valuesById[99] = "NON_SDAT"] = 99;
        return values;
    })();

    /**
     * MessageClass enum.
     * @name sdat.MessageClass
     * @enum {number}
     * @property {number} MESSAGE_CLASS_UNSPECIFIED=0 MESSAGE_CLASS_UNSPECIFIED value
     * @property {number} CONFIRMED=3 CONFIRMED value
     * @property {number} REJECTED=4 REJECTED value
     * @property {number} INFO=5 INFO value
     * @property {number} REQUEST=6 REQUEST value
     * @property {number} DOCUMENT_CANCELLED=7 DOCUMENT_CANCELLED value
     * @property {number} PROCESS_CANCELLATION_REQUESTED=8 PROCESS_CANCELLATION_REQUESTED value
     * @property {number} PROCESS_CANCELLATION_CONFIRMED=9 PROCESS_CANCELLATION_CONFIRMED value
     * @property {number} PROCESS_CANCELLATION_REJECTED=10 PROCESS_CANCELLATION_REJECTED value
     */
    sdat.MessageClass = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MESSAGE_CLASS_UNSPECIFIED"] = 0;
        values[valuesById[3] = "CONFIRMED"] = 3;
        values[valuesById[4] = "REJECTED"] = 4;
        values[valuesById[5] = "INFO"] = 5;
        values[valuesById[6] = "REQUEST"] = 6;
        values[valuesById[7] = "DOCUMENT_CANCELLED"] = 7;
        values[valuesById[8] = "PROCESS_CANCELLATION_REQUESTED"] = 8;
        values[valuesById[9] = "PROCESS_CANCELLATION_CONFIRMED"] = 9;
        values[valuesById[10] = "PROCESS_CANCELLATION_REJECTED"] = 10;
        return values;
    })();

    /**
     * BusinessReason enum.
     * @name sdat.BusinessReason
     * @enum {number}
     * @property {number} BUSINESS_REASON_UNSPECIFIED=0 BUSINESS_REASON_UNSPECIFIED value
     * @property {number} SUPPLIER_CHANGE=1 SUPPLIER_CHANGE value
     * @property {number} SUPPLIER_END=2 SUPPLIER_END value
     * @property {number} CONSUMER_CHANGE=3 CONSUMER_CHANGE value
     * @property {number} CONSUMER_END=4 CONSUMER_END value
     * @property {number} ASP_START=5 ASP_START value
     * @property {number} ASP_END=6 ASP_END value
     * @property {number} BASE_SUPPLY_REGISTRATION_BASE=7 BASE_SUPPLY_REGISTRATION_BASE value
     * @property {number} BASE_SUPPLY_REGISTRATION_SUBSTITUTE=8 BASE_SUPPLY_REGISTRATION_SUBSTITUTE value
     * @property {number} CONSUMER_MASTER_DATA_CHANGE=9 CONSUMER_MASTER_DATA_CHANGE value
     * @property {number} MP_MASTER_DATA_CHANGE=10 MP_MASTER_DATA_CHANGE value
     * @property {number} CHECK_INFORMATION=11 CHECK_INFORMATION value
     * @property {number} QUERY_SWITCH_INFORMATION=12 QUERY_SWITCH_INFORMATION value
     * @property {number} QUERY_MEASUREMENTS=13 QUERY_MEASUREMENTS value
     * @property {number} QUERY_MEASUREMENT_AGGREGATES=14 QUERY_MEASUREMENT_AGGREGATES value
     * @property {number} QUERY_METERING_POINT_INFORMATION=15 QUERY_METERING_POINT_INFORMATION value
     * @property {number} CANCELLATION=51 CANCELLATION value
     * @property {number} MP_REGISTRATION=101 MP_REGISTRATION value
     * @property {number} MP_DEREGISTRATION=102 MP_DEREGISTRATION value
     * @property {number} MP_DATA_CHANGE=103 MP_DATA_CHANGE value
     * @property {number} COST_ALLOCATION=16 COST_ALLOCATION value
     * @property {number} COST_SHIFTING=17 COST_SHIFTING value
     * @property {number} ENERGY_ORDER=18 ENERGY_ORDER value
     * @property {number} METERED_DATA_OSTRAL_PS=19 METERED_DATA_OSTRAL_PS value
     * @property {number} ANCILLARY_SERVICE_FOR_CRF=20 ANCILLARY_SERVICE_FOR_CRF value
     * @property {number} LABELLING_ENERGY=21 LABELLING_ENERGY value
     * @property {number} IMBALANCE_SETTLEMENT=22 IMBALANCE_SETTLEMENT value
     * @property {number} BILLING_ENERGY=23 BILLING_ENERGY value
     * @property {number} BILLING_GRID_COST=24 BILLING_GRID_COST value
     */
    sdat.BusinessReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BUSINESS_REASON_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SUPPLIER_CHANGE"] = 1;
        values[valuesById[2] = "SUPPLIER_END"] = 2;
        values[valuesById[3] = "CONSUMER_CHANGE"] = 3;
        values[valuesById[4] = "CONSUMER_END"] = 4;
        values[valuesById[5] = "ASP_START"] = 5;
        values[valuesById[6] = "ASP_END"] = 6;
        values[valuesById[7] = "BASE_SUPPLY_REGISTRATION_BASE"] = 7;
        values[valuesById[8] = "BASE_SUPPLY_REGISTRATION_SUBSTITUTE"] = 8;
        values[valuesById[9] = "CONSUMER_MASTER_DATA_CHANGE"] = 9;
        values[valuesById[10] = "MP_MASTER_DATA_CHANGE"] = 10;
        values[valuesById[11] = "CHECK_INFORMATION"] = 11;
        values[valuesById[12] = "QUERY_SWITCH_INFORMATION"] = 12;
        values[valuesById[13] = "QUERY_MEASUREMENTS"] = 13;
        values[valuesById[14] = "QUERY_MEASUREMENT_AGGREGATES"] = 14;
        values[valuesById[15] = "QUERY_METERING_POINT_INFORMATION"] = 15;
        values[valuesById[51] = "CANCELLATION"] = 51;
        values[valuesById[101] = "MP_REGISTRATION"] = 101;
        values[valuesById[102] = "MP_DEREGISTRATION"] = 102;
        values[valuesById[103] = "MP_DATA_CHANGE"] = 103;
        values[valuesById[16] = "COST_ALLOCATION"] = 16;
        values[valuesById[17] = "COST_SHIFTING"] = 17;
        values[valuesById[18] = "ENERGY_ORDER"] = 18;
        values[valuesById[19] = "METERED_DATA_OSTRAL_PS"] = 19;
        values[valuesById[20] = "ANCILLARY_SERVICE_FOR_CRF"] = 20;
        values[valuesById[21] = "LABELLING_ENERGY"] = 21;
        values[valuesById[22] = "IMBALANCE_SETTLEMENT"] = 22;
        values[valuesById[23] = "BILLING_ENERGY"] = 23;
        values[valuesById[24] = "BILLING_GRID_COST"] = 24;
        return values;
    })();

    /**
     * BusinessRole enum.
     * @name sdat.BusinessRole
     * @enum {number}
     * @property {number} BUSINESS_ROLE_UNSPECIFIED=0 BUSINESS_ROLE_UNSPECIFIED value
     * @property {number} BALANCE_RESPONSIBLE=1 BALANCE_RESPONSIBLE value
     * @property {number} GRID_ACCESS_PROVIDER=2 GRID_ACCESS_PROVIDER value
     * @property {number} BALANCE_SUPPLIER=3 BALANCE_SUPPLIER value
     * @property {number} IMBALANCE_SETTLEMENT_RESPONSIBLE=4 IMBALANCE_SETTLEMENT_RESPONSIBLE value
     * @property {number} METERING_POINT_ADMINISTRATOR=5 METERING_POINT_ADMINISTRATOR value
     * @property {number} LOCAL_METERED_DATA_AGGREGATOR=6 LOCAL_METERED_DATA_AGGREGATOR value
     * @property {number} PARTY_CONNECTED_TO_THE_GRID=7 PARTY_CONNECTED_TO_THE_GRID value
     * @property {number} PRODUCER=8 PRODUCER value
     * @property {number} METERED_DATA_RESPONSIBLE=9 METERED_DATA_RESPONSIBLE value
     * @property {number} CERTIFYING_PARTY=10 CERTIFYING_PARTY value
     * @property {number} CONSUMER=11 CONSUMER value
     * @property {number} ANCILLARY_SERVICE_PROVIDER=12 ANCILLARY_SERVICE_PROVIDER value
     * @property {number} CENTRAL_METERED_DATA_AGGREGATOR=13 CENTRAL_METERED_DATA_AGGREGATOR value
     * @property {number} RECONCILIATION_RESPONSIBLE=14 RECONCILIATION_RESPONSIBLE value
     * @property {number} RECONCILIATION_ACCOUNTABLE=15 RECONCILIATION_ACCOUNTABLE value
     * @property {number} BILLING_AGENT=16 BILLING_AGENT value
     * @property {number} SWISSELDEX_DATAHUB=101 SWISSELDEX_DATAHUB value
     */
    sdat.BusinessRole = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BUSINESS_ROLE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "BALANCE_RESPONSIBLE"] = 1;
        values[valuesById[2] = "GRID_ACCESS_PROVIDER"] = 2;
        values[valuesById[3] = "BALANCE_SUPPLIER"] = 3;
        values[valuesById[4] = "IMBALANCE_SETTLEMENT_RESPONSIBLE"] = 4;
        values[valuesById[5] = "METERING_POINT_ADMINISTRATOR"] = 5;
        values[valuesById[6] = "LOCAL_METERED_DATA_AGGREGATOR"] = 6;
        values[valuesById[7] = "PARTY_CONNECTED_TO_THE_GRID"] = 7;
        values[valuesById[8] = "PRODUCER"] = 8;
        values[valuesById[9] = "METERED_DATA_RESPONSIBLE"] = 9;
        values[valuesById[10] = "CERTIFYING_PARTY"] = 10;
        values[valuesById[11] = "CONSUMER"] = 11;
        values[valuesById[12] = "ANCILLARY_SERVICE_PROVIDER"] = 12;
        values[valuesById[13] = "CENTRAL_METERED_DATA_AGGREGATOR"] = 13;
        values[valuesById[14] = "RECONCILIATION_RESPONSIBLE"] = 14;
        values[valuesById[15] = "RECONCILIATION_ACCOUNTABLE"] = 15;
        values[valuesById[16] = "BILLING_AGENT"] = 16;
        values[valuesById[101] = "SWISSELDEX_DATAHUB"] = 101;
        return values;
    })();

    sdat.Party = (function() {

        /**
         * Properties of a Party.
         * @memberof sdat
         * @interface IParty
         * @property {google.protobuf.IStringValue|null} [eic] Party eic
         * @property {sdat.BusinessRole|null} [role] Party role
         */

        /**
         * Constructs a new Party.
         * @memberof sdat
         * @classdesc Represents a Party.
         * @implements IParty
         * @constructor
         * @param {sdat.IParty=} [properties] Properties to set
         */
        function Party(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Party eic.
         * @member {google.protobuf.IStringValue|null|undefined} eic
         * @memberof sdat.Party
         * @instance
         */
        Party.prototype.eic = null;

        /**
         * Party role.
         * @member {sdat.BusinessRole} role
         * @memberof sdat.Party
         * @instance
         */
        Party.prototype.role = 0;

        /**
         * Gets the default type url for Party
         * @function getTypeUrl
         * @memberof sdat.Party
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Party.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sdat.Party";
        };

        return Party;
    })();

    sdat.SdatMessageReference = (function() {

        /**
         * Properties of a SdatMessageReference.
         * @memberof sdat
         * @interface ISdatMessageReference
         * @property {google.protobuf.IStringValue|null} [messageId] SdatMessageReference messageId
         * @property {google.protobuf.ITimestamp|null} [creation] SdatMessageReference creation
         * @property {sdat.SdatMessageType|null} [type] SdatMessageReference type
         */

        /**
         * Constructs a new SdatMessageReference.
         * @memberof sdat
         * @classdesc Represents a SdatMessageReference.
         * @implements ISdatMessageReference
         * @constructor
         * @param {sdat.ISdatMessageReference=} [properties] Properties to set
         */
        function SdatMessageReference(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SdatMessageReference messageId.
         * @member {google.protobuf.IStringValue|null|undefined} messageId
         * @memberof sdat.SdatMessageReference
         * @instance
         */
        SdatMessageReference.prototype.messageId = null;

        /**
         * SdatMessageReference creation.
         * @member {google.protobuf.ITimestamp|null|undefined} creation
         * @memberof sdat.SdatMessageReference
         * @instance
         */
        SdatMessageReference.prototype.creation = null;

        /**
         * SdatMessageReference type.
         * @member {sdat.SdatMessageType} type
         * @memberof sdat.SdatMessageReference
         * @instance
         */
        SdatMessageReference.prototype.type = 0;

        /**
         * Gets the default type url for SdatMessageReference
         * @function getTypeUrl
         * @memberof sdat.SdatMessageReference
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SdatMessageReference.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sdat.SdatMessageReference";
        };

        return SdatMessageReference;
    })();

    /**
     * GridBillingMethod enum.
     * @name sdat.GridBillingMethod
     * @enum {number}
     * @property {number} GRID_BILLING_METHOD_UNSPECIFIED=0 GRID_BILLING_METHOD_UNSPECIFIED value
     * @property {number} DSO=1 DSO value
     * @property {number} SUPPLIER=2 SUPPLIER value
     */
    sdat.GridBillingMethod = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "GRID_BILLING_METHOD_UNSPECIFIED"] = 0;
        values[valuesById[1] = "DSO"] = 1;
        values[valuesById[2] = "SUPPLIER"] = 2;
        return values;
    })();

    /**
     * RejectionReason enum.
     * @name sdat.RejectionReason
     * @enum {number}
     * @property {number} REJECTION_REASON_UNSPECIFIED=0 REJECTION_REASON_UNSPECIFIED value
     * @property {number} UNAUTHORISED_GRID_ACCESS_PROVIDER=8 UNAUTHORISED_GRID_ACCESS_PROVIDER value
     * @property {number} UNAUTHORISED_PARTY_CONNECTED_TO_THE_GRID=9 UNAUTHORISED_PARTY_CONNECTED_TO_THE_GRID value
     * @property {number} METERING_POINT_NOT_IDENTIFIABLE=10 METERING_POINT_NOT_IDENTIFIABLE value
     * @property {number} METERING_POINT_IN_BASIC_SUPPLY_WITHOUT_VALID_GRID_ACCESS=11 METERING_POINT_IN_BASIC_SUPPLY_WITHOUT_VALID_GRID_ACCESS value
     * @property {number} OTHER_REASON=14 OTHER_REASON value
     * @property {number} NO_CORRECTIONS=15 NO_CORRECTIONS value
     * @property {number} UNAUTHORISED_BALANCE_SUPPLIER=16 UNAUTHORISED_BALANCE_SUPPLIER value
     * @property {number} DATE_NOT_WITHIN_TIME_LIMITS=17 DATE_NOT_WITHIN_TIME_LIMITS value
     * @property {number} UNAUTHORISED_BALANCE_RESPONSIBLE=18 UNAUTHORISED_BALANCE_RESPONSIBLE value
     * @property {number} ALREADY_A_CHANGE_OF_BALANCE_SUPPLIER_IN_FUTURE_EXISTING=20 ALREADY_A_CHANGE_OF_BALANCE_SUPPLIER_IN_FUTURE_EXISTING value
     * @property {number} METERING_POINT_BLOCKED_FOR_SWITCHING=22 METERING_POINT_BLOCKED_FOR_SWITCHING value
     * @property {number} NO_VALID_COLLABORATION=36 NO_VALID_COLLABORATION value
     * @property {number} NO_VALID_GRID_ACCESS_CONTRACT=37 NO_VALID_GRID_ACCESS_CONTRACT value
     * @property {number} NO_ONGOING_SWITCH_FOR_MP=47 NO_ONGOING_SWITCH_FOR_MP value
     * @property {number} ALREADY_EXISTING_RELATION=59 ALREADY_EXISTING_RELATION value
     * @property {number} UNAUTHORISED_ANCILLARY_SERVICE_PROVIDER=110 UNAUTHORISED_ANCILLARY_SERVICE_PROVIDER value
     */
    sdat.RejectionReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REJECTION_REASON_UNSPECIFIED"] = 0;
        values[valuesById[8] = "UNAUTHORISED_GRID_ACCESS_PROVIDER"] = 8;
        values[valuesById[9] = "UNAUTHORISED_PARTY_CONNECTED_TO_THE_GRID"] = 9;
        values[valuesById[10] = "METERING_POINT_NOT_IDENTIFIABLE"] = 10;
        values[valuesById[11] = "METERING_POINT_IN_BASIC_SUPPLY_WITHOUT_VALID_GRID_ACCESS"] = 11;
        values[valuesById[14] = "OTHER_REASON"] = 14;
        values[valuesById[15] = "NO_CORRECTIONS"] = 15;
        values[valuesById[16] = "UNAUTHORISED_BALANCE_SUPPLIER"] = 16;
        values[valuesById[17] = "DATE_NOT_WITHIN_TIME_LIMITS"] = 17;
        values[valuesById[18] = "UNAUTHORISED_BALANCE_RESPONSIBLE"] = 18;
        values[valuesById[20] = "ALREADY_A_CHANGE_OF_BALANCE_SUPPLIER_IN_FUTURE_EXISTING"] = 20;
        values[valuesById[22] = "METERING_POINT_BLOCKED_FOR_SWITCHING"] = 22;
        values[valuesById[36] = "NO_VALID_COLLABORATION"] = 36;
        values[valuesById[37] = "NO_VALID_GRID_ACCESS_CONTRACT"] = 37;
        values[valuesById[47] = "NO_ONGOING_SWITCH_FOR_MP"] = 47;
        values[valuesById[59] = "ALREADY_EXISTING_RELATION"] = 59;
        values[valuesById[110] = "UNAUTHORISED_ANCILLARY_SERVICE_PROVIDER"] = 110;
        return values;
    })();

    return sdat;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.DoubleValue = (function() {

            /**
             * Properties of a DoubleValue.
             * @memberof google.protobuf
             * @interface IDoubleValue
             * @property {number|null} [value] DoubleValue value
             */

            /**
             * Constructs a new DoubleValue.
             * @memberof google.protobuf
             * @classdesc Represents a DoubleValue.
             * @implements IDoubleValue
             * @constructor
             * @param {google.protobuf.IDoubleValue=} [properties] Properties to set
             */
            function DoubleValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DoubleValue value.
             * @member {number} value
             * @memberof google.protobuf.DoubleValue
             * @instance
             */
            DoubleValue.prototype.value = 0;

            /**
             * Gets the default type url for DoubleValue
             * @function getTypeUrl
             * @memberof google.protobuf.DoubleValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DoubleValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.DoubleValue";
            };

            return DoubleValue;
        })();

        protobuf.FloatValue = (function() {

            /**
             * Properties of a FloatValue.
             * @memberof google.protobuf
             * @interface IFloatValue
             * @property {number|null} [value] FloatValue value
             */

            /**
             * Constructs a new FloatValue.
             * @memberof google.protobuf
             * @classdesc Represents a FloatValue.
             * @implements IFloatValue
             * @constructor
             * @param {google.protobuf.IFloatValue=} [properties] Properties to set
             */
            function FloatValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FloatValue value.
             * @member {number} value
             * @memberof google.protobuf.FloatValue
             * @instance
             */
            FloatValue.prototype.value = 0;

            /**
             * Gets the default type url for FloatValue
             * @function getTypeUrl
             * @memberof google.protobuf.FloatValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FloatValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.FloatValue";
            };

            return FloatValue;
        })();

        protobuf.Int64Value = (function() {

            /**
             * Properties of an Int64Value.
             * @memberof google.protobuf
             * @interface IInt64Value
             * @property {number|null} [value] Int64Value value
             */

            /**
             * Constructs a new Int64Value.
             * @memberof google.protobuf
             * @classdesc Represents an Int64Value.
             * @implements IInt64Value
             * @constructor
             * @param {google.protobuf.IInt64Value=} [properties] Properties to set
             */
            function Int64Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Int64Value value.
             * @member {number} value
             * @memberof google.protobuf.Int64Value
             * @instance
             */
            Int64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Gets the default type url for Int64Value
             * @function getTypeUrl
             * @memberof google.protobuf.Int64Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Int64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int64Value";
            };

            return Int64Value;
        })();

        protobuf.UInt64Value = (function() {

            /**
             * Properties of a UInt64Value.
             * @memberof google.protobuf
             * @interface IUInt64Value
             * @property {number|null} [value] UInt64Value value
             */

            /**
             * Constructs a new UInt64Value.
             * @memberof google.protobuf
             * @classdesc Represents a UInt64Value.
             * @implements IUInt64Value
             * @constructor
             * @param {google.protobuf.IUInt64Value=} [properties] Properties to set
             */
            function UInt64Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UInt64Value value.
             * @member {number} value
             * @memberof google.protobuf.UInt64Value
             * @instance
             */
            UInt64Value.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Gets the default type url for UInt64Value
             * @function getTypeUrl
             * @memberof google.protobuf.UInt64Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UInt64Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt64Value";
            };

            return UInt64Value;
        })();

        protobuf.Int32Value = (function() {

            /**
             * Properties of an Int32Value.
             * @memberof google.protobuf
             * @interface IInt32Value
             * @property {number|null} [value] Int32Value value
             */

            /**
             * Constructs a new Int32Value.
             * @memberof google.protobuf
             * @classdesc Represents an Int32Value.
             * @implements IInt32Value
             * @constructor
             * @param {google.protobuf.IInt32Value=} [properties] Properties to set
             */
            function Int32Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Int32Value value.
             * @member {number} value
             * @memberof google.protobuf.Int32Value
             * @instance
             */
            Int32Value.prototype.value = 0;

            /**
             * Gets the default type url for Int32Value
             * @function getTypeUrl
             * @memberof google.protobuf.Int32Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Int32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Int32Value";
            };

            return Int32Value;
        })();

        protobuf.UInt32Value = (function() {

            /**
             * Properties of a UInt32Value.
             * @memberof google.protobuf
             * @interface IUInt32Value
             * @property {number|null} [value] UInt32Value value
             */

            /**
             * Constructs a new UInt32Value.
             * @memberof google.protobuf
             * @classdesc Represents a UInt32Value.
             * @implements IUInt32Value
             * @constructor
             * @param {google.protobuf.IUInt32Value=} [properties] Properties to set
             */
            function UInt32Value(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UInt32Value value.
             * @member {number} value
             * @memberof google.protobuf.UInt32Value
             * @instance
             */
            UInt32Value.prototype.value = 0;

            /**
             * Gets the default type url for UInt32Value
             * @function getTypeUrl
             * @memberof google.protobuf.UInt32Value
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UInt32Value.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.UInt32Value";
            };

            return UInt32Value;
        })();

        protobuf.BoolValue = (function() {

            /**
             * Properties of a BoolValue.
             * @memberof google.protobuf
             * @interface IBoolValue
             * @property {boolean|null} [value] BoolValue value
             */

            /**
             * Constructs a new BoolValue.
             * @memberof google.protobuf
             * @classdesc Represents a BoolValue.
             * @implements IBoolValue
             * @constructor
             * @param {google.protobuf.IBoolValue=} [properties] Properties to set
             */
            function BoolValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BoolValue value.
             * @member {boolean} value
             * @memberof google.protobuf.BoolValue
             * @instance
             */
            BoolValue.prototype.value = false;

            /**
             * Gets the default type url for BoolValue
             * @function getTypeUrl
             * @memberof google.protobuf.BoolValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BoolValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BoolValue";
            };

            return BoolValue;
        })();

        protobuf.StringValue = (function() {

            /**
             * Properties of a StringValue.
             * @memberof google.protobuf
             * @interface IStringValue
             * @property {string|null} [value] StringValue value
             */

            /**
             * Constructs a new StringValue.
             * @memberof google.protobuf
             * @classdesc Represents a StringValue.
             * @implements IStringValue
             * @constructor
             * @param {google.protobuf.IStringValue=} [properties] Properties to set
             */
            function StringValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StringValue value.
             * @member {string} value
             * @memberof google.protobuf.StringValue
             * @instance
             */
            StringValue.prototype.value = "";

            /**
             * Gets the default type url for StringValue
             * @function getTypeUrl
             * @memberof google.protobuf.StringValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StringValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.StringValue";
            };

            return StringValue;
        })();

        protobuf.BytesValue = (function() {

            /**
             * Properties of a BytesValue.
             * @memberof google.protobuf
             * @interface IBytesValue
             * @property {Uint8Array|null} [value] BytesValue value
             */

            /**
             * Constructs a new BytesValue.
             * @memberof google.protobuf
             * @classdesc Represents a BytesValue.
             * @implements IBytesValue
             * @constructor
             * @param {google.protobuf.IBytesValue=} [properties] Properties to set
             */
            function BytesValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BytesValue value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.BytesValue
             * @instance
             */
            BytesValue.prototype.value = $util.newBuffer([]);

            /**
             * Gets the default type url for BytesValue
             * @function getTypeUrl
             * @memberof google.protobuf.BytesValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BytesValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.BytesValue";
            };

            return BytesValue;
        })();

        protobuf.Empty = (function() {

            /**
             * Properties of an Empty.
             * @memberof google.protobuf
             * @interface IEmpty
             */

            /**
             * Constructs a new Empty.
             * @memberof google.protobuf
             * @classdesc Represents an Empty.
             * @implements IEmpty
             * @constructor
             * @param {google.protobuf.IEmpty=} [properties] Properties to set
             */
            function Empty(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Gets the default type url for Empty
             * @function getTypeUrl
             * @memberof google.protobuf.Empty
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Empty.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Empty";
            };

            return Empty;
        })();

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Gets the default type url for Timestamp
             * @function getTypeUrl
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Timestamp";
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    google.type = (function() {

        /**
         * Namespace type.
         * @memberof google
         * @namespace
         */
        const type = {};

        type.Date = (function() {

            /**
             * Properties of a Date.
             * @memberof google.type
             * @interface IDate
             * @property {number|null} [year] Date year
             * @property {number|null} [month] Date month
             * @property {number|null} [day] Date day
             */

            /**
             * Constructs a new Date.
             * @memberof google.type
             * @classdesc Represents a Date.
             * @implements IDate
             * @constructor
             * @param {google.type.IDate=} [properties] Properties to set
             */
            function Date(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Date year.
             * @member {number} year
             * @memberof google.type.Date
             * @instance
             */
            Date.prototype.year = 0;

            /**
             * Date month.
             * @member {number} month
             * @memberof google.type.Date
             * @instance
             */
            Date.prototype.month = 0;

            /**
             * Date day.
             * @member {number} day
             * @memberof google.type.Date
             * @instance
             */
            Date.prototype.day = 0;

            /**
             * Gets the default type url for Date
             * @function getTypeUrl
             * @memberof google.type.Date
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Date.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.type.Date";
            };

            return Date;
        })();

        type.DateRange = (function() {

            /**
             * Properties of a DateRange.
             * @memberof google.type
             * @interface IDateRange
             * @property {google.type.IDate|null} [from] DateRange from
             * @property {google.type.IDate|null} [to] DateRange to
             */

            /**
             * Constructs a new DateRange.
             * @memberof google.type
             * @classdesc Represents a DateRange.
             * @implements IDateRange
             * @constructor
             * @param {google.type.IDateRange=} [properties] Properties to set
             */
            function DateRange(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DateRange from.
             * @member {google.type.IDate|null|undefined} from
             * @memberof google.type.DateRange
             * @instance
             */
            DateRange.prototype.from = null;

            /**
             * DateRange to.
             * @member {google.type.IDate|null|undefined} to
             * @memberof google.type.DateRange
             * @instance
             */
            DateRange.prototype.to = null;

            /**
             * Gets the default type url for DateRange
             * @function getTypeUrl
             * @memberof google.type.DateRange
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DateRange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.type.DateRange";
            };

            return DateRange;
        })();

        return type;
    })();

    return google;
})();

export const companyservice = $root.companyservice = (() => {

    /**
     * Namespace companyservice.
     * @exports companyservice
     * @namespace
     */
    const companyservice = {};

    companyservice.CompanyService = (function() {

        /**
         * Constructs a new CompanyService service.
         * @memberof companyservice
         * @classdesc Represents a CompanyService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CompanyService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CompanyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CompanyService;

        /**
         * Callback as used by {@link companyservice.CompanyService#uploadBusinessPartnerData}.
         * @memberof companyservice.CompanyService
         * @typedef UploadBusinessPartnerDataCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.UploadBusinessPartnerDataResponse} [response] UploadBusinessPartnerDataResponse
         */

        /**
         * Calls UploadBusinessPartnerData.
         * @function uploadBusinessPartnerData
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IUploadBusinessPartnerDataRequest} request UploadBusinessPartnerDataRequest message or plain object
         * @param {companyservice.CompanyService.UploadBusinessPartnerDataCallback} callback Node-style callback called with the error, if any, and UploadBusinessPartnerDataResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.uploadBusinessPartnerData = function uploadBusinessPartnerData(request, callback) {
            return this.rpcCall(uploadBusinessPartnerData, $root.companyservice.UploadBusinessPartnerDataRequest, $root.companyservice.UploadBusinessPartnerDataResponse, request, callback);
        }, "name", { value: "UploadBusinessPartnerData" });

        /**
         * Calls UploadBusinessPartnerData.
         * @function uploadBusinessPartnerData
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IUploadBusinessPartnerDataRequest} request UploadBusinessPartnerDataRequest message or plain object
         * @returns {Promise<companyservice.UploadBusinessPartnerDataResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#listBusinessPartnersPaginated}.
         * @memberof companyservice.CompanyService
         * @typedef ListBusinessPartnersPaginatedCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.PaginatedBusinessPartnersListResponse} [response] PaginatedBusinessPartnersListResponse
         */

        /**
         * Calls ListBusinessPartnersPaginated.
         * @function listBusinessPartnersPaginated
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPaginatedPartnerListRequest} request PaginatedPartnerListRequest message or plain object
         * @param {companyservice.CompanyService.ListBusinessPartnersPaginatedCallback} callback Node-style callback called with the error, if any, and PaginatedBusinessPartnersListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.listBusinessPartnersPaginated = function listBusinessPartnersPaginated(request, callback) {
            return this.rpcCall(listBusinessPartnersPaginated, $root.base.PaginatedPartnerListRequest, $root.companyservice.PaginatedBusinessPartnersListResponse, request, callback);
        }, "name", { value: "ListBusinessPartnersPaginated" });

        /**
         * Calls ListBusinessPartnersPaginated.
         * @function listBusinessPartnersPaginated
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPaginatedPartnerListRequest} request PaginatedPartnerListRequest message or plain object
         * @returns {Promise<companyservice.PaginatedBusinessPartnersListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#listCompanyPartnersPaginated}.
         * @memberof companyservice.CompanyService
         * @typedef ListCompanyPartnersPaginatedCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.PaginatedCompanyPartnersListResponse} [response] PaginatedCompanyPartnersListResponse
         */

        /**
         * Calls ListCompanyPartnersPaginated.
         * @function listCompanyPartnersPaginated
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPaginatedPartnerListRequest} request PaginatedPartnerListRequest message or plain object
         * @param {companyservice.CompanyService.ListCompanyPartnersPaginatedCallback} callback Node-style callback called with the error, if any, and PaginatedCompanyPartnersListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.listCompanyPartnersPaginated = function listCompanyPartnersPaginated(request, callback) {
            return this.rpcCall(listCompanyPartnersPaginated, $root.base.PaginatedPartnerListRequest, $root.companyservice.PaginatedCompanyPartnersListResponse, request, callback);
        }, "name", { value: "ListCompanyPartnersPaginated" });

        /**
         * Calls ListCompanyPartnersPaginated.
         * @function listCompanyPartnersPaginated
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPaginatedPartnerListRequest} request PaginatedPartnerListRequest message or plain object
         * @returns {Promise<companyservice.PaginatedCompanyPartnersListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#listAllBusinessPartnersOfOneCompanyPartner}.
         * @memberof companyservice.CompanyService
         * @typedef ListAllBusinessPartnersOfOneCompanyPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.PaginatedBusinessPartnersListResponse} [response] PaginatedBusinessPartnersListResponse
         */

        /**
         * Calls ListAllBusinessPartnersOfOneCompanyPartner.
         * @function listAllBusinessPartnersOfOneCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {companyservice.CompanyService.ListAllBusinessPartnersOfOneCompanyPartnerCallback} callback Node-style callback called with the error, if any, and PaginatedBusinessPartnersListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.listAllBusinessPartnersOfOneCompanyPartner = function listAllBusinessPartnersOfOneCompanyPartner(request, callback) {
            return this.rpcCall(listAllBusinessPartnersOfOneCompanyPartner, $root.base.PartnerRequest, $root.companyservice.PaginatedBusinessPartnersListResponse, request, callback);
        }, "name", { value: "ListAllBusinessPartnersOfOneCompanyPartner" });

        /**
         * Calls ListAllBusinessPartnersOfOneCompanyPartner.
         * @function listAllBusinessPartnersOfOneCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<companyservice.PaginatedBusinessPartnersListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#downloadRegistrationCodes}.
         * @memberof companyservice.CompanyService
         * @typedef DownloadRegistrationCodesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.DownloadFileResponse} [response] DownloadFileResponse
         */

        /**
         * Calls DownloadRegistrationCodes.
         * @function downloadRegistrationCodes
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IDownloadRegistrationCodesRequest} request DownloadRegistrationCodesRequest message or plain object
         * @param {companyservice.CompanyService.DownloadRegistrationCodesCallback} callback Node-style callback called with the error, if any, and DownloadFileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.downloadRegistrationCodes = function downloadRegistrationCodes(request, callback) {
            return this.rpcCall(downloadRegistrationCodes, $root.companyservice.DownloadRegistrationCodesRequest, $root.base.DownloadFileResponse, request, callback);
        }, "name", { value: "DownloadRegistrationCodes" });

        /**
         * Calls DownloadRegistrationCodes.
         * @function downloadRegistrationCodes
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IDownloadRegistrationCodesRequest} request DownloadRegistrationCodesRequest message or plain object
         * @returns {Promise<base.DownloadFileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#registerCompanyPartner}.
         * @memberof companyservice.CompanyService
         * @typedef RegisterCompanyPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.RegisterCompanyPartnerResponse} [response] RegisterCompanyPartnerResponse
         */

        /**
         * Calls RegisterCompanyPartner.
         * @function registerCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IRegisterCompanyPartnerRequest} request RegisterCompanyPartnerRequest message or plain object
         * @param {companyservice.CompanyService.RegisterCompanyPartnerCallback} callback Node-style callback called with the error, if any, and RegisterCompanyPartnerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.registerCompanyPartner = function registerCompanyPartner(request, callback) {
            return this.rpcCall(registerCompanyPartner, $root.companyservice.RegisterCompanyPartnerRequest, $root.companyservice.RegisterCompanyPartnerResponse, request, callback);
        }, "name", { value: "RegisterCompanyPartner" });

        /**
         * Calls RegisterCompanyPartner.
         * @function registerCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IRegisterCompanyPartnerRequest} request RegisterCompanyPartnerRequest message or plain object
         * @returns {Promise<companyservice.RegisterCompanyPartnerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#getBusinessPartner}.
         * @memberof companyservice.CompanyService
         * @typedef GetBusinessPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.GetCompanyPartnerResponse} [response] GetCompanyPartnerResponse
         */

        /**
         * Calls GetBusinessPartner.
         * @function getBusinessPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IBusinessPartnerRequest} request BusinessPartnerRequest message or plain object
         * @param {companyservice.CompanyService.GetBusinessPartnerCallback} callback Node-style callback called with the error, if any, and GetCompanyPartnerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.getBusinessPartner = function getBusinessPartner(request, callback) {
            return this.rpcCall(getBusinessPartner, $root.companyservice.BusinessPartnerRequest, $root.companyservice.GetCompanyPartnerResponse, request, callback);
        }, "name", { value: "GetBusinessPartner" });

        /**
         * Calls GetBusinessPartner.
         * @function getBusinessPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IBusinessPartnerRequest} request BusinessPartnerRequest message or plain object
         * @returns {Promise<companyservice.GetCompanyPartnerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#getCompanyPartner}.
         * @memberof companyservice.CompanyService
         * @typedef GetCompanyPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.GetCompanyPartnerResponse} [response] GetCompanyPartnerResponse
         */

        /**
         * Calls GetCompanyPartner.
         * @function getCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IGetCompanyPartnerRequest} request GetCompanyPartnerRequest message or plain object
         * @param {companyservice.CompanyService.GetCompanyPartnerCallback} callback Node-style callback called with the error, if any, and GetCompanyPartnerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.getCompanyPartner = function getCompanyPartner(request, callback) {
            return this.rpcCall(getCompanyPartner, $root.companyservice.GetCompanyPartnerRequest, $root.companyservice.GetCompanyPartnerResponse, request, callback);
        }, "name", { value: "GetCompanyPartner" });

        /**
         * Calls GetCompanyPartner.
         * @function getCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IGetCompanyPartnerRequest} request GetCompanyPartnerRequest message or plain object
         * @returns {Promise<companyservice.GetCompanyPartnerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#deleteCompanyPartnerByPartnerId}.
         * @memberof companyservice.CompanyService
         * @typedef DeleteCompanyPartnerByPartnerIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.PartnerIdResponse} [response] PartnerIdResponse
         */

        /**
         * Calls DeleteCompanyPartnerByPartnerId.
         * @function deleteCompanyPartnerByPartnerId
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {companyservice.CompanyService.DeleteCompanyPartnerByPartnerIdCallback} callback Node-style callback called with the error, if any, and PartnerIdResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.deleteCompanyPartnerByPartnerId = function deleteCompanyPartnerByPartnerId(request, callback) {
            return this.rpcCall(deleteCompanyPartnerByPartnerId, $root.base.PartnerRequest, $root.base.PartnerIdResponse, request, callback);
        }, "name", { value: "DeleteCompanyPartnerByPartnerId" });

        /**
         * Calls DeleteCompanyPartnerByPartnerId.
         * @function deleteCompanyPartnerByPartnerId
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<base.PartnerIdResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#deleteBusinessPartnersByPartnerId}.
         * @memberof companyservice.CompanyService
         * @typedef DeleteBusinessPartnersByPartnerIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.PartnerIdResponse} [response] PartnerIdResponse
         */

        /**
         * Calls DeleteBusinessPartnersByPartnerId.
         * @function deleteBusinessPartnersByPartnerId
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {companyservice.CompanyService.DeleteBusinessPartnersByPartnerIdCallback} callback Node-style callback called with the error, if any, and PartnerIdResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.deleteBusinessPartnersByPartnerId = function deleteBusinessPartnersByPartnerId(request, callback) {
            return this.rpcCall(deleteBusinessPartnersByPartnerId, $root.base.PartnerRequest, $root.base.PartnerIdResponse, request, callback);
        }, "name", { value: "DeleteBusinessPartnersByPartnerId" });

        /**
         * Calls DeleteBusinessPartnersByPartnerId.
         * @function deleteBusinessPartnersByPartnerId
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<base.PartnerIdResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#deleteBusinessPartner}.
         * @memberof companyservice.CompanyService
         * @typedef DeleteBusinessPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.PartnerIdResponse} [response] PartnerIdResponse
         */

        /**
         * Calls DeleteBusinessPartner.
         * @function deleteBusinessPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IBusinessPartnerRequest} request BusinessPartnerRequest message or plain object
         * @param {companyservice.CompanyService.DeleteBusinessPartnerCallback} callback Node-style callback called with the error, if any, and PartnerIdResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.deleteBusinessPartner = function deleteBusinessPartner(request, callback) {
            return this.rpcCall(deleteBusinessPartner, $root.companyservice.BusinessPartnerRequest, $root.base.PartnerIdResponse, request, callback);
        }, "name", { value: "DeleteBusinessPartner" });

        /**
         * Calls DeleteBusinessPartner.
         * @function deleteBusinessPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IBusinessPartnerRequest} request BusinessPartnerRequest message or plain object
         * @returns {Promise<base.PartnerIdResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#deleteCompanyPartnerAndBusinessPartners}.
         * @memberof companyservice.CompanyService
         * @typedef DeleteCompanyPartnerAndBusinessPartnersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.PartnerIdResponse} [response] PartnerIdResponse
         */

        /**
         * Calls DeleteCompanyPartnerAndBusinessPartners.
         * @function deleteCompanyPartnerAndBusinessPartners
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {companyservice.CompanyService.DeleteCompanyPartnerAndBusinessPartnersCallback} callback Node-style callback called with the error, if any, and PartnerIdResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.deleteCompanyPartnerAndBusinessPartners = function deleteCompanyPartnerAndBusinessPartners(request, callback) {
            return this.rpcCall(deleteCompanyPartnerAndBusinessPartners, $root.base.PartnerRequest, $root.base.PartnerIdResponse, request, callback);
        }, "name", { value: "DeleteCompanyPartnerAndBusinessPartners" });

        /**
         * Calls DeleteCompanyPartnerAndBusinessPartners.
         * @function deleteCompanyPartnerAndBusinessPartners
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<base.PartnerIdResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#getCompanyByRegistrationCode}.
         * @memberof companyservice.CompanyService
         * @typedef GetCompanyByRegistrationCodeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.GetCompanyByRegistrationCodeResponse} [response] GetCompanyByRegistrationCodeResponse
         */

        /**
         * Calls GetCompanyByRegistrationCode.
         * @function getCompanyByRegistrationCode
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IGetCompanyByRegistrationCodeRequest} request GetCompanyByRegistrationCodeRequest message or plain object
         * @param {companyservice.CompanyService.GetCompanyByRegistrationCodeCallback} callback Node-style callback called with the error, if any, and GetCompanyByRegistrationCodeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.getCompanyByRegistrationCode = function getCompanyByRegistrationCode(request, callback) {
            return this.rpcCall(getCompanyByRegistrationCode, $root.companyservice.GetCompanyByRegistrationCodeRequest, $root.companyservice.GetCompanyByRegistrationCodeResponse, request, callback);
        }, "name", { value: "GetCompanyByRegistrationCode" });

        /**
         * Calls GetCompanyByRegistrationCode.
         * @function getCompanyByRegistrationCode
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IGetCompanyByRegistrationCodeRequest} request GetCompanyByRegistrationCodeRequest message or plain object
         * @returns {Promise<companyservice.GetCompanyByRegistrationCodeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#getCompanyPartnerByPartnerId}.
         * @memberof companyservice.CompanyService
         * @typedef GetCompanyPartnerByPartnerIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.GetCompanyPartnerResponse} [response] GetCompanyPartnerResponse
         */

        /**
         * Calls GetCompanyPartnerByPartnerId.
         * @function getCompanyPartnerByPartnerId
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {companyservice.CompanyService.GetCompanyPartnerByPartnerIdCallback} callback Node-style callback called with the error, if any, and GetCompanyPartnerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.getCompanyPartnerByPartnerId = function getCompanyPartnerByPartnerId(request, callback) {
            return this.rpcCall(getCompanyPartnerByPartnerId, $root.base.PartnerRequest, $root.companyservice.GetCompanyPartnerResponse, request, callback);
        }, "name", { value: "GetCompanyPartnerByPartnerId" });

        /**
         * Calls GetCompanyPartnerByPartnerId.
         * @function getCompanyPartnerByPartnerId
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<companyservice.GetCompanyPartnerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#uniteCompanyPartner}.
         * @memberof companyservice.CompanyService
         * @typedef UniteCompanyPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.UniteCompanyPartnerResponse} [response] UniteCompanyPartnerResponse
         */

        /**
         * Calls UniteCompanyPartner.
         * @function uniteCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IUniteCompanyPartnerRequest} request UniteCompanyPartnerRequest message or plain object
         * @param {companyservice.CompanyService.UniteCompanyPartnerCallback} callback Node-style callback called with the error, if any, and UniteCompanyPartnerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.uniteCompanyPartner = function uniteCompanyPartner(request, callback) {
            return this.rpcCall(uniteCompanyPartner, $root.companyservice.UniteCompanyPartnerRequest, $root.companyservice.UniteCompanyPartnerResponse, request, callback);
        }, "name", { value: "UniteCompanyPartner" });

        /**
         * Calls UniteCompanyPartner.
         * @function uniteCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IUniteCompanyPartnerRequest} request UniteCompanyPartnerRequest message or plain object
         * @returns {Promise<companyservice.UniteCompanyPartnerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#resetRegistrationStateToInvited}.
         * @memberof companyservice.CompanyService
         * @typedef ResetRegistrationStateToInvitedCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.LocalizedResult} [response] LocalizedResult
         */

        /**
         * Calls ResetRegistrationStateToInvited.
         * @function resetRegistrationStateToInvited
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IRegisterCompanyPartnerRequest} request RegisterCompanyPartnerRequest message or plain object
         * @param {companyservice.CompanyService.ResetRegistrationStateToInvitedCallback} callback Node-style callback called with the error, if any, and LocalizedResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.resetRegistrationStateToInvited = function resetRegistrationStateToInvited(request, callback) {
            return this.rpcCall(resetRegistrationStateToInvited, $root.companyservice.RegisterCompanyPartnerRequest, $root.base.LocalizedResult, request, callback);
        }, "name", { value: "ResetRegistrationStateToInvited" });

        /**
         * Calls ResetRegistrationStateToInvited.
         * @function resetRegistrationStateToInvited
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IRegisterCompanyPartnerRequest} request RegisterCompanyPartnerRequest message or plain object
         * @returns {Promise<base.LocalizedResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#updateCompanyPartner}.
         * @memberof companyservice.CompanyService
         * @typedef UpdateCompanyPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.UpdateCompanyPartnerResponse} [response] UpdateCompanyPartnerResponse
         */

        /**
         * Calls UpdateCompanyPartner.
         * @function updateCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IUpdateCompanyPartnerRequest} request UpdateCompanyPartnerRequest message or plain object
         * @param {companyservice.CompanyService.UpdateCompanyPartnerCallback} callback Node-style callback called with the error, if any, and UpdateCompanyPartnerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.updateCompanyPartner = function updateCompanyPartner(request, callback) {
            return this.rpcCall(updateCompanyPartner, $root.companyservice.UpdateCompanyPartnerRequest, $root.companyservice.UpdateCompanyPartnerResponse, request, callback);
        }, "name", { value: "UpdateCompanyPartner" });

        /**
         * Calls UpdateCompanyPartner.
         * @function updateCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IUpdateCompanyPartnerRequest} request UpdateCompanyPartnerRequest message or plain object
         * @returns {Promise<companyservice.UpdateCompanyPartnerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#initiateCompanyPartnerTransfer}.
         * @memberof companyservice.CompanyService
         * @typedef InitiateCompanyPartnerTransferCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.InitiateCompanyPartnerTransferResponse} [response] InitiateCompanyPartnerTransferResponse
         */

        /**
         * Calls InitiateCompanyPartnerTransfer.
         * @function initiateCompanyPartnerTransfer
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IInitiateCompanyPartnerTransferRequest} request InitiateCompanyPartnerTransferRequest message or plain object
         * @param {companyservice.CompanyService.InitiateCompanyPartnerTransferCallback} callback Node-style callback called with the error, if any, and InitiateCompanyPartnerTransferResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.initiateCompanyPartnerTransfer = function initiateCompanyPartnerTransfer(request, callback) {
            return this.rpcCall(initiateCompanyPartnerTransfer, $root.companyservice.InitiateCompanyPartnerTransferRequest, $root.companyservice.InitiateCompanyPartnerTransferResponse, request, callback);
        }, "name", { value: "InitiateCompanyPartnerTransfer" });

        /**
         * Calls InitiateCompanyPartnerTransfer.
         * @function initiateCompanyPartnerTransfer
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IInitiateCompanyPartnerTransferRequest} request InitiateCompanyPartnerTransferRequest message or plain object
         * @returns {Promise<companyservice.InitiateCompanyPartnerTransferResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#getConsumptionDataCompanyPartner}.
         * @memberof companyservice.CompanyService
         * @typedef GetConsumptionDataCompanyPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.ConsumptionDataBusinessPartners} [response] ConsumptionDataBusinessPartners
         */

        /**
         * Calls GetConsumptionDataCompanyPartner.
         * @function getConsumptionDataCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {companyservice.CompanyService.GetConsumptionDataCompanyPartnerCallback} callback Node-style callback called with the error, if any, and ConsumptionDataBusinessPartners
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.getConsumptionDataCompanyPartner = function getConsumptionDataCompanyPartner(request, callback) {
            return this.rpcCall(getConsumptionDataCompanyPartner, $root.base.PartnerRequest, $root.companyservice.ConsumptionDataBusinessPartners, request, callback);
        }, "name", { value: "GetConsumptionDataCompanyPartner" });

        /**
         * Calls GetConsumptionDataCompanyPartner.
         * @function getConsumptionDataCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<companyservice.ConsumptionDataBusinessPartners>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#getConsumptionDataBusinessPartners}.
         * @memberof companyservice.CompanyService
         * @typedef GetConsumptionDataBusinessPartnersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.ConsumptionDataBusinessPartners} [response] ConsumptionDataBusinessPartners
         */

        /**
         * Calls GetConsumptionDataBusinessPartners.
         * @function getConsumptionDataBusinessPartners
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IBusinessPartnerRequest} request BusinessPartnerRequest message or plain object
         * @param {companyservice.CompanyService.GetConsumptionDataBusinessPartnersCallback} callback Node-style callback called with the error, if any, and ConsumptionDataBusinessPartners
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.getConsumptionDataBusinessPartners = function getConsumptionDataBusinessPartners(request, callback) {
            return this.rpcCall(getConsumptionDataBusinessPartners, $root.companyservice.BusinessPartnerRequest, $root.companyservice.ConsumptionDataBusinessPartners, request, callback);
        }, "name", { value: "GetConsumptionDataBusinessPartners" });

        /**
         * Calls GetConsumptionDataBusinessPartners.
         * @function getConsumptionDataBusinessPartners
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IBusinessPartnerRequest} request BusinessPartnerRequest message or plain object
         * @returns {Promise<companyservice.ConsumptionDataBusinessPartners>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#getConsumptionDataBusinessPartnersWithDailySdat}.
         * @memberof companyservice.CompanyService
         * @typedef GetConsumptionDataBusinessPartnersWithDailySdatCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.ConsumptionDataBusinessPartners} [response] ConsumptionDataBusinessPartners
         */

        /**
         * Calls GetConsumptionDataBusinessPartnersWithDailySdat.
         * @function getConsumptionDataBusinessPartnersWithDailySdat
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IConsumptionDataBusinessPartnersWithDailySdatRequest} request ConsumptionDataBusinessPartnersWithDailySdatRequest message or plain object
         * @param {companyservice.CompanyService.GetConsumptionDataBusinessPartnersWithDailySdatCallback} callback Node-style callback called with the error, if any, and ConsumptionDataBusinessPartners
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.getConsumptionDataBusinessPartnersWithDailySdat = function getConsumptionDataBusinessPartnersWithDailySdat(request, callback) {
            return this.rpcCall(getConsumptionDataBusinessPartnersWithDailySdat, $root.companyservice.ConsumptionDataBusinessPartnersWithDailySdatRequest, $root.companyservice.ConsumptionDataBusinessPartners, request, callback);
        }, "name", { value: "GetConsumptionDataBusinessPartnersWithDailySdat" });

        /**
         * Calls GetConsumptionDataBusinessPartnersWithDailySdat.
         * @function getConsumptionDataBusinessPartnersWithDailySdat
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IConsumptionDataBusinessPartnersWithDailySdatRequest} request ConsumptionDataBusinessPartnersWithDailySdatRequest message or plain object
         * @returns {Promise<companyservice.ConsumptionDataBusinessPartners>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#listMeteringPointsCompanyPartner}.
         * @memberof companyservice.CompanyService
         * @typedef ListMeteringPointsCompanyPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.MeteringPoints} [response] MeteringPoints
         */

        /**
         * Calls ListMeteringPointsCompanyPartner.
         * @function listMeteringPointsCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {companyservice.CompanyService.ListMeteringPointsCompanyPartnerCallback} callback Node-style callback called with the error, if any, and MeteringPoints
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.listMeteringPointsCompanyPartner = function listMeteringPointsCompanyPartner(request, callback) {
            return this.rpcCall(listMeteringPointsCompanyPartner, $root.base.PartnerRequest, $root.companyservice.MeteringPoints, request, callback);
        }, "name", { value: "ListMeteringPointsCompanyPartner" });

        /**
         * Calls ListMeteringPointsCompanyPartner.
         * @function listMeteringPointsCompanyPartner
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<companyservice.MeteringPoints>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#listMeteringPointsBusinessPartners}.
         * @memberof companyservice.CompanyService
         * @typedef ListMeteringPointsBusinessPartnersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.MeteringPoints} [response] MeteringPoints
         */

        /**
         * Calls ListMeteringPointsBusinessPartners.
         * @function listMeteringPointsBusinessPartners
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IBusinessPartnerRequest} request BusinessPartnerRequest message or plain object
         * @param {companyservice.CompanyService.ListMeteringPointsBusinessPartnersCallback} callback Node-style callback called with the error, if any, and MeteringPoints
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.listMeteringPointsBusinessPartners = function listMeteringPointsBusinessPartners(request, callback) {
            return this.rpcCall(listMeteringPointsBusinessPartners, $root.companyservice.BusinessPartnerRequest, $root.companyservice.MeteringPoints, request, callback);
        }, "name", { value: "ListMeteringPointsBusinessPartners" });

        /**
         * Calls ListMeteringPointsBusinessPartners.
         * @function listMeteringPointsBusinessPartners
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IBusinessPartnerRequest} request BusinessPartnerRequest message or plain object
         * @returns {Promise<companyservice.MeteringPoints>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#getMeteringPointMonthlyDetails}.
         * @memberof companyservice.CompanyService
         * @typedef GetMeteringPointMonthlyDetailsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.MeteringPointMonthlyDetails} [response] MeteringPointMonthlyDetails
         */

        /**
         * Calls GetMeteringPointMonthlyDetails.
         * @function getMeteringPointMonthlyDetails
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IMeteringPointMonthlyRequest} request MeteringPointMonthlyRequest message or plain object
         * @param {companyservice.CompanyService.GetMeteringPointMonthlyDetailsCallback} callback Node-style callback called with the error, if any, and MeteringPointMonthlyDetails
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.getMeteringPointMonthlyDetails = function getMeteringPointMonthlyDetails(request, callback) {
            return this.rpcCall(getMeteringPointMonthlyDetails, $root.companyservice.MeteringPointMonthlyRequest, $root.companyservice.MeteringPointMonthlyDetails, request, callback);
        }, "name", { value: "GetMeteringPointMonthlyDetails" });

        /**
         * Calls GetMeteringPointMonthlyDetails.
         * @function getMeteringPointMonthlyDetails
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IMeteringPointMonthlyRequest} request MeteringPointMonthlyRequest message or plain object
         * @returns {Promise<companyservice.MeteringPointMonthlyDetails>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#getMeteringPointDailyDetails}.
         * @memberof companyservice.CompanyService
         * @typedef GetMeteringPointDailyDetailsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.MeteringPointDailyDetails} [response] MeteringPointDailyDetails
         */

        /**
         * Calls GetMeteringPointDailyDetails.
         * @function getMeteringPointDailyDetails
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IMeteringPointMonthlyRequest} request MeteringPointMonthlyRequest message or plain object
         * @param {companyservice.CompanyService.GetMeteringPointDailyDetailsCallback} callback Node-style callback called with the error, if any, and MeteringPointDailyDetails
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.getMeteringPointDailyDetails = function getMeteringPointDailyDetails(request, callback) {
            return this.rpcCall(getMeteringPointDailyDetails, $root.companyservice.MeteringPointMonthlyRequest, $root.companyservice.MeteringPointDailyDetails, request, callback);
        }, "name", { value: "GetMeteringPointDailyDetails" });

        /**
         * Calls GetMeteringPointDailyDetails.
         * @function getMeteringPointDailyDetails
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IMeteringPointMonthlyRequest} request MeteringPointMonthlyRequest message or plain object
         * @returns {Promise<companyservice.MeteringPointDailyDetails>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#migratePlainToEncryptedConsumptionData}.
         * @memberof companyservice.CompanyService
         * @typedef MigratePlainToEncryptedConsumptionDataCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls MigratePlainToEncryptedConsumptionData.
         * @function migratePlainToEncryptedConsumptionData
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {companyservice.CompanyService.MigratePlainToEncryptedConsumptionDataCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.migratePlainToEncryptedConsumptionData = function migratePlainToEncryptedConsumptionData(request, callback) {
            return this.rpcCall(migratePlainToEncryptedConsumptionData, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "MigratePlainToEncryptedConsumptionData" });

        /**
         * Calls MigratePlainToEncryptedConsumptionData.
         * @function migratePlainToEncryptedConsumptionData
         * @memberof companyservice.CompanyService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#getAllContingentRates}.
         * @memberof companyservice.CompanyService
         * @typedef GetAllContingentRatesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companyservice.ContingentRatesResponse} [response] ContingentRatesResponse
         */

        /**
         * Calls GetAllContingentRates.
         * @function getAllContingentRates
         * @memberof companyservice.CompanyService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {companyservice.CompanyService.GetAllContingentRatesCallback} callback Node-style callback called with the error, if any, and ContingentRatesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.getAllContingentRates = function getAllContingentRates(request, callback) {
            return this.rpcCall(getAllContingentRates, $root.google.protobuf.Empty, $root.companyservice.ContingentRatesResponse, request, callback);
        }, "name", { value: "GetAllContingentRates" });

        /**
         * Calls GetAllContingentRates.
         * @function getAllContingentRates
         * @memberof companyservice.CompanyService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<companyservice.ContingentRatesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#setContingentRate}.
         * @memberof companyservice.CompanyService
         * @typedef SetContingentRateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls SetContingentRate.
         * @function setContingentRate
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IContingentRate} request ContingentRate message or plain object
         * @param {companyservice.CompanyService.SetContingentRateCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.setContingentRate = function setContingentRate(request, callback) {
            return this.rpcCall(setContingentRate, $root.companyservice.ContingentRate, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "SetContingentRate" });

        /**
         * Calls SetContingentRate.
         * @function setContingentRate
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IContingentRate} request ContingentRate message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#deleteContingentRate}.
         * @memberof companyservice.CompanyService
         * @typedef DeleteContingentRateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteContingentRate.
         * @function deleteContingentRate
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IContingentRate} request ContingentRate message or plain object
         * @param {companyservice.CompanyService.DeleteContingentRateCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.deleteContingentRate = function deleteContingentRate(request, callback) {
            return this.rpcCall(deleteContingentRate, $root.companyservice.ContingentRate, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteContingentRate" });

        /**
         * Calls DeleteContingentRate.
         * @function deleteContingentRate
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IContingentRate} request ContingentRate message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#downloadDataForContingencyLetters}.
         * @memberof companyservice.CompanyService
         * @typedef DownloadDataForContingencyLettersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.Chunk} [response] Chunk
         */

        /**
         * Calls DownloadDataForContingencyLetters.
         * @function downloadDataForContingencyLetters
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IDownloadDataForContingencyLettersRequest} request DownloadDataForContingencyLettersRequest message or plain object
         * @param {companyservice.CompanyService.DownloadDataForContingencyLettersCallback} callback Node-style callback called with the error, if any, and Chunk
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.downloadDataForContingencyLetters = function downloadDataForContingencyLetters(request, callback) {
            return this.rpcCall(downloadDataForContingencyLetters, $root.companyservice.DownloadDataForContingencyLettersRequest, $root.base.Chunk, request, callback);
        }, "name", { value: "DownloadDataForContingencyLetters" });

        /**
         * Calls DownloadDataForContingencyLetters.
         * @function downloadDataForContingencyLetters
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IDownloadDataForContingencyLettersRequest} request DownloadDataForContingencyLettersRequest message or plain object
         * @returns {Promise<base.Chunk>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#cancelDownload}.
         * @memberof companyservice.CompanyService
         * @typedef CancelDownloadCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls CancelDownload.
         * @function cancelDownload
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IDownloadDataForContingencyLettersRequest} request DownloadDataForContingencyLettersRequest message or plain object
         * @param {companyservice.CompanyService.CancelDownloadCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.cancelDownload = function cancelDownload(request, callback) {
            return this.rpcCall(cancelDownload, $root.companyservice.DownloadDataForContingencyLettersRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "CancelDownload" });

        /**
         * Calls CancelDownload.
         * @function cancelDownload
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IDownloadDataForContingencyLettersRequest} request DownloadDataForContingencyLettersRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companyservice.CompanyService#downloadDataForContingencyLettersByBusinessPartners}.
         * @memberof companyservice.CompanyService
         * @typedef DownloadDataForContingencyLettersByBusinessPartnersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.DownloadFileResponse} [response] DownloadFileResponse
         */

        /**
         * Calls DownloadDataForContingencyLettersByBusinessPartners.
         * @function downloadDataForContingencyLettersByBusinessPartners
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IDownloadDataForContingencyLettersByBusinessPartnersRequest} request DownloadDataForContingencyLettersByBusinessPartnersRequest message or plain object
         * @param {companyservice.CompanyService.DownloadDataForContingencyLettersByBusinessPartnersCallback} callback Node-style callback called with the error, if any, and DownloadFileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyService.prototype.downloadDataForContingencyLettersByBusinessPartners = function downloadDataForContingencyLettersByBusinessPartners(request, callback) {
            return this.rpcCall(downloadDataForContingencyLettersByBusinessPartners, $root.companyservice.DownloadDataForContingencyLettersByBusinessPartnersRequest, $root.base.DownloadFileResponse, request, callback);
        }, "name", { value: "DownloadDataForContingencyLettersByBusinessPartners" });

        /**
         * Calls DownloadDataForContingencyLettersByBusinessPartners.
         * @function downloadDataForContingencyLettersByBusinessPartners
         * @memberof companyservice.CompanyService
         * @instance
         * @param {companyservice.IDownloadDataForContingencyLettersByBusinessPartnersRequest} request DownloadDataForContingencyLettersByBusinessPartnersRequest message or plain object
         * @returns {Promise<base.DownloadFileResponse>} Promise
         * @variation 2
         */

        return CompanyService;
    })();

    companyservice.UploadBusinessPartnerDataRequest = (function() {

        /**
         * Properties of an UploadBusinessPartnerDataRequest.
         * @memberof companyservice
         * @interface IUploadBusinessPartnerDataRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] UploadBusinessPartnerDataRequest partnerId
         * @property {base.IDownloadFileResponse|null} [file] UploadBusinessPartnerDataRequest file
         */

        /**
         * Constructs a new UploadBusinessPartnerDataRequest.
         * @memberof companyservice
         * @classdesc Represents an UploadBusinessPartnerDataRequest.
         * @implements IUploadBusinessPartnerDataRequest
         * @constructor
         * @param {companyservice.IUploadBusinessPartnerDataRequest=} [properties] Properties to set
         */
        function UploadBusinessPartnerDataRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadBusinessPartnerDataRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.UploadBusinessPartnerDataRequest
         * @instance
         */
        UploadBusinessPartnerDataRequest.prototype.partnerId = null;

        /**
         * UploadBusinessPartnerDataRequest file.
         * @member {base.IDownloadFileResponse|null|undefined} file
         * @memberof companyservice.UploadBusinessPartnerDataRequest
         * @instance
         */
        UploadBusinessPartnerDataRequest.prototype.file = null;

        /**
         * Gets the default type url for UploadBusinessPartnerDataRequest
         * @function getTypeUrl
         * @memberof companyservice.UploadBusinessPartnerDataRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadBusinessPartnerDataRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.UploadBusinessPartnerDataRequest";
        };

        return UploadBusinessPartnerDataRequest;
    })();

    companyservice.UploadBusinessPartnerDataResponse = (function() {

        /**
         * Properties of an UploadBusinessPartnerDataResponse.
         * @memberof companyservice
         * @interface IUploadBusinessPartnerDataResponse
         * @property {google.protobuf.IInt32Value|null} [processedLines] UploadBusinessPartnerDataResponse processedLines
         * @property {Array.<base.ILocalizedMessage>|null} [warnings] UploadBusinessPartnerDataResponse warnings
         */

        /**
         * Constructs a new UploadBusinessPartnerDataResponse.
         * @memberof companyservice
         * @classdesc Represents an UploadBusinessPartnerDataResponse.
         * @implements IUploadBusinessPartnerDataResponse
         * @constructor
         * @param {companyservice.IUploadBusinessPartnerDataResponse=} [properties] Properties to set
         */
        function UploadBusinessPartnerDataResponse(properties) {
            this.warnings = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadBusinessPartnerDataResponse processedLines.
         * @member {google.protobuf.IInt32Value|null|undefined} processedLines
         * @memberof companyservice.UploadBusinessPartnerDataResponse
         * @instance
         */
        UploadBusinessPartnerDataResponse.prototype.processedLines = null;

        /**
         * UploadBusinessPartnerDataResponse warnings.
         * @member {Array.<base.ILocalizedMessage>} warnings
         * @memberof companyservice.UploadBusinessPartnerDataResponse
         * @instance
         */
        UploadBusinessPartnerDataResponse.prototype.warnings = $util.emptyArray;

        /**
         * Gets the default type url for UploadBusinessPartnerDataResponse
         * @function getTypeUrl
         * @memberof companyservice.UploadBusinessPartnerDataResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadBusinessPartnerDataResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.UploadBusinessPartnerDataResponse";
        };

        return UploadBusinessPartnerDataResponse;
    })();

    companyservice.BusinessPartnerId = (function() {

        /**
         * Properties of a BusinessPartnerId.
         * @memberof companyservice
         * @interface IBusinessPartnerId
         * @property {google.protobuf.IStringValue|null} [businessPartnerId] BusinessPartnerId businessPartnerId
         */

        /**
         * Constructs a new BusinessPartnerId.
         * @memberof companyservice
         * @classdesc Represents a BusinessPartnerId.
         * @implements IBusinessPartnerId
         * @constructor
         * @param {companyservice.IBusinessPartnerId=} [properties] Properties to set
         */
        function BusinessPartnerId(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BusinessPartnerId businessPartnerId.
         * @member {google.protobuf.IStringValue|null|undefined} businessPartnerId
         * @memberof companyservice.BusinessPartnerId
         * @instance
         */
        BusinessPartnerId.prototype.businessPartnerId = null;

        /**
         * Gets the default type url for BusinessPartnerId
         * @function getTypeUrl
         * @memberof companyservice.BusinessPartnerId
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BusinessPartnerId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.BusinessPartnerId";
        };

        return BusinessPartnerId;
    })();

    companyservice.DownloadRegistrationCodesRequest = (function() {

        /**
         * Properties of a DownloadRegistrationCodesRequest.
         * @memberof companyservice
         * @interface IDownloadRegistrationCodesRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] DownloadRegistrationCodesRequest partnerId
         * @property {google.protobuf.IStringValue|null} [businessPartnerId] DownloadRegistrationCodesRequest businessPartnerId
         */

        /**
         * Constructs a new DownloadRegistrationCodesRequest.
         * @memberof companyservice
         * @classdesc Represents a DownloadRegistrationCodesRequest.
         * @implements IDownloadRegistrationCodesRequest
         * @constructor
         * @param {companyservice.IDownloadRegistrationCodesRequest=} [properties] Properties to set
         */
        function DownloadRegistrationCodesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DownloadRegistrationCodesRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.DownloadRegistrationCodesRequest
         * @instance
         */
        DownloadRegistrationCodesRequest.prototype.partnerId = null;

        /**
         * DownloadRegistrationCodesRequest businessPartnerId.
         * @member {google.protobuf.IStringValue|null|undefined} businessPartnerId
         * @memberof companyservice.DownloadRegistrationCodesRequest
         * @instance
         */
        DownloadRegistrationCodesRequest.prototype.businessPartnerId = null;

        /**
         * Gets the default type url for DownloadRegistrationCodesRequest
         * @function getTypeUrl
         * @memberof companyservice.DownloadRegistrationCodesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DownloadRegistrationCodesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.DownloadRegistrationCodesRequest";
        };

        return DownloadRegistrationCodesRequest;
    })();

    companyservice.PaginatedBusinessPartnersListResponse = (function() {

        /**
         * Properties of a PaginatedBusinessPartnersListResponse.
         * @memberof companyservice
         * @interface IPaginatedBusinessPartnersListResponse
         * @property {Array.<companyservice.IBusinessPartner>|null} [businessPartners] PaginatedBusinessPartnersListResponse businessPartners
         * @property {query.IPageInfo|null} [pageInfo] PaginatedBusinessPartnersListResponse pageInfo
         */

        /**
         * Constructs a new PaginatedBusinessPartnersListResponse.
         * @memberof companyservice
         * @classdesc Represents a PaginatedBusinessPartnersListResponse.
         * @implements IPaginatedBusinessPartnersListResponse
         * @constructor
         * @param {companyservice.IPaginatedBusinessPartnersListResponse=} [properties] Properties to set
         */
        function PaginatedBusinessPartnersListResponse(properties) {
            this.businessPartners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginatedBusinessPartnersListResponse businessPartners.
         * @member {Array.<companyservice.IBusinessPartner>} businessPartners
         * @memberof companyservice.PaginatedBusinessPartnersListResponse
         * @instance
         */
        PaginatedBusinessPartnersListResponse.prototype.businessPartners = $util.emptyArray;

        /**
         * PaginatedBusinessPartnersListResponse pageInfo.
         * @member {query.IPageInfo|null|undefined} pageInfo
         * @memberof companyservice.PaginatedBusinessPartnersListResponse
         * @instance
         */
        PaginatedBusinessPartnersListResponse.prototype.pageInfo = null;

        /**
         * Gets the default type url for PaginatedBusinessPartnersListResponse
         * @function getTypeUrl
         * @memberof companyservice.PaginatedBusinessPartnersListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaginatedBusinessPartnersListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.PaginatedBusinessPartnersListResponse";
        };

        return PaginatedBusinessPartnersListResponse;
    })();

    companyservice.PaginatedCompanyPartnersListResponse = (function() {

        /**
         * Properties of a PaginatedCompanyPartnersListResponse.
         * @memberof companyservice
         * @interface IPaginatedCompanyPartnersListResponse
         * @property {Array.<companyservice.ICompanyPartner>|null} [companyPartners] PaginatedCompanyPartnersListResponse companyPartners
         * @property {query.IPageInfo|null} [pageInfo] PaginatedCompanyPartnersListResponse pageInfo
         */

        /**
         * Constructs a new PaginatedCompanyPartnersListResponse.
         * @memberof companyservice
         * @classdesc Represents a PaginatedCompanyPartnersListResponse.
         * @implements IPaginatedCompanyPartnersListResponse
         * @constructor
         * @param {companyservice.IPaginatedCompanyPartnersListResponse=} [properties] Properties to set
         */
        function PaginatedCompanyPartnersListResponse(properties) {
            this.companyPartners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginatedCompanyPartnersListResponse companyPartners.
         * @member {Array.<companyservice.ICompanyPartner>} companyPartners
         * @memberof companyservice.PaginatedCompanyPartnersListResponse
         * @instance
         */
        PaginatedCompanyPartnersListResponse.prototype.companyPartners = $util.emptyArray;

        /**
         * PaginatedCompanyPartnersListResponse pageInfo.
         * @member {query.IPageInfo|null|undefined} pageInfo
         * @memberof companyservice.PaginatedCompanyPartnersListResponse
         * @instance
         */
        PaginatedCompanyPartnersListResponse.prototype.pageInfo = null;

        /**
         * Gets the default type url for PaginatedCompanyPartnersListResponse
         * @function getTypeUrl
         * @memberof companyservice.PaginatedCompanyPartnersListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaginatedCompanyPartnersListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.PaginatedCompanyPartnersListResponse";
        };

        return PaginatedCompanyPartnersListResponse;
    })();

    /**
     * RegistrationStatus enum.
     * @name companyservice.RegistrationStatus
     * @enum {number}
     * @property {number} REGISTRATION_STATUS_UNSPECIFIED=0 REGISTRATION_STATUS_UNSPECIFIED value
     * @property {number} UNREGISTERED=1 UNREGISTERED value
     * @property {number} INVITED=2 INVITED value
     * @property {number} REGISTERED=3 REGISTERED value
     * @property {number} EXPIRED=4 EXPIRED value
     */
    companyservice.RegistrationStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REGISTRATION_STATUS_UNSPECIFIED"] = 0;
        values[valuesById[1] = "UNREGISTERED"] = 1;
        values[valuesById[2] = "INVITED"] = 2;
        values[valuesById[3] = "REGISTERED"] = 3;
        values[valuesById[4] = "EXPIRED"] = 4;
        return values;
    })();

    companyservice.BusinessPartner = (function() {

        /**
         * Properties of a BusinessPartner.
         * @memberof companyservice
         * @interface IBusinessPartner
         * @property {google.protobuf.IStringValue|null} [businessPartnerId] BusinessPartner businessPartnerId
         * @property {google.protobuf.IStringValue|null} [name] BusinessPartner name
         * @property {google.protobuf.IStringValue|null} [contactFirstName] BusinessPartner contactFirstName
         * @property {google.protobuf.IStringValue|null} [contactName] BusinessPartner contactName
         * @property {google.protobuf.IStringValue|null} [addressStreet] BusinessPartner addressStreet
         * @property {google.protobuf.IStringValue|null} [addressHouseNumber] BusinessPartner addressHouseNumber
         * @property {google.protobuf.IStringValue|null} [addressZipCode] BusinessPartner addressZipCode
         * @property {google.protobuf.IStringValue|null} [addressTown] BusinessPartner addressTown
         * @property {google.type.IDate|null} [lastImported] BusinessPartner lastImported
         * @property {google.type.IDate|null} [registrationCodeCreatedTimestamp] BusinessPartner registrationCodeCreatedTimestamp
         * @property {companyservice.RegistrationStatus|null} [registrationStatus] BusinessPartner registrationStatus
         * @property {google.protobuf.ITimestamp|null} [createdOrModifiedTimestamp] BusinessPartner createdOrModifiedTimestamp
         */

        /**
         * Constructs a new BusinessPartner.
         * @memberof companyservice
         * @classdesc Represents a BusinessPartner.
         * @implements IBusinessPartner
         * @constructor
         * @param {companyservice.IBusinessPartner=} [properties] Properties to set
         */
        function BusinessPartner(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BusinessPartner businessPartnerId.
         * @member {google.protobuf.IStringValue|null|undefined} businessPartnerId
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.businessPartnerId = null;

        /**
         * BusinessPartner name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.name = null;

        /**
         * BusinessPartner contactFirstName.
         * @member {google.protobuf.IStringValue|null|undefined} contactFirstName
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.contactFirstName = null;

        /**
         * BusinessPartner contactName.
         * @member {google.protobuf.IStringValue|null|undefined} contactName
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.contactName = null;

        /**
         * BusinessPartner addressStreet.
         * @member {google.protobuf.IStringValue|null|undefined} addressStreet
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.addressStreet = null;

        /**
         * BusinessPartner addressHouseNumber.
         * @member {google.protobuf.IStringValue|null|undefined} addressHouseNumber
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.addressHouseNumber = null;

        /**
         * BusinessPartner addressZipCode.
         * @member {google.protobuf.IStringValue|null|undefined} addressZipCode
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.addressZipCode = null;

        /**
         * BusinessPartner addressTown.
         * @member {google.protobuf.IStringValue|null|undefined} addressTown
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.addressTown = null;

        /**
         * BusinessPartner lastImported.
         * @member {google.type.IDate|null|undefined} lastImported
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.lastImported = null;

        /**
         * BusinessPartner registrationCodeCreatedTimestamp.
         * @member {google.type.IDate|null|undefined} registrationCodeCreatedTimestamp
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.registrationCodeCreatedTimestamp = null;

        /**
         * BusinessPartner registrationStatus.
         * @member {companyservice.RegistrationStatus} registrationStatus
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.registrationStatus = 0;

        /**
         * BusinessPartner createdOrModifiedTimestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} createdOrModifiedTimestamp
         * @memberof companyservice.BusinessPartner
         * @instance
         */
        BusinessPartner.prototype.createdOrModifiedTimestamp = null;

        /**
         * Gets the default type url for BusinessPartner
         * @function getTypeUrl
         * @memberof companyservice.BusinessPartner
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BusinessPartner.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.BusinessPartner";
        };

        return BusinessPartner;
    })();

    companyservice.CompanyPartner = (function() {

        /**
         * Properties of a CompanyPartner.
         * @memberof companyservice
         * @interface ICompanyPartner
         * @property {google.protobuf.IStringValue|null} [id] CompanyPartner id
         * @property {google.protobuf.IStringValue|null} [name] CompanyPartner name
         * @property {google.protobuf.IStringValue|null} [contactFirstName] CompanyPartner contactFirstName
         * @property {google.protobuf.IStringValue|null} [contactName] CompanyPartner contactName
         * @property {google.protobuf.IStringValue|null} [addressStreet] CompanyPartner addressStreet
         * @property {google.protobuf.IStringValue|null} [addressHouseNumber] CompanyPartner addressHouseNumber
         * @property {google.protobuf.IStringValue|null} [addressZipCode] CompanyPartner addressZipCode
         * @property {google.protobuf.IStringValue|null} [addressTown] CompanyPartner addressTown
         * @property {google.protobuf.IStringValue|null} [fusionCode] CompanyPartner fusionCode
         * @property {google.type.IDate|null} [fusionCodeCreatedTimestamp] CompanyPartner fusionCodeCreatedTimestamp
         * @property {companyservice.RegistrationStatus|null} [fusionStatus] CompanyPartner fusionStatus
         * @property {google.protobuf.ITimestamp|null} [createdOrModifiedTimestamp] CompanyPartner createdOrModifiedTimestamp
         * @property {google.protobuf.IStringValue|null} [contactEmail] CompanyPartner contactEmail
         * @property {google.protobuf.IStringValue|null} [contactPhone] CompanyPartner contactPhone
         * @property {google.protobuf.IStringValue|null} [contactIsoLanguageCode] CompanyPartner contactIsoLanguageCode
         */

        /**
         * Constructs a new CompanyPartner.
         * @memberof companyservice
         * @classdesc Represents a CompanyPartner.
         * @implements ICompanyPartner
         * @constructor
         * @param {companyservice.ICompanyPartner=} [properties] Properties to set
         */
        function CompanyPartner(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompanyPartner id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.id = null;

        /**
         * CompanyPartner name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.name = null;

        /**
         * CompanyPartner contactFirstName.
         * @member {google.protobuf.IStringValue|null|undefined} contactFirstName
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.contactFirstName = null;

        /**
         * CompanyPartner contactName.
         * @member {google.protobuf.IStringValue|null|undefined} contactName
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.contactName = null;

        /**
         * CompanyPartner addressStreet.
         * @member {google.protobuf.IStringValue|null|undefined} addressStreet
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.addressStreet = null;

        /**
         * CompanyPartner addressHouseNumber.
         * @member {google.protobuf.IStringValue|null|undefined} addressHouseNumber
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.addressHouseNumber = null;

        /**
         * CompanyPartner addressZipCode.
         * @member {google.protobuf.IStringValue|null|undefined} addressZipCode
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.addressZipCode = null;

        /**
         * CompanyPartner addressTown.
         * @member {google.protobuf.IStringValue|null|undefined} addressTown
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.addressTown = null;

        /**
         * CompanyPartner fusionCode.
         * @member {google.protobuf.IStringValue|null|undefined} fusionCode
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.fusionCode = null;

        /**
         * CompanyPartner fusionCodeCreatedTimestamp.
         * @member {google.type.IDate|null|undefined} fusionCodeCreatedTimestamp
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.fusionCodeCreatedTimestamp = null;

        /**
         * CompanyPartner fusionStatus.
         * @member {companyservice.RegistrationStatus} fusionStatus
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.fusionStatus = 0;

        /**
         * CompanyPartner createdOrModifiedTimestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} createdOrModifiedTimestamp
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.createdOrModifiedTimestamp = null;

        /**
         * CompanyPartner contactEmail.
         * @member {google.protobuf.IStringValue|null|undefined} contactEmail
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.contactEmail = null;

        /**
         * CompanyPartner contactPhone.
         * @member {google.protobuf.IStringValue|null|undefined} contactPhone
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.contactPhone = null;

        /**
         * CompanyPartner contactIsoLanguageCode.
         * @member {google.protobuf.IStringValue|null|undefined} contactIsoLanguageCode
         * @memberof companyservice.CompanyPartner
         * @instance
         */
        CompanyPartner.prototype.contactIsoLanguageCode = null;

        /**
         * Gets the default type url for CompanyPartner
         * @function getTypeUrl
         * @memberof companyservice.CompanyPartner
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CompanyPartner.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.CompanyPartner";
        };

        return CompanyPartner;
    })();

    companyservice.RegisterCompanyPartnerRequest = (function() {

        /**
         * Properties of a RegisterCompanyPartnerRequest.
         * @memberof companyservice
         * @interface IRegisterCompanyPartnerRequest
         * @property {google.protobuf.IStringValue|null} [registrationCode] RegisterCompanyPartnerRequest registrationCode
         * @property {google.protobuf.IStringValue|null} [contactFirstName] RegisterCompanyPartnerRequest contactFirstName
         * @property {google.protobuf.IStringValue|null} [contactName] RegisterCompanyPartnerRequest contactName
         * @property {google.protobuf.IStringValue|null} [contactEmail] RegisterCompanyPartnerRequest contactEmail
         * @property {google.protobuf.IStringValue|null} [contactSalutation] RegisterCompanyPartnerRequest contactSalutation
         * @property {google.protobuf.IStringValue|null} [contactPhone] RegisterCompanyPartnerRequest contactPhone
         * @property {google.protobuf.IStringValue|null} [contactIsoLanguageCode] RegisterCompanyPartnerRequest contactIsoLanguageCode
         * @property {google.protobuf.IStringValue|null} [contactPassword] RegisterCompanyPartnerRequest contactPassword
         * @property {google.protobuf.IInt32Value|null} [partnerId] RegisterCompanyPartnerRequest partnerId
         */

        /**
         * Constructs a new RegisterCompanyPartnerRequest.
         * @memberof companyservice
         * @classdesc Represents a RegisterCompanyPartnerRequest.
         * @implements IRegisterCompanyPartnerRequest
         * @constructor
         * @param {companyservice.IRegisterCompanyPartnerRequest=} [properties] Properties to set
         */
        function RegisterCompanyPartnerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegisterCompanyPartnerRequest registrationCode.
         * @member {google.protobuf.IStringValue|null|undefined} registrationCode
         * @memberof companyservice.RegisterCompanyPartnerRequest
         * @instance
         */
        RegisterCompanyPartnerRequest.prototype.registrationCode = null;

        /**
         * RegisterCompanyPartnerRequest contactFirstName.
         * @member {google.protobuf.IStringValue|null|undefined} contactFirstName
         * @memberof companyservice.RegisterCompanyPartnerRequest
         * @instance
         */
        RegisterCompanyPartnerRequest.prototype.contactFirstName = null;

        /**
         * RegisterCompanyPartnerRequest contactName.
         * @member {google.protobuf.IStringValue|null|undefined} contactName
         * @memberof companyservice.RegisterCompanyPartnerRequest
         * @instance
         */
        RegisterCompanyPartnerRequest.prototype.contactName = null;

        /**
         * RegisterCompanyPartnerRequest contactEmail.
         * @member {google.protobuf.IStringValue|null|undefined} contactEmail
         * @memberof companyservice.RegisterCompanyPartnerRequest
         * @instance
         */
        RegisterCompanyPartnerRequest.prototype.contactEmail = null;

        /**
         * RegisterCompanyPartnerRequest contactSalutation.
         * @member {google.protobuf.IStringValue|null|undefined} contactSalutation
         * @memberof companyservice.RegisterCompanyPartnerRequest
         * @instance
         */
        RegisterCompanyPartnerRequest.prototype.contactSalutation = null;

        /**
         * RegisterCompanyPartnerRequest contactPhone.
         * @member {google.protobuf.IStringValue|null|undefined} contactPhone
         * @memberof companyservice.RegisterCompanyPartnerRequest
         * @instance
         */
        RegisterCompanyPartnerRequest.prototype.contactPhone = null;

        /**
         * RegisterCompanyPartnerRequest contactIsoLanguageCode.
         * @member {google.protobuf.IStringValue|null|undefined} contactIsoLanguageCode
         * @memberof companyservice.RegisterCompanyPartnerRequest
         * @instance
         */
        RegisterCompanyPartnerRequest.prototype.contactIsoLanguageCode = null;

        /**
         * RegisterCompanyPartnerRequest contactPassword.
         * @member {google.protobuf.IStringValue|null|undefined} contactPassword
         * @memberof companyservice.RegisterCompanyPartnerRequest
         * @instance
         */
        RegisterCompanyPartnerRequest.prototype.contactPassword = null;

        /**
         * RegisterCompanyPartnerRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.RegisterCompanyPartnerRequest
         * @instance
         */
        RegisterCompanyPartnerRequest.prototype.partnerId = null;

        /**
         * Gets the default type url for RegisterCompanyPartnerRequest
         * @function getTypeUrl
         * @memberof companyservice.RegisterCompanyPartnerRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RegisterCompanyPartnerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.RegisterCompanyPartnerRequest";
        };

        return RegisterCompanyPartnerRequest;
    })();

    /**
     * RegistrationResult enum.
     * @name companyservice.RegistrationResult
     * @enum {number}
     * @property {number} REGISTRATION_RESULT_UNSPECIFIED=0 REGISTRATION_RESULT_UNSPECIFIED value
     * @property {number} SUCCESS=1 SUCCESS value
     * @property {number} REGISTRATION_CODE_INVALID=2 REGISTRATION_CODE_INVALID value
     * @property {number} REGISTRATION_CODE_ALREADY_USED=3 REGISTRATION_CODE_ALREADY_USED value
     * @property {number} REGISTRATION_CODE_EXPIRED=4 REGISTRATION_CODE_EXPIRED value
     * @property {number} REGISTRATION_CODE_HAS_WRONG_STATUS=5 REGISTRATION_CODE_HAS_WRONG_STATUS value
     */
    companyservice.RegistrationResult = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REGISTRATION_RESULT_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SUCCESS"] = 1;
        values[valuesById[2] = "REGISTRATION_CODE_INVALID"] = 2;
        values[valuesById[3] = "REGISTRATION_CODE_ALREADY_USED"] = 3;
        values[valuesById[4] = "REGISTRATION_CODE_EXPIRED"] = 4;
        values[valuesById[5] = "REGISTRATION_CODE_HAS_WRONG_STATUS"] = 5;
        return values;
    })();

    companyservice.RegisterCompanyPartnerResponse = (function() {

        /**
         * Properties of a RegisterCompanyPartnerResponse.
         * @memberof companyservice
         * @interface IRegisterCompanyPartnerResponse
         * @property {base.ILocalizedResult|null} [result] RegisterCompanyPartnerResponse result
         * @property {companyservice.RegistrationResult|null} [registrationResult] RegisterCompanyPartnerResponse registrationResult
         * @property {google.protobuf.IInt32Value|null} [partnerId] RegisterCompanyPartnerResponse partnerId
         * @property {companyservice.IBusinessPartner|null} [businessPartner] RegisterCompanyPartnerResponse businessPartner
         */

        /**
         * Constructs a new RegisterCompanyPartnerResponse.
         * @memberof companyservice
         * @classdesc Represents a RegisterCompanyPartnerResponse.
         * @implements IRegisterCompanyPartnerResponse
         * @constructor
         * @param {companyservice.IRegisterCompanyPartnerResponse=} [properties] Properties to set
         */
        function RegisterCompanyPartnerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RegisterCompanyPartnerResponse result.
         * @member {base.ILocalizedResult|null|undefined} result
         * @memberof companyservice.RegisterCompanyPartnerResponse
         * @instance
         */
        RegisterCompanyPartnerResponse.prototype.result = null;

        /**
         * RegisterCompanyPartnerResponse registrationResult.
         * @member {companyservice.RegistrationResult} registrationResult
         * @memberof companyservice.RegisterCompanyPartnerResponse
         * @instance
         */
        RegisterCompanyPartnerResponse.prototype.registrationResult = 0;

        /**
         * RegisterCompanyPartnerResponse partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.RegisterCompanyPartnerResponse
         * @instance
         */
        RegisterCompanyPartnerResponse.prototype.partnerId = null;

        /**
         * RegisterCompanyPartnerResponse businessPartner.
         * @member {companyservice.IBusinessPartner|null|undefined} businessPartner
         * @memberof companyservice.RegisterCompanyPartnerResponse
         * @instance
         */
        RegisterCompanyPartnerResponse.prototype.businessPartner = null;

        /**
         * Gets the default type url for RegisterCompanyPartnerResponse
         * @function getTypeUrl
         * @memberof companyservice.RegisterCompanyPartnerResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RegisterCompanyPartnerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.RegisterCompanyPartnerResponse";
        };

        return RegisterCompanyPartnerResponse;
    })();

    companyservice.GetCompanyPartnerRequest = (function() {

        /**
         * Properties of a GetCompanyPartnerRequest.
         * @memberof companyservice
         * @interface IGetCompanyPartnerRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] GetCompanyPartnerRequest partnerId
         */

        /**
         * Constructs a new GetCompanyPartnerRequest.
         * @memberof companyservice
         * @classdesc Represents a GetCompanyPartnerRequest.
         * @implements IGetCompanyPartnerRequest
         * @constructor
         * @param {companyservice.IGetCompanyPartnerRequest=} [properties] Properties to set
         */
        function GetCompanyPartnerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCompanyPartnerRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.GetCompanyPartnerRequest
         * @instance
         */
        GetCompanyPartnerRequest.prototype.partnerId = null;

        /**
         * Gets the default type url for GetCompanyPartnerRequest
         * @function getTypeUrl
         * @memberof companyservice.GetCompanyPartnerRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetCompanyPartnerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.GetCompanyPartnerRequest";
        };

        return GetCompanyPartnerRequest;
    })();

    companyservice.GetCompanyPartnerResponse = (function() {

        /**
         * Properties of a GetCompanyPartnerResponse.
         * @memberof companyservice
         * @interface IGetCompanyPartnerResponse
         * @property {base.ILocalizedResult|null} [result] GetCompanyPartnerResponse result
         * @property {google.protobuf.IInt32Value|null} [partnerId] GetCompanyPartnerResponse partnerId
         * @property {companyservice.IBusinessPartner|null} [businessPartner] GetCompanyPartnerResponse businessPartner
         * @property {companyservice.ICompanyPartner|null} [companyPartner] GetCompanyPartnerResponse companyPartner
         * @property {google.protobuf.IBoolValue|null} [hasMultipleVnb] GetCompanyPartnerResponse hasMultipleVnb
         */

        /**
         * Constructs a new GetCompanyPartnerResponse.
         * @memberof companyservice
         * @classdesc Represents a GetCompanyPartnerResponse.
         * @implements IGetCompanyPartnerResponse
         * @constructor
         * @param {companyservice.IGetCompanyPartnerResponse=} [properties] Properties to set
         */
        function GetCompanyPartnerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCompanyPartnerResponse result.
         * @member {base.ILocalizedResult|null|undefined} result
         * @memberof companyservice.GetCompanyPartnerResponse
         * @instance
         */
        GetCompanyPartnerResponse.prototype.result = null;

        /**
         * GetCompanyPartnerResponse partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.GetCompanyPartnerResponse
         * @instance
         */
        GetCompanyPartnerResponse.prototype.partnerId = null;

        /**
         * GetCompanyPartnerResponse businessPartner.
         * @member {companyservice.IBusinessPartner|null|undefined} businessPartner
         * @memberof companyservice.GetCompanyPartnerResponse
         * @instance
         */
        GetCompanyPartnerResponse.prototype.businessPartner = null;

        /**
         * GetCompanyPartnerResponse companyPartner.
         * @member {companyservice.ICompanyPartner|null|undefined} companyPartner
         * @memberof companyservice.GetCompanyPartnerResponse
         * @instance
         */
        GetCompanyPartnerResponse.prototype.companyPartner = null;

        /**
         * GetCompanyPartnerResponse hasMultipleVnb.
         * @member {google.protobuf.IBoolValue|null|undefined} hasMultipleVnb
         * @memberof companyservice.GetCompanyPartnerResponse
         * @instance
         */
        GetCompanyPartnerResponse.prototype.hasMultipleVnb = null;

        /**
         * Gets the default type url for GetCompanyPartnerResponse
         * @function getTypeUrl
         * @memberof companyservice.GetCompanyPartnerResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetCompanyPartnerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.GetCompanyPartnerResponse";
        };

        return GetCompanyPartnerResponse;
    })();

    companyservice.BusinessPartnerRequest = (function() {

        /**
         * Properties of a BusinessPartnerRequest.
         * @memberof companyservice
         * @interface IBusinessPartnerRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] BusinessPartnerRequest partnerId
         * @property {google.protobuf.IStringValue|null} [businessPartnerId] BusinessPartnerRequest businessPartnerId
         * @property {google.protobuf.IBoolValue|null} [returnLinkedCompanyPartner] BusinessPartnerRequest returnLinkedCompanyPartner
         */

        /**
         * Constructs a new BusinessPartnerRequest.
         * @memberof companyservice
         * @classdesc Represents a BusinessPartnerRequest.
         * @implements IBusinessPartnerRequest
         * @constructor
         * @param {companyservice.IBusinessPartnerRequest=} [properties] Properties to set
         */
        function BusinessPartnerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BusinessPartnerRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.BusinessPartnerRequest
         * @instance
         */
        BusinessPartnerRequest.prototype.partnerId = null;

        /**
         * BusinessPartnerRequest businessPartnerId.
         * @member {google.protobuf.IStringValue|null|undefined} businessPartnerId
         * @memberof companyservice.BusinessPartnerRequest
         * @instance
         */
        BusinessPartnerRequest.prototype.businessPartnerId = null;

        /**
         * BusinessPartnerRequest returnLinkedCompanyPartner.
         * @member {google.protobuf.IBoolValue|null|undefined} returnLinkedCompanyPartner
         * @memberof companyservice.BusinessPartnerRequest
         * @instance
         */
        BusinessPartnerRequest.prototype.returnLinkedCompanyPartner = null;

        /**
         * Gets the default type url for BusinessPartnerRequest
         * @function getTypeUrl
         * @memberof companyservice.BusinessPartnerRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BusinessPartnerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.BusinessPartnerRequest";
        };

        return BusinessPartnerRequest;
    })();

    companyservice.GetCompanyByRegistrationCodeRequest = (function() {

        /**
         * Properties of a GetCompanyByRegistrationCodeRequest.
         * @memberof companyservice
         * @interface IGetCompanyByRegistrationCodeRequest
         * @property {google.protobuf.IStringValue|null} [registrationCode] GetCompanyByRegistrationCodeRequest registrationCode
         */

        /**
         * Constructs a new GetCompanyByRegistrationCodeRequest.
         * @memberof companyservice
         * @classdesc Represents a GetCompanyByRegistrationCodeRequest.
         * @implements IGetCompanyByRegistrationCodeRequest
         * @constructor
         * @param {companyservice.IGetCompanyByRegistrationCodeRequest=} [properties] Properties to set
         */
        function GetCompanyByRegistrationCodeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCompanyByRegistrationCodeRequest registrationCode.
         * @member {google.protobuf.IStringValue|null|undefined} registrationCode
         * @memberof companyservice.GetCompanyByRegistrationCodeRequest
         * @instance
         */
        GetCompanyByRegistrationCodeRequest.prototype.registrationCode = null;

        /**
         * Gets the default type url for GetCompanyByRegistrationCodeRequest
         * @function getTypeUrl
         * @memberof companyservice.GetCompanyByRegistrationCodeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetCompanyByRegistrationCodeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.GetCompanyByRegistrationCodeRequest";
        };

        return GetCompanyByRegistrationCodeRequest;
    })();

    companyservice.GetCompanyByRegistrationCodeResponse = (function() {

        /**
         * Properties of a GetCompanyByRegistrationCodeResponse.
         * @memberof companyservice
         * @interface IGetCompanyByRegistrationCodeResponse
         * @property {base.ILocalizedResult|null} [result] GetCompanyByRegistrationCodeResponse result
         * @property {google.protobuf.IStringValue|null} [registrationCode] GetCompanyByRegistrationCodeResponse registrationCode
         * @property {google.protobuf.IBoolValue|null} [registrationCodeExpired] GetCompanyByRegistrationCodeResponse registrationCodeExpired
         * @property {google.protobuf.IBoolValue|null} [registrationCodeHasWrongStatus] GetCompanyByRegistrationCodeResponse registrationCodeHasWrongStatus
         * @property {companyservice.RegistrationStatus|null} [registrationStatus] GetCompanyByRegistrationCodeResponse registrationStatus
         * @property {companyservice.IBusinessPartner|null} [businessPartner] GetCompanyByRegistrationCodeResponse businessPartner
         * @property {companyservice.ICompanyPartner|null} [companyPartner] GetCompanyByRegistrationCodeResponse companyPartner
         */

        /**
         * Constructs a new GetCompanyByRegistrationCodeResponse.
         * @memberof companyservice
         * @classdesc Represents a GetCompanyByRegistrationCodeResponse.
         * @implements IGetCompanyByRegistrationCodeResponse
         * @constructor
         * @param {companyservice.IGetCompanyByRegistrationCodeResponse=} [properties] Properties to set
         */
        function GetCompanyByRegistrationCodeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCompanyByRegistrationCodeResponse result.
         * @member {base.ILocalizedResult|null|undefined} result
         * @memberof companyservice.GetCompanyByRegistrationCodeResponse
         * @instance
         */
        GetCompanyByRegistrationCodeResponse.prototype.result = null;

        /**
         * GetCompanyByRegistrationCodeResponse registrationCode.
         * @member {google.protobuf.IStringValue|null|undefined} registrationCode
         * @memberof companyservice.GetCompanyByRegistrationCodeResponse
         * @instance
         */
        GetCompanyByRegistrationCodeResponse.prototype.registrationCode = null;

        /**
         * GetCompanyByRegistrationCodeResponse registrationCodeExpired.
         * @member {google.protobuf.IBoolValue|null|undefined} registrationCodeExpired
         * @memberof companyservice.GetCompanyByRegistrationCodeResponse
         * @instance
         */
        GetCompanyByRegistrationCodeResponse.prototype.registrationCodeExpired = null;

        /**
         * GetCompanyByRegistrationCodeResponse registrationCodeHasWrongStatus.
         * @member {google.protobuf.IBoolValue|null|undefined} registrationCodeHasWrongStatus
         * @memberof companyservice.GetCompanyByRegistrationCodeResponse
         * @instance
         */
        GetCompanyByRegistrationCodeResponse.prototype.registrationCodeHasWrongStatus = null;

        /**
         * GetCompanyByRegistrationCodeResponse registrationStatus.
         * @member {companyservice.RegistrationStatus} registrationStatus
         * @memberof companyservice.GetCompanyByRegistrationCodeResponse
         * @instance
         */
        GetCompanyByRegistrationCodeResponse.prototype.registrationStatus = 0;

        /**
         * GetCompanyByRegistrationCodeResponse businessPartner.
         * @member {companyservice.IBusinessPartner|null|undefined} businessPartner
         * @memberof companyservice.GetCompanyByRegistrationCodeResponse
         * @instance
         */
        GetCompanyByRegistrationCodeResponse.prototype.businessPartner = null;

        /**
         * GetCompanyByRegistrationCodeResponse companyPartner.
         * @member {companyservice.ICompanyPartner|null|undefined} companyPartner
         * @memberof companyservice.GetCompanyByRegistrationCodeResponse
         * @instance
         */
        GetCompanyByRegistrationCodeResponse.prototype.companyPartner = null;

        /**
         * Gets the default type url for GetCompanyByRegistrationCodeResponse
         * @function getTypeUrl
         * @memberof companyservice.GetCompanyByRegistrationCodeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetCompanyByRegistrationCodeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.GetCompanyByRegistrationCodeResponse";
        };

        return GetCompanyByRegistrationCodeResponse;
    })();

    companyservice.UniteCompanyPartnerRequest = (function() {

        /**
         * Properties of an UniteCompanyPartnerRequest.
         * @memberof companyservice
         * @interface IUniteCompanyPartnerRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] UniteCompanyPartnerRequest partnerId
         * @property {google.protobuf.IStringValue|null} [registrationCode] UniteCompanyPartnerRequest registrationCode
         */

        /**
         * Constructs a new UniteCompanyPartnerRequest.
         * @memberof companyservice
         * @classdesc Represents an UniteCompanyPartnerRequest.
         * @implements IUniteCompanyPartnerRequest
         * @constructor
         * @param {companyservice.IUniteCompanyPartnerRequest=} [properties] Properties to set
         */
        function UniteCompanyPartnerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UniteCompanyPartnerRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.UniteCompanyPartnerRequest
         * @instance
         */
        UniteCompanyPartnerRequest.prototype.partnerId = null;

        /**
         * UniteCompanyPartnerRequest registrationCode.
         * @member {google.protobuf.IStringValue|null|undefined} registrationCode
         * @memberof companyservice.UniteCompanyPartnerRequest
         * @instance
         */
        UniteCompanyPartnerRequest.prototype.registrationCode = null;

        /**
         * Gets the default type url for UniteCompanyPartnerRequest
         * @function getTypeUrl
         * @memberof companyservice.UniteCompanyPartnerRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UniteCompanyPartnerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.UniteCompanyPartnerRequest";
        };

        return UniteCompanyPartnerRequest;
    })();

    companyservice.UniteCompanyPartnerResponse = (function() {

        /**
         * Properties of an UniteCompanyPartnerResponse.
         * @memberof companyservice
         * @interface IUniteCompanyPartnerResponse
         * @property {base.ILocalizedResult|null} [result] UniteCompanyPartnerResponse result
         * @property {companyservice.RegistrationResult|null} [registrationResult] UniteCompanyPartnerResponse registrationResult
         * @property {google.protobuf.IInt32Value|null} [targetPartnerId] UniteCompanyPartnerResponse targetPartnerId
         * @property {google.protobuf.IInt32Value|null} [partnerIdDeleted] UniteCompanyPartnerResponse partnerIdDeleted
         * @property {google.protobuf.IInt32Value|null} [numberOfTransferredBusinessPartners] UniteCompanyPartnerResponse numberOfTransferredBusinessPartners
         * @property {google.protobuf.IInt32Value|null} [numberOfTransferredUsers] UniteCompanyPartnerResponse numberOfTransferredUsers
         */

        /**
         * Constructs a new UniteCompanyPartnerResponse.
         * @memberof companyservice
         * @classdesc Represents an UniteCompanyPartnerResponse.
         * @implements IUniteCompanyPartnerResponse
         * @constructor
         * @param {companyservice.IUniteCompanyPartnerResponse=} [properties] Properties to set
         */
        function UniteCompanyPartnerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UniteCompanyPartnerResponse result.
         * @member {base.ILocalizedResult|null|undefined} result
         * @memberof companyservice.UniteCompanyPartnerResponse
         * @instance
         */
        UniteCompanyPartnerResponse.prototype.result = null;

        /**
         * UniteCompanyPartnerResponse registrationResult.
         * @member {companyservice.RegistrationResult} registrationResult
         * @memberof companyservice.UniteCompanyPartnerResponse
         * @instance
         */
        UniteCompanyPartnerResponse.prototype.registrationResult = 0;

        /**
         * UniteCompanyPartnerResponse targetPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} targetPartnerId
         * @memberof companyservice.UniteCompanyPartnerResponse
         * @instance
         */
        UniteCompanyPartnerResponse.prototype.targetPartnerId = null;

        /**
         * UniteCompanyPartnerResponse partnerIdDeleted.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerIdDeleted
         * @memberof companyservice.UniteCompanyPartnerResponse
         * @instance
         */
        UniteCompanyPartnerResponse.prototype.partnerIdDeleted = null;

        /**
         * UniteCompanyPartnerResponse numberOfTransferredBusinessPartners.
         * @member {google.protobuf.IInt32Value|null|undefined} numberOfTransferredBusinessPartners
         * @memberof companyservice.UniteCompanyPartnerResponse
         * @instance
         */
        UniteCompanyPartnerResponse.prototype.numberOfTransferredBusinessPartners = null;

        /**
         * UniteCompanyPartnerResponse numberOfTransferredUsers.
         * @member {google.protobuf.IInt32Value|null|undefined} numberOfTransferredUsers
         * @memberof companyservice.UniteCompanyPartnerResponse
         * @instance
         */
        UniteCompanyPartnerResponse.prototype.numberOfTransferredUsers = null;

        /**
         * Gets the default type url for UniteCompanyPartnerResponse
         * @function getTypeUrl
         * @memberof companyservice.UniteCompanyPartnerResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UniteCompanyPartnerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.UniteCompanyPartnerResponse";
        };

        return UniteCompanyPartnerResponse;
    })();

    companyservice.UpdateCompanyPartnerRequest = (function() {

        /**
         * Properties of an UpdateCompanyPartnerRequest.
         * @memberof companyservice
         * @interface IUpdateCompanyPartnerRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] UpdateCompanyPartnerRequest partnerId
         * @property {companyservice.ICompanyPartner|null} [companyPartner] UpdateCompanyPartnerRequest companyPartner
         */

        /**
         * Constructs a new UpdateCompanyPartnerRequest.
         * @memberof companyservice
         * @classdesc Represents an UpdateCompanyPartnerRequest.
         * @implements IUpdateCompanyPartnerRequest
         * @constructor
         * @param {companyservice.IUpdateCompanyPartnerRequest=} [properties] Properties to set
         */
        function UpdateCompanyPartnerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateCompanyPartnerRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.UpdateCompanyPartnerRequest
         * @instance
         */
        UpdateCompanyPartnerRequest.prototype.partnerId = null;

        /**
         * UpdateCompanyPartnerRequest companyPartner.
         * @member {companyservice.ICompanyPartner|null|undefined} companyPartner
         * @memberof companyservice.UpdateCompanyPartnerRequest
         * @instance
         */
        UpdateCompanyPartnerRequest.prototype.companyPartner = null;

        /**
         * Gets the default type url for UpdateCompanyPartnerRequest
         * @function getTypeUrl
         * @memberof companyservice.UpdateCompanyPartnerRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateCompanyPartnerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.UpdateCompanyPartnerRequest";
        };

        return UpdateCompanyPartnerRequest;
    })();

    companyservice.UpdateCompanyPartnerResponse = (function() {

        /**
         * Properties of an UpdateCompanyPartnerResponse.
         * @memberof companyservice
         * @interface IUpdateCompanyPartnerResponse
         * @property {base.ILocalizedResult|null} [result] UpdateCompanyPartnerResponse result
         * @property {companyservice.ICompanyPartner|null} [companyPartner] UpdateCompanyPartnerResponse companyPartner
         */

        /**
         * Constructs a new UpdateCompanyPartnerResponse.
         * @memberof companyservice
         * @classdesc Represents an UpdateCompanyPartnerResponse.
         * @implements IUpdateCompanyPartnerResponse
         * @constructor
         * @param {companyservice.IUpdateCompanyPartnerResponse=} [properties] Properties to set
         */
        function UpdateCompanyPartnerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateCompanyPartnerResponse result.
         * @member {base.ILocalizedResult|null|undefined} result
         * @memberof companyservice.UpdateCompanyPartnerResponse
         * @instance
         */
        UpdateCompanyPartnerResponse.prototype.result = null;

        /**
         * UpdateCompanyPartnerResponse companyPartner.
         * @member {companyservice.ICompanyPartner|null|undefined} companyPartner
         * @memberof companyservice.UpdateCompanyPartnerResponse
         * @instance
         */
        UpdateCompanyPartnerResponse.prototype.companyPartner = null;

        /**
         * Gets the default type url for UpdateCompanyPartnerResponse
         * @function getTypeUrl
         * @memberof companyservice.UpdateCompanyPartnerResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateCompanyPartnerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.UpdateCompanyPartnerResponse";
        };

        return UpdateCompanyPartnerResponse;
    })();

    companyservice.InitiateCompanyPartnerTransferRequest = (function() {

        /**
         * Properties of an InitiateCompanyPartnerTransferRequest.
         * @memberof companyservice
         * @interface IInitiateCompanyPartnerTransferRequest
         * @property {google.protobuf.IInt32Value|null} [sourcePartnerId] InitiateCompanyPartnerTransferRequest sourcePartnerId
         * @property {google.protobuf.IStringValue|null} [targetEmail] InitiateCompanyPartnerTransferRequest targetEmail
         */

        /**
         * Constructs a new InitiateCompanyPartnerTransferRequest.
         * @memberof companyservice
         * @classdesc Represents an InitiateCompanyPartnerTransferRequest.
         * @implements IInitiateCompanyPartnerTransferRequest
         * @constructor
         * @param {companyservice.IInitiateCompanyPartnerTransferRequest=} [properties] Properties to set
         */
        function InitiateCompanyPartnerTransferRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InitiateCompanyPartnerTransferRequest sourcePartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} sourcePartnerId
         * @memberof companyservice.InitiateCompanyPartnerTransferRequest
         * @instance
         */
        InitiateCompanyPartnerTransferRequest.prototype.sourcePartnerId = null;

        /**
         * InitiateCompanyPartnerTransferRequest targetEmail.
         * @member {google.protobuf.IStringValue|null|undefined} targetEmail
         * @memberof companyservice.InitiateCompanyPartnerTransferRequest
         * @instance
         */
        InitiateCompanyPartnerTransferRequest.prototype.targetEmail = null;

        /**
         * Gets the default type url for InitiateCompanyPartnerTransferRequest
         * @function getTypeUrl
         * @memberof companyservice.InitiateCompanyPartnerTransferRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InitiateCompanyPartnerTransferRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.InitiateCompanyPartnerTransferRequest";
        };

        return InitiateCompanyPartnerTransferRequest;
    })();

    companyservice.InitiateCompanyPartnerTransferResponse = (function() {

        /**
         * Properties of an InitiateCompanyPartnerTransferResponse.
         * @memberof companyservice
         * @interface IInitiateCompanyPartnerTransferResponse
         * @property {base.ILocalizedResult|null} [result] InitiateCompanyPartnerTransferResponse result
         */

        /**
         * Constructs a new InitiateCompanyPartnerTransferResponse.
         * @memberof companyservice
         * @classdesc Represents an InitiateCompanyPartnerTransferResponse.
         * @implements IInitiateCompanyPartnerTransferResponse
         * @constructor
         * @param {companyservice.IInitiateCompanyPartnerTransferResponse=} [properties] Properties to set
         */
        function InitiateCompanyPartnerTransferResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InitiateCompanyPartnerTransferResponse result.
         * @member {base.ILocalizedResult|null|undefined} result
         * @memberof companyservice.InitiateCompanyPartnerTransferResponse
         * @instance
         */
        InitiateCompanyPartnerTransferResponse.prototype.result = null;

        /**
         * Gets the default type url for InitiateCompanyPartnerTransferResponse
         * @function getTypeUrl
         * @memberof companyservice.InitiateCompanyPartnerTransferResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InitiateCompanyPartnerTransferResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.InitiateCompanyPartnerTransferResponse";
        };

        return InitiateCompanyPartnerTransferResponse;
    })();

    /**
     * DataSource enum.
     * @name companyservice.DataSource
     * @enum {number}
     * @property {number} DATA_SOURCE_UNSPECIFIED=0 DATA_SOURCE_UNSPECIFIED value
     * @property {number} CSV=1 CSV value
     * @property {number} SDAT=2 SDAT value
     * @property {number} ENTRY=3 ENTRY value
     * @property {number} SDAT_INCOMPLETE=4 SDAT_INCOMPLETE value
     */
    companyservice.DataSource = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DATA_SOURCE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "CSV"] = 1;
        values[valuesById[2] = "SDAT"] = 2;
        values[valuesById[3] = "ENTRY"] = 3;
        values[valuesById[4] = "SDAT_INCOMPLETE"] = 4;
        return values;
    })();

    companyservice.ConsumptionDataBusinessPartnersWithDailySdatRequest = (function() {

        /**
         * Properties of a ConsumptionDataBusinessPartnersWithDailySdatRequest.
         * @memberof companyservice
         * @interface IConsumptionDataBusinessPartnersWithDailySdatRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] ConsumptionDataBusinessPartnersWithDailySdatRequest partnerId
         * @property {google.protobuf.IStringValue|null} [businessPartnerId] ConsumptionDataBusinessPartnersWithDailySdatRequest businessPartnerId
         * @property {google.protobuf.IBoolValue|null} [returnLinkedCompanyPartner] ConsumptionDataBusinessPartnersWithDailySdatRequest returnLinkedCompanyPartner
         * @property {google.protobuf.IInt32Value|null} [yearOfDailyValues] ConsumptionDataBusinessPartnersWithDailySdatRequest yearOfDailyValues
         */

        /**
         * Constructs a new ConsumptionDataBusinessPartnersWithDailySdatRequest.
         * @memberof companyservice
         * @classdesc Represents a ConsumptionDataBusinessPartnersWithDailySdatRequest.
         * @implements IConsumptionDataBusinessPartnersWithDailySdatRequest
         * @constructor
         * @param {companyservice.IConsumptionDataBusinessPartnersWithDailySdatRequest=} [properties] Properties to set
         */
        function ConsumptionDataBusinessPartnersWithDailySdatRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumptionDataBusinessPartnersWithDailySdatRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.ConsumptionDataBusinessPartnersWithDailySdatRequest
         * @instance
         */
        ConsumptionDataBusinessPartnersWithDailySdatRequest.prototype.partnerId = null;

        /**
         * ConsumptionDataBusinessPartnersWithDailySdatRequest businessPartnerId.
         * @member {google.protobuf.IStringValue|null|undefined} businessPartnerId
         * @memberof companyservice.ConsumptionDataBusinessPartnersWithDailySdatRequest
         * @instance
         */
        ConsumptionDataBusinessPartnersWithDailySdatRequest.prototype.businessPartnerId = null;

        /**
         * ConsumptionDataBusinessPartnersWithDailySdatRequest returnLinkedCompanyPartner.
         * @member {google.protobuf.IBoolValue|null|undefined} returnLinkedCompanyPartner
         * @memberof companyservice.ConsumptionDataBusinessPartnersWithDailySdatRequest
         * @instance
         */
        ConsumptionDataBusinessPartnersWithDailySdatRequest.prototype.returnLinkedCompanyPartner = null;

        /**
         * ConsumptionDataBusinessPartnersWithDailySdatRequest yearOfDailyValues.
         * @member {google.protobuf.IInt32Value|null|undefined} yearOfDailyValues
         * @memberof companyservice.ConsumptionDataBusinessPartnersWithDailySdatRequest
         * @instance
         */
        ConsumptionDataBusinessPartnersWithDailySdatRequest.prototype.yearOfDailyValues = null;

        /**
         * Gets the default type url for ConsumptionDataBusinessPartnersWithDailySdatRequest
         * @function getTypeUrl
         * @memberof companyservice.ConsumptionDataBusinessPartnersWithDailySdatRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumptionDataBusinessPartnersWithDailySdatRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.ConsumptionDataBusinessPartnersWithDailySdatRequest";
        };

        return ConsumptionDataBusinessPartnersWithDailySdatRequest;
    })();

    companyservice.ConsumptionDataBusinessPartners = (function() {

        /**
         * Properties of a ConsumptionDataBusinessPartners.
         * @memberof companyservice
         * @interface IConsumptionDataBusinessPartners
         * @property {Array.<companyservice.IConsumptionDataBusinessPartner>|null} [businessPartners] ConsumptionDataBusinessPartners businessPartners
         * @property {Array.<companyservice.IConsumptionDataForYear>|null} [contingents] ConsumptionDataBusinessPartners contingents
         */

        /**
         * Constructs a new ConsumptionDataBusinessPartners.
         * @memberof companyservice
         * @classdesc Represents a ConsumptionDataBusinessPartners.
         * @implements IConsumptionDataBusinessPartners
         * @constructor
         * @param {companyservice.IConsumptionDataBusinessPartners=} [properties] Properties to set
         */
        function ConsumptionDataBusinessPartners(properties) {
            this.businessPartners = [];
            this.contingents = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumptionDataBusinessPartners businessPartners.
         * @member {Array.<companyservice.IConsumptionDataBusinessPartner>} businessPartners
         * @memberof companyservice.ConsumptionDataBusinessPartners
         * @instance
         */
        ConsumptionDataBusinessPartners.prototype.businessPartners = $util.emptyArray;

        /**
         * ConsumptionDataBusinessPartners contingents.
         * @member {Array.<companyservice.IConsumptionDataForYear>} contingents
         * @memberof companyservice.ConsumptionDataBusinessPartners
         * @instance
         */
        ConsumptionDataBusinessPartners.prototype.contingents = $util.emptyArray;

        /**
         * Gets the default type url for ConsumptionDataBusinessPartners
         * @function getTypeUrl
         * @memberof companyservice.ConsumptionDataBusinessPartners
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumptionDataBusinessPartners.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.ConsumptionDataBusinessPartners";
        };

        return ConsumptionDataBusinessPartners;
    })();

    companyservice.ConsumptionDataBusinessPartner = (function() {

        /**
         * Properties of a ConsumptionDataBusinessPartner.
         * @memberof companyservice
         * @interface IConsumptionDataBusinessPartner
         * @property {google.protobuf.IStringValue|null} [name] ConsumptionDataBusinessPartner name
         * @property {Array.<companyservice.IConsumptionDataMeteringPoint>|null} [meteringPoints] ConsumptionDataBusinessPartner meteringPoints
         * @property {google.protobuf.IInt32Value|null} [yearOfDailyValues] ConsumptionDataBusinessPartner yearOfDailyValues
         */

        /**
         * Constructs a new ConsumptionDataBusinessPartner.
         * @memberof companyservice
         * @classdesc Represents a ConsumptionDataBusinessPartner.
         * @implements IConsumptionDataBusinessPartner
         * @constructor
         * @param {companyservice.IConsumptionDataBusinessPartner=} [properties] Properties to set
         */
        function ConsumptionDataBusinessPartner(properties) {
            this.meteringPoints = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumptionDataBusinessPartner name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof companyservice.ConsumptionDataBusinessPartner
         * @instance
         */
        ConsumptionDataBusinessPartner.prototype.name = null;

        /**
         * ConsumptionDataBusinessPartner meteringPoints.
         * @member {Array.<companyservice.IConsumptionDataMeteringPoint>} meteringPoints
         * @memberof companyservice.ConsumptionDataBusinessPartner
         * @instance
         */
        ConsumptionDataBusinessPartner.prototype.meteringPoints = $util.emptyArray;

        /**
         * ConsumptionDataBusinessPartner yearOfDailyValues.
         * @member {google.protobuf.IInt32Value|null|undefined} yearOfDailyValues
         * @memberof companyservice.ConsumptionDataBusinessPartner
         * @instance
         */
        ConsumptionDataBusinessPartner.prototype.yearOfDailyValues = null;

        /**
         * Gets the default type url for ConsumptionDataBusinessPartner
         * @function getTypeUrl
         * @memberof companyservice.ConsumptionDataBusinessPartner
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumptionDataBusinessPartner.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.ConsumptionDataBusinessPartner";
        };

        return ConsumptionDataBusinessPartner;
    })();

    companyservice.ConsumptionDataMeteringPoint = (function() {

        /**
         * Properties of a ConsumptionDataMeteringPoint.
         * @memberof companyservice
         * @interface IConsumptionDataMeteringPoint
         * @property {google.protobuf.IStringValue|null} [id] ConsumptionDataMeteringPoint id
         * @property {Array.<companyservice.IConsumptionDataForYear>|null} [dataForYear] ConsumptionDataMeteringPoint dataForYear
         * @property {base.IMeteringPointSelector|null} [meteringPoint] ConsumptionDataMeteringPoint meteringPoint
         * @property {google.protobuf.IStringValue|null} [businessPartnerId] ConsumptionDataMeteringPoint businessPartnerId
         * @property {companyservice.IIdName|null} [mpa] ConsumptionDataMeteringPoint mpa
         * @property {google.protobuf.IStringValue|null} [businessPartnerName] ConsumptionDataMeteringPoint businessPartnerName
         * @property {companyservice.IAddress|null} [address] ConsumptionDataMeteringPoint address
         * @property {google.type.IDateRange|null} [valid] ConsumptionDataMeteringPoint valid
         */

        /**
         * Constructs a new ConsumptionDataMeteringPoint.
         * @memberof companyservice
         * @classdesc Represents a ConsumptionDataMeteringPoint.
         * @implements IConsumptionDataMeteringPoint
         * @constructor
         * @param {companyservice.IConsumptionDataMeteringPoint=} [properties] Properties to set
         */
        function ConsumptionDataMeteringPoint(properties) {
            this.dataForYear = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumptionDataMeteringPoint id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof companyservice.ConsumptionDataMeteringPoint
         * @instance
         */
        ConsumptionDataMeteringPoint.prototype.id = null;

        /**
         * ConsumptionDataMeteringPoint dataForYear.
         * @member {Array.<companyservice.IConsumptionDataForYear>} dataForYear
         * @memberof companyservice.ConsumptionDataMeteringPoint
         * @instance
         */
        ConsumptionDataMeteringPoint.prototype.dataForYear = $util.emptyArray;

        /**
         * ConsumptionDataMeteringPoint meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof companyservice.ConsumptionDataMeteringPoint
         * @instance
         */
        ConsumptionDataMeteringPoint.prototype.meteringPoint = null;

        /**
         * ConsumptionDataMeteringPoint businessPartnerId.
         * @member {google.protobuf.IStringValue|null|undefined} businessPartnerId
         * @memberof companyservice.ConsumptionDataMeteringPoint
         * @instance
         */
        ConsumptionDataMeteringPoint.prototype.businessPartnerId = null;

        /**
         * ConsumptionDataMeteringPoint mpa.
         * @member {companyservice.IIdName|null|undefined} mpa
         * @memberof companyservice.ConsumptionDataMeteringPoint
         * @instance
         */
        ConsumptionDataMeteringPoint.prototype.mpa = null;

        /**
         * ConsumptionDataMeteringPoint businessPartnerName.
         * @member {google.protobuf.IStringValue|null|undefined} businessPartnerName
         * @memberof companyservice.ConsumptionDataMeteringPoint
         * @instance
         */
        ConsumptionDataMeteringPoint.prototype.businessPartnerName = null;

        /**
         * ConsumptionDataMeteringPoint address.
         * @member {companyservice.IAddress|null|undefined} address
         * @memberof companyservice.ConsumptionDataMeteringPoint
         * @instance
         */
        ConsumptionDataMeteringPoint.prototype.address = null;

        /**
         * ConsumptionDataMeteringPoint valid.
         * @member {google.type.IDateRange|null|undefined} valid
         * @memberof companyservice.ConsumptionDataMeteringPoint
         * @instance
         */
        ConsumptionDataMeteringPoint.prototype.valid = null;

        /**
         * Gets the default type url for ConsumptionDataMeteringPoint
         * @function getTypeUrl
         * @memberof companyservice.ConsumptionDataMeteringPoint
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumptionDataMeteringPoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.ConsumptionDataMeteringPoint";
        };

        return ConsumptionDataMeteringPoint;
    })();

    companyservice.ConsumptionDataForYear = (function() {

        /**
         * Properties of a ConsumptionDataForYear.
         * @memberof companyservice
         * @interface IConsumptionDataForYear
         * @property {google.protobuf.IInt32Value|null} [year] ConsumptionDataForYear year
         * @property {Array.<google.protobuf.IDoubleValue>|null} [values] ConsumptionDataForYear values
         * @property {Array.<google.protobuf.IDoubleValue>|null} [contingents] ConsumptionDataForYear contingents
         * @property {Array.<companyservice.DataSource>|null} [dataSource] ConsumptionDataForYear dataSource
         * @property {Array.<companyservice.IConsumptionDataMeteringPointForMonth>|null} [dailyValues] ConsumptionDataForYear dailyValues
         */

        /**
         * Constructs a new ConsumptionDataForYear.
         * @memberof companyservice
         * @classdesc Represents a ConsumptionDataForYear.
         * @implements IConsumptionDataForYear
         * @constructor
         * @param {companyservice.IConsumptionDataForYear=} [properties] Properties to set
         */
        function ConsumptionDataForYear(properties) {
            this.values = [];
            this.contingents = [];
            this.dataSource = [];
            this.dailyValues = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumptionDataForYear year.
         * @member {google.protobuf.IInt32Value|null|undefined} year
         * @memberof companyservice.ConsumptionDataForYear
         * @instance
         */
        ConsumptionDataForYear.prototype.year = null;

        /**
         * ConsumptionDataForYear values.
         * @member {Array.<google.protobuf.IDoubleValue>} values
         * @memberof companyservice.ConsumptionDataForYear
         * @instance
         */
        ConsumptionDataForYear.prototype.values = $util.emptyArray;

        /**
         * ConsumptionDataForYear contingents.
         * @member {Array.<google.protobuf.IDoubleValue>} contingents
         * @memberof companyservice.ConsumptionDataForYear
         * @instance
         */
        ConsumptionDataForYear.prototype.contingents = $util.emptyArray;

        /**
         * ConsumptionDataForYear dataSource.
         * @member {Array.<companyservice.DataSource>} dataSource
         * @memberof companyservice.ConsumptionDataForYear
         * @instance
         */
        ConsumptionDataForYear.prototype.dataSource = $util.emptyArray;

        /**
         * ConsumptionDataForYear dailyValues.
         * @member {Array.<companyservice.IConsumptionDataMeteringPointForMonth>} dailyValues
         * @memberof companyservice.ConsumptionDataForYear
         * @instance
         */
        ConsumptionDataForYear.prototype.dailyValues = $util.emptyArray;

        /**
         * Gets the default type url for ConsumptionDataForYear
         * @function getTypeUrl
         * @memberof companyservice.ConsumptionDataForYear
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumptionDataForYear.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.ConsumptionDataForYear";
        };

        return ConsumptionDataForYear;
    })();

    companyservice.QuantitiesFor1Year = (function() {

        /**
         * Properties of a QuantitiesFor1Year.
         * @memberof companyservice
         * @interface IQuantitiesFor1Year
         * @property {Array.<google.protobuf.IDoubleValue>|null} [values] QuantitiesFor1Year values
         */

        /**
         * Constructs a new QuantitiesFor1Year.
         * @memberof companyservice
         * @classdesc Represents a QuantitiesFor1Year.
         * @implements IQuantitiesFor1Year
         * @constructor
         * @param {companyservice.IQuantitiesFor1Year=} [properties] Properties to set
         */
        function QuantitiesFor1Year(properties) {
            this.values = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QuantitiesFor1Year values.
         * @member {Array.<google.protobuf.IDoubleValue>} values
         * @memberof companyservice.QuantitiesFor1Year
         * @instance
         */
        QuantitiesFor1Year.prototype.values = $util.emptyArray;

        /**
         * Gets the default type url for QuantitiesFor1Year
         * @function getTypeUrl
         * @memberof companyservice.QuantitiesFor1Year
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        QuantitiesFor1Year.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.QuantitiesFor1Year";
        };

        return QuantitiesFor1Year;
    })();

    companyservice.ConsumptionDataMeteringPointForMonth = (function() {

        /**
         * Properties of a ConsumptionDataMeteringPointForMonth.
         * @memberof companyservice
         * @interface IConsumptionDataMeteringPointForMonth
         * @property {google.protobuf.IInt32Value|null} [month] ConsumptionDataMeteringPointForMonth month
         * @property {google.protobuf.IBoolValue|null} [isNoSdatAssigned] ConsumptionDataMeteringPointForMonth isNoSdatAssigned
         * @property {Array.<google.protobuf.IDoubleValue>|null} [values] ConsumptionDataMeteringPointForMonth values
         * @property {Array.<companyservice.DataSource>|null} [dataSource] ConsumptionDataMeteringPointForMonth dataSource
         */

        /**
         * Constructs a new ConsumptionDataMeteringPointForMonth.
         * @memberof companyservice
         * @classdesc Represents a ConsumptionDataMeteringPointForMonth.
         * @implements IConsumptionDataMeteringPointForMonth
         * @constructor
         * @param {companyservice.IConsumptionDataMeteringPointForMonth=} [properties] Properties to set
         */
        function ConsumptionDataMeteringPointForMonth(properties) {
            this.values = [];
            this.dataSource = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumptionDataMeteringPointForMonth month.
         * @member {google.protobuf.IInt32Value|null|undefined} month
         * @memberof companyservice.ConsumptionDataMeteringPointForMonth
         * @instance
         */
        ConsumptionDataMeteringPointForMonth.prototype.month = null;

        /**
         * ConsumptionDataMeteringPointForMonth isNoSdatAssigned.
         * @member {google.protobuf.IBoolValue|null|undefined} isNoSdatAssigned
         * @memberof companyservice.ConsumptionDataMeteringPointForMonth
         * @instance
         */
        ConsumptionDataMeteringPointForMonth.prototype.isNoSdatAssigned = null;

        /**
         * ConsumptionDataMeteringPointForMonth values.
         * @member {Array.<google.protobuf.IDoubleValue>} values
         * @memberof companyservice.ConsumptionDataMeteringPointForMonth
         * @instance
         */
        ConsumptionDataMeteringPointForMonth.prototype.values = $util.emptyArray;

        /**
         * ConsumptionDataMeteringPointForMonth dataSource.
         * @member {Array.<companyservice.DataSource>} dataSource
         * @memberof companyservice.ConsumptionDataMeteringPointForMonth
         * @instance
         */
        ConsumptionDataMeteringPointForMonth.prototype.dataSource = $util.emptyArray;

        /**
         * Gets the default type url for ConsumptionDataMeteringPointForMonth
         * @function getTypeUrl
         * @memberof companyservice.ConsumptionDataMeteringPointForMonth
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumptionDataMeteringPointForMonth.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.ConsumptionDataMeteringPointForMonth";
        };

        return ConsumptionDataMeteringPointForMonth;
    })();

    companyservice.MeteringPoints = (function() {

        /**
         * Properties of a MeteringPoints.
         * @memberof companyservice
         * @interface IMeteringPoints
         * @property {Array.<companyservice.IMeteringPointCompanyService>|null} [meteringPoints] MeteringPoints meteringPoints
         */

        /**
         * Constructs a new MeteringPoints.
         * @memberof companyservice
         * @classdesc Represents a MeteringPoints.
         * @implements IMeteringPoints
         * @constructor
         * @param {companyservice.IMeteringPoints=} [properties] Properties to set
         */
        function MeteringPoints(properties) {
            this.meteringPoints = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPoints meteringPoints.
         * @member {Array.<companyservice.IMeteringPointCompanyService>} meteringPoints
         * @memberof companyservice.MeteringPoints
         * @instance
         */
        MeteringPoints.prototype.meteringPoints = $util.emptyArray;

        /**
         * Gets the default type url for MeteringPoints
         * @function getTypeUrl
         * @memberof companyservice.MeteringPoints
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPoints.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.MeteringPoints";
        };

        return MeteringPoints;
    })();

    companyservice.MeteringPointCompanyService = (function() {

        /**
         * Properties of a MeteringPointCompanyService.
         * @memberof companyservice
         * @interface IMeteringPointCompanyService
         * @property {google.protobuf.IStringValue|null} [id] MeteringPointCompanyService id
         * @property {companyservice.IIdName|null} [mpa] MeteringPointCompanyService mpa
         * @property {google.protobuf.IStringValue|null} [businessPartnerName] MeteringPointCompanyService businessPartnerName
         * @property {companyservice.IAddress|null} [address] MeteringPointCompanyService address
         */

        /**
         * Constructs a new MeteringPointCompanyService.
         * @memberof companyservice
         * @classdesc Represents a MeteringPointCompanyService.
         * @implements IMeteringPointCompanyService
         * @constructor
         * @param {companyservice.IMeteringPointCompanyService=} [properties] Properties to set
         */
        function MeteringPointCompanyService(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointCompanyService id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof companyservice.MeteringPointCompanyService
         * @instance
         */
        MeteringPointCompanyService.prototype.id = null;

        /**
         * MeteringPointCompanyService mpa.
         * @member {companyservice.IIdName|null|undefined} mpa
         * @memberof companyservice.MeteringPointCompanyService
         * @instance
         */
        MeteringPointCompanyService.prototype.mpa = null;

        /**
         * MeteringPointCompanyService businessPartnerName.
         * @member {google.protobuf.IStringValue|null|undefined} businessPartnerName
         * @memberof companyservice.MeteringPointCompanyService
         * @instance
         */
        MeteringPointCompanyService.prototype.businessPartnerName = null;

        /**
         * MeteringPointCompanyService address.
         * @member {companyservice.IAddress|null|undefined} address
         * @memberof companyservice.MeteringPointCompanyService
         * @instance
         */
        MeteringPointCompanyService.prototype.address = null;

        /**
         * Gets the default type url for MeteringPointCompanyService
         * @function getTypeUrl
         * @memberof companyservice.MeteringPointCompanyService
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointCompanyService.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.MeteringPointCompanyService";
        };

        return MeteringPointCompanyService;
    })();

    companyservice.Address = (function() {

        /**
         * Properties of an Address.
         * @memberof companyservice
         * @interface IAddress
         * @property {google.protobuf.IStringValue|null} [street] Address street
         * @property {google.protobuf.IStringValue|null} [houseNumber] Address houseNumber
         * @property {google.protobuf.IStringValue|null} [zipCode] Address zipCode
         * @property {google.protobuf.IStringValue|null} [town] Address town
         */

        /**
         * Constructs a new Address.
         * @memberof companyservice
         * @classdesc Represents an Address.
         * @implements IAddress
         * @constructor
         * @param {companyservice.IAddress=} [properties] Properties to set
         */
        function Address(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Address street.
         * @member {google.protobuf.IStringValue|null|undefined} street
         * @memberof companyservice.Address
         * @instance
         */
        Address.prototype.street = null;

        /**
         * Address houseNumber.
         * @member {google.protobuf.IStringValue|null|undefined} houseNumber
         * @memberof companyservice.Address
         * @instance
         */
        Address.prototype.houseNumber = null;

        /**
         * Address zipCode.
         * @member {google.protobuf.IStringValue|null|undefined} zipCode
         * @memberof companyservice.Address
         * @instance
         */
        Address.prototype.zipCode = null;

        /**
         * Address town.
         * @member {google.protobuf.IStringValue|null|undefined} town
         * @memberof companyservice.Address
         * @instance
         */
        Address.prototype.town = null;

        /**
         * Gets the default type url for Address
         * @function getTypeUrl
         * @memberof companyservice.Address
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Address.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.Address";
        };

        return Address;
    })();

    companyservice.IdName = (function() {

        /**
         * Properties of an IdName.
         * @memberof companyservice
         * @interface IIdName
         * @property {google.protobuf.IInt32Value|null} [id] IdName id
         * @property {google.protobuf.IStringValue|null} [name] IdName name
         */

        /**
         * Constructs a new IdName.
         * @memberof companyservice
         * @classdesc Represents an IdName.
         * @implements IIdName
         * @constructor
         * @param {companyservice.IIdName=} [properties] Properties to set
         */
        function IdName(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IdName id.
         * @member {google.protobuf.IInt32Value|null|undefined} id
         * @memberof companyservice.IdName
         * @instance
         */
        IdName.prototype.id = null;

        /**
         * IdName name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof companyservice.IdName
         * @instance
         */
        IdName.prototype.name = null;

        /**
         * Gets the default type url for IdName
         * @function getTypeUrl
         * @memberof companyservice.IdName
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IdName.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.IdName";
        };

        return IdName;
    })();

    companyservice.MeteringPointSelectorId = (function() {

        /**
         * Properties of a MeteringPointSelectorId.
         * @memberof companyservice
         * @interface IMeteringPointSelectorId
         * @property {google.protobuf.IStringValue|null} [id] MeteringPointSelectorId id
         * @property {google.protobuf.IInt32Value|null} [administratorId] MeteringPointSelectorId administratorId
         */

        /**
         * Constructs a new MeteringPointSelectorId.
         * @memberof companyservice
         * @classdesc Represents a MeteringPointSelectorId.
         * @implements IMeteringPointSelectorId
         * @constructor
         * @param {companyservice.IMeteringPointSelectorId=} [properties] Properties to set
         */
        function MeteringPointSelectorId(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointSelectorId id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof companyservice.MeteringPointSelectorId
         * @instance
         */
        MeteringPointSelectorId.prototype.id = null;

        /**
         * MeteringPointSelectorId administratorId.
         * @member {google.protobuf.IInt32Value|null|undefined} administratorId
         * @memberof companyservice.MeteringPointSelectorId
         * @instance
         */
        MeteringPointSelectorId.prototype.administratorId = null;

        /**
         * Gets the default type url for MeteringPointSelectorId
         * @function getTypeUrl
         * @memberof companyservice.MeteringPointSelectorId
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointSelectorId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.MeteringPointSelectorId";
        };

        return MeteringPointSelectorId;
    })();

    companyservice.MeteringPointMonthlyRequest = (function() {

        /**
         * Properties of a MeteringPointMonthlyRequest.
         * @memberof companyservice
         * @interface IMeteringPointMonthlyRequest
         * @property {companyservice.IMeteringPointSelectorId|null} [selector] MeteringPointMonthlyRequest selector
         * @property {companyservice.IBusinessPartnerRequest|null} [businessPartner] MeteringPointMonthlyRequest businessPartner
         */

        /**
         * Constructs a new MeteringPointMonthlyRequest.
         * @memberof companyservice
         * @classdesc Represents a MeteringPointMonthlyRequest.
         * @implements IMeteringPointMonthlyRequest
         * @constructor
         * @param {companyservice.IMeteringPointMonthlyRequest=} [properties] Properties to set
         */
        function MeteringPointMonthlyRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointMonthlyRequest selector.
         * @member {companyservice.IMeteringPointSelectorId|null|undefined} selector
         * @memberof companyservice.MeteringPointMonthlyRequest
         * @instance
         */
        MeteringPointMonthlyRequest.prototype.selector = null;

        /**
         * MeteringPointMonthlyRequest businessPartner.
         * @member {companyservice.IBusinessPartnerRequest|null|undefined} businessPartner
         * @memberof companyservice.MeteringPointMonthlyRequest
         * @instance
         */
        MeteringPointMonthlyRequest.prototype.businessPartner = null;

        /**
         * Gets the default type url for MeteringPointMonthlyRequest
         * @function getTypeUrl
         * @memberof companyservice.MeteringPointMonthlyRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointMonthlyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.MeteringPointMonthlyRequest";
        };

        return MeteringPointMonthlyRequest;
    })();

    companyservice.MeteringPointMonthlyDetails = (function() {

        /**
         * Properties of a MeteringPointMonthlyDetails.
         * @memberof companyservice
         * @interface IMeteringPointMonthlyDetails
         * @property {Array.<companyservice.IMonthlyConsumption>|null} [monthlyConsumption] MeteringPointMonthlyDetails monthlyConsumption
         * @property {google.protobuf.IBoolValue|null} [referenceTimeframeComplete] MeteringPointMonthlyDetails referenceTimeframeComplete
         */

        /**
         * Constructs a new MeteringPointMonthlyDetails.
         * @memberof companyservice
         * @classdesc Represents a MeteringPointMonthlyDetails.
         * @implements IMeteringPointMonthlyDetails
         * @constructor
         * @param {companyservice.IMeteringPointMonthlyDetails=} [properties] Properties to set
         */
        function MeteringPointMonthlyDetails(properties) {
            this.monthlyConsumption = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointMonthlyDetails monthlyConsumption.
         * @member {Array.<companyservice.IMonthlyConsumption>} monthlyConsumption
         * @memberof companyservice.MeteringPointMonthlyDetails
         * @instance
         */
        MeteringPointMonthlyDetails.prototype.monthlyConsumption = $util.emptyArray;

        /**
         * MeteringPointMonthlyDetails referenceTimeframeComplete.
         * @member {google.protobuf.IBoolValue|null|undefined} referenceTimeframeComplete
         * @memberof companyservice.MeteringPointMonthlyDetails
         * @instance
         */
        MeteringPointMonthlyDetails.prototype.referenceTimeframeComplete = null;

        /**
         * Gets the default type url for MeteringPointMonthlyDetails
         * @function getTypeUrl
         * @memberof companyservice.MeteringPointMonthlyDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointMonthlyDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.MeteringPointMonthlyDetails";
        };

        return MeteringPointMonthlyDetails;
    })();

    companyservice.MonthlyConsumption = (function() {

        /**
         * Properties of a MonthlyConsumption.
         * @memberof companyservice
         * @interface IMonthlyConsumption
         * @property {google.protobuf.IInt32Value|null} [year] MonthlyConsumption year
         * @property {google.protobuf.IInt32Value|null} [month] MonthlyConsumption month
         * @property {google.protobuf.IDoubleValue|null} [value] MonthlyConsumption value
         * @property {companyservice.DataSource|null} [dataSource] MonthlyConsumption dataSource
         */

        /**
         * Constructs a new MonthlyConsumption.
         * @memberof companyservice
         * @classdesc Represents a MonthlyConsumption.
         * @implements IMonthlyConsumption
         * @constructor
         * @param {companyservice.IMonthlyConsumption=} [properties] Properties to set
         */
        function MonthlyConsumption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MonthlyConsumption year.
         * @member {google.protobuf.IInt32Value|null|undefined} year
         * @memberof companyservice.MonthlyConsumption
         * @instance
         */
        MonthlyConsumption.prototype.year = null;

        /**
         * MonthlyConsumption month.
         * @member {google.protobuf.IInt32Value|null|undefined} month
         * @memberof companyservice.MonthlyConsumption
         * @instance
         */
        MonthlyConsumption.prototype.month = null;

        /**
         * MonthlyConsumption value.
         * @member {google.protobuf.IDoubleValue|null|undefined} value
         * @memberof companyservice.MonthlyConsumption
         * @instance
         */
        MonthlyConsumption.prototype.value = null;

        /**
         * MonthlyConsumption dataSource.
         * @member {companyservice.DataSource} dataSource
         * @memberof companyservice.MonthlyConsumption
         * @instance
         */
        MonthlyConsumption.prototype.dataSource = 0;

        /**
         * Gets the default type url for MonthlyConsumption
         * @function getTypeUrl
         * @memberof companyservice.MonthlyConsumption
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MonthlyConsumption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.MonthlyConsumption";
        };

        return MonthlyConsumption;
    })();

    companyservice.MeteringPointDailyDetails = (function() {

        /**
         * Properties of a MeteringPointDailyDetails.
         * @memberof companyservice
         * @interface IMeteringPointDailyDetails
         * @property {Array.<companyservice.IDailyConsumption>|null} [dailyConsumption] MeteringPointDailyDetails dailyConsumption
         * @property {google.protobuf.IBoolValue|null} [referenceTimeframeComplete] MeteringPointDailyDetails referenceTimeframeComplete
         */

        /**
         * Constructs a new MeteringPointDailyDetails.
         * @memberof companyservice
         * @classdesc Represents a MeteringPointDailyDetails.
         * @implements IMeteringPointDailyDetails
         * @constructor
         * @param {companyservice.IMeteringPointDailyDetails=} [properties] Properties to set
         */
        function MeteringPointDailyDetails(properties) {
            this.dailyConsumption = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointDailyDetails dailyConsumption.
         * @member {Array.<companyservice.IDailyConsumption>} dailyConsumption
         * @memberof companyservice.MeteringPointDailyDetails
         * @instance
         */
        MeteringPointDailyDetails.prototype.dailyConsumption = $util.emptyArray;

        /**
         * MeteringPointDailyDetails referenceTimeframeComplete.
         * @member {google.protobuf.IBoolValue|null|undefined} referenceTimeframeComplete
         * @memberof companyservice.MeteringPointDailyDetails
         * @instance
         */
        MeteringPointDailyDetails.prototype.referenceTimeframeComplete = null;

        /**
         * Gets the default type url for MeteringPointDailyDetails
         * @function getTypeUrl
         * @memberof companyservice.MeteringPointDailyDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointDailyDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.MeteringPointDailyDetails";
        };

        return MeteringPointDailyDetails;
    })();

    companyservice.DailyConsumption = (function() {

        /**
         * Properties of a DailyConsumption.
         * @memberof companyservice
         * @interface IDailyConsumption
         * @property {google.protobuf.IInt32Value|null} [year] DailyConsumption year
         * @property {google.protobuf.IInt32Value|null} [month] DailyConsumption month
         * @property {google.protobuf.IInt32Value|null} [day] DailyConsumption day
         * @property {google.protobuf.IDoubleValue|null} [value] DailyConsumption value
         */

        /**
         * Constructs a new DailyConsumption.
         * @memberof companyservice
         * @classdesc Represents a DailyConsumption.
         * @implements IDailyConsumption
         * @constructor
         * @param {companyservice.IDailyConsumption=} [properties] Properties to set
         */
        function DailyConsumption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DailyConsumption year.
         * @member {google.protobuf.IInt32Value|null|undefined} year
         * @memberof companyservice.DailyConsumption
         * @instance
         */
        DailyConsumption.prototype.year = null;

        /**
         * DailyConsumption month.
         * @member {google.protobuf.IInt32Value|null|undefined} month
         * @memberof companyservice.DailyConsumption
         * @instance
         */
        DailyConsumption.prototype.month = null;

        /**
         * DailyConsumption day.
         * @member {google.protobuf.IInt32Value|null|undefined} day
         * @memberof companyservice.DailyConsumption
         * @instance
         */
        DailyConsumption.prototype.day = null;

        /**
         * DailyConsumption value.
         * @member {google.protobuf.IDoubleValue|null|undefined} value
         * @memberof companyservice.DailyConsumption
         * @instance
         */
        DailyConsumption.prototype.value = null;

        /**
         * Gets the default type url for DailyConsumption
         * @function getTypeUrl
         * @memberof companyservice.DailyConsumption
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DailyConsumption.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.DailyConsumption";
        };

        return DailyConsumption;
    })();

    companyservice.ContingentRate = (function() {

        /**
         * Properties of a ContingentRate.
         * @memberof companyservice
         * @interface IContingentRate
         * @property {google.protobuf.IInt32Value|null} [year] ContingentRate year
         * @property {google.protobuf.IInt32Value|null} [month] ContingentRate month
         * @property {google.protobuf.IDoubleValue|null} [rate] ContingentRate rate
         */

        /**
         * Constructs a new ContingentRate.
         * @memberof companyservice
         * @classdesc Represents a ContingentRate.
         * @implements IContingentRate
         * @constructor
         * @param {companyservice.IContingentRate=} [properties] Properties to set
         */
        function ContingentRate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContingentRate year.
         * @member {google.protobuf.IInt32Value|null|undefined} year
         * @memberof companyservice.ContingentRate
         * @instance
         */
        ContingentRate.prototype.year = null;

        /**
         * ContingentRate month.
         * @member {google.protobuf.IInt32Value|null|undefined} month
         * @memberof companyservice.ContingentRate
         * @instance
         */
        ContingentRate.prototype.month = null;

        /**
         * ContingentRate rate.
         * @member {google.protobuf.IDoubleValue|null|undefined} rate
         * @memberof companyservice.ContingentRate
         * @instance
         */
        ContingentRate.prototype.rate = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ContingentRate _rate.
         * @member {"rate"|undefined} _rate
         * @memberof companyservice.ContingentRate
         * @instance
         */
        Object.defineProperty(ContingentRate.prototype, "_rate", {
            get: $util.oneOfGetter($oneOfFields = ["rate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for ContingentRate
         * @function getTypeUrl
         * @memberof companyservice.ContingentRate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ContingentRate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.ContingentRate";
        };

        return ContingentRate;
    })();

    companyservice.ContingentRatesResponse = (function() {

        /**
         * Properties of a ContingentRatesResponse.
         * @memberof companyservice
         * @interface IContingentRatesResponse
         * @property {Array.<companyservice.IContingentRate>|null} [contingentRates] ContingentRatesResponse contingentRates
         */

        /**
         * Constructs a new ContingentRatesResponse.
         * @memberof companyservice
         * @classdesc Represents a ContingentRatesResponse.
         * @implements IContingentRatesResponse
         * @constructor
         * @param {companyservice.IContingentRatesResponse=} [properties] Properties to set
         */
        function ContingentRatesResponse(properties) {
            this.contingentRates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContingentRatesResponse contingentRates.
         * @member {Array.<companyservice.IContingentRate>} contingentRates
         * @memberof companyservice.ContingentRatesResponse
         * @instance
         */
        ContingentRatesResponse.prototype.contingentRates = $util.emptyArray;

        /**
         * Gets the default type url for ContingentRatesResponse
         * @function getTypeUrl
         * @memberof companyservice.ContingentRatesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ContingentRatesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.ContingentRatesResponse";
        };

        return ContingentRatesResponse;
    })();

    companyservice.DownloadDataForContingencyLettersRequest = (function() {

        /**
         * Properties of a DownloadDataForContingencyLettersRequest.
         * @memberof companyservice
         * @interface IDownloadDataForContingencyLettersRequest
         * @property {google.protobuf.IInt32Value|null} [year] DownloadDataForContingencyLettersRequest year
         * @property {google.protobuf.IInt32Value|null} [month] DownloadDataForContingencyLettersRequest month
         */

        /**
         * Constructs a new DownloadDataForContingencyLettersRequest.
         * @memberof companyservice
         * @classdesc Represents a DownloadDataForContingencyLettersRequest.
         * @implements IDownloadDataForContingencyLettersRequest
         * @constructor
         * @param {companyservice.IDownloadDataForContingencyLettersRequest=} [properties] Properties to set
         */
        function DownloadDataForContingencyLettersRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DownloadDataForContingencyLettersRequest year.
         * @member {google.protobuf.IInt32Value|null|undefined} year
         * @memberof companyservice.DownloadDataForContingencyLettersRequest
         * @instance
         */
        DownloadDataForContingencyLettersRequest.prototype.year = null;

        /**
         * DownloadDataForContingencyLettersRequest month.
         * @member {google.protobuf.IInt32Value|null|undefined} month
         * @memberof companyservice.DownloadDataForContingencyLettersRequest
         * @instance
         */
        DownloadDataForContingencyLettersRequest.prototype.month = null;

        /**
         * Gets the default type url for DownloadDataForContingencyLettersRequest
         * @function getTypeUrl
         * @memberof companyservice.DownloadDataForContingencyLettersRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DownloadDataForContingencyLettersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.DownloadDataForContingencyLettersRequest";
        };

        return DownloadDataForContingencyLettersRequest;
    })();

    companyservice.DownloadDataForContingencyLettersByBusinessPartnersRequest = (function() {

        /**
         * Properties of a DownloadDataForContingencyLettersByBusinessPartnersRequest.
         * @memberof companyservice
         * @interface IDownloadDataForContingencyLettersByBusinessPartnersRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] DownloadDataForContingencyLettersByBusinessPartnersRequest partnerId
         * @property {google.protobuf.IStringValue|null} [businessPartnerId] DownloadDataForContingencyLettersByBusinessPartnersRequest businessPartnerId
         * @property {google.protobuf.IInt32Value|null} [year] DownloadDataForContingencyLettersByBusinessPartnersRequest year
         * @property {google.protobuf.IInt32Value|null} [month] DownloadDataForContingencyLettersByBusinessPartnersRequest month
         */

        /**
         * Constructs a new DownloadDataForContingencyLettersByBusinessPartnersRequest.
         * @memberof companyservice
         * @classdesc Represents a DownloadDataForContingencyLettersByBusinessPartnersRequest.
         * @implements IDownloadDataForContingencyLettersByBusinessPartnersRequest
         * @constructor
         * @param {companyservice.IDownloadDataForContingencyLettersByBusinessPartnersRequest=} [properties] Properties to set
         */
        function DownloadDataForContingencyLettersByBusinessPartnersRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DownloadDataForContingencyLettersByBusinessPartnersRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companyservice.DownloadDataForContingencyLettersByBusinessPartnersRequest
         * @instance
         */
        DownloadDataForContingencyLettersByBusinessPartnersRequest.prototype.partnerId = null;

        /**
         * DownloadDataForContingencyLettersByBusinessPartnersRequest businessPartnerId.
         * @member {google.protobuf.IStringValue|null|undefined} businessPartnerId
         * @memberof companyservice.DownloadDataForContingencyLettersByBusinessPartnersRequest
         * @instance
         */
        DownloadDataForContingencyLettersByBusinessPartnersRequest.prototype.businessPartnerId = null;

        /**
         * DownloadDataForContingencyLettersByBusinessPartnersRequest year.
         * @member {google.protobuf.IInt32Value|null|undefined} year
         * @memberof companyservice.DownloadDataForContingencyLettersByBusinessPartnersRequest
         * @instance
         */
        DownloadDataForContingencyLettersByBusinessPartnersRequest.prototype.year = null;

        /**
         * DownloadDataForContingencyLettersByBusinessPartnersRequest month.
         * @member {google.protobuf.IInt32Value|null|undefined} month
         * @memberof companyservice.DownloadDataForContingencyLettersByBusinessPartnersRequest
         * @instance
         */
        DownloadDataForContingencyLettersByBusinessPartnersRequest.prototype.month = null;

        /**
         * Gets the default type url for DownloadDataForContingencyLettersByBusinessPartnersRequest
         * @function getTypeUrl
         * @memberof companyservice.DownloadDataForContingencyLettersByBusinessPartnersRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DownloadDataForContingencyLettersByBusinessPartnersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companyservice.DownloadDataForContingencyLettersByBusinessPartnersRequest";
        };

        return DownloadDataForContingencyLettersByBusinessPartnersRequest;
    })();

    return companyservice;
})();

export const emailservice = $root.emailservice = (() => {

    /**
     * Namespace emailservice.
     * @exports emailservice
     * @namespace
     */
    const emailservice = {};

    emailservice.EmailService = (function() {

        /**
         * Constructs a new EmailService service.
         * @memberof emailservice
         * @classdesc Represents an EmailService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function EmailService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (EmailService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EmailService;

        /**
         * Callback as used by {@link emailservice.EmailService#sendMail}.
         * @memberof emailservice.EmailService
         * @typedef sendMailCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls sendMail.
         * @function sendMail
         * @memberof emailservice.EmailService
         * @instance
         * @param {emailservice.IEmailRequest} request EmailRequest message or plain object
         * @param {emailservice.EmailService.sendMailCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EmailService.prototype.sendMail = function sendMail(request, callback) {
            return this.rpcCall(sendMail, $root.emailservice.EmailRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "sendMail" });

        /**
         * Calls sendMail.
         * @function sendMail
         * @memberof emailservice.EmailService
         * @instance
         * @param {emailservice.IEmailRequest} request EmailRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link emailservice.EmailService#sendOperatorMail}.
         * @memberof emailservice.EmailService
         * @typedef sendOperatorMailCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls sendOperatorMail.
         * @function sendOperatorMail
         * @memberof emailservice.EmailService
         * @instance
         * @param {emailservice.IOperatorEmailRequest} request OperatorEmailRequest message or plain object
         * @param {emailservice.EmailService.sendOperatorMailCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EmailService.prototype.sendOperatorMail = function sendOperatorMail(request, callback) {
            return this.rpcCall(sendOperatorMail, $root.emailservice.OperatorEmailRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "sendOperatorMail" });

        /**
         * Calls sendOperatorMail.
         * @function sendOperatorMail
         * @memberof emailservice.EmailService
         * @instance
         * @param {emailservice.IOperatorEmailRequest} request OperatorEmailRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link emailservice.EmailService#sendUserMail}.
         * @memberof emailservice.EmailService
         * @typedef sendUserMailCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls sendUserMail.
         * @function sendUserMail
         * @memberof emailservice.EmailService
         * @instance
         * @param {emailservice.IUserEmailRequest} request UserEmailRequest message or plain object
         * @param {emailservice.EmailService.sendUserMailCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EmailService.prototype.sendUserMail = function sendUserMail(request, callback) {
            return this.rpcCall(sendUserMail, $root.emailservice.UserEmailRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "sendUserMail" });

        /**
         * Calls sendUserMail.
         * @function sendUserMail
         * @memberof emailservice.EmailService
         * @instance
         * @param {emailservice.IUserEmailRequest} request UserEmailRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link emailservice.EmailService#getAnnouncementDraft}.
         * @memberof emailservice.EmailService
         * @typedef getAnnouncementDraftCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.MessagePart} [response] MessagePart
         */

        /**
         * Calls getAnnouncementDraft.
         * @function getAnnouncementDraft
         * @memberof emailservice.EmailService
         * @instance
         * @param {emailservice.IEmailDetails} request EmailDetails message or plain object
         * @param {emailservice.EmailService.getAnnouncementDraftCallback} callback Node-style callback called with the error, if any, and MessagePart
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EmailService.prototype.getAnnouncementDraft = function getAnnouncementDraft(request, callback) {
            return this.rpcCall(getAnnouncementDraft, $root.emailservice.EmailDetails, $root.base.MessagePart, request, callback);
        }, "name", { value: "getAnnouncementDraft" });

        /**
         * Calls getAnnouncementDraft.
         * @function getAnnouncementDraft
         * @memberof emailservice.EmailService
         * @instance
         * @param {emailservice.IEmailDetails} request EmailDetails message or plain object
         * @returns {Promise<base.MessagePart>} Promise
         * @variation 2
         */

        return EmailService;
    })();

    /**
     * EmailType enum.
     * @name emailservice.EmailType
     * @enum {number}
     * @property {number} REGISTRATION=0 REGISTRATION value
     * @property {number} RESET_PASSWORD=1 RESET_PASSWORD value
     * @property {number} RESET_PHONE_NUMBER=2 RESET_PHONE_NUMBER value
     * @property {number} ERROR_MESSAGE_OWNER=3 ERROR_MESSAGE_OWNER value
     * @property {number} NEW_WEB_CLIENT_MESSAGES=4 NEW_WEB_CLIENT_MESSAGES value
     * @property {number} UNSUPPLIED_METERING_POINTS=5 UNSUPPLIED_METERING_POINTS value
     * @property {number} NEW_PROCESS_BEFORE_IMPORT=6 NEW_PROCESS_BEFORE_IMPORT value
     * @property {number} OSTRAL_FUSION_CODE=7 OSTRAL_FUSION_CODE value
     * @property {number} OSTRAL_REGISTRATION=8 OSTRAL_REGISTRATION value
     */
    emailservice.EmailType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REGISTRATION"] = 0;
        values[valuesById[1] = "RESET_PASSWORD"] = 1;
        values[valuesById[2] = "RESET_PHONE_NUMBER"] = 2;
        values[valuesById[3] = "ERROR_MESSAGE_OWNER"] = 3;
        values[valuesById[4] = "NEW_WEB_CLIENT_MESSAGES"] = 4;
        values[valuesById[5] = "UNSUPPLIED_METERING_POINTS"] = 5;
        values[valuesById[6] = "NEW_PROCESS_BEFORE_IMPORT"] = 6;
        values[valuesById[7] = "OSTRAL_FUSION_CODE"] = 7;
        values[valuesById[8] = "OSTRAL_REGISTRATION"] = 8;
        return values;
    })();

    emailservice.EmailRequest = (function() {

        /**
         * Properties of an EmailRequest.
         * @memberof emailservice
         * @interface IEmailRequest
         * @property {google.protobuf.IInt32Value|null} [recipientPartnerId] EmailRequest recipientPartnerId
         * @property {emailservice.EmailType|null} [type] EmailRequest type
         * @property {google.protobuf.IStringValue|null} [param1] EmailRequest param1
         * @property {Array.<google.protobuf.IStringValue>|null} [paramList1] EmailRequest paramList1
         */

        /**
         * Constructs a new EmailRequest.
         * @memberof emailservice
         * @classdesc Represents an EmailRequest.
         * @implements IEmailRequest
         * @constructor
         * @param {emailservice.IEmailRequest=} [properties] Properties to set
         */
        function EmailRequest(properties) {
            this.paramList1 = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmailRequest recipientPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} recipientPartnerId
         * @memberof emailservice.EmailRequest
         * @instance
         */
        EmailRequest.prototype.recipientPartnerId = null;

        /**
         * EmailRequest type.
         * @member {emailservice.EmailType} type
         * @memberof emailservice.EmailRequest
         * @instance
         */
        EmailRequest.prototype.type = 0;

        /**
         * EmailRequest param1.
         * @member {google.protobuf.IStringValue|null|undefined} param1
         * @memberof emailservice.EmailRequest
         * @instance
         */
        EmailRequest.prototype.param1 = null;

        /**
         * EmailRequest paramList1.
         * @member {Array.<google.protobuf.IStringValue>} paramList1
         * @memberof emailservice.EmailRequest
         * @instance
         */
        EmailRequest.prototype.paramList1 = $util.emptyArray;

        /**
         * Gets the default type url for EmailRequest
         * @function getTypeUrl
         * @memberof emailservice.EmailRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmailRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/emailservice.EmailRequest";
        };

        return EmailRequest;
    })();

    emailservice.OperatorEmailRequest = (function() {

        /**
         * Properties of an OperatorEmailRequest.
         * @memberof emailservice
         * @interface IOperatorEmailRequest
         * @property {emailservice.EmailType|null} [type] OperatorEmailRequest type
         * @property {google.protobuf.IStringValue|null} [param1] OperatorEmailRequest param1
         */

        /**
         * Constructs a new OperatorEmailRequest.
         * @memberof emailservice
         * @classdesc Represents an OperatorEmailRequest.
         * @implements IOperatorEmailRequest
         * @constructor
         * @param {emailservice.IOperatorEmailRequest=} [properties] Properties to set
         */
        function OperatorEmailRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OperatorEmailRequest type.
         * @member {emailservice.EmailType} type
         * @memberof emailservice.OperatorEmailRequest
         * @instance
         */
        OperatorEmailRequest.prototype.type = 0;

        /**
         * OperatorEmailRequest param1.
         * @member {google.protobuf.IStringValue|null|undefined} param1
         * @memberof emailservice.OperatorEmailRequest
         * @instance
         */
        OperatorEmailRequest.prototype.param1 = null;

        /**
         * Gets the default type url for OperatorEmailRequest
         * @function getTypeUrl
         * @memberof emailservice.OperatorEmailRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OperatorEmailRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/emailservice.OperatorEmailRequest";
        };

        return OperatorEmailRequest;
    })();

    emailservice.UserEmailRequest = (function() {

        /**
         * Properties of a UserEmailRequest.
         * @memberof emailservice
         * @interface IUserEmailRequest
         * @property {google.protobuf.IStringValue|null} [recipientEmail] UserEmailRequest recipientEmail
         * @property {emailservice.EmailType|null} [type] UserEmailRequest type
         * @property {google.protobuf.IStringValue|null} [param1] UserEmailRequest param1
         * @property {Array.<google.protobuf.IStringValue>|null} [paramList1] UserEmailRequest paramList1
         */

        /**
         * Constructs a new UserEmailRequest.
         * @memberof emailservice
         * @classdesc Represents a UserEmailRequest.
         * @implements IUserEmailRequest
         * @constructor
         * @param {emailservice.IUserEmailRequest=} [properties] Properties to set
         */
        function UserEmailRequest(properties) {
            this.paramList1 = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserEmailRequest recipientEmail.
         * @member {google.protobuf.IStringValue|null|undefined} recipientEmail
         * @memberof emailservice.UserEmailRequest
         * @instance
         */
        UserEmailRequest.prototype.recipientEmail = null;

        /**
         * UserEmailRequest type.
         * @member {emailservice.EmailType} type
         * @memberof emailservice.UserEmailRequest
         * @instance
         */
        UserEmailRequest.prototype.type = 0;

        /**
         * UserEmailRequest param1.
         * @member {google.protobuf.IStringValue|null|undefined} param1
         * @memberof emailservice.UserEmailRequest
         * @instance
         */
        UserEmailRequest.prototype.param1 = null;

        /**
         * UserEmailRequest paramList1.
         * @member {Array.<google.protobuf.IStringValue>} paramList1
         * @memberof emailservice.UserEmailRequest
         * @instance
         */
        UserEmailRequest.prototype.paramList1 = $util.emptyArray;

        /**
         * Gets the default type url for UserEmailRequest
         * @function getTypeUrl
         * @memberof emailservice.UserEmailRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserEmailRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/emailservice.UserEmailRequest";
        };

        return UserEmailRequest;
    })();

    emailservice.EmailDetails = (function() {

        /**
         * Properties of an EmailDetails.
         * @memberof emailservice
         * @interface IEmailDetails
         * @property {google.protobuf.IStringValue|null} [subject] EmailDetails subject
         * @property {google.protobuf.IStringValue|null} [body1] EmailDetails body1
         * @property {google.protobuf.IStringValue|null} [body2] EmailDetails body2
         */

        /**
         * Constructs a new EmailDetails.
         * @memberof emailservice
         * @classdesc Represents an EmailDetails.
         * @implements IEmailDetails
         * @constructor
         * @param {emailservice.IEmailDetails=} [properties] Properties to set
         */
        function EmailDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmailDetails subject.
         * @member {google.protobuf.IStringValue|null|undefined} subject
         * @memberof emailservice.EmailDetails
         * @instance
         */
        EmailDetails.prototype.subject = null;

        /**
         * EmailDetails body1.
         * @member {google.protobuf.IStringValue|null|undefined} body1
         * @memberof emailservice.EmailDetails
         * @instance
         */
        EmailDetails.prototype.body1 = null;

        /**
         * EmailDetails body2.
         * @member {google.protobuf.IStringValue|null|undefined} body2
         * @memberof emailservice.EmailDetails
         * @instance
         */
        EmailDetails.prototype.body2 = null;

        /**
         * Gets the default type url for EmailDetails
         * @function getTypeUrl
         * @memberof emailservice.EmailDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EmailDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/emailservice.EmailDetails";
        };

        return EmailDetails;
    })();

    return emailservice;
})();

export const encryptionService = $root.encryptionService = (() => {

    /**
     * Namespace encryptionService.
     * @exports encryptionService
     * @namespace
     */
    const encryptionService = {};

    encryptionService.EncryptionService = (function() {

        /**
         * Constructs a new EncryptionService service.
         * @memberof encryptionService
         * @classdesc Represents an EncryptionService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function EncryptionService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (EncryptionService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EncryptionService;

        /**
         * Callback as used by {@link encryptionService.EncryptionService#generateKeys}.
         * @memberof encryptionService.EncryptionService
         * @typedef GenerateKeysCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls GenerateKeys.
         * @function generateKeys
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IKeyRequest} request KeyRequest message or plain object
         * @param {encryptionService.EncryptionService.GenerateKeysCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.generateKeys = function generateKeys(request, callback) {
            return this.rpcCall(generateKeys, $root.encryptionService.KeyRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "GenerateKeys" });

        /**
         * Calls GenerateKeys.
         * @function generateKeys
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IKeyRequest} request KeyRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link encryptionService.EncryptionService#deleteKeys}.
         * @memberof encryptionService.EncryptionService
         * @typedef DeleteKeysCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteKeys.
         * @function deleteKeys
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IKeyRequest} request KeyRequest message or plain object
         * @param {encryptionService.EncryptionService.DeleteKeysCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.deleteKeys = function deleteKeys(request, callback) {
            return this.rpcCall(deleteKeys, $root.encryptionService.KeyRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteKeys" });

        /**
         * Calls DeleteKeys.
         * @function deleteKeys
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IKeyRequest} request KeyRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link encryptionService.EncryptionService#encryptExternalFtpAccountPassword}.
         * @memberof encryptionService.EncryptionService
         * @typedef EncryptExternalFtpAccountPasswordCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {common.CiphertextData} [response] CiphertextData
         */

        /**
         * Calls EncryptExternalFtpAccountPassword.
         * @function encryptExternalFtpAccountPassword
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionRequest} request EncryptionRequest message or plain object
         * @param {encryptionService.EncryptionService.EncryptExternalFtpAccountPasswordCallback} callback Node-style callback called with the error, if any, and CiphertextData
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.encryptExternalFtpAccountPassword = function encryptExternalFtpAccountPassword(request, callback) {
            return this.rpcCall(encryptExternalFtpAccountPassword, $root.encryptionService.EncryptionRequest, $root.common.CiphertextData, request, callback);
        }, "name", { value: "EncryptExternalFtpAccountPassword" });

        /**
         * Calls EncryptExternalFtpAccountPassword.
         * @function encryptExternalFtpAccountPassword
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionRequest} request EncryptionRequest message or plain object
         * @returns {Promise<common.CiphertextData>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link encryptionService.EncryptionService#decryptExternalFtpAccountPassword}.
         * @memberof encryptionService.EncryptionService
         * @typedef DecryptExternalFtpAccountPasswordCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {encryptionService.PlaintextBytes} [response] PlaintextBytes
         */

        /**
         * Calls DecryptExternalFtpAccountPassword.
         * @function decryptExternalFtpAccountPassword
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IDecryptionRequest} request DecryptionRequest message or plain object
         * @param {encryptionService.EncryptionService.DecryptExternalFtpAccountPasswordCallback} callback Node-style callback called with the error, if any, and PlaintextBytes
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.decryptExternalFtpAccountPassword = function decryptExternalFtpAccountPassword(request, callback) {
            return this.rpcCall(decryptExternalFtpAccountPassword, $root.encryptionService.DecryptionRequest, $root.encryptionService.PlaintextBytes, request, callback);
        }, "name", { value: "DecryptExternalFtpAccountPassword" });

        /**
         * Calls DecryptExternalFtpAccountPassword.
         * @function decryptExternalFtpAccountPassword
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IDecryptionRequest} request DecryptionRequest message or plain object
         * @returns {Promise<encryptionService.PlaintextBytes>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link encryptionService.EncryptionService#encryptSdatMessageKey}.
         * @memberof encryptionService.EncryptionService
         * @typedef EncryptSdatMessageKeyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {common.CiphertextData} [response] CiphertextData
         */

        /**
         * Calls EncryptSdatMessageKey.
         * @function encryptSdatMessageKey
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionRequest} request EncryptionRequest message or plain object
         * @param {encryptionService.EncryptionService.EncryptSdatMessageKeyCallback} callback Node-style callback called with the error, if any, and CiphertextData
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.encryptSdatMessageKey = function encryptSdatMessageKey(request, callback) {
            return this.rpcCall(encryptSdatMessageKey, $root.encryptionService.EncryptionRequest, $root.common.CiphertextData, request, callback);
        }, "name", { value: "EncryptSdatMessageKey" });

        /**
         * Calls EncryptSdatMessageKey.
         * @function encryptSdatMessageKey
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionRequest} request EncryptionRequest message or plain object
         * @returns {Promise<common.CiphertextData>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link encryptionService.EncryptionService#decryptSdatMessageKey}.
         * @memberof encryptionService.EncryptionService
         * @typedef DecryptSdatMessageKeyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {encryptionService.PlaintextBytes} [response] PlaintextBytes
         */

        /**
         * Calls DecryptSdatMessageKey.
         * @function decryptSdatMessageKey
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IDecryptionRequest} request DecryptionRequest message or plain object
         * @param {encryptionService.EncryptionService.DecryptSdatMessageKeyCallback} callback Node-style callback called with the error, if any, and PlaintextBytes
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.decryptSdatMessageKey = function decryptSdatMessageKey(request, callback) {
            return this.rpcCall(decryptSdatMessageKey, $root.encryptionService.DecryptionRequest, $root.encryptionService.PlaintextBytes, request, callback);
        }, "name", { value: "DecryptSdatMessageKey" });

        /**
         * Calls DecryptSdatMessageKey.
         * @function decryptSdatMessageKey
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IDecryptionRequest} request DecryptionRequest message or plain object
         * @returns {Promise<encryptionService.PlaintextBytes>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link encryptionService.EncryptionService#getMeteringPointEncryptionKey}.
         * @memberof encryptionService.EncryptionService
         * @typedef GetMeteringPointEncryptionKeyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {encryptionService.EncryptionKey} [response] EncryptionKey
         */

        /**
         * Calls GetMeteringPointEncryptionKey.
         * @function getMeteringPointEncryptionKey
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionKeyRequest} request EncryptionKeyRequest message or plain object
         * @param {encryptionService.EncryptionService.GetMeteringPointEncryptionKeyCallback} callback Node-style callback called with the error, if any, and EncryptionKey
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.getMeteringPointEncryptionKey = function getMeteringPointEncryptionKey(request, callback) {
            return this.rpcCall(getMeteringPointEncryptionKey, $root.encryptionService.EncryptionKeyRequest, $root.encryptionService.EncryptionKey, request, callback);
        }, "name", { value: "GetMeteringPointEncryptionKey" });

        /**
         * Calls GetMeteringPointEncryptionKey.
         * @function getMeteringPointEncryptionKey
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionKeyRequest} request EncryptionKeyRequest message or plain object
         * @returns {Promise<encryptionService.EncryptionKey>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link encryptionService.EncryptionService#hashPartnerSecret}.
         * @memberof encryptionService.EncryptionService
         * @typedef HashPartnerSecretCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.BytesValue} [response] BytesValue
         */

        /**
         * Calls HashPartnerSecret.
         * @function hashPartnerSecret
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionRequest} request EncryptionRequest message or plain object
         * @param {encryptionService.EncryptionService.HashPartnerSecretCallback} callback Node-style callback called with the error, if any, and BytesValue
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.hashPartnerSecret = function hashPartnerSecret(request, callback) {
            return this.rpcCall(hashPartnerSecret, $root.encryptionService.EncryptionRequest, $root.google.protobuf.BytesValue, request, callback);
        }, "name", { value: "HashPartnerSecret" });

        /**
         * Calls HashPartnerSecret.
         * @function hashPartnerSecret
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionRequest} request EncryptionRequest message or plain object
         * @returns {Promise<google.protobuf.BytesValue>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link encryptionService.EncryptionService#hashMeteringpointParty}.
         * @memberof encryptionService.EncryptionService
         * @typedef HashMeteringpointPartyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.BytesValue} [response] BytesValue
         */

        /**
         * Calls HashMeteringpointParty.
         * @function hashMeteringpointParty
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEicAndRoleHashRequest} request EicAndRoleHashRequest message or plain object
         * @param {encryptionService.EncryptionService.HashMeteringpointPartyCallback} callback Node-style callback called with the error, if any, and BytesValue
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.hashMeteringpointParty = function hashMeteringpointParty(request, callback) {
            return this.rpcCall(hashMeteringpointParty, $root.encryptionService.EicAndRoleHashRequest, $root.google.protobuf.BytesValue, request, callback);
        }, "name", { value: "HashMeteringpointParty" });

        /**
         * Calls HashMeteringpointParty.
         * @function hashMeteringpointParty
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEicAndRoleHashRequest} request EicAndRoleHashRequest message or plain object
         * @returns {Promise<google.protobuf.BytesValue>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link encryptionService.EncryptionService#getCompanyServiceEncryptionKey}.
         * @memberof encryptionService.EncryptionService
         * @typedef GetCompanyServiceEncryptionKeyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {encryptionService.EncryptionKey} [response] EncryptionKey
         */

        /**
         * Calls GetCompanyServiceEncryptionKey.
         * @function getCompanyServiceEncryptionKey
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionKeyRequest} request EncryptionKeyRequest message or plain object
         * @param {encryptionService.EncryptionService.GetCompanyServiceEncryptionKeyCallback} callback Node-style callback called with the error, if any, and EncryptionKey
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.getCompanyServiceEncryptionKey = function getCompanyServiceEncryptionKey(request, callback) {
            return this.rpcCall(getCompanyServiceEncryptionKey, $root.encryptionService.EncryptionKeyRequest, $root.encryptionService.EncryptionKey, request, callback);
        }, "name", { value: "GetCompanyServiceEncryptionKey" });

        /**
         * Calls GetCompanyServiceEncryptionKey.
         * @function getCompanyServiceEncryptionKey
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionKeyRequest} request EncryptionKeyRequest message or plain object
         * @returns {Promise<encryptionService.EncryptionKey>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link encryptionService.EncryptionService#getMeteringdataServiceEncryptionKey}.
         * @memberof encryptionService.EncryptionService
         * @typedef GetMeteringdataServiceEncryptionKeyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {encryptionService.EncryptionKey} [response] EncryptionKey
         */

        /**
         * Calls GetMeteringdataServiceEncryptionKey.
         * @function getMeteringdataServiceEncryptionKey
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionKeyRequest} request EncryptionKeyRequest message or plain object
         * @param {encryptionService.EncryptionService.GetMeteringdataServiceEncryptionKeyCallback} callback Node-style callback called with the error, if any, and EncryptionKey
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EncryptionService.prototype.getMeteringdataServiceEncryptionKey = function getMeteringdataServiceEncryptionKey(request, callback) {
            return this.rpcCall(getMeteringdataServiceEncryptionKey, $root.encryptionService.EncryptionKeyRequest, $root.encryptionService.EncryptionKey, request, callback);
        }, "name", { value: "GetMeteringdataServiceEncryptionKey" });

        /**
         * Calls GetMeteringdataServiceEncryptionKey.
         * @function getMeteringdataServiceEncryptionKey
         * @memberof encryptionService.EncryptionService
         * @instance
         * @param {encryptionService.IEncryptionKeyRequest} request EncryptionKeyRequest message or plain object
         * @returns {Promise<encryptionService.EncryptionKey>} Promise
         * @variation 2
         */

        return EncryptionService;
    })();

    encryptionService.KeyRequest = (function() {

        /**
         * Properties of a KeyRequest.
         * @memberof encryptionService
         * @interface IKeyRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] KeyRequest partnerId
         */

        /**
         * Constructs a new KeyRequest.
         * @memberof encryptionService
         * @classdesc Represents a KeyRequest.
         * @implements IKeyRequest
         * @constructor
         * @param {encryptionService.IKeyRequest=} [properties] Properties to set
         */
        function KeyRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KeyRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof encryptionService.KeyRequest
         * @instance
         */
        KeyRequest.prototype.partnerId = null;

        /**
         * Gets the default type url for KeyRequest
         * @function getTypeUrl
         * @memberof encryptionService.KeyRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        KeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/encryptionService.KeyRequest";
        };

        return KeyRequest;
    })();

    encryptionService.EncryptionRequest = (function() {

        /**
         * Properties of an EncryptionRequest.
         * @memberof encryptionService
         * @interface IEncryptionRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] EncryptionRequest partnerId
         * @property {google.protobuf.IBytesValue|null} [plaintext] EncryptionRequest plaintext
         */

        /**
         * Constructs a new EncryptionRequest.
         * @memberof encryptionService
         * @classdesc Represents an EncryptionRequest.
         * @implements IEncryptionRequest
         * @constructor
         * @param {encryptionService.IEncryptionRequest=} [properties] Properties to set
         */
        function EncryptionRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EncryptionRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof encryptionService.EncryptionRequest
         * @instance
         */
        EncryptionRequest.prototype.partnerId = null;

        /**
         * EncryptionRequest plaintext.
         * @member {google.protobuf.IBytesValue|null|undefined} plaintext
         * @memberof encryptionService.EncryptionRequest
         * @instance
         */
        EncryptionRequest.prototype.plaintext = null;

        /**
         * Gets the default type url for EncryptionRequest
         * @function getTypeUrl
         * @memberof encryptionService.EncryptionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EncryptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/encryptionService.EncryptionRequest";
        };

        return EncryptionRequest;
    })();

    encryptionService.EicAndRoleHashRequest = (function() {

        /**
         * Properties of an EicAndRoleHashRequest.
         * @memberof encryptionService
         * @interface IEicAndRoleHashRequest
         * @property {google.protobuf.IStringValue|null} [eic] EicAndRoleHashRequest eic
         * @property {base.Role|null} [role] EicAndRoleHashRequest role
         */

        /**
         * Constructs a new EicAndRoleHashRequest.
         * @memberof encryptionService
         * @classdesc Represents an EicAndRoleHashRequest.
         * @implements IEicAndRoleHashRequest
         * @constructor
         * @param {encryptionService.IEicAndRoleHashRequest=} [properties] Properties to set
         */
        function EicAndRoleHashRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EicAndRoleHashRequest eic.
         * @member {google.protobuf.IStringValue|null|undefined} eic
         * @memberof encryptionService.EicAndRoleHashRequest
         * @instance
         */
        EicAndRoleHashRequest.prototype.eic = null;

        /**
         * EicAndRoleHashRequest role.
         * @member {base.Role} role
         * @memberof encryptionService.EicAndRoleHashRequest
         * @instance
         */
        EicAndRoleHashRequest.prototype.role = 0;

        /**
         * Gets the default type url for EicAndRoleHashRequest
         * @function getTypeUrl
         * @memberof encryptionService.EicAndRoleHashRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EicAndRoleHashRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/encryptionService.EicAndRoleHashRequest";
        };

        return EicAndRoleHashRequest;
    })();

    encryptionService.DecryptionRequest = (function() {

        /**
         * Properties of a DecryptionRequest.
         * @memberof encryptionService
         * @interface IDecryptionRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] DecryptionRequest partnerId
         * @property {common.ICiphertextData|null} [ciphertextData] DecryptionRequest ciphertextData
         */

        /**
         * Constructs a new DecryptionRequest.
         * @memberof encryptionService
         * @classdesc Represents a DecryptionRequest.
         * @implements IDecryptionRequest
         * @constructor
         * @param {encryptionService.IDecryptionRequest=} [properties] Properties to set
         */
        function DecryptionRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DecryptionRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof encryptionService.DecryptionRequest
         * @instance
         */
        DecryptionRequest.prototype.partnerId = null;

        /**
         * DecryptionRequest ciphertextData.
         * @member {common.ICiphertextData|null|undefined} ciphertextData
         * @memberof encryptionService.DecryptionRequest
         * @instance
         */
        DecryptionRequest.prototype.ciphertextData = null;

        /**
         * Gets the default type url for DecryptionRequest
         * @function getTypeUrl
         * @memberof encryptionService.DecryptionRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DecryptionRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/encryptionService.DecryptionRequest";
        };

        return DecryptionRequest;
    })();

    encryptionService.PlaintextBytes = (function() {

        /**
         * Properties of a PlaintextBytes.
         * @memberof encryptionService
         * @interface IPlaintextBytes
         * @property {google.protobuf.IBytesValue|null} [plaintext] PlaintextBytes plaintext
         */

        /**
         * Constructs a new PlaintextBytes.
         * @memberof encryptionService
         * @classdesc Represents a PlaintextBytes.
         * @implements IPlaintextBytes
         * @constructor
         * @param {encryptionService.IPlaintextBytes=} [properties] Properties to set
         */
        function PlaintextBytes(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlaintextBytes plaintext.
         * @member {google.protobuf.IBytesValue|null|undefined} plaintext
         * @memberof encryptionService.PlaintextBytes
         * @instance
         */
        PlaintextBytes.prototype.plaintext = null;

        /**
         * Gets the default type url for PlaintextBytes
         * @function getTypeUrl
         * @memberof encryptionService.PlaintextBytes
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PlaintextBytes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/encryptionService.PlaintextBytes";
        };

        return PlaintextBytes;
    })();

    encryptionService.EncryptionKeyRequest = (function() {

        /**
         * Properties of an EncryptionKeyRequest.
         * @memberof encryptionService
         * @interface IEncryptionKeyRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] EncryptionKeyRequest partnerId
         */

        /**
         * Constructs a new EncryptionKeyRequest.
         * @memberof encryptionService
         * @classdesc Represents an EncryptionKeyRequest.
         * @implements IEncryptionKeyRequest
         * @constructor
         * @param {encryptionService.IEncryptionKeyRequest=} [properties] Properties to set
         */
        function EncryptionKeyRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EncryptionKeyRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof encryptionService.EncryptionKeyRequest
         * @instance
         */
        EncryptionKeyRequest.prototype.partnerId = null;

        /**
         * Gets the default type url for EncryptionKeyRequest
         * @function getTypeUrl
         * @memberof encryptionService.EncryptionKeyRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EncryptionKeyRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/encryptionService.EncryptionKeyRequest";
        };

        return EncryptionKeyRequest;
    })();

    encryptionService.EncryptionKey = (function() {

        /**
         * Properties of an EncryptionKey.
         * @memberof encryptionService
         * @interface IEncryptionKey
         * @property {google.protobuf.IBytesValue|null} [key] EncryptionKey key
         */

        /**
         * Constructs a new EncryptionKey.
         * @memberof encryptionService
         * @classdesc Represents an EncryptionKey.
         * @implements IEncryptionKey
         * @constructor
         * @param {encryptionService.IEncryptionKey=} [properties] Properties to set
         */
        function EncryptionKey(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EncryptionKey key.
         * @member {google.protobuf.IBytesValue|null|undefined} key
         * @memberof encryptionService.EncryptionKey
         * @instance
         */
        EncryptionKey.prototype.key = null;

        /**
         * Gets the default type url for EncryptionKey
         * @function getTypeUrl
         * @memberof encryptionService.EncryptionKey
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EncryptionKey.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/encryptionService.EncryptionKey";
        };

        return EncryptionKey;
    })();

    return encryptionService;
})();

export const ftpsenderservice = $root.ftpsenderservice = (() => {

    /**
     * Namespace ftpsenderservice.
     * @exports ftpsenderservice
     * @namespace
     */
    const ftpsenderservice = {};

    ftpsenderservice.FtpSenderService = (function() {

        /**
         * Constructs a new FtpSenderService service.
         * @memberof ftpsenderservice
         * @classdesc Represents a FtpSenderService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function FtpSenderService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (FtpSenderService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = FtpSenderService;

        /**
         * Callback as used by {@link ftpsenderservice.FtpSenderService#checkConnectionAndLogin}.
         * @memberof ftpsenderservice.FtpSenderService
         * @typedef CheckConnectionAndLoginCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls CheckConnectionAndLogin.
         * @function checkConnectionAndLogin
         * @memberof ftpsenderservice.FtpSenderService
         * @instance
         * @param {common.IFtpAccount} request FtpAccount message or plain object
         * @param {ftpsenderservice.FtpSenderService.CheckConnectionAndLoginCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FtpSenderService.prototype.checkConnectionAndLogin = function checkConnectionAndLogin(request, callback) {
            return this.rpcCall(checkConnectionAndLogin, $root.common.FtpAccount, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "CheckConnectionAndLogin" });

        /**
         * Calls CheckConnectionAndLogin.
         * @function checkConnectionAndLogin
         * @memberof ftpsenderservice.FtpSenderService
         * @instance
         * @param {common.IFtpAccount} request FtpAccount message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        return FtpSenderService;
    })();

    return ftpsenderservice;
})();

export const messagestore = $root.messagestore = (() => {

    /**
     * Namespace messagestore.
     * @exports messagestore
     * @namespace
     */
    const messagestore = {};

    messagestore.MessageStoreManagementService = (function() {

        /**
         * Constructs a new MessageStoreManagementService service.
         * @memberof messagestore
         * @classdesc Represents a MessageStoreManagementService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MessageStoreManagementService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MessageStoreManagementService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MessageStoreManagementService;

        /**
         * Callback as used by {@link messagestore.MessageStoreManagementService#deletePartner}.
         * @memberof messagestore.MessageStoreManagementService
         * @typedef DeletePartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeletePartner.
         * @function deletePartner
         * @memberof messagestore.MessageStoreManagementService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {messagestore.MessageStoreManagementService.DeletePartnerCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreManagementService.prototype.deletePartner = function deletePartner(request, callback) {
            return this.rpcCall(deletePartner, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeletePartner" });

        /**
         * Calls DeletePartner.
         * @function deletePartner
         * @memberof messagestore.MessageStoreManagementService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        return MessageStoreManagementService;
    })();

    messagestore.MessageStoreService = (function() {

        /**
         * Constructs a new MessageStoreService service.
         * @memberof messagestore
         * @classdesc Represents a MessageStoreService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MessageStoreService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MessageStoreService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MessageStoreService;

        /**
         * Callback as used by {@link messagestore.MessageStoreService#listAllMessagesStream}.
         * @memberof messagestore.MessageStoreService
         * @typedef ListAllMessagesStreamCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {messagestore.MessageListResponse} [response] MessageListResponse
         */

        /**
         * Calls ListAllMessagesStream.
         * @function listAllMessagesStream
         * @memberof messagestore.MessageStoreService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {messagestore.MessageStoreService.ListAllMessagesStreamCallback} callback Node-style callback called with the error, if any, and MessageListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreService.prototype.listAllMessagesStream = function listAllMessagesStream(request, callback) {
            return this.rpcCall(listAllMessagesStream, $root.base.PartnerRequest, $root.messagestore.MessageListResponse, request, callback);
        }, "name", { value: "ListAllMessagesStream" });

        /**
         * Calls ListAllMessagesStream.
         * @function listAllMessagesStream
         * @memberof messagestore.MessageStoreService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<messagestore.MessageListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreService#getMessageContent}.
         * @memberof messagestore.MessageStoreService
         * @typedef GetMessageContentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.MessagePart} [response] MessagePart
         */

        /**
         * Calls GetMessageContent.
         * @function getMessageContent
         * @memberof messagestore.MessageStoreService
         * @instance
         * @param {messagestore.IByMessageUuidRequest} request ByMessageUuidRequest message or plain object
         * @param {messagestore.MessageStoreService.GetMessageContentCallback} callback Node-style callback called with the error, if any, and MessagePart
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreService.prototype.getMessageContent = function getMessageContent(request, callback) {
            return this.rpcCall(getMessageContent, $root.messagestore.ByMessageUuidRequest, $root.base.MessagePart, request, callback);
        }, "name", { value: "GetMessageContent" });

        /**
         * Calls GetMessageContent.
         * @function getMessageContent
         * @memberof messagestore.MessageStoreService
         * @instance
         * @param {messagestore.IByMessageUuidRequest} request ByMessageUuidRequest message or plain object
         * @returns {Promise<base.MessagePart>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreService#deleteMessage}.
         * @memberof messagestore.MessageStoreService
         * @typedef DeleteMessageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteMessage.
         * @function deleteMessage
         * @memberof messagestore.MessageStoreService
         * @instance
         * @param {messagestore.IByMessageUuidRequest} request ByMessageUuidRequest message or plain object
         * @param {messagestore.MessageStoreService.DeleteMessageCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreService.prototype.deleteMessage = function deleteMessage(request, callback) {
            return this.rpcCall(deleteMessage, $root.messagestore.ByMessageUuidRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteMessage" });

        /**
         * Calls DeleteMessage.
         * @function deleteMessage
         * @memberof messagestore.MessageStoreService
         * @instance
         * @param {messagestore.IByMessageUuidRequest} request ByMessageUuidRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreService#setMessageRead}.
         * @memberof messagestore.MessageStoreService
         * @typedef SetMessageReadCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls SetMessageRead.
         * @function setMessageRead
         * @memberof messagestore.MessageStoreService
         * @instance
         * @param {messagestore.IMessageReadRequest} request MessageReadRequest message or plain object
         * @param {messagestore.MessageStoreService.SetMessageReadCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreService.prototype.setMessageRead = function setMessageRead(request, callback) {
            return this.rpcCall(setMessageRead, $root.messagestore.MessageReadRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "SetMessageRead" });

        /**
         * Calls SetMessageRead.
         * @function setMessageRead
         * @memberof messagestore.MessageStoreService
         * @instance
         * @param {messagestore.IMessageReadRequest} request MessageReadRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreService#deleteAllMessages}.
         * @memberof messagestore.MessageStoreService
         * @typedef DeleteAllMessagesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteAllMessages.
         * @function deleteAllMessages
         * @memberof messagestore.MessageStoreService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {messagestore.MessageStoreService.DeleteAllMessagesCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreService.prototype.deleteAllMessages = function deleteAllMessages(request, callback) {
            return this.rpcCall(deleteAllMessages, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteAllMessages" });

        /**
         * Calls DeleteAllMessages.
         * @function deleteAllMessages
         * @memberof messagestore.MessageStoreService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        return MessageStoreService;
    })();

    messagestore.MessageStoreErrorService = (function() {

        /**
         * Constructs a new MessageStoreErrorService service.
         * @memberof messagestore
         * @classdesc Represents a MessageStoreErrorService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MessageStoreErrorService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MessageStoreErrorService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MessageStoreErrorService;

        /**
         * Callback as used by {@link messagestore.MessageStoreErrorService#listAllErrorNotificationsPaginated}.
         * @memberof messagestore.MessageStoreErrorService
         * @typedef ListAllErrorNotificationsPaginatedCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {messagestore.PaginatedErrorNotificationListResponse} [response] PaginatedErrorNotificationListResponse
         */

        /**
         * Calls ListAllErrorNotificationsPaginated.
         * @function listAllErrorNotificationsPaginated
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IPaginatedPartnerListRequest} request PaginatedPartnerListRequest message or plain object
         * @param {messagestore.MessageStoreErrorService.ListAllErrorNotificationsPaginatedCallback} callback Node-style callback called with the error, if any, and PaginatedErrorNotificationListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreErrorService.prototype.listAllErrorNotificationsPaginated = function listAllErrorNotificationsPaginated(request, callback) {
            return this.rpcCall(listAllErrorNotificationsPaginated, $root.base.PaginatedPartnerListRequest, $root.messagestore.PaginatedErrorNotificationListResponse, request, callback);
        }, "name", { value: "ListAllErrorNotificationsPaginated" });

        /**
         * Calls ListAllErrorNotificationsPaginated.
         * @function listAllErrorNotificationsPaginated
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IPaginatedPartnerListRequest} request PaginatedPartnerListRequest message or plain object
         * @returns {Promise<messagestore.PaginatedErrorNotificationListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreErrorService#deleteErrorNotification}.
         * @memberof messagestore.MessageStoreErrorService
         * @typedef DeleteErrorNotificationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteErrorNotification.
         * @function deleteErrorNotification
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @param {messagestore.MessageStoreErrorService.DeleteErrorNotificationCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreErrorService.prototype.deleteErrorNotification = function deleteErrorNotification(request, callback) {
            return this.rpcCall(deleteErrorNotification, $root.base.UuidAndPartnerId, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteErrorNotification" });

        /**
         * Calls DeleteErrorNotification.
         * @function deleteErrorNotification
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreErrorService#setErrorNotificationRead}.
         * @memberof messagestore.MessageStoreErrorService
         * @typedef SetErrorNotificationReadCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls SetErrorNotificationRead.
         * @function setErrorNotificationRead
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {messagestore.IReadRequest} request ReadRequest message or plain object
         * @param {messagestore.MessageStoreErrorService.SetErrorNotificationReadCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreErrorService.prototype.setErrorNotificationRead = function setErrorNotificationRead(request, callback) {
            return this.rpcCall(setErrorNotificationRead, $root.messagestore.ReadRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "SetErrorNotificationRead" });

        /**
         * Calls SetErrorNotificationRead.
         * @function setErrorNotificationRead
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {messagestore.IReadRequest} request ReadRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreErrorService#getUnreadCount}.
         * @memberof messagestore.MessageStoreErrorService
         * @typedef GetUnreadCountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Int32Value} [response] Int32Value
         */

        /**
         * Calls GetUnreadCount.
         * @function getUnreadCount
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {messagestore.MessageStoreErrorService.GetUnreadCountCallback} callback Node-style callback called with the error, if any, and Int32Value
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreErrorService.prototype.getUnreadCount = function getUnreadCount(request, callback) {
            return this.rpcCall(getUnreadCount, $root.base.PartnerRequest, $root.google.protobuf.Int32Value, request, callback);
        }, "name", { value: "GetUnreadCount" });

        /**
         * Calls GetUnreadCount.
         * @function getUnreadCount
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Int32Value>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreErrorService#resendMessage}.
         * @memberof messagestore.MessageStoreErrorService
         * @typedef ResendMessageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls ResendMessage.
         * @function resendMessage
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @param {messagestore.MessageStoreErrorService.ResendMessageCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreErrorService.prototype.resendMessage = function resendMessage(request, callback) {
            return this.rpcCall(resendMessage, $root.base.UuidAndPartnerId, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "ResendMessage" });

        /**
         * Calls ResendMessage.
         * @function resendMessage
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreErrorService#getErrorNotification}.
         * @memberof messagestore.MessageStoreErrorService
         * @typedef GetErrorNotificationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {messagestore.ErrorMessage} [response] ErrorMessage
         */

        /**
         * Calls GetErrorNotification.
         * @function getErrorNotification
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @param {messagestore.MessageStoreErrorService.GetErrorNotificationCallback} callback Node-style callback called with the error, if any, and ErrorMessage
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreErrorService.prototype.getErrorNotification = function getErrorNotification(request, callback) {
            return this.rpcCall(getErrorNotification, $root.base.UuidAndPartnerId, $root.messagestore.ErrorMessage, request, callback);
        }, "name", { value: "GetErrorNotification" });

        /**
         * Calls GetErrorNotification.
         * @function getErrorNotification
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @returns {Promise<messagestore.ErrorMessage>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreErrorService#deleteAllErrorNotifications}.
         * @memberof messagestore.MessageStoreErrorService
         * @typedef DeleteAllErrorNotificationsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteAllErrorNotifications.
         * @function deleteAllErrorNotifications
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {messagestore.IDeleteAllErrorNotificationsRequest} request DeleteAllErrorNotificationsRequest message or plain object
         * @param {messagestore.MessageStoreErrorService.DeleteAllErrorNotificationsCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreErrorService.prototype.deleteAllErrorNotifications = function deleteAllErrorNotifications(request, callback) {
            return this.rpcCall(deleteAllErrorNotifications, $root.messagestore.DeleteAllErrorNotificationsRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteAllErrorNotifications" });

        /**
         * Calls DeleteAllErrorNotifications.
         * @function deleteAllErrorNotifications
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {messagestore.IDeleteAllErrorNotificationsRequest} request DeleteAllErrorNotificationsRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreErrorService#getMessageContent}.
         * @memberof messagestore.MessageStoreErrorService
         * @typedef GetMessageContentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.MessagePart} [response] MessagePart
         */

        /**
         * Calls GetMessageContent.
         * @function getMessageContent
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @param {messagestore.MessageStoreErrorService.GetMessageContentCallback} callback Node-style callback called with the error, if any, and MessagePart
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreErrorService.prototype.getMessageContent = function getMessageContent(request, callback) {
            return this.rpcCall(getMessageContent, $root.base.UuidAndPartnerId, $root.base.MessagePart, request, callback);
        }, "name", { value: "GetMessageContent" });

        /**
         * Calls GetMessageContent.
         * @function getMessageContent
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @returns {Promise<base.MessagePart>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.MessageStoreErrorService#getMessageContentFilename}.
         * @memberof messagestore.MessageStoreErrorService
         * @typedef GetMessageContentFilenameCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.StringValue} [response] StringValue
         */

        /**
         * Calls GetMessageContentFilename.
         * @function getMessageContentFilename
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @param {messagestore.MessageStoreErrorService.GetMessageContentFilenameCallback} callback Node-style callback called with the error, if any, and StringValue
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MessageStoreErrorService.prototype.getMessageContentFilename = function getMessageContentFilename(request, callback) {
            return this.rpcCall(getMessageContentFilename, $root.base.UuidAndPartnerId, $root.google.protobuf.StringValue, request, callback);
        }, "name", { value: "GetMessageContentFilename" });

        /**
         * Calls GetMessageContentFilename.
         * @function getMessageContentFilename
         * @memberof messagestore.MessageStoreErrorService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @returns {Promise<google.protobuf.StringValue>} Promise
         * @variation 2
         */

        return MessageStoreErrorService;
    })();

    messagestore.SdatWebClientChangeProcessService = (function() {

        /**
         * Constructs a new SdatWebClientChangeProcessService service.
         * @memberof messagestore
         * @classdesc Represents a SdatWebClientChangeProcessService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function SdatWebClientChangeProcessService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (SdatWebClientChangeProcessService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SdatWebClientChangeProcessService;

        /**
         * Callback as used by {@link messagestore.SdatWebClientChangeProcessService#listProcessesPaginated}.
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @typedef ListProcessesPaginatedCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {messagestore.PaginatedChangeProcessListResponse} [response] PaginatedChangeProcessListResponse
         */

        /**
         * Calls ListProcessesPaginated.
         * @function listProcessesPaginated
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IPaginatedPartnerListRequest} request PaginatedPartnerListRequest message or plain object
         * @param {messagestore.SdatWebClientChangeProcessService.ListProcessesPaginatedCallback} callback Node-style callback called with the error, if any, and PaginatedChangeProcessListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SdatWebClientChangeProcessService.prototype.listProcessesPaginated = function listProcessesPaginated(request, callback) {
            return this.rpcCall(listProcessesPaginated, $root.base.PaginatedPartnerListRequest, $root.messagestore.PaginatedChangeProcessListResponse, request, callback);
        }, "name", { value: "ListProcessesPaginated" });

        /**
         * Calls ListProcessesPaginated.
         * @function listProcessesPaginated
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IPaginatedPartnerListRequest} request PaginatedPartnerListRequest message or plain object
         * @returns {Promise<messagestore.PaginatedChangeProcessListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.SdatWebClientChangeProcessService#setProcessRead}.
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @typedef SetProcessReadCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls SetProcessRead.
         * @function setProcessRead
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {messagestore.IReadRequest} request ReadRequest message or plain object
         * @param {messagestore.SdatWebClientChangeProcessService.SetProcessReadCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SdatWebClientChangeProcessService.prototype.setProcessRead = function setProcessRead(request, callback) {
            return this.rpcCall(setProcessRead, $root.messagestore.ReadRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "SetProcessRead" });

        /**
         * Calls SetProcessRead.
         * @function setProcessRead
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {messagestore.IReadRequest} request ReadRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.SdatWebClientChangeProcessService#getUnreadCount}.
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @typedef GetUnreadCountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Int32Value} [response] Int32Value
         */

        /**
         * Calls GetUnreadCount.
         * @function getUnreadCount
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {messagestore.SdatWebClientChangeProcessService.GetUnreadCountCallback} callback Node-style callback called with the error, if any, and Int32Value
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SdatWebClientChangeProcessService.prototype.getUnreadCount = function getUnreadCount(request, callback) {
            return this.rpcCall(getUnreadCount, $root.base.PartnerRequest, $root.google.protobuf.Int32Value, request, callback);
        }, "name", { value: "GetUnreadCount" });

        /**
         * Calls GetUnreadCount.
         * @function getUnreadCount
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Int32Value>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.SdatWebClientChangeProcessService#deleteAllProcesses}.
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @typedef DeleteAllProcessesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteAllProcesses.
         * @function deleteAllProcesses
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {messagestore.SdatWebClientChangeProcessService.DeleteAllProcessesCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SdatWebClientChangeProcessService.prototype.deleteAllProcesses = function deleteAllProcesses(request, callback) {
            return this.rpcCall(deleteAllProcesses, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteAllProcesses" });

        /**
         * Calls DeleteAllProcesses.
         * @function deleteAllProcesses
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.SdatWebClientChangeProcessService#archiveProcess}.
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @typedef ArchiveProcessCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls ArchiveProcess.
         * @function archiveProcess
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {messagestore.IArchiveRequest} request ArchiveRequest message or plain object
         * @param {messagestore.SdatWebClientChangeProcessService.ArchiveProcessCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SdatWebClientChangeProcessService.prototype.archiveProcess = function archiveProcess(request, callback) {
            return this.rpcCall(archiveProcess, $root.messagestore.ArchiveRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "ArchiveProcess" });

        /**
         * Calls ArchiveProcess.
         * @function archiveProcess
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {messagestore.IArchiveRequest} request ArchiveRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.SdatWebClientChangeProcessService#getProcessDocuments}.
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @typedef GetProcessDocumentsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {messagestore.ProcessDocuments} [response] ProcessDocuments
         */

        /**
         * Calls GetProcessDocuments.
         * @function getProcessDocuments
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @param {messagestore.SdatWebClientChangeProcessService.GetProcessDocumentsCallback} callback Node-style callback called with the error, if any, and ProcessDocuments
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SdatWebClientChangeProcessService.prototype.getProcessDocuments = function getProcessDocuments(request, callback) {
            return this.rpcCall(getProcessDocuments, $root.base.UuidAndPartnerId, $root.messagestore.ProcessDocuments, request, callback);
        }, "name", { value: "GetProcessDocuments" });

        /**
         * Calls GetProcessDocuments.
         * @function getProcessDocuments
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @returns {Promise<messagestore.ProcessDocuments>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.SdatWebClientChangeProcessService#getMessageContent}.
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @typedef GetMessageContentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.MessagePart} [response] MessagePart
         */

        /**
         * Calls GetMessageContent.
         * @function getMessageContent
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @param {messagestore.SdatWebClientChangeProcessService.GetMessageContentCallback} callback Node-style callback called with the error, if any, and MessagePart
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SdatWebClientChangeProcessService.prototype.getMessageContent = function getMessageContent(request, callback) {
            return this.rpcCall(getMessageContent, $root.base.UuidAndPartnerId, $root.base.MessagePart, request, callback);
        }, "name", { value: "GetMessageContent" });

        /**
         * Calls GetMessageContent.
         * @function getMessageContent
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @returns {Promise<base.MessagePart>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link messagestore.SdatWebClientChangeProcessService#getMessageContentFilename}.
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @typedef GetMessageContentFilenameCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.StringValue} [response] StringValue
         */

        /**
         * Calls GetMessageContentFilename.
         * @function getMessageContentFilename
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @param {messagestore.SdatWebClientChangeProcessService.GetMessageContentFilenameCallback} callback Node-style callback called with the error, if any, and StringValue
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SdatWebClientChangeProcessService.prototype.getMessageContentFilename = function getMessageContentFilename(request, callback) {
            return this.rpcCall(getMessageContentFilename, $root.base.UuidAndPartnerId, $root.google.protobuf.StringValue, request, callback);
        }, "name", { value: "GetMessageContentFilename" });

        /**
         * Calls GetMessageContentFilename.
         * @function getMessageContentFilename
         * @memberof messagestore.SdatWebClientChangeProcessService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @returns {Promise<google.protobuf.StringValue>} Promise
         * @variation 2
         */

        return SdatWebClientChangeProcessService;
    })();

    messagestore.EchoHandlingService = (function() {

        /**
         * Constructs a new EchoHandlingService service.
         * @memberof messagestore
         * @classdesc Represents an EchoHandlingService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function EchoHandlingService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (EchoHandlingService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = EchoHandlingService;

        /**
         * Callback as used by {@link messagestore.EchoHandlingService#listAllShortcutMessages}.
         * @memberof messagestore.EchoHandlingService
         * @typedef ListAllShortcutMessagesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {messagestore.MessageListResponse} [response] MessageListResponse
         */

        /**
         * Calls ListAllShortcutMessages.
         * @function listAllShortcutMessages
         * @memberof messagestore.EchoHandlingService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {messagestore.EchoHandlingService.ListAllShortcutMessagesCallback} callback Node-style callback called with the error, if any, and MessageListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(EchoHandlingService.prototype.listAllShortcutMessages = function listAllShortcutMessages(request, callback) {
            return this.rpcCall(listAllShortcutMessages, $root.google.protobuf.Empty, $root.messagestore.MessageListResponse, request, callback);
        }, "name", { value: "ListAllShortcutMessages" });

        /**
         * Calls ListAllShortcutMessages.
         * @function listAllShortcutMessages
         * @memberof messagestore.EchoHandlingService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<messagestore.MessageListResponse>} Promise
         * @variation 2
         */

        return EchoHandlingService;
    })();

    messagestore.ByMessageUuidRequest = (function() {

        /**
         * Properties of a ByMessageUuidRequest.
         * @memberof messagestore
         * @interface IByMessageUuidRequest
         * @property {google.protobuf.IStringValue|null} [messageUuid] ByMessageUuidRequest messageUuid
         */

        /**
         * Constructs a new ByMessageUuidRequest.
         * @memberof messagestore
         * @classdesc Represents a ByMessageUuidRequest.
         * @implements IByMessageUuidRequest
         * @constructor
         * @param {messagestore.IByMessageUuidRequest=} [properties] Properties to set
         */
        function ByMessageUuidRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ByMessageUuidRequest messageUuid.
         * @member {google.protobuf.IStringValue|null|undefined} messageUuid
         * @memberof messagestore.ByMessageUuidRequest
         * @instance
         */
        ByMessageUuidRequest.prototype.messageUuid = null;

        /**
         * Gets the default type url for ByMessageUuidRequest
         * @function getTypeUrl
         * @memberof messagestore.ByMessageUuidRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ByMessageUuidRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.ByMessageUuidRequest";
        };

        return ByMessageUuidRequest;
    })();

    messagestore.ReadRequest = (function() {

        /**
         * Properties of a ReadRequest.
         * @memberof messagestore
         * @interface IReadRequest
         * @property {base.IUuidAndPartnerId|null} [id] ReadRequest id
         * @property {google.protobuf.IBoolValue|null} [read] ReadRequest read
         */

        /**
         * Constructs a new ReadRequest.
         * @memberof messagestore
         * @classdesc Represents a ReadRequest.
         * @implements IReadRequest
         * @constructor
         * @param {messagestore.IReadRequest=} [properties] Properties to set
         */
        function ReadRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReadRequest id.
         * @member {base.IUuidAndPartnerId|null|undefined} id
         * @memberof messagestore.ReadRequest
         * @instance
         */
        ReadRequest.prototype.id = null;

        /**
         * ReadRequest read.
         * @member {google.protobuf.IBoolValue|null|undefined} read
         * @memberof messagestore.ReadRequest
         * @instance
         */
        ReadRequest.prototype.read = null;

        /**
         * Gets the default type url for ReadRequest
         * @function getTypeUrl
         * @memberof messagestore.ReadRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReadRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.ReadRequest";
        };

        return ReadRequest;
    })();

    messagestore.ArchiveRequest = (function() {

        /**
         * Properties of an ArchiveRequest.
         * @memberof messagestore
         * @interface IArchiveRequest
         * @property {base.IUuidAndPartnerId|null} [id] ArchiveRequest id
         * @property {google.protobuf.IBoolValue|null} [archive] ArchiveRequest archive
         */

        /**
         * Constructs a new ArchiveRequest.
         * @memberof messagestore
         * @classdesc Represents an ArchiveRequest.
         * @implements IArchiveRequest
         * @constructor
         * @param {messagestore.IArchiveRequest=} [properties] Properties to set
         */
        function ArchiveRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ArchiveRequest id.
         * @member {base.IUuidAndPartnerId|null|undefined} id
         * @memberof messagestore.ArchiveRequest
         * @instance
         */
        ArchiveRequest.prototype.id = null;

        /**
         * ArchiveRequest archive.
         * @member {google.protobuf.IBoolValue|null|undefined} archive
         * @memberof messagestore.ArchiveRequest
         * @instance
         */
        ArchiveRequest.prototype.archive = null;

        /**
         * Gets the default type url for ArchiveRequest
         * @function getTypeUrl
         * @memberof messagestore.ArchiveRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ArchiveRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.ArchiveRequest";
        };

        return ArchiveRequest;
    })();

    messagestore.DeleteAllErrorNotificationsRequest = (function() {

        /**
         * Properties of a DeleteAllErrorNotificationsRequest.
         * @memberof messagestore
         * @interface IDeleteAllErrorNotificationsRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] DeleteAllErrorNotificationsRequest partnerId
         * @property {messagestore.OlderThan|null} [olderThan] DeleteAllErrorNotificationsRequest olderThan
         */

        /**
         * Constructs a new DeleteAllErrorNotificationsRequest.
         * @memberof messagestore
         * @classdesc Represents a DeleteAllErrorNotificationsRequest.
         * @implements IDeleteAllErrorNotificationsRequest
         * @constructor
         * @param {messagestore.IDeleteAllErrorNotificationsRequest=} [properties] Properties to set
         */
        function DeleteAllErrorNotificationsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteAllErrorNotificationsRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof messagestore.DeleteAllErrorNotificationsRequest
         * @instance
         */
        DeleteAllErrorNotificationsRequest.prototype.partnerId = null;

        /**
         * DeleteAllErrorNotificationsRequest olderThan.
         * @member {messagestore.OlderThan} olderThan
         * @memberof messagestore.DeleteAllErrorNotificationsRequest
         * @instance
         */
        DeleteAllErrorNotificationsRequest.prototype.olderThan = 0;

        /**
         * Gets the default type url for DeleteAllErrorNotificationsRequest
         * @function getTypeUrl
         * @memberof messagestore.DeleteAllErrorNotificationsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteAllErrorNotificationsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.DeleteAllErrorNotificationsRequest";
        };

        return DeleteAllErrorNotificationsRequest;
    })();

    messagestore.MessageListResponse = (function() {

        /**
         * Properties of a MessageListResponse.
         * @memberof messagestore
         * @interface IMessageListResponse
         * @property {Array.<messagestore.IMessageMetaData>|null} [metaData] MessageListResponse metaData
         */

        /**
         * Constructs a new MessageListResponse.
         * @memberof messagestore
         * @classdesc Represents a MessageListResponse.
         * @implements IMessageListResponse
         * @constructor
         * @param {messagestore.IMessageListResponse=} [properties] Properties to set
         */
        function MessageListResponse(properties) {
            this.metaData = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessageListResponse metaData.
         * @member {Array.<messagestore.IMessageMetaData>} metaData
         * @memberof messagestore.MessageListResponse
         * @instance
         */
        MessageListResponse.prototype.metaData = $util.emptyArray;

        /**
         * Gets the default type url for MessageListResponse
         * @function getTypeUrl
         * @memberof messagestore.MessageListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessageListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.MessageListResponse";
        };

        return MessageListResponse;
    })();

    messagestore.PaginatedErrorNotificationListResponse = (function() {

        /**
         * Properties of a PaginatedErrorNotificationListResponse.
         * @memberof messagestore
         * @interface IPaginatedErrorNotificationListResponse
         * @property {Array.<messagestore.IErrorMessage>|null} [errorNotification] PaginatedErrorNotificationListResponse errorNotification
         * @property {query.IPageInfo|null} [pageInfo] PaginatedErrorNotificationListResponse pageInfo
         */

        /**
         * Constructs a new PaginatedErrorNotificationListResponse.
         * @memberof messagestore
         * @classdesc Represents a PaginatedErrorNotificationListResponse.
         * @implements IPaginatedErrorNotificationListResponse
         * @constructor
         * @param {messagestore.IPaginatedErrorNotificationListResponse=} [properties] Properties to set
         */
        function PaginatedErrorNotificationListResponse(properties) {
            this.errorNotification = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginatedErrorNotificationListResponse errorNotification.
         * @member {Array.<messagestore.IErrorMessage>} errorNotification
         * @memberof messagestore.PaginatedErrorNotificationListResponse
         * @instance
         */
        PaginatedErrorNotificationListResponse.prototype.errorNotification = $util.emptyArray;

        /**
         * PaginatedErrorNotificationListResponse pageInfo.
         * @member {query.IPageInfo|null|undefined} pageInfo
         * @memberof messagestore.PaginatedErrorNotificationListResponse
         * @instance
         */
        PaginatedErrorNotificationListResponse.prototype.pageInfo = null;

        /**
         * Gets the default type url for PaginatedErrorNotificationListResponse
         * @function getTypeUrl
         * @memberof messagestore.PaginatedErrorNotificationListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaginatedErrorNotificationListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.PaginatedErrorNotificationListResponse";
        };

        return PaginatedErrorNotificationListResponse;
    })();

    messagestore.PaginatedChangeProcessListResponse = (function() {

        /**
         * Properties of a PaginatedChangeProcessListResponse.
         * @memberof messagestore
         * @interface IPaginatedChangeProcessListResponse
         * @property {Array.<messagestore.IChangeProcess>|null} [changeProcess] PaginatedChangeProcessListResponse changeProcess
         * @property {query.IPageInfo|null} [pageInfo] PaginatedChangeProcessListResponse pageInfo
         */

        /**
         * Constructs a new PaginatedChangeProcessListResponse.
         * @memberof messagestore
         * @classdesc Represents a PaginatedChangeProcessListResponse.
         * @implements IPaginatedChangeProcessListResponse
         * @constructor
         * @param {messagestore.IPaginatedChangeProcessListResponse=} [properties] Properties to set
         */
        function PaginatedChangeProcessListResponse(properties) {
            this.changeProcess = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginatedChangeProcessListResponse changeProcess.
         * @member {Array.<messagestore.IChangeProcess>} changeProcess
         * @memberof messagestore.PaginatedChangeProcessListResponse
         * @instance
         */
        PaginatedChangeProcessListResponse.prototype.changeProcess = $util.emptyArray;

        /**
         * PaginatedChangeProcessListResponse pageInfo.
         * @member {query.IPageInfo|null|undefined} pageInfo
         * @memberof messagestore.PaginatedChangeProcessListResponse
         * @instance
         */
        PaginatedChangeProcessListResponse.prototype.pageInfo = null;

        /**
         * Gets the default type url for PaginatedChangeProcessListResponse
         * @function getTypeUrl
         * @memberof messagestore.PaginatedChangeProcessListResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaginatedChangeProcessListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.PaginatedChangeProcessListResponse";
        };

        return PaginatedChangeProcessListResponse;
    })();

    messagestore.ProcessDocuments = (function() {

        /**
         * Properties of a ProcessDocuments.
         * @memberof messagestore
         * @interface IProcessDocuments
         * @property {Array.<messagestore.IWebClientDocument>|null} [documents] ProcessDocuments documents
         */

        /**
         * Constructs a new ProcessDocuments.
         * @memberof messagestore
         * @classdesc Represents a ProcessDocuments.
         * @implements IProcessDocuments
         * @constructor
         * @param {messagestore.IProcessDocuments=} [properties] Properties to set
         */
        function ProcessDocuments(properties) {
            this.documents = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProcessDocuments documents.
         * @member {Array.<messagestore.IWebClientDocument>} documents
         * @memberof messagestore.ProcessDocuments
         * @instance
         */
        ProcessDocuments.prototype.documents = $util.emptyArray;

        /**
         * Gets the default type url for ProcessDocuments
         * @function getTypeUrl
         * @memberof messagestore.ProcessDocuments
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProcessDocuments.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.ProcessDocuments";
        };

        return ProcessDocuments;
    })();

    messagestore.MessageMetaData = (function() {

        /**
         * Properties of a MessageMetaData.
         * @memberof messagestore
         * @interface IMessageMetaData
         * @property {google.protobuf.IStringValue|null} [messageUuid] MessageMetaData messageUuid
         * @property {google.protobuf.IStringValue|null} [fileName] MessageMetaData fileName
         * @property {google.protobuf.ITimestamp|null} [timestamp] MessageMetaData timestamp
         * @property {google.protobuf.IUInt64Value|null} [size] MessageMetaData size
         * @property {google.protobuf.IBoolValue|null} [read] MessageMetaData read
         * @property {google.protobuf.IInt32Value|null} [senderId] MessageMetaData senderId
         * @property {google.protobuf.IInt32Value|null} [recipientId] MessageMetaData recipientId
         */

        /**
         * Constructs a new MessageMetaData.
         * @memberof messagestore
         * @classdesc Represents a MessageMetaData.
         * @implements IMessageMetaData
         * @constructor
         * @param {messagestore.IMessageMetaData=} [properties] Properties to set
         */
        function MessageMetaData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessageMetaData messageUuid.
         * @member {google.protobuf.IStringValue|null|undefined} messageUuid
         * @memberof messagestore.MessageMetaData
         * @instance
         */
        MessageMetaData.prototype.messageUuid = null;

        /**
         * MessageMetaData fileName.
         * @member {google.protobuf.IStringValue|null|undefined} fileName
         * @memberof messagestore.MessageMetaData
         * @instance
         */
        MessageMetaData.prototype.fileName = null;

        /**
         * MessageMetaData timestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} timestamp
         * @memberof messagestore.MessageMetaData
         * @instance
         */
        MessageMetaData.prototype.timestamp = null;

        /**
         * MessageMetaData size.
         * @member {google.protobuf.IUInt64Value|null|undefined} size
         * @memberof messagestore.MessageMetaData
         * @instance
         */
        MessageMetaData.prototype.size = null;

        /**
         * MessageMetaData read.
         * @member {google.protobuf.IBoolValue|null|undefined} read
         * @memberof messagestore.MessageMetaData
         * @instance
         */
        MessageMetaData.prototype.read = null;

        /**
         * MessageMetaData senderId.
         * @member {google.protobuf.IInt32Value|null|undefined} senderId
         * @memberof messagestore.MessageMetaData
         * @instance
         */
        MessageMetaData.prototype.senderId = null;

        /**
         * MessageMetaData recipientId.
         * @member {google.protobuf.IInt32Value|null|undefined} recipientId
         * @memberof messagestore.MessageMetaData
         * @instance
         */
        MessageMetaData.prototype.recipientId = null;

        /**
         * Gets the default type url for MessageMetaData
         * @function getTypeUrl
         * @memberof messagestore.MessageMetaData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessageMetaData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.MessageMetaData";
        };

        return MessageMetaData;
    })();

    messagestore.ErrorMessage = (function() {

        /**
         * Properties of an ErrorMessage.
         * @memberof messagestore
         * @interface IErrorMessage
         * @property {messagestore.IMessageMetaData|null} [messageMetadata] ErrorMessage messageMetadata
         * @property {google.protobuf.IInt32Value|null} [owner] ErrorMessage owner
         * @property {google.protobuf.ITimestamp|null} [errorCreationTimestamp] ErrorMessage errorCreationTimestamp
         * @property {google.protobuf.IBoolValue|null} [resendAllowed] ErrorMessage resendAllowed
         * @property {Array.<messagestore.ILocalizedSubjectBodyMessage>|null} [userMessage] ErrorMessage userMessage
         * @property {google.protobuf.IBoolValue|null} [originalMessageAvailable] ErrorMessage originalMessageAvailable
         */

        /**
         * Constructs a new ErrorMessage.
         * @memberof messagestore
         * @classdesc Represents an ErrorMessage.
         * @implements IErrorMessage
         * @constructor
         * @param {messagestore.IErrorMessage=} [properties] Properties to set
         */
        function ErrorMessage(properties) {
            this.userMessage = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ErrorMessage messageMetadata.
         * @member {messagestore.IMessageMetaData|null|undefined} messageMetadata
         * @memberof messagestore.ErrorMessage
         * @instance
         */
        ErrorMessage.prototype.messageMetadata = null;

        /**
         * ErrorMessage owner.
         * @member {google.protobuf.IInt32Value|null|undefined} owner
         * @memberof messagestore.ErrorMessage
         * @instance
         */
        ErrorMessage.prototype.owner = null;

        /**
         * ErrorMessage errorCreationTimestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} errorCreationTimestamp
         * @memberof messagestore.ErrorMessage
         * @instance
         */
        ErrorMessage.prototype.errorCreationTimestamp = null;

        /**
         * ErrorMessage resendAllowed.
         * @member {google.protobuf.IBoolValue|null|undefined} resendAllowed
         * @memberof messagestore.ErrorMessage
         * @instance
         */
        ErrorMessage.prototype.resendAllowed = null;

        /**
         * ErrorMessage userMessage.
         * @member {Array.<messagestore.ILocalizedSubjectBodyMessage>} userMessage
         * @memberof messagestore.ErrorMessage
         * @instance
         */
        ErrorMessage.prototype.userMessage = $util.emptyArray;

        /**
         * ErrorMessage originalMessageAvailable.
         * @member {google.protobuf.IBoolValue|null|undefined} originalMessageAvailable
         * @memberof messagestore.ErrorMessage
         * @instance
         */
        ErrorMessage.prototype.originalMessageAvailable = null;

        /**
         * Gets the default type url for ErrorMessage
         * @function getTypeUrl
         * @memberof messagestore.ErrorMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ErrorMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.ErrorMessage";
        };

        return ErrorMessage;
    })();

    messagestore.LocalizedSubjectBodyMessage = (function() {

        /**
         * Properties of a LocalizedSubjectBodyMessage.
         * @memberof messagestore
         * @interface ILocalizedSubjectBodyMessage
         * @property {messagestore.IMessageContent|null} [de] LocalizedSubjectBodyMessage de
         * @property {messagestore.IMessageContent|null} [fr] LocalizedSubjectBodyMessage fr
         * @property {messagestore.IMessageContent|null} [it] LocalizedSubjectBodyMessage it
         * @property {google.protobuf.IStringValue|null} [documentId] LocalizedSubjectBodyMessage documentId
         */

        /**
         * Constructs a new LocalizedSubjectBodyMessage.
         * @memberof messagestore
         * @classdesc Represents a LocalizedSubjectBodyMessage.
         * @implements ILocalizedSubjectBodyMessage
         * @constructor
         * @param {messagestore.ILocalizedSubjectBodyMessage=} [properties] Properties to set
         */
        function LocalizedSubjectBodyMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocalizedSubjectBodyMessage de.
         * @member {messagestore.IMessageContent|null|undefined} de
         * @memberof messagestore.LocalizedSubjectBodyMessage
         * @instance
         */
        LocalizedSubjectBodyMessage.prototype.de = null;

        /**
         * LocalizedSubjectBodyMessage fr.
         * @member {messagestore.IMessageContent|null|undefined} fr
         * @memberof messagestore.LocalizedSubjectBodyMessage
         * @instance
         */
        LocalizedSubjectBodyMessage.prototype.fr = null;

        /**
         * LocalizedSubjectBodyMessage it.
         * @member {messagestore.IMessageContent|null|undefined} it
         * @memberof messagestore.LocalizedSubjectBodyMessage
         * @instance
         */
        LocalizedSubjectBodyMessage.prototype.it = null;

        /**
         * LocalizedSubjectBodyMessage documentId.
         * @member {google.protobuf.IStringValue|null|undefined} documentId
         * @memberof messagestore.LocalizedSubjectBodyMessage
         * @instance
         */
        LocalizedSubjectBodyMessage.prototype.documentId = null;

        /**
         * Gets the default type url for LocalizedSubjectBodyMessage
         * @function getTypeUrl
         * @memberof messagestore.LocalizedSubjectBodyMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocalizedSubjectBodyMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.LocalizedSubjectBodyMessage";
        };

        return LocalizedSubjectBodyMessage;
    })();

    messagestore.MessageContent = (function() {

        /**
         * Properties of a MessageContent.
         * @memberof messagestore
         * @interface IMessageContent
         * @property {string|null} [subject] MessageContent subject
         * @property {string|null} [body] MessageContent body
         */

        /**
         * Constructs a new MessageContent.
         * @memberof messagestore
         * @classdesc Represents a MessageContent.
         * @implements IMessageContent
         * @constructor
         * @param {messagestore.IMessageContent=} [properties] Properties to set
         */
        function MessageContent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessageContent subject.
         * @member {string} subject
         * @memberof messagestore.MessageContent
         * @instance
         */
        MessageContent.prototype.subject = "";

        /**
         * MessageContent body.
         * @member {string} body
         * @memberof messagestore.MessageContent
         * @instance
         */
        MessageContent.prototype.body = "";

        /**
         * Gets the default type url for MessageContent
         * @function getTypeUrl
         * @memberof messagestore.MessageContent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessageContent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.MessageContent";
        };

        return MessageContent;
    })();

    messagestore.MessageReadRequest = (function() {

        /**
         * Properties of a MessageReadRequest.
         * @memberof messagestore
         * @interface IMessageReadRequest
         * @property {google.protobuf.IStringValue|null} [messageUuid] MessageReadRequest messageUuid
         * @property {boolean|null} [read] MessageReadRequest read
         */

        /**
         * Constructs a new MessageReadRequest.
         * @memberof messagestore
         * @classdesc Represents a MessageReadRequest.
         * @implements IMessageReadRequest
         * @constructor
         * @param {messagestore.IMessageReadRequest=} [properties] Properties to set
         */
        function MessageReadRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessageReadRequest messageUuid.
         * @member {google.protobuf.IStringValue|null|undefined} messageUuid
         * @memberof messagestore.MessageReadRequest
         * @instance
         */
        MessageReadRequest.prototype.messageUuid = null;

        /**
         * MessageReadRequest read.
         * @member {boolean} read
         * @memberof messagestore.MessageReadRequest
         * @instance
         */
        MessageReadRequest.prototype.read = false;

        /**
         * Gets the default type url for MessageReadRequest
         * @function getTypeUrl
         * @memberof messagestore.MessageReadRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessageReadRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.MessageReadRequest";
        };

        return MessageReadRequest;
    })();

    messagestore.ChangeProcess = (function() {

        /**
         * Properties of a ChangeProcess.
         * @memberof messagestore
         * @interface IChangeProcess
         * @property {google.protobuf.IStringValue|null} [processUuid] ChangeProcess processUuid
         * @property {sdat.BusinessReason|null} [businessReason] ChangeProcess businessReason
         * @property {google.protobuf.ITimestamp|null} [lastModificationTime] ChangeProcess lastModificationTime
         * @property {google.protobuf.IStringValue|null} [sdatProcessId] ChangeProcess sdatProcessId
         * @property {sdat.MessageClass|null} [processState] ChangeProcess processState
         * @property {google.protobuf.IBoolValue|null} [read] ChangeProcess read
         * @property {base.Direction|null} [direction] ChangeProcess direction
         * @property {google.protobuf.IBoolValue|null} [archive] ChangeProcess archive
         */

        /**
         * Constructs a new ChangeProcess.
         * @memberof messagestore
         * @classdesc Represents a ChangeProcess.
         * @implements IChangeProcess
         * @constructor
         * @param {messagestore.IChangeProcess=} [properties] Properties to set
         */
        function ChangeProcess(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangeProcess processUuid.
         * @member {google.protobuf.IStringValue|null|undefined} processUuid
         * @memberof messagestore.ChangeProcess
         * @instance
         */
        ChangeProcess.prototype.processUuid = null;

        /**
         * ChangeProcess businessReason.
         * @member {sdat.BusinessReason} businessReason
         * @memberof messagestore.ChangeProcess
         * @instance
         */
        ChangeProcess.prototype.businessReason = 0;

        /**
         * ChangeProcess lastModificationTime.
         * @member {google.protobuf.ITimestamp|null|undefined} lastModificationTime
         * @memberof messagestore.ChangeProcess
         * @instance
         */
        ChangeProcess.prototype.lastModificationTime = null;

        /**
         * ChangeProcess sdatProcessId.
         * @member {google.protobuf.IStringValue|null|undefined} sdatProcessId
         * @memberof messagestore.ChangeProcess
         * @instance
         */
        ChangeProcess.prototype.sdatProcessId = null;

        /**
         * ChangeProcess processState.
         * @member {sdat.MessageClass} processState
         * @memberof messagestore.ChangeProcess
         * @instance
         */
        ChangeProcess.prototype.processState = 0;

        /**
         * ChangeProcess read.
         * @member {google.protobuf.IBoolValue|null|undefined} read
         * @memberof messagestore.ChangeProcess
         * @instance
         */
        ChangeProcess.prototype.read = null;

        /**
         * ChangeProcess direction.
         * @member {base.Direction} direction
         * @memberof messagestore.ChangeProcess
         * @instance
         */
        ChangeProcess.prototype.direction = 0;

        /**
         * ChangeProcess archive.
         * @member {google.protobuf.IBoolValue|null|undefined} archive
         * @memberof messagestore.ChangeProcess
         * @instance
         */
        ChangeProcess.prototype.archive = null;

        /**
         * Gets the default type url for ChangeProcess
         * @function getTypeUrl
         * @memberof messagestore.ChangeProcess
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ChangeProcess.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.ChangeProcess";
        };

        return ChangeProcess;
    })();

    messagestore.WebClientDocument = (function() {

        /**
         * Properties of a WebClientDocument.
         * @memberof messagestore
         * @interface IWebClientDocument
         * @property {messagestore.IMessageMetaData|null} [messageMetadata] WebClientDocument messageMetadata
         * @property {base.Direction|null} [direction] WebClientDocument direction
         * @property {sdat.MessageClass|null} [messageClass] WebClientDocument messageClass
         * @property {sdat.BusinessReason|null} [businessReason] WebClientDocument businessReason
         * @property {google.protobuf.IBoolValue|null} [ackOfAcc] WebClientDocument ackOfAcc
         */

        /**
         * Constructs a new WebClientDocument.
         * @memberof messagestore
         * @classdesc Represents a WebClientDocument.
         * @implements IWebClientDocument
         * @constructor
         * @param {messagestore.IWebClientDocument=} [properties] Properties to set
         */
        function WebClientDocument(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WebClientDocument messageMetadata.
         * @member {messagestore.IMessageMetaData|null|undefined} messageMetadata
         * @memberof messagestore.WebClientDocument
         * @instance
         */
        WebClientDocument.prototype.messageMetadata = null;

        /**
         * WebClientDocument direction.
         * @member {base.Direction} direction
         * @memberof messagestore.WebClientDocument
         * @instance
         */
        WebClientDocument.prototype.direction = 0;

        /**
         * WebClientDocument messageClass.
         * @member {sdat.MessageClass} messageClass
         * @memberof messagestore.WebClientDocument
         * @instance
         */
        WebClientDocument.prototype.messageClass = 0;

        /**
         * WebClientDocument businessReason.
         * @member {sdat.BusinessReason} businessReason
         * @memberof messagestore.WebClientDocument
         * @instance
         */
        WebClientDocument.prototype.businessReason = 0;

        /**
         * WebClientDocument ackOfAcc.
         * @member {google.protobuf.IBoolValue|null|undefined} ackOfAcc
         * @memberof messagestore.WebClientDocument
         * @instance
         */
        WebClientDocument.prototype.ackOfAcc = null;

        /**
         * Gets the default type url for WebClientDocument
         * @function getTypeUrl
         * @memberof messagestore.WebClientDocument
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebClientDocument.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/messagestore.WebClientDocument";
        };

        return WebClientDocument;
    })();

    /**
     * OlderThan enum.
     * @name messagestore.OlderThan
     * @enum {number}
     * @property {number} OLDER_THAN_UNSPECIFIED=0 OLDER_THAN_UNSPECIFIED value
     * @property {number} ALL=1 ALL value
     * @property {number} ONE_WEEK=2 ONE_WEEK value
     * @property {number} ONE_MONTH=3 ONE_MONTH value
     */
    messagestore.OlderThan = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OLDER_THAN_UNSPECIFIED"] = 0;
        values[valuesById[1] = "ALL"] = 1;
        values[valuesById[2] = "ONE_WEEK"] = 2;
        values[valuesById[3] = "ONE_MONTH"] = 3;
        return values;
    })();

    return messagestore;
})();

export const meteringdataservice = $root.meteringdataservice = (() => {

    /**
     * Namespace meteringdataservice.
     * @exports meteringdataservice
     * @namespace
     */
    const meteringdataservice = {};

    meteringdataservice.MeteringDataService = (function() {

        /**
         * Constructs a new MeteringDataService service.
         * @memberof meteringdataservice
         * @classdesc Represents a MeteringDataService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MeteringDataService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MeteringDataService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MeteringDataService;

        /**
         * Callback as used by {@link meteringdataservice.MeteringDataService#getMeteringDataMPA}.
         * @memberof meteringdataservice.MeteringDataService
         * @typedef GetMeteringDataMPACallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {meteringdataservice.MeteringDataResponse} [response] MeteringDataResponse
         */

        /**
         * Calls GetMeteringDataMPA.
         * @function getMeteringDataMPA
         * @memberof meteringdataservice.MeteringDataService
         * @instance
         * @param {meteringdataservice.IMeteringDataRequest} request MeteringDataRequest message or plain object
         * @param {meteringdataservice.MeteringDataService.GetMeteringDataMPACallback} callback Node-style callback called with the error, if any, and MeteringDataResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringDataService.prototype.getMeteringDataMPA = function getMeteringDataMPA(request, callback) {
            return this.rpcCall(getMeteringDataMPA, $root.meteringdataservice.MeteringDataRequest, $root.meteringdataservice.MeteringDataResponse, request, callback);
        }, "name", { value: "GetMeteringDataMPA" });

        /**
         * Calls GetMeteringDataMPA.
         * @function getMeteringDataMPA
         * @memberof meteringdataservice.MeteringDataService
         * @instance
         * @param {meteringdataservice.IMeteringDataRequest} request MeteringDataRequest message or plain object
         * @returns {Promise<meteringdataservice.MeteringDataResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringdataservice.MeteringDataService#getMeteringDataCompany}.
         * @memberof meteringdataservice.MeteringDataService
         * @typedef GetMeteringDataCompanyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {meteringdataservice.MeteringDataResponse} [response] MeteringDataResponse
         */

        /**
         * Calls GetMeteringDataCompany.
         * @function getMeteringDataCompany
         * @memberof meteringdataservice.MeteringDataService
         * @instance
         * @param {meteringdataservice.IMeteringDataRequest} request MeteringDataRequest message or plain object
         * @param {meteringdataservice.MeteringDataService.GetMeteringDataCompanyCallback} callback Node-style callback called with the error, if any, and MeteringDataResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringDataService.prototype.getMeteringDataCompany = function getMeteringDataCompany(request, callback) {
            return this.rpcCall(getMeteringDataCompany, $root.meteringdataservice.MeteringDataRequest, $root.meteringdataservice.MeteringDataResponse, request, callback);
        }, "name", { value: "GetMeteringDataCompany" });

        /**
         * Calls GetMeteringDataCompany.
         * @function getMeteringDataCompany
         * @memberof meteringdataservice.MeteringDataService
         * @instance
         * @param {meteringdataservice.IMeteringDataRequest} request MeteringDataRequest message or plain object
         * @returns {Promise<meteringdataservice.MeteringDataResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringdataservice.MeteringDataService#deletePartner}.
         * @memberof meteringdataservice.MeteringDataService
         * @typedef DeletePartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeletePartner.
         * @function deletePartner
         * @memberof meteringdataservice.MeteringDataService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {meteringdataservice.MeteringDataService.DeletePartnerCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringDataService.prototype.deletePartner = function deletePartner(request, callback) {
            return this.rpcCall(deletePartner, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeletePartner" });

        /**
         * Calls DeletePartner.
         * @function deletePartner
         * @memberof meteringdataservice.MeteringDataService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringdataservice.MeteringDataService#deleteMeteringPoint}.
         * @memberof meteringdataservice.MeteringDataService
         * @typedef DeleteMeteringPointCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteMeteringPoint.
         * @function deleteMeteringPoint
         * @memberof meteringdataservice.MeteringDataService
         * @instance
         * @param {base.IMeteringPointSelector} request MeteringPointSelector message or plain object
         * @param {meteringdataservice.MeteringDataService.DeleteMeteringPointCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringDataService.prototype.deleteMeteringPoint = function deleteMeteringPoint(request, callback) {
            return this.rpcCall(deleteMeteringPoint, $root.base.MeteringPointSelector, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteMeteringPoint" });

        /**
         * Calls DeleteMeteringPoint.
         * @function deleteMeteringPoint
         * @memberof meteringdataservice.MeteringDataService
         * @instance
         * @param {base.IMeteringPointSelector} request MeteringPointSelector message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        return MeteringDataService;
    })();

    meteringdataservice.MeteringDataResponse = (function() {

        /**
         * Properties of a MeteringDataResponse.
         * @memberof meteringdataservice
         * @interface IMeteringDataResponse
         * @property {Array.<meteringdataservice.IMeteringData>|null} [meteringData] MeteringDataResponse meteringData
         */

        /**
         * Constructs a new MeteringDataResponse.
         * @memberof meteringdataservice
         * @classdesc Represents a MeteringDataResponse.
         * @implements IMeteringDataResponse
         * @constructor
         * @param {meteringdataservice.IMeteringDataResponse=} [properties] Properties to set
         */
        function MeteringDataResponse(properties) {
            this.meteringData = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringDataResponse meteringData.
         * @member {Array.<meteringdataservice.IMeteringData>} meteringData
         * @memberof meteringdataservice.MeteringDataResponse
         * @instance
         */
        MeteringDataResponse.prototype.meteringData = $util.emptyArray;

        /**
         * Gets the default type url for MeteringDataResponse
         * @function getTypeUrl
         * @memberof meteringdataservice.MeteringDataResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringDataResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringdataservice.MeteringDataResponse";
        };

        return MeteringDataResponse;
    })();

    meteringdataservice.MeteringData = (function() {

        /**
         * Properties of a MeteringData.
         * @memberof meteringdataservice
         * @interface IMeteringData
         * @property {base.IMeteringPointSelector|null} [mpSel] MeteringData mpSel
         * @property {google.protobuf.ITimestamp|null} [startTime] MeteringData startTime
         * @property {meteringdataservice.Resolution|null} [resolution] MeteringData resolution
         * @property {Array.<meteringdataservice.IMeteringDataPoint>|null} [measurements] MeteringData measurements
         */

        /**
         * Constructs a new MeteringData.
         * @memberof meteringdataservice
         * @classdesc Represents a MeteringData.
         * @implements IMeteringData
         * @constructor
         * @param {meteringdataservice.IMeteringData=} [properties] Properties to set
         */
        function MeteringData(properties) {
            this.measurements = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringData mpSel.
         * @member {base.IMeteringPointSelector|null|undefined} mpSel
         * @memberof meteringdataservice.MeteringData
         * @instance
         */
        MeteringData.prototype.mpSel = null;

        /**
         * MeteringData startTime.
         * @member {google.protobuf.ITimestamp|null|undefined} startTime
         * @memberof meteringdataservice.MeteringData
         * @instance
         */
        MeteringData.prototype.startTime = null;

        /**
         * MeteringData resolution.
         * @member {meteringdataservice.Resolution} resolution
         * @memberof meteringdataservice.MeteringData
         * @instance
         */
        MeteringData.prototype.resolution = 0;

        /**
         * MeteringData measurements.
         * @member {Array.<meteringdataservice.IMeteringDataPoint>} measurements
         * @memberof meteringdataservice.MeteringData
         * @instance
         */
        MeteringData.prototype.measurements = $util.emptyArray;

        /**
         * Gets the default type url for MeteringData
         * @function getTypeUrl
         * @memberof meteringdataservice.MeteringData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringdataservice.MeteringData";
        };

        return MeteringData;
    })();

    meteringdataservice.MeteringDataRequest = (function() {

        /**
         * Properties of a MeteringDataRequest.
         * @memberof meteringdataservice
         * @interface IMeteringDataRequest
         * @property {Array.<meteringdataservice.IMeteringDataIdentifier>|null} [identifiers] MeteringDataRequest identifiers
         */

        /**
         * Constructs a new MeteringDataRequest.
         * @memberof meteringdataservice
         * @classdesc Represents a MeteringDataRequest.
         * @implements IMeteringDataRequest
         * @constructor
         * @param {meteringdataservice.IMeteringDataRequest=} [properties] Properties to set
         */
        function MeteringDataRequest(properties) {
            this.identifiers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringDataRequest identifiers.
         * @member {Array.<meteringdataservice.IMeteringDataIdentifier>} identifiers
         * @memberof meteringdataservice.MeteringDataRequest
         * @instance
         */
        MeteringDataRequest.prototype.identifiers = $util.emptyArray;

        /**
         * Gets the default type url for MeteringDataRequest
         * @function getTypeUrl
         * @memberof meteringdataservice.MeteringDataRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringDataRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringdataservice.MeteringDataRequest";
        };

        return MeteringDataRequest;
    })();

    meteringdataservice.MeteringDataIdentifier = (function() {

        /**
         * Properties of a MeteringDataIdentifier.
         * @memberof meteringdataservice
         * @interface IMeteringDataIdentifier
         * @property {base.IMeteringPointSelector|null} [meteringPoint] MeteringDataIdentifier meteringPoint
         * @property {meteringdataservice.Resolution|null} [timeScale] MeteringDataIdentifier timeScale
         */

        /**
         * Constructs a new MeteringDataIdentifier.
         * @memberof meteringdataservice
         * @classdesc Represents a MeteringDataIdentifier.
         * @implements IMeteringDataIdentifier
         * @constructor
         * @param {meteringdataservice.IMeteringDataIdentifier=} [properties] Properties to set
         */
        function MeteringDataIdentifier(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringDataIdentifier meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof meteringdataservice.MeteringDataIdentifier
         * @instance
         */
        MeteringDataIdentifier.prototype.meteringPoint = null;

        /**
         * MeteringDataIdentifier timeScale.
         * @member {meteringdataservice.Resolution} timeScale
         * @memberof meteringdataservice.MeteringDataIdentifier
         * @instance
         */
        MeteringDataIdentifier.prototype.timeScale = 0;

        /**
         * Gets the default type url for MeteringDataIdentifier
         * @function getTypeUrl
         * @memberof meteringdataservice.MeteringDataIdentifier
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringDataIdentifier.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringdataservice.MeteringDataIdentifier";
        };

        return MeteringDataIdentifier;
    })();

    meteringdataservice.MeteringDataPoint = (function() {

        /**
         * Properties of a MeteringDataPoint.
         * @memberof meteringdataservice
         * @interface IMeteringDataPoint
         * @property {google.protobuf.ITimestamp|null} [time] MeteringDataPoint time
         * @property {number|null} [value] MeteringDataPoint value
         * @property {google.protobuf.IBoolValue|null} [complete] MeteringDataPoint complete
         */

        /**
         * Constructs a new MeteringDataPoint.
         * @memberof meteringdataservice
         * @classdesc Represents a MeteringDataPoint.
         * @implements IMeteringDataPoint
         * @constructor
         * @param {meteringdataservice.IMeteringDataPoint=} [properties] Properties to set
         */
        function MeteringDataPoint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringDataPoint time.
         * @member {google.protobuf.ITimestamp|null|undefined} time
         * @memberof meteringdataservice.MeteringDataPoint
         * @instance
         */
        MeteringDataPoint.prototype.time = null;

        /**
         * MeteringDataPoint value.
         * @member {number} value
         * @memberof meteringdataservice.MeteringDataPoint
         * @instance
         */
        MeteringDataPoint.prototype.value = 0;

        /**
         * MeteringDataPoint complete.
         * @member {google.protobuf.IBoolValue|null|undefined} complete
         * @memberof meteringdataservice.MeteringDataPoint
         * @instance
         */
        MeteringDataPoint.prototype.complete = null;

        /**
         * Gets the default type url for MeteringDataPoint
         * @function getTypeUrl
         * @memberof meteringdataservice.MeteringDataPoint
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringDataPoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringdataservice.MeteringDataPoint";
        };

        return MeteringDataPoint;
    })();

    /**
     * Resolution enum.
     * @name meteringdataservice.Resolution
     * @enum {number}
     * @property {number} RESOLUTION_UNSPECIFIED=0 RESOLUTION_UNSPECIFIED value
     * @property {number} MIN15=1 MIN15 value
     * @property {number} DAILY=2 DAILY value
     * @property {number} MONTHLY=3 MONTHLY value
     */
    meteringdataservice.Resolution = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RESOLUTION_UNSPECIFIED"] = 0;
        values[valuesById[1] = "MIN15"] = 1;
        values[valuesById[2] = "DAILY"] = 2;
        values[valuesById[3] = "MONTHLY"] = 3;
        return values;
    })();

    meteringdataservice.MeteringDataPoints = (function() {

        /**
         * Properties of a MeteringDataPoints.
         * @memberof meteringdataservice
         * @interface IMeteringDataPoints
         * @property {Array.<meteringdataservice.IMeteringDataPoint>|null} [measurements] MeteringDataPoints measurements
         */

        /**
         * Constructs a new MeteringDataPoints.
         * @memberof meteringdataservice
         * @classdesc Represents a MeteringDataPoints.
         * @implements IMeteringDataPoints
         * @constructor
         * @param {meteringdataservice.IMeteringDataPoints=} [properties] Properties to set
         */
        function MeteringDataPoints(properties) {
            this.measurements = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringDataPoints measurements.
         * @member {Array.<meteringdataservice.IMeteringDataPoint>} measurements
         * @memberof meteringdataservice.MeteringDataPoints
         * @instance
         */
        MeteringDataPoints.prototype.measurements = $util.emptyArray;

        /**
         * Gets the default type url for MeteringDataPoints
         * @function getTypeUrl
         * @memberof meteringdataservice.MeteringDataPoints
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringDataPoints.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringdataservice.MeteringDataPoints";
        };

        return MeteringDataPoints;
    })();

    return meteringdataservice;
})();

export const meteringpointdirectory = $root.meteringpointdirectory = (() => {

    /**
     * Namespace meteringpointdirectory.
     * @exports meteringpointdirectory
     * @namespace
     */
    const meteringpointdirectory = {};

    meteringpointdirectory.MeteringPointDirectoryService = (function() {

        /**
         * Constructs a new MeteringPointDirectoryService service.
         * @memberof meteringpointdirectory
         * @classdesc Represents a MeteringPointDirectoryService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MeteringPointDirectoryService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MeteringPointDirectoryService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MeteringPointDirectoryService;

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#listMeteringPointsPaginated}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef ListMeteringPointsPaginatedCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {meteringpointdirectory.PaginatedListMeteringPointsResponse} [response] PaginatedListMeteringPointsResponse
         */

        /**
         * Calls ListMeteringPointsPaginated.
         * @function listMeteringPointsPaginated
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IPaginatedListMeteringPointsRequest} request PaginatedListMeteringPointsRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.ListMeteringPointsPaginatedCallback} callback Node-style callback called with the error, if any, and PaginatedListMeteringPointsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.listMeteringPointsPaginated = function listMeteringPointsPaginated(request, callback) {
            return this.rpcCall(listMeteringPointsPaginated, $root.meteringpointdirectory.PaginatedListMeteringPointsRequest, $root.meteringpointdirectory.PaginatedListMeteringPointsResponse, request, callback);
        }, "name", { value: "ListMeteringPointsPaginated" });

        /**
         * Calls ListMeteringPointsPaginated.
         * @function listMeteringPointsPaginated
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IPaginatedListMeteringPointsRequest} request PaginatedListMeteringPointsRequest message or plain object
         * @returns {Promise<meteringpointdirectory.PaginatedListMeteringPointsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#announceUpload}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef AnnounceUploadCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls AnnounceUpload.
         * @function announceUpload
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IAnnounceUploadRequest} request AnnounceUploadRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.AnnounceUploadCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.announceUpload = function announceUpload(request, callback) {
            return this.rpcCall(announceUpload, $root.meteringpointdirectory.AnnounceUploadRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "AnnounceUpload" });

        /**
         * Calls AnnounceUpload.
         * @function announceUpload
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IAnnounceUploadRequest} request AnnounceUploadRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#getUploadStatus}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef GetUploadStatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {meteringpointdirectory.UploadStatus} [response] UploadStatus
         */

        /**
         * Calls GetUploadStatus.
         * @function getUploadStatus
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.GetUploadStatusCallback} callback Node-style callback called with the error, if any, and UploadStatus
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.getUploadStatus = function getUploadStatus(request, callback) {
            return this.rpcCall(getUploadStatus, $root.base.PartnerRequest, $root.meteringpointdirectory.UploadStatus, request, callback);
        }, "name", { value: "GetUploadStatus" });

        /**
         * Calls GetUploadStatus.
         * @function getUploadStatus
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<meteringpointdirectory.UploadStatus>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#acknowledgeUpload}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef AcknowledgeUploadCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls AcknowledgeUpload.
         * @function acknowledgeUpload
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.AcknowledgeUploadCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.acknowledgeUpload = function acknowledgeUpload(request, callback) {
            return this.rpcCall(acknowledgeUpload, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "AcknowledgeUpload" });

        /**
         * Calls AcknowledgeUpload.
         * @function acknowledgeUpload
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#downloadMeteringPointsStreaming}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef DownloadMeteringPointsStreamingCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.Chunk} [response] Chunk
         */

        /**
         * Calls DownloadMeteringPointsStreaming.
         * @function downloadMeteringPointsStreaming
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IDownloadMeteringPointsRequest} request DownloadMeteringPointsRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.DownloadMeteringPointsStreamingCallback} callback Node-style callback called with the error, if any, and Chunk
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.downloadMeteringPointsStreaming = function downloadMeteringPointsStreaming(request, callback) {
            return this.rpcCall(downloadMeteringPointsStreaming, $root.meteringpointdirectory.DownloadMeteringPointsRequest, $root.base.Chunk, request, callback);
        }, "name", { value: "DownloadMeteringPointsStreaming" });

        /**
         * Calls DownloadMeteringPointsStreaming.
         * @function downloadMeteringPointsStreaming
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IDownloadMeteringPointsRequest} request DownloadMeteringPointsRequest message or plain object
         * @returns {Promise<base.Chunk>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#getDownloadStatus}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef GetDownloadStatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {meteringpointdirectory.DownloadStatus} [response] DownloadStatus
         */

        /**
         * Calls GetDownloadStatus.
         * @function getDownloadStatus
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.GetDownloadStatusCallback} callback Node-style callback called with the error, if any, and DownloadStatus
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.getDownloadStatus = function getDownloadStatus(request, callback) {
            return this.rpcCall(getDownloadStatus, $root.base.PartnerRequest, $root.meteringpointdirectory.DownloadStatus, request, callback);
        }, "name", { value: "GetDownloadStatus" });

        /**
         * Calls GetDownloadStatus.
         * @function getDownloadStatus
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<meteringpointdirectory.DownloadStatus>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#cancelDownload}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef CancelDownloadCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls CancelDownload.
         * @function cancelDownload
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.CancelDownloadCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.cancelDownload = function cancelDownload(request, callback) {
            return this.rpcCall(cancelDownload, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "CancelDownload" });

        /**
         * Calls CancelDownload.
         * @function cancelDownload
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#getMeteringPointDetails}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef GetMeteringPointDetailsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {meteringpointdirectory.GetMeteringPointDetailsResponse} [response] GetMeteringPointDetailsResponse
         */

        /**
         * Calls GetMeteringPointDetails.
         * @function getMeteringPointDetails
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IGetMeteringPointDetailsRequest} request GetMeteringPointDetailsRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.GetMeteringPointDetailsCallback} callback Node-style callback called with the error, if any, and GetMeteringPointDetailsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.getMeteringPointDetails = function getMeteringPointDetails(request, callback) {
            return this.rpcCall(getMeteringPointDetails, $root.meteringpointdirectory.GetMeteringPointDetailsRequest, $root.meteringpointdirectory.GetMeteringPointDetailsResponse, request, callback);
        }, "name", { value: "GetMeteringPointDetails" });

        /**
         * Calls GetMeteringPointDetails.
         * @function getMeteringPointDetails
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IGetMeteringPointDetailsRequest} request GetMeteringPointDetailsRequest message or plain object
         * @returns {Promise<meteringpointdirectory.GetMeteringPointDetailsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#getMeteringPointExport}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef GetMeteringPointExportCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.DownloadFileResponse} [response] DownloadFileResponse
         */

        /**
         * Calls GetMeteringPointExport.
         * @function getMeteringPointExport
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IGetMeteringPointExportRequest} request GetMeteringPointExportRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.GetMeteringPointExportCallback} callback Node-style callback called with the error, if any, and DownloadFileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.getMeteringPointExport = function getMeteringPointExport(request, callback) {
            return this.rpcCall(getMeteringPointExport, $root.meteringpointdirectory.GetMeteringPointExportRequest, $root.base.DownloadFileResponse, request, callback);
        }, "name", { value: "GetMeteringPointExport" });

        /**
         * Calls GetMeteringPointExport.
         * @function getMeteringPointExport
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IGetMeteringPointExportRequest} request GetMeteringPointExportRequest message or plain object
         * @returns {Promise<base.DownloadFileResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#invalidateEvent}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef InvalidateEventCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls InvalidateEvent.
         * @function invalidateEvent
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IInvalidateEventRequest} request InvalidateEventRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.InvalidateEventCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.invalidateEvent = function invalidateEvent(request, callback) {
            return this.rpcCall(invalidateEvent, $root.meteringpointdirectory.InvalidateEventRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "InvalidateEvent" });

        /**
         * Calls InvalidateEvent.
         * @function invalidateEvent
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IInvalidateEventRequest} request InvalidateEventRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#deleteMeteringPoints}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef DeleteMeteringPointsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteMeteringPoints.
         * @function deleteMeteringPoints
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.DeleteMeteringPointsCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.deleteMeteringPoints = function deleteMeteringPoints(request, callback) {
            return this.rpcCall(deleteMeteringPoints, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteMeteringPoints" });

        /**
         * Calls DeleteMeteringPoints.
         * @function deleteMeteringPoints
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#deleteMeteringPoint}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef DeleteMeteringPointCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteMeteringPoint.
         * @function deleteMeteringPoint
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IDeleteMeteringPointRequest} request DeleteMeteringPointRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.DeleteMeteringPointCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.deleteMeteringPoint = function deleteMeteringPoint(request, callback) {
            return this.rpcCall(deleteMeteringPoint, $root.meteringpointdirectory.DeleteMeteringPointRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteMeteringPoint" });

        /**
         * Calls DeleteMeteringPoint.
         * @function deleteMeteringPoint
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {meteringpointdirectory.IDeleteMeteringPointRequest} request DeleteMeteringPointRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#getUnsuppliedCount}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef GetUnsuppliedCountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Int32Value} [response] Int32Value
         */

        /**
         * Calls GetUnsuppliedCount.
         * @function getUnsuppliedCount
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.GetUnsuppliedCountCallback} callback Node-style callback called with the error, if any, and Int32Value
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.getUnsuppliedCount = function getUnsuppliedCount(request, callback) {
            return this.rpcCall(getUnsuppliedCount, $root.base.PartnerRequest, $root.google.protobuf.Int32Value, request, callback);
        }, "name", { value: "GetUnsuppliedCount" });

        /**
         * Calls GetUnsuppliedCount.
         * @function getUnsuppliedCount
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Int32Value>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#restoreRelationTable}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef RestoreRelationTableCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls RestoreRelationTable.
         * @function restoreRelationTable
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.RestoreRelationTableCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.restoreRelationTable = function restoreRelationTable(request, callback) {
            return this.rpcCall(restoreRelationTable, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "RestoreRelationTable" });

        /**
         * Calls RestoreRelationTable.
         * @function restoreRelationTable
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#initializeMeteringPointStatistics}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef InitializeMeteringPointStatisticsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls InitializeMeteringPointStatistics.
         * @function initializeMeteringPointStatistics
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.InitializeMeteringPointStatisticsCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.initializeMeteringPointStatistics = function initializeMeteringPointStatistics(request, callback) {
            return this.rpcCall(initializeMeteringPointStatistics, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "InitializeMeteringPointStatistics" });

        /**
         * Calls InitializeMeteringPointStatistics.
         * @function initializeMeteringPointStatistics
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link meteringpointdirectory.MeteringPointDirectoryService#deleteStatistics}.
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @typedef DeleteStatisticsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteStatistics.
         * @function deleteStatistics
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {meteringpointdirectory.MeteringPointDirectoryService.DeleteStatisticsCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MeteringPointDirectoryService.prototype.deleteStatistics = function deleteStatistics(request, callback) {
            return this.rpcCall(deleteStatistics, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteStatistics" });

        /**
         * Calls DeleteStatistics.
         * @function deleteStatistics
         * @memberof meteringpointdirectory.MeteringPointDirectoryService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        return MeteringPointDirectoryService;
    })();

    meteringpointdirectory.MeteringPoint = (function() {

        /**
         * Properties of a MeteringPoint.
         * @memberof meteringpointdirectory
         * @interface IMeteringPoint
         * @property {google.protobuf.IStringValue|null} [id] MeteringPoint id
         * @property {meteringpointdirectory.IEicName|null} [meteringPointAdministrator] MeteringPoint meteringPointAdministrator
         * @property {meteringpointdirectory.IEicName|null} [balanceSupplier] MeteringPoint balanceSupplier
         * @property {meteringpointdirectory.IEicName|null} [balanceResponsible] MeteringPoint balanceResponsible
         * @property {Array.<meteringpointdirectory.IEicName>|null} [ancillaryServiceProviders] MeteringPoint ancillaryServiceProviders
         * @property {google.protobuf.IBoolValue|null} [isEligibleForSwitch] MeteringPoint isEligibleForSwitch
         */

        /**
         * Constructs a new MeteringPoint.
         * @memberof meteringpointdirectory
         * @classdesc Represents a MeteringPoint.
         * @implements IMeteringPoint
         * @constructor
         * @param {meteringpointdirectory.IMeteringPoint=} [properties] Properties to set
         */
        function MeteringPoint(properties) {
            this.ancillaryServiceProviders = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPoint id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof meteringpointdirectory.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.id = null;

        /**
         * MeteringPoint meteringPointAdministrator.
         * @member {meteringpointdirectory.IEicName|null|undefined} meteringPointAdministrator
         * @memberof meteringpointdirectory.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.meteringPointAdministrator = null;

        /**
         * MeteringPoint balanceSupplier.
         * @member {meteringpointdirectory.IEicName|null|undefined} balanceSupplier
         * @memberof meteringpointdirectory.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.balanceSupplier = null;

        /**
         * MeteringPoint balanceResponsible.
         * @member {meteringpointdirectory.IEicName|null|undefined} balanceResponsible
         * @memberof meteringpointdirectory.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.balanceResponsible = null;

        /**
         * MeteringPoint ancillaryServiceProviders.
         * @member {Array.<meteringpointdirectory.IEicName>} ancillaryServiceProviders
         * @memberof meteringpointdirectory.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.ancillaryServiceProviders = $util.emptyArray;

        /**
         * MeteringPoint isEligibleForSwitch.
         * @member {google.protobuf.IBoolValue|null|undefined} isEligibleForSwitch
         * @memberof meteringpointdirectory.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.isEligibleForSwitch = null;

        /**
         * Gets the default type url for MeteringPoint
         * @function getTypeUrl
         * @memberof meteringpointdirectory.MeteringPoint
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.MeteringPoint";
        };

        return MeteringPoint;
    })();

    meteringpointdirectory.EicName = (function() {

        /**
         * Properties of an EicName.
         * @memberof meteringpointdirectory
         * @interface IEicName
         * @property {google.protobuf.IStringValue|null} [eic] EicName eic
         * @property {google.protobuf.IStringValue|null} [name] EicName name
         */

        /**
         * Constructs a new EicName.
         * @memberof meteringpointdirectory
         * @classdesc Represents an EicName.
         * @implements IEicName
         * @constructor
         * @param {meteringpointdirectory.IEicName=} [properties] Properties to set
         */
        function EicName(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EicName eic.
         * @member {google.protobuf.IStringValue|null|undefined} eic
         * @memberof meteringpointdirectory.EicName
         * @instance
         */
        EicName.prototype.eic = null;

        /**
         * EicName name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof meteringpointdirectory.EicName
         * @instance
         */
        EicName.prototype.name = null;

        /**
         * Gets the default type url for EicName
         * @function getTypeUrl
         * @memberof meteringpointdirectory.EicName
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EicName.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.EicName";
        };

        return EicName;
    })();

    /**
     * MeteringPointListType enum.
     * @name meteringpointdirectory.MeteringPointListType
     * @enum {number}
     * @property {number} METERING_POINT_LIST_TYPE_UNSPECIFIED=0 METERING_POINT_LIST_TYPE_UNSPECIFIED value
     * @property {number} ALL_EVER_CONNECTED=1 ALL_EVER_CONNECTED value
     * @property {number} CONNECTED_ON_DATE=2 CONNECTED_ON_DATE value
     * @property {number} UNSUPPLIED_ON_DATE=3 UNSUPPLIED_ON_DATE value
     */
    meteringpointdirectory.MeteringPointListType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "METERING_POINT_LIST_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "ALL_EVER_CONNECTED"] = 1;
        values[valuesById[2] = "CONNECTED_ON_DATE"] = 2;
        values[valuesById[3] = "UNSUPPLIED_ON_DATE"] = 3;
        return values;
    })();

    meteringpointdirectory.PaginatedListMeteringPointsRequest = (function() {

        /**
         * Properties of a PaginatedListMeteringPointsRequest.
         * @memberof meteringpointdirectory
         * @interface IPaginatedListMeteringPointsRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] PaginatedListMeteringPointsRequest partnerId
         * @property {query.IQuery|null} [query] PaginatedListMeteringPointsRequest query
         * @property {meteringpointdirectory.MeteringPointListType|null} [type] PaginatedListMeteringPointsRequest type
         * @property {google.type.IDate|null} [date] PaginatedListMeteringPointsRequest date
         */

        /**
         * Constructs a new PaginatedListMeteringPointsRequest.
         * @memberof meteringpointdirectory
         * @classdesc Represents a PaginatedListMeteringPointsRequest.
         * @implements IPaginatedListMeteringPointsRequest
         * @constructor
         * @param {meteringpointdirectory.IPaginatedListMeteringPointsRequest=} [properties] Properties to set
         */
        function PaginatedListMeteringPointsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginatedListMeteringPointsRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof meteringpointdirectory.PaginatedListMeteringPointsRequest
         * @instance
         */
        PaginatedListMeteringPointsRequest.prototype.partnerId = null;

        /**
         * PaginatedListMeteringPointsRequest query.
         * @member {query.IQuery|null|undefined} query
         * @memberof meteringpointdirectory.PaginatedListMeteringPointsRequest
         * @instance
         */
        PaginatedListMeteringPointsRequest.prototype.query = null;

        /**
         * PaginatedListMeteringPointsRequest type.
         * @member {meteringpointdirectory.MeteringPointListType} type
         * @memberof meteringpointdirectory.PaginatedListMeteringPointsRequest
         * @instance
         */
        PaginatedListMeteringPointsRequest.prototype.type = 0;

        /**
         * PaginatedListMeteringPointsRequest date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof meteringpointdirectory.PaginatedListMeteringPointsRequest
         * @instance
         */
        PaginatedListMeteringPointsRequest.prototype.date = null;

        /**
         * Gets the default type url for PaginatedListMeteringPointsRequest
         * @function getTypeUrl
         * @memberof meteringpointdirectory.PaginatedListMeteringPointsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaginatedListMeteringPointsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.PaginatedListMeteringPointsRequest";
        };

        return PaginatedListMeteringPointsRequest;
    })();

    meteringpointdirectory.PaginatedListMeteringPointsResponse = (function() {

        /**
         * Properties of a PaginatedListMeteringPointsResponse.
         * @memberof meteringpointdirectory
         * @interface IPaginatedListMeteringPointsResponse
         * @property {Array.<meteringpointdirectory.IMeteringPoint>|null} [meteringPoints] PaginatedListMeteringPointsResponse meteringPoints
         * @property {query.IPageInfo|null} [pageInfo] PaginatedListMeteringPointsResponse pageInfo
         */

        /**
         * Constructs a new PaginatedListMeteringPointsResponse.
         * @memberof meteringpointdirectory
         * @classdesc Represents a PaginatedListMeteringPointsResponse.
         * @implements IPaginatedListMeteringPointsResponse
         * @constructor
         * @param {meteringpointdirectory.IPaginatedListMeteringPointsResponse=} [properties] Properties to set
         */
        function PaginatedListMeteringPointsResponse(properties) {
            this.meteringPoints = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginatedListMeteringPointsResponse meteringPoints.
         * @member {Array.<meteringpointdirectory.IMeteringPoint>} meteringPoints
         * @memberof meteringpointdirectory.PaginatedListMeteringPointsResponse
         * @instance
         */
        PaginatedListMeteringPointsResponse.prototype.meteringPoints = $util.emptyArray;

        /**
         * PaginatedListMeteringPointsResponse pageInfo.
         * @member {query.IPageInfo|null|undefined} pageInfo
         * @memberof meteringpointdirectory.PaginatedListMeteringPointsResponse
         * @instance
         */
        PaginatedListMeteringPointsResponse.prototype.pageInfo = null;

        /**
         * Gets the default type url for PaginatedListMeteringPointsResponse
         * @function getTypeUrl
         * @memberof meteringpointdirectory.PaginatedListMeteringPointsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaginatedListMeteringPointsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.PaginatedListMeteringPointsResponse";
        };

        return PaginatedListMeteringPointsResponse;
    })();

    meteringpointdirectory.AnnounceUploadRequest = (function() {

        /**
         * Properties of an AnnounceUploadRequest.
         * @memberof meteringpointdirectory
         * @interface IAnnounceUploadRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] AnnounceUploadRequest partnerId
         * @property {google.protobuf.IStringValue|null} [uuid] AnnounceUploadRequest uuid
         */

        /**
         * Constructs a new AnnounceUploadRequest.
         * @memberof meteringpointdirectory
         * @classdesc Represents an AnnounceUploadRequest.
         * @implements IAnnounceUploadRequest
         * @constructor
         * @param {meteringpointdirectory.IAnnounceUploadRequest=} [properties] Properties to set
         */
        function AnnounceUploadRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AnnounceUploadRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof meteringpointdirectory.AnnounceUploadRequest
         * @instance
         */
        AnnounceUploadRequest.prototype.partnerId = null;

        /**
         * AnnounceUploadRequest uuid.
         * @member {google.protobuf.IStringValue|null|undefined} uuid
         * @memberof meteringpointdirectory.AnnounceUploadRequest
         * @instance
         */
        AnnounceUploadRequest.prototype.uuid = null;

        /**
         * Gets the default type url for AnnounceUploadRequest
         * @function getTypeUrl
         * @memberof meteringpointdirectory.AnnounceUploadRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AnnounceUploadRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.AnnounceUploadRequest";
        };

        return AnnounceUploadRequest;
    })();

    meteringpointdirectory.ExportContent = (function() {

        /**
         * Properties of an ExportContent.
         * @memberof meteringpointdirectory
         * @interface IExportContent
         * @property {google.protobuf.IEmpty|null} [events] ExportContent events
         * @property {google.type.IDate|null} [state] ExportContent state
         */

        /**
         * Constructs a new ExportContent.
         * @memberof meteringpointdirectory
         * @classdesc Represents an ExportContent.
         * @implements IExportContent
         * @constructor
         * @param {meteringpointdirectory.IExportContent=} [properties] Properties to set
         */
        function ExportContent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExportContent events.
         * @member {google.protobuf.IEmpty|null|undefined} events
         * @memberof meteringpointdirectory.ExportContent
         * @instance
         */
        ExportContent.prototype.events = null;

        /**
         * ExportContent state.
         * @member {google.type.IDate|null|undefined} state
         * @memberof meteringpointdirectory.ExportContent
         * @instance
         */
        ExportContent.prototype.state = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ExportContent union.
         * @member {"events"|"state"|undefined} union
         * @memberof meteringpointdirectory.ExportContent
         * @instance
         */
        Object.defineProperty(ExportContent.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["events", "state"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for ExportContent
         * @function getTypeUrl
         * @memberof meteringpointdirectory.ExportContent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExportContent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.ExportContent";
        };

        return ExportContent;
    })();

    meteringpointdirectory.DownloadMeteringPointsRequest = (function() {

        /**
         * Properties of a DownloadMeteringPointsRequest.
         * @memberof meteringpointdirectory
         * @interface IDownloadMeteringPointsRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] DownloadMeteringPointsRequest partnerId
         * @property {meteringpointdirectory.IExportContent|null} [content] DownloadMeteringPointsRequest content
         */

        /**
         * Constructs a new DownloadMeteringPointsRequest.
         * @memberof meteringpointdirectory
         * @classdesc Represents a DownloadMeteringPointsRequest.
         * @implements IDownloadMeteringPointsRequest
         * @constructor
         * @param {meteringpointdirectory.IDownloadMeteringPointsRequest=} [properties] Properties to set
         */
        function DownloadMeteringPointsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DownloadMeteringPointsRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof meteringpointdirectory.DownloadMeteringPointsRequest
         * @instance
         */
        DownloadMeteringPointsRequest.prototype.partnerId = null;

        /**
         * DownloadMeteringPointsRequest content.
         * @member {meteringpointdirectory.IExportContent|null|undefined} content
         * @memberof meteringpointdirectory.DownloadMeteringPointsRequest
         * @instance
         */
        DownloadMeteringPointsRequest.prototype.content = null;

        /**
         * Gets the default type url for DownloadMeteringPointsRequest
         * @function getTypeUrl
         * @memberof meteringpointdirectory.DownloadMeteringPointsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DownloadMeteringPointsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.DownloadMeteringPointsRequest";
        };

        return DownloadMeteringPointsRequest;
    })();

    meteringpointdirectory.GetMeteringPointDetailsRequest = (function() {

        /**
         * Properties of a GetMeteringPointDetailsRequest.
         * @memberof meteringpointdirectory
         * @interface IGetMeteringPointDetailsRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] GetMeteringPointDetailsRequest partnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] GetMeteringPointDetailsRequest meteringPoint
         * @property {google.type.IDate|null} [date] GetMeteringPointDetailsRequest date
         */

        /**
         * Constructs a new GetMeteringPointDetailsRequest.
         * @memberof meteringpointdirectory
         * @classdesc Represents a GetMeteringPointDetailsRequest.
         * @implements IGetMeteringPointDetailsRequest
         * @constructor
         * @param {meteringpointdirectory.IGetMeteringPointDetailsRequest=} [properties] Properties to set
         */
        function GetMeteringPointDetailsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMeteringPointDetailsRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof meteringpointdirectory.GetMeteringPointDetailsRequest
         * @instance
         */
        GetMeteringPointDetailsRequest.prototype.partnerId = null;

        /**
         * GetMeteringPointDetailsRequest meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof meteringpointdirectory.GetMeteringPointDetailsRequest
         * @instance
         */
        GetMeteringPointDetailsRequest.prototype.meteringPoint = null;

        /**
         * GetMeteringPointDetailsRequest date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof meteringpointdirectory.GetMeteringPointDetailsRequest
         * @instance
         */
        GetMeteringPointDetailsRequest.prototype.date = null;

        /**
         * Gets the default type url for GetMeteringPointDetailsRequest
         * @function getTypeUrl
         * @memberof meteringpointdirectory.GetMeteringPointDetailsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMeteringPointDetailsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.GetMeteringPointDetailsRequest";
        };

        return GetMeteringPointDetailsRequest;
    })();

    meteringpointdirectory.MeteringPointState = (function() {

        /**
         * Properties of a MeteringPointState.
         * @memberof meteringpointdirectory
         * @interface IMeteringPointState
         * @property {google.type.IDate|null} [date] MeteringPointState date
         * @property {meteringpointdirectory.MeteringPointState.IAssigned|null} [assigned] MeteringPointState assigned
         * @property {meteringpointdirectory.MeteringPointState.IUnassigned|null} [unassigned] MeteringPointState unassigned
         */

        /**
         * Constructs a new MeteringPointState.
         * @memberof meteringpointdirectory
         * @classdesc Represents a MeteringPointState.
         * @implements IMeteringPointState
         * @constructor
         * @param {meteringpointdirectory.IMeteringPointState=} [properties] Properties to set
         */
        function MeteringPointState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointState date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof meteringpointdirectory.MeteringPointState
         * @instance
         */
        MeteringPointState.prototype.date = null;

        /**
         * MeteringPointState assigned.
         * @member {meteringpointdirectory.MeteringPointState.IAssigned|null|undefined} assigned
         * @memberof meteringpointdirectory.MeteringPointState
         * @instance
         */
        MeteringPointState.prototype.assigned = null;

        /**
         * MeteringPointState unassigned.
         * @member {meteringpointdirectory.MeteringPointState.IUnassigned|null|undefined} unassigned
         * @memberof meteringpointdirectory.MeteringPointState
         * @instance
         */
        MeteringPointState.prototype.unassigned = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MeteringPointState union.
         * @member {"assigned"|"unassigned"|undefined} union
         * @memberof meteringpointdirectory.MeteringPointState
         * @instance
         */
        Object.defineProperty(MeteringPointState.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["assigned", "unassigned"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for MeteringPointState
         * @function getTypeUrl
         * @memberof meteringpointdirectory.MeteringPointState
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointState.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.MeteringPointState";
        };

        MeteringPointState.Assigned = (function() {

            /**
             * Properties of an Assigned.
             * @memberof meteringpointdirectory.MeteringPointState
             * @interface IAssigned
             * @property {meteringpointdirectory.IParties|null} [parties] Assigned parties
             * @property {google.type.IDate|null} [stateUntil] Assigned stateUntil
             * @property {google.protobuf.IBoolValue|null} [isEligibleForSwitch] Assigned isEligibleForSwitch
             * @property {sdat.GridBillingMethod|null} [gridBillingMethod] Assigned gridBillingMethod
             * @property {ech.IContact|null} [consumer] Assigned consumer
             * @property {mp.IMeteringPointCharacteristics|null} [characteristics] Assigned characteristics
             * @property {mp.IMeteringPointAddress|null} [address] Assigned address
             */

            /**
             * Constructs a new Assigned.
             * @memberof meteringpointdirectory.MeteringPointState
             * @classdesc Represents an Assigned.
             * @implements IAssigned
             * @constructor
             * @param {meteringpointdirectory.MeteringPointState.IAssigned=} [properties] Properties to set
             */
            function Assigned(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Assigned parties.
             * @member {meteringpointdirectory.IParties|null|undefined} parties
             * @memberof meteringpointdirectory.MeteringPointState.Assigned
             * @instance
             */
            Assigned.prototype.parties = null;

            /**
             * Assigned stateUntil.
             * @member {google.type.IDate|null|undefined} stateUntil
             * @memberof meteringpointdirectory.MeteringPointState.Assigned
             * @instance
             */
            Assigned.prototype.stateUntil = null;

            /**
             * Assigned isEligibleForSwitch.
             * @member {google.protobuf.IBoolValue|null|undefined} isEligibleForSwitch
             * @memberof meteringpointdirectory.MeteringPointState.Assigned
             * @instance
             */
            Assigned.prototype.isEligibleForSwitch = null;

            /**
             * Assigned gridBillingMethod.
             * @member {sdat.GridBillingMethod} gridBillingMethod
             * @memberof meteringpointdirectory.MeteringPointState.Assigned
             * @instance
             */
            Assigned.prototype.gridBillingMethod = 0;

            /**
             * Assigned consumer.
             * @member {ech.IContact|null|undefined} consumer
             * @memberof meteringpointdirectory.MeteringPointState.Assigned
             * @instance
             */
            Assigned.prototype.consumer = null;

            /**
             * Assigned characteristics.
             * @member {mp.IMeteringPointCharacteristics|null|undefined} characteristics
             * @memberof meteringpointdirectory.MeteringPointState.Assigned
             * @instance
             */
            Assigned.prototype.characteristics = null;

            /**
             * Assigned address.
             * @member {mp.IMeteringPointAddress|null|undefined} address
             * @memberof meteringpointdirectory.MeteringPointState.Assigned
             * @instance
             */
            Assigned.prototype.address = null;

            /**
             * Gets the default type url for Assigned
             * @function getTypeUrl
             * @memberof meteringpointdirectory.MeteringPointState.Assigned
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Assigned.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/meteringpointdirectory.MeteringPointState.Assigned";
            };

            return Assigned;
        })();

        MeteringPointState.Unassigned = (function() {

            /**
             * Properties of an Unassigned.
             * @memberof meteringpointdirectory.MeteringPointState
             * @interface IUnassigned
             * @property {google.type.IDate|null} [since] Unassigned since
             * @property {google.type.IDate|null} [until] Unassigned until
             */

            /**
             * Constructs a new Unassigned.
             * @memberof meteringpointdirectory.MeteringPointState
             * @classdesc Represents an Unassigned.
             * @implements IUnassigned
             * @constructor
             * @param {meteringpointdirectory.MeteringPointState.IUnassigned=} [properties] Properties to set
             */
            function Unassigned(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Unassigned since.
             * @member {google.type.IDate|null|undefined} since
             * @memberof meteringpointdirectory.MeteringPointState.Unassigned
             * @instance
             */
            Unassigned.prototype.since = null;

            /**
             * Unassigned until.
             * @member {google.type.IDate|null|undefined} until
             * @memberof meteringpointdirectory.MeteringPointState.Unassigned
             * @instance
             */
            Unassigned.prototype.until = null;

            /**
             * Gets the default type url for Unassigned
             * @function getTypeUrl
             * @memberof meteringpointdirectory.MeteringPointState.Unassigned
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Unassigned.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/meteringpointdirectory.MeteringPointState.Unassigned";
            };

            return Unassigned;
        })();

        return MeteringPointState;
    })();

    meteringpointdirectory.GetMeteringPointDetailsResponse = (function() {

        /**
         * Properties of a GetMeteringPointDetailsResponse.
         * @memberof meteringpointdirectory
         * @interface IGetMeteringPointDetailsResponse
         * @property {base.IMeteringPointSelector|null} [meteringPoint] GetMeteringPointDetailsResponse meteringPoint
         * @property {meteringpointdirectory.IMeteringPointState|null} [state] GetMeteringPointDetailsResponse state
         * @property {Array.<meteringpointdirectory.IMeteringPointEvent>|null} [events] GetMeteringPointDetailsResponse events
         */

        /**
         * Constructs a new GetMeteringPointDetailsResponse.
         * @memberof meteringpointdirectory
         * @classdesc Represents a GetMeteringPointDetailsResponse.
         * @implements IGetMeteringPointDetailsResponse
         * @constructor
         * @param {meteringpointdirectory.IGetMeteringPointDetailsResponse=} [properties] Properties to set
         */
        function GetMeteringPointDetailsResponse(properties) {
            this.events = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMeteringPointDetailsResponse meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof meteringpointdirectory.GetMeteringPointDetailsResponse
         * @instance
         */
        GetMeteringPointDetailsResponse.prototype.meteringPoint = null;

        /**
         * GetMeteringPointDetailsResponse state.
         * @member {meteringpointdirectory.IMeteringPointState|null|undefined} state
         * @memberof meteringpointdirectory.GetMeteringPointDetailsResponse
         * @instance
         */
        GetMeteringPointDetailsResponse.prototype.state = null;

        /**
         * GetMeteringPointDetailsResponse events.
         * @member {Array.<meteringpointdirectory.IMeteringPointEvent>} events
         * @memberof meteringpointdirectory.GetMeteringPointDetailsResponse
         * @instance
         */
        GetMeteringPointDetailsResponse.prototype.events = $util.emptyArray;

        /**
         * Gets the default type url for GetMeteringPointDetailsResponse
         * @function getTypeUrl
         * @memberof meteringpointdirectory.GetMeteringPointDetailsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMeteringPointDetailsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.GetMeteringPointDetailsResponse";
        };

        return GetMeteringPointDetailsResponse;
    })();

    /**
     * EventType enum.
     * @name meteringpointdirectory.EventType
     * @enum {number}
     * @property {number} TYPE_UNSPECIFIED=0 TYPE_UNSPECIFIED value
     * @property {number} CREATE=1 CREATE value
     * @property {number} UPDATE=2 UPDATE value
     * @property {number} DELETE=3 DELETE value
     * @property {number} INVALIDATE=4 INVALIDATE value
     */
    meteringpointdirectory.EventType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "CREATE"] = 1;
        values[valuesById[2] = "UPDATE"] = 2;
        values[valuesById[3] = "DELETE"] = 3;
        values[valuesById[4] = "INVALIDATE"] = 4;
        return values;
    })();

    /**
     * BalanceOp enum.
     * @name meteringpointdirectory.BalanceOp
     * @enum {number}
     * @property {number} BALANCE_OP_UNSPECIFIED=0 BALANCE_OP_UNSPECIFIED value
     * @property {number} BALANCE_OP_REMOVE=1 BALANCE_OP_REMOVE value
     * @property {number} BALANCE_OP_SET=2 BALANCE_OP_SET value
     */
    meteringpointdirectory.BalanceOp = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BALANCE_OP_UNSPECIFIED"] = 0;
        values[valuesById[1] = "BALANCE_OP_REMOVE"] = 1;
        values[valuesById[2] = "BALANCE_OP_SET"] = 2;
        return values;
    })();

    meteringpointdirectory.BalanceUpdate = (function() {

        /**
         * Properties of a BalanceUpdate.
         * @memberof meteringpointdirectory
         * @interface IBalanceUpdate
         * @property {meteringpointdirectory.BalanceOp|null} [op] BalanceUpdate op
         * @property {google.protobuf.IStringValue|null} [balanceSupplierEic] BalanceUpdate balanceSupplierEic
         * @property {google.protobuf.IStringValue|null} [balanceResponsibleEic] BalanceUpdate balanceResponsibleEic
         */

        /**
         * Constructs a new BalanceUpdate.
         * @memberof meteringpointdirectory
         * @classdesc Represents a BalanceUpdate.
         * @implements IBalanceUpdate
         * @constructor
         * @param {meteringpointdirectory.IBalanceUpdate=} [properties] Properties to set
         */
        function BalanceUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BalanceUpdate op.
         * @member {meteringpointdirectory.BalanceOp} op
         * @memberof meteringpointdirectory.BalanceUpdate
         * @instance
         */
        BalanceUpdate.prototype.op = 0;

        /**
         * BalanceUpdate balanceSupplierEic.
         * @member {google.protobuf.IStringValue|null|undefined} balanceSupplierEic
         * @memberof meteringpointdirectory.BalanceUpdate
         * @instance
         */
        BalanceUpdate.prototype.balanceSupplierEic = null;

        /**
         * BalanceUpdate balanceResponsibleEic.
         * @member {google.protobuf.IStringValue|null|undefined} balanceResponsibleEic
         * @memberof meteringpointdirectory.BalanceUpdate
         * @instance
         */
        BalanceUpdate.prototype.balanceResponsibleEic = null;

        /**
         * Gets the default type url for BalanceUpdate
         * @function getTypeUrl
         * @memberof meteringpointdirectory.BalanceUpdate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BalanceUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.BalanceUpdate";
        };

        return BalanceUpdate;
    })();

    /**
     * AspOp enum.
     * @name meteringpointdirectory.AspOp
     * @enum {number}
     * @property {number} ASP_OP_UNSPECIFIED=0 ASP_OP_UNSPECIFIED value
     * @property {number} ASP_OP_REMOVE=1 ASP_OP_REMOVE value
     * @property {number} ASP_OP_ADD=2 ASP_OP_ADD value
     */
    meteringpointdirectory.AspOp = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ASP_OP_UNSPECIFIED"] = 0;
        values[valuesById[1] = "ASP_OP_REMOVE"] = 1;
        values[valuesById[2] = "ASP_OP_ADD"] = 2;
        return values;
    })();

    meteringpointdirectory.AspUpdate = (function() {

        /**
         * Properties of an AspUpdate.
         * @memberof meteringpointdirectory
         * @interface IAspUpdate
         * @property {meteringpointdirectory.AspOp|null} [op] AspUpdate op
         * @property {google.protobuf.IStringValue|null} [ancillaryServiceProviderEic] AspUpdate ancillaryServiceProviderEic
         */

        /**
         * Constructs a new AspUpdate.
         * @memberof meteringpointdirectory
         * @classdesc Represents an AspUpdate.
         * @implements IAspUpdate
         * @constructor
         * @param {meteringpointdirectory.IAspUpdate=} [properties] Properties to set
         */
        function AspUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AspUpdate op.
         * @member {meteringpointdirectory.AspOp} op
         * @memberof meteringpointdirectory.AspUpdate
         * @instance
         */
        AspUpdate.prototype.op = 0;

        /**
         * AspUpdate ancillaryServiceProviderEic.
         * @member {google.protobuf.IStringValue|null|undefined} ancillaryServiceProviderEic
         * @memberof meteringpointdirectory.AspUpdate
         * @instance
         */
        AspUpdate.prototype.ancillaryServiceProviderEic = null;

        /**
         * Gets the default type url for AspUpdate
         * @function getTypeUrl
         * @memberof meteringpointdirectory.AspUpdate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AspUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.AspUpdate";
        };

        return AspUpdate;
    })();

    /**
     * ConsumerOp enum.
     * @name meteringpointdirectory.ConsumerOp
     * @enum {number}
     * @property {number} CONSUMER_OP_UNSPECIFIED=0 CONSUMER_OP_UNSPECIFIED value
     * @property {number} CONSUMER_OP_REMOVE=1 CONSUMER_OP_REMOVE value
     * @property {number} CONSUMER_OP_SET=2 CONSUMER_OP_SET value
     */
    meteringpointdirectory.ConsumerOp = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CONSUMER_OP_UNSPECIFIED"] = 0;
        values[valuesById[1] = "CONSUMER_OP_REMOVE"] = 1;
        values[valuesById[2] = "CONSUMER_OP_SET"] = 2;
        return values;
    })();

    meteringpointdirectory.ConsumerUpdate = (function() {

        /**
         * Properties of a ConsumerUpdate.
         * @memberof meteringpointdirectory
         * @interface IConsumerUpdate
         * @property {meteringpointdirectory.ConsumerOp|null} [op] ConsumerUpdate op
         * @property {ech.IContact|null} [consumer] ConsumerUpdate consumer
         */

        /**
         * Constructs a new ConsumerUpdate.
         * @memberof meteringpointdirectory
         * @classdesc Represents a ConsumerUpdate.
         * @implements IConsumerUpdate
         * @constructor
         * @param {meteringpointdirectory.IConsumerUpdate=} [properties] Properties to set
         */
        function ConsumerUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumerUpdate op.
         * @member {meteringpointdirectory.ConsumerOp} op
         * @memberof meteringpointdirectory.ConsumerUpdate
         * @instance
         */
        ConsumerUpdate.prototype.op = 0;

        /**
         * ConsumerUpdate consumer.
         * @member {ech.IContact|null|undefined} consumer
         * @memberof meteringpointdirectory.ConsumerUpdate
         * @instance
         */
        ConsumerUpdate.prototype.consumer = null;

        /**
         * Gets the default type url for ConsumerUpdate
         * @function getTypeUrl
         * @memberof meteringpointdirectory.ConsumerUpdate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumerUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.ConsumerUpdate";
        };

        return ConsumerUpdate;
    })();

    meteringpointdirectory.MeteringPointEvent = (function() {

        /**
         * Properties of a MeteringPointEvent.
         * @memberof meteringpointdirectory
         * @interface IMeteringPointEvent
         * @property {google.protobuf.ITimestamp|null} [transactionTime] MeteringPointEvent transactionTime
         * @property {meteringpointdirectory.IMetadata|null} [transactionReason] MeteringPointEvent transactionReason
         * @property {google.type.IDate|null} [dueDate] MeteringPointEvent dueDate
         * @property {meteringpointdirectory.EventType|null} [type] MeteringPointEvent type
         * @property {google.protobuf.IBoolValue|null} [isEligibleForSwitch] MeteringPointEvent isEligibleForSwitch
         * @property {meteringpointdirectory.IBalanceUpdate|null} [balanceUpdate] MeteringPointEvent balanceUpdate
         * @property {Array.<meteringpointdirectory.IAspUpdate>|null} [aspUpdates] MeteringPointEvent aspUpdates
         * @property {sdat.GridBillingMethod|null} [gridBillingMethod] MeteringPointEvent gridBillingMethod
         * @property {google.protobuf.IInt32Value|null} [eventIndex] MeteringPointEvent eventIndex
         * @property {meteringpointdirectory.IConsumerUpdate|null} [consumerUpdate] MeteringPointEvent consumerUpdate
         * @property {mp.IMeteringPointCharacteristics|null} [characteristics] MeteringPointEvent characteristics
         * @property {mp.IMeteringPointAddress|null} [address] MeteringPointEvent address
         */

        /**
         * Constructs a new MeteringPointEvent.
         * @memberof meteringpointdirectory
         * @classdesc Represents a MeteringPointEvent.
         * @implements IMeteringPointEvent
         * @constructor
         * @param {meteringpointdirectory.IMeteringPointEvent=} [properties] Properties to set
         */
        function MeteringPointEvent(properties) {
            this.aspUpdates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointEvent transactionTime.
         * @member {google.protobuf.ITimestamp|null|undefined} transactionTime
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.transactionTime = null;

        /**
         * MeteringPointEvent transactionReason.
         * @member {meteringpointdirectory.IMetadata|null|undefined} transactionReason
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.transactionReason = null;

        /**
         * MeteringPointEvent dueDate.
         * @member {google.type.IDate|null|undefined} dueDate
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.dueDate = null;

        /**
         * MeteringPointEvent type.
         * @member {meteringpointdirectory.EventType} type
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.type = 0;

        /**
         * MeteringPointEvent isEligibleForSwitch.
         * @member {google.protobuf.IBoolValue|null|undefined} isEligibleForSwitch
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.isEligibleForSwitch = null;

        /**
         * MeteringPointEvent balanceUpdate.
         * @member {meteringpointdirectory.IBalanceUpdate|null|undefined} balanceUpdate
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.balanceUpdate = null;

        /**
         * MeteringPointEvent aspUpdates.
         * @member {Array.<meteringpointdirectory.IAspUpdate>} aspUpdates
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.aspUpdates = $util.emptyArray;

        /**
         * MeteringPointEvent gridBillingMethod.
         * @member {sdat.GridBillingMethod} gridBillingMethod
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.gridBillingMethod = 0;

        /**
         * MeteringPointEvent eventIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} eventIndex
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.eventIndex = null;

        /**
         * MeteringPointEvent consumerUpdate.
         * @member {meteringpointdirectory.IConsumerUpdate|null|undefined} consumerUpdate
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.consumerUpdate = null;

        /**
         * MeteringPointEvent characteristics.
         * @member {mp.IMeteringPointCharacteristics|null|undefined} characteristics
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.characteristics = null;

        /**
         * MeteringPointEvent address.
         * @member {mp.IMeteringPointAddress|null|undefined} address
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @instance
         */
        MeteringPointEvent.prototype.address = null;

        /**
         * Gets the default type url for MeteringPointEvent
         * @function getTypeUrl
         * @memberof meteringpointdirectory.MeteringPointEvent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.MeteringPointEvent";
        };

        return MeteringPointEvent;
    })();

    meteringpointdirectory.Parties = (function() {

        /**
         * Properties of a Parties.
         * @memberof meteringpointdirectory
         * @interface IParties
         * @property {google.protobuf.IStringValue|null} [balanceSupplierEic] Parties balanceSupplierEic
         * @property {google.protobuf.IStringValue|null} [balanceResponsibleEic] Parties balanceResponsibleEic
         * @property {Array.<google.protobuf.IStringValue>|null} [ancillaryServiceProviderEics] Parties ancillaryServiceProviderEics
         */

        /**
         * Constructs a new Parties.
         * @memberof meteringpointdirectory
         * @classdesc Represents a Parties.
         * @implements IParties
         * @constructor
         * @param {meteringpointdirectory.IParties=} [properties] Properties to set
         */
        function Parties(properties) {
            this.ancillaryServiceProviderEics = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Parties balanceSupplierEic.
         * @member {google.protobuf.IStringValue|null|undefined} balanceSupplierEic
         * @memberof meteringpointdirectory.Parties
         * @instance
         */
        Parties.prototype.balanceSupplierEic = null;

        /**
         * Parties balanceResponsibleEic.
         * @member {google.protobuf.IStringValue|null|undefined} balanceResponsibleEic
         * @memberof meteringpointdirectory.Parties
         * @instance
         */
        Parties.prototype.balanceResponsibleEic = null;

        /**
         * Parties ancillaryServiceProviderEics.
         * @member {Array.<google.protobuf.IStringValue>} ancillaryServiceProviderEics
         * @memberof meteringpointdirectory.Parties
         * @instance
         */
        Parties.prototype.ancillaryServiceProviderEics = $util.emptyArray;

        /**
         * Gets the default type url for Parties
         * @function getTypeUrl
         * @memberof meteringpointdirectory.Parties
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Parties.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.Parties";
        };

        return Parties;
    })();

    meteringpointdirectory.Metadata = (function() {

        /**
         * Properties of a Metadata.
         * @memberof meteringpointdirectory
         * @interface IMetadata
         * @property {meteringpointdirectory.ISdatEventMetadata|null} [sdat] Metadata sdat
         * @property {meteringpointdirectory.IImportEventMetadata|null} ["import"] Metadata import
         * @property {meteringpointdirectory.IWebUiEventMetadata|null} [webUi] Metadata webUi
         */

        /**
         * Constructs a new Metadata.
         * @memberof meteringpointdirectory
         * @classdesc Represents a Metadata.
         * @implements IMetadata
         * @constructor
         * @param {meteringpointdirectory.IMetadata=} [properties] Properties to set
         */
        function Metadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Metadata sdat.
         * @member {meteringpointdirectory.ISdatEventMetadata|null|undefined} sdat
         * @memberof meteringpointdirectory.Metadata
         * @instance
         */
        Metadata.prototype.sdat = null;

        /**
         * Metadata import.
         * @member {meteringpointdirectory.IImportEventMetadata|null|undefined} import
         * @memberof meteringpointdirectory.Metadata
         * @instance
         */
        Metadata.prototype["import"] = null;

        /**
         * Metadata webUi.
         * @member {meteringpointdirectory.IWebUiEventMetadata|null|undefined} webUi
         * @memberof meteringpointdirectory.Metadata
         * @instance
         */
        Metadata.prototype.webUi = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Metadata transactionReason.
         * @member {"sdat"|"import"|"webUi"|undefined} transactionReason
         * @memberof meteringpointdirectory.Metadata
         * @instance
         */
        Object.defineProperty(Metadata.prototype, "transactionReason", {
            get: $util.oneOfGetter($oneOfFields = ["sdat", "import", "webUi"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for Metadata
         * @function getTypeUrl
         * @memberof meteringpointdirectory.Metadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Metadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.Metadata";
        };

        return Metadata;
    })();

    meteringpointdirectory.SdatEventMetadata = (function() {

        /**
         * Properties of a SdatEventMetadata.
         * @memberof meteringpointdirectory
         * @interface ISdatEventMetadata
         * @property {google.protobuf.IStringValue|null} [message] SdatEventMetadata message
         * @property {sdat.BusinessReason|null} [causingProcessType] SdatEventMetadata causingProcessType
         * @property {google.protobuf.IStringValue|null} [causingProcessId] SdatEventMetadata causingProcessId
         */

        /**
         * Constructs a new SdatEventMetadata.
         * @memberof meteringpointdirectory
         * @classdesc Represents a SdatEventMetadata.
         * @implements ISdatEventMetadata
         * @constructor
         * @param {meteringpointdirectory.ISdatEventMetadata=} [properties] Properties to set
         */
        function SdatEventMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SdatEventMetadata message.
         * @member {google.protobuf.IStringValue|null|undefined} message
         * @memberof meteringpointdirectory.SdatEventMetadata
         * @instance
         */
        SdatEventMetadata.prototype.message = null;

        /**
         * SdatEventMetadata causingProcessType.
         * @member {sdat.BusinessReason} causingProcessType
         * @memberof meteringpointdirectory.SdatEventMetadata
         * @instance
         */
        SdatEventMetadata.prototype.causingProcessType = 0;

        /**
         * SdatEventMetadata causingProcessId.
         * @member {google.protobuf.IStringValue|null|undefined} causingProcessId
         * @memberof meteringpointdirectory.SdatEventMetadata
         * @instance
         */
        SdatEventMetadata.prototype.causingProcessId = null;

        /**
         * Gets the default type url for SdatEventMetadata
         * @function getTypeUrl
         * @memberof meteringpointdirectory.SdatEventMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SdatEventMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.SdatEventMetadata";
        };

        return SdatEventMetadata;
    })();

    meteringpointdirectory.ImportEventMetadata = (function() {

        /**
         * Properties of an ImportEventMetadata.
         * @memberof meteringpointdirectory
         * @interface IImportEventMetadata
         * @property {google.protobuf.IStringValue|null} [author] ImportEventMetadata author
         */

        /**
         * Constructs a new ImportEventMetadata.
         * @memberof meteringpointdirectory
         * @classdesc Represents an ImportEventMetadata.
         * @implements IImportEventMetadata
         * @constructor
         * @param {meteringpointdirectory.IImportEventMetadata=} [properties] Properties to set
         */
        function ImportEventMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImportEventMetadata author.
         * @member {google.protobuf.IStringValue|null|undefined} author
         * @memberof meteringpointdirectory.ImportEventMetadata
         * @instance
         */
        ImportEventMetadata.prototype.author = null;

        /**
         * Gets the default type url for ImportEventMetadata
         * @function getTypeUrl
         * @memberof meteringpointdirectory.ImportEventMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImportEventMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.ImportEventMetadata";
        };

        return ImportEventMetadata;
    })();

    meteringpointdirectory.WebUiEventMetadata = (function() {

        /**
         * Properties of a WebUiEventMetadata.
         * @memberof meteringpointdirectory
         * @interface IWebUiEventMetadata
         * @property {google.protobuf.IStringValue|null} [author] WebUiEventMetadata author
         */

        /**
         * Constructs a new WebUiEventMetadata.
         * @memberof meteringpointdirectory
         * @classdesc Represents a WebUiEventMetadata.
         * @implements IWebUiEventMetadata
         * @constructor
         * @param {meteringpointdirectory.IWebUiEventMetadata=} [properties] Properties to set
         */
        function WebUiEventMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WebUiEventMetadata author.
         * @member {google.protobuf.IStringValue|null|undefined} author
         * @memberof meteringpointdirectory.WebUiEventMetadata
         * @instance
         */
        WebUiEventMetadata.prototype.author = null;

        /**
         * Gets the default type url for WebUiEventMetadata
         * @function getTypeUrl
         * @memberof meteringpointdirectory.WebUiEventMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebUiEventMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.WebUiEventMetadata";
        };

        return WebUiEventMetadata;
    })();

    meteringpointdirectory.GetMeteringPointExportRequest = (function() {

        /**
         * Properties of a GetMeteringPointExportRequest.
         * @memberof meteringpointdirectory
         * @interface IGetMeteringPointExportRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] GetMeteringPointExportRequest partnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] GetMeteringPointExportRequest meteringPoint
         * @property {meteringpointdirectory.IExportContent|null} [content] GetMeteringPointExportRequest content
         */

        /**
         * Constructs a new GetMeteringPointExportRequest.
         * @memberof meteringpointdirectory
         * @classdesc Represents a GetMeteringPointExportRequest.
         * @implements IGetMeteringPointExportRequest
         * @constructor
         * @param {meteringpointdirectory.IGetMeteringPointExportRequest=} [properties] Properties to set
         */
        function GetMeteringPointExportRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMeteringPointExportRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof meteringpointdirectory.GetMeteringPointExportRequest
         * @instance
         */
        GetMeteringPointExportRequest.prototype.partnerId = null;

        /**
         * GetMeteringPointExportRequest meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof meteringpointdirectory.GetMeteringPointExportRequest
         * @instance
         */
        GetMeteringPointExportRequest.prototype.meteringPoint = null;

        /**
         * GetMeteringPointExportRequest content.
         * @member {meteringpointdirectory.IExportContent|null|undefined} content
         * @memberof meteringpointdirectory.GetMeteringPointExportRequest
         * @instance
         */
        GetMeteringPointExportRequest.prototype.content = null;

        /**
         * Gets the default type url for GetMeteringPointExportRequest
         * @function getTypeUrl
         * @memberof meteringpointdirectory.GetMeteringPointExportRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMeteringPointExportRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.GetMeteringPointExportRequest";
        };

        return GetMeteringPointExportRequest;
    })();

    meteringpointdirectory.UploadStatus = (function() {

        /**
         * Properties of an UploadStatus.
         * @memberof meteringpointdirectory
         * @interface IUploadStatus
         * @property {google.protobuf.IEmpty|null} [noUpload] UploadStatus noUpload
         * @property {google.protobuf.IEmpty|null} [scheduled] UploadStatus scheduled
         * @property {google.protobuf.IInt32Value|null} [validating] UploadStatus validating
         * @property {meteringpointdirectory.IProgress|null} [running] UploadStatus running
         * @property {meteringpointdirectory.IResult|null} [finished] UploadStatus finished
         */

        /**
         * Constructs a new UploadStatus.
         * @memberof meteringpointdirectory
         * @classdesc Represents an UploadStatus.
         * @implements IUploadStatus
         * @constructor
         * @param {meteringpointdirectory.IUploadStatus=} [properties] Properties to set
         */
        function UploadStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStatus noUpload.
         * @member {google.protobuf.IEmpty|null|undefined} noUpload
         * @memberof meteringpointdirectory.UploadStatus
         * @instance
         */
        UploadStatus.prototype.noUpload = null;

        /**
         * UploadStatus scheduled.
         * @member {google.protobuf.IEmpty|null|undefined} scheduled
         * @memberof meteringpointdirectory.UploadStatus
         * @instance
         */
        UploadStatus.prototype.scheduled = null;

        /**
         * UploadStatus validating.
         * @member {google.protobuf.IInt32Value|null|undefined} validating
         * @memberof meteringpointdirectory.UploadStatus
         * @instance
         */
        UploadStatus.prototype.validating = null;

        /**
         * UploadStatus running.
         * @member {meteringpointdirectory.IProgress|null|undefined} running
         * @memberof meteringpointdirectory.UploadStatus
         * @instance
         */
        UploadStatus.prototype.running = null;

        /**
         * UploadStatus finished.
         * @member {meteringpointdirectory.IResult|null|undefined} finished
         * @memberof meteringpointdirectory.UploadStatus
         * @instance
         */
        UploadStatus.prototype.finished = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * UploadStatus union.
         * @member {"noUpload"|"scheduled"|"validating"|"running"|"finished"|undefined} union
         * @memberof meteringpointdirectory.UploadStatus
         * @instance
         */
        Object.defineProperty(UploadStatus.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["noUpload", "scheduled", "validating", "running", "finished"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for UploadStatus
         * @function getTypeUrl
         * @memberof meteringpointdirectory.UploadStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.UploadStatus";
        };

        return UploadStatus;
    })();

    meteringpointdirectory.DownloadStatus = (function() {

        /**
         * Properties of a DownloadStatus.
         * @memberof meteringpointdirectory
         * @interface IDownloadStatus
         * @property {google.protobuf.IEmpty|null} [noDownload] DownloadStatus noDownload
         * @property {meteringpointdirectory.IProgress|null} [running] DownloadStatus running
         */

        /**
         * Constructs a new DownloadStatus.
         * @memberof meteringpointdirectory
         * @classdesc Represents a DownloadStatus.
         * @implements IDownloadStatus
         * @constructor
         * @param {meteringpointdirectory.IDownloadStatus=} [properties] Properties to set
         */
        function DownloadStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DownloadStatus noDownload.
         * @member {google.protobuf.IEmpty|null|undefined} noDownload
         * @memberof meteringpointdirectory.DownloadStatus
         * @instance
         */
        DownloadStatus.prototype.noDownload = null;

        /**
         * DownloadStatus running.
         * @member {meteringpointdirectory.IProgress|null|undefined} running
         * @memberof meteringpointdirectory.DownloadStatus
         * @instance
         */
        DownloadStatus.prototype.running = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * DownloadStatus union.
         * @member {"noDownload"|"running"|undefined} union
         * @memberof meteringpointdirectory.DownloadStatus
         * @instance
         */
        Object.defineProperty(DownloadStatus.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["noDownload", "running"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for DownloadStatus
         * @function getTypeUrl
         * @memberof meteringpointdirectory.DownloadStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DownloadStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.DownloadStatus";
        };

        return DownloadStatus;
    })();

    meteringpointdirectory.Progress = (function() {

        /**
         * Properties of a Progress.
         * @memberof meteringpointdirectory
         * @interface IProgress
         * @property {google.protobuf.IInt32Value|null} [processed] Progress processed
         * @property {google.protobuf.IInt32Value|null} [total] Progress total
         */

        /**
         * Constructs a new Progress.
         * @memberof meteringpointdirectory
         * @classdesc Represents a Progress.
         * @implements IProgress
         * @constructor
         * @param {meteringpointdirectory.IProgress=} [properties] Properties to set
         */
        function Progress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Progress processed.
         * @member {google.protobuf.IInt32Value|null|undefined} processed
         * @memberof meteringpointdirectory.Progress
         * @instance
         */
        Progress.prototype.processed = null;

        /**
         * Progress total.
         * @member {google.protobuf.IInt32Value|null|undefined} total
         * @memberof meteringpointdirectory.Progress
         * @instance
         */
        Progress.prototype.total = null;

        /**
         * Gets the default type url for Progress
         * @function getTypeUrl
         * @memberof meteringpointdirectory.Progress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Progress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.Progress";
        };

        return Progress;
    })();

    meteringpointdirectory.Result = (function() {

        /**
         * Properties of a Result.
         * @memberof meteringpointdirectory
         * @interface IResult
         * @property {meteringpointdirectory.IAccepted|null} [accepted] Result accepted
         * @property {meteringpointdirectory.IRejected|null} [rejected] Result rejected
         */

        /**
         * Constructs a new Result.
         * @memberof meteringpointdirectory
         * @classdesc Represents a Result.
         * @implements IResult
         * @constructor
         * @param {meteringpointdirectory.IResult=} [properties] Properties to set
         */
        function Result(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Result accepted.
         * @member {meteringpointdirectory.IAccepted|null|undefined} accepted
         * @memberof meteringpointdirectory.Result
         * @instance
         */
        Result.prototype.accepted = null;

        /**
         * Result rejected.
         * @member {meteringpointdirectory.IRejected|null|undefined} rejected
         * @memberof meteringpointdirectory.Result
         * @instance
         */
        Result.prototype.rejected = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Result union.
         * @member {"accepted"|"rejected"|undefined} union
         * @memberof meteringpointdirectory.Result
         * @instance
         */
        Object.defineProperty(Result.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["accepted", "rejected"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for Result
         * @function getTypeUrl
         * @memberof meteringpointdirectory.Result
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Result.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.Result";
        };

        return Result;
    })();

    meteringpointdirectory.Accepted = (function() {

        /**
         * Properties of an Accepted.
         * @memberof meteringpointdirectory
         * @interface IAccepted
         * @property {Array.<base.ILocalizedMessage>|null} [warnings] Accepted warnings
         * @property {Array.<base.ILocalizedMessage>|null} [errors] Accepted errors
         */

        /**
         * Constructs a new Accepted.
         * @memberof meteringpointdirectory
         * @classdesc Represents an Accepted.
         * @implements IAccepted
         * @constructor
         * @param {meteringpointdirectory.IAccepted=} [properties] Properties to set
         */
        function Accepted(properties) {
            this.warnings = [];
            this.errors = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Accepted warnings.
         * @member {Array.<base.ILocalizedMessage>} warnings
         * @memberof meteringpointdirectory.Accepted
         * @instance
         */
        Accepted.prototype.warnings = $util.emptyArray;

        /**
         * Accepted errors.
         * @member {Array.<base.ILocalizedMessage>} errors
         * @memberof meteringpointdirectory.Accepted
         * @instance
         */
        Accepted.prototype.errors = $util.emptyArray;

        /**
         * Gets the default type url for Accepted
         * @function getTypeUrl
         * @memberof meteringpointdirectory.Accepted
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Accepted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.Accepted";
        };

        return Accepted;
    })();

    meteringpointdirectory.Rejected = (function() {

        /**
         * Properties of a Rejected.
         * @memberof meteringpointdirectory
         * @interface IRejected
         * @property {Array.<base.ILocalizedMessage>|null} [errors] Rejected errors
         */

        /**
         * Constructs a new Rejected.
         * @memberof meteringpointdirectory
         * @classdesc Represents a Rejected.
         * @implements IRejected
         * @constructor
         * @param {meteringpointdirectory.IRejected=} [properties] Properties to set
         */
        function Rejected(properties) {
            this.errors = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Rejected errors.
         * @member {Array.<base.ILocalizedMessage>} errors
         * @memberof meteringpointdirectory.Rejected
         * @instance
         */
        Rejected.prototype.errors = $util.emptyArray;

        /**
         * Gets the default type url for Rejected
         * @function getTypeUrl
         * @memberof meteringpointdirectory.Rejected
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Rejected.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.Rejected";
        };

        return Rejected;
    })();

    meteringpointdirectory.InvalidateEventRequest = (function() {

        /**
         * Properties of an InvalidateEventRequest.
         * @memberof meteringpointdirectory
         * @interface IInvalidateEventRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] InvalidateEventRequest partnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] InvalidateEventRequest meteringPoint
         * @property {google.protobuf.IInt32Value|null} [eventIndex] InvalidateEventRequest eventIndex
         */

        /**
         * Constructs a new InvalidateEventRequest.
         * @memberof meteringpointdirectory
         * @classdesc Represents an InvalidateEventRequest.
         * @implements IInvalidateEventRequest
         * @constructor
         * @param {meteringpointdirectory.IInvalidateEventRequest=} [properties] Properties to set
         */
        function InvalidateEventRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InvalidateEventRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof meteringpointdirectory.InvalidateEventRequest
         * @instance
         */
        InvalidateEventRequest.prototype.partnerId = null;

        /**
         * InvalidateEventRequest meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof meteringpointdirectory.InvalidateEventRequest
         * @instance
         */
        InvalidateEventRequest.prototype.meteringPoint = null;

        /**
         * InvalidateEventRequest eventIndex.
         * @member {google.protobuf.IInt32Value|null|undefined} eventIndex
         * @memberof meteringpointdirectory.InvalidateEventRequest
         * @instance
         */
        InvalidateEventRequest.prototype.eventIndex = null;

        /**
         * Gets the default type url for InvalidateEventRequest
         * @function getTypeUrl
         * @memberof meteringpointdirectory.InvalidateEventRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InvalidateEventRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.InvalidateEventRequest";
        };

        return InvalidateEventRequest;
    })();

    meteringpointdirectory.DeleteMeteringPointRequest = (function() {

        /**
         * Properties of a DeleteMeteringPointRequest.
         * @memberof meteringpointdirectory
         * @interface IDeleteMeteringPointRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] DeleteMeteringPointRequest partnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] DeleteMeteringPointRequest meteringPoint
         */

        /**
         * Constructs a new DeleteMeteringPointRequest.
         * @memberof meteringpointdirectory
         * @classdesc Represents a DeleteMeteringPointRequest.
         * @implements IDeleteMeteringPointRequest
         * @constructor
         * @param {meteringpointdirectory.IDeleteMeteringPointRequest=} [properties] Properties to set
         */
        function DeleteMeteringPointRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteMeteringPointRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof meteringpointdirectory.DeleteMeteringPointRequest
         * @instance
         */
        DeleteMeteringPointRequest.prototype.partnerId = null;

        /**
         * DeleteMeteringPointRequest meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof meteringpointdirectory.DeleteMeteringPointRequest
         * @instance
         */
        DeleteMeteringPointRequest.prototype.meteringPoint = null;

        /**
         * Gets the default type url for DeleteMeteringPointRequest
         * @function getTypeUrl
         * @memberof meteringpointdirectory.DeleteMeteringPointRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteMeteringPointRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/meteringpointdirectory.DeleteMeteringPointRequest";
        };

        return DeleteMeteringPointRequest;
    })();

    return meteringpointdirectory;
})();

export const ech = $root.ech = (() => {

    /**
     * Namespace ech.
     * @exports ech
     * @namespace
     */
    const ech = {};

    ech.Contact = (function() {

        /**
         * Properties of a Contact.
         * @memberof ech
         * @interface IContact
         * @property {ech.INamedPersonId|null} [localId] Contact localId
         * @property {Array.<ech.IContactItemMailAddressBaseCategory>|null} [address] Contact address
         * @property {Array.<ech.IContactItemStringBaseCategory>|null} [email] Contact email
         * @property {Array.<ech.IContactItemStringPhoneCategory>|null} [phone] Contact phone
         * @property {Array.<ech.IContactItemStringBaseCategory>|null} [internet] Contact internet
         */

        /**
         * Constructs a new Contact.
         * @memberof ech
         * @classdesc Represents a Contact.
         * @implements IContact
         * @constructor
         * @param {ech.IContact=} [properties] Properties to set
         */
        function Contact(properties) {
            this.address = [];
            this.email = [];
            this.phone = [];
            this.internet = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Contact localId.
         * @member {ech.INamedPersonId|null|undefined} localId
         * @memberof ech.Contact
         * @instance
         */
        Contact.prototype.localId = null;

        /**
         * Contact address.
         * @member {Array.<ech.IContactItemMailAddressBaseCategory>} address
         * @memberof ech.Contact
         * @instance
         */
        Contact.prototype.address = $util.emptyArray;

        /**
         * Contact email.
         * @member {Array.<ech.IContactItemStringBaseCategory>} email
         * @memberof ech.Contact
         * @instance
         */
        Contact.prototype.email = $util.emptyArray;

        /**
         * Contact phone.
         * @member {Array.<ech.IContactItemStringPhoneCategory>} phone
         * @memberof ech.Contact
         * @instance
         */
        Contact.prototype.phone = $util.emptyArray;

        /**
         * Contact internet.
         * @member {Array.<ech.IContactItemStringBaseCategory>} internet
         * @memberof ech.Contact
         * @instance
         */
        Contact.prototype.internet = $util.emptyArray;

        /**
         * Gets the default type url for Contact
         * @function getTypeUrl
         * @memberof ech.Contact
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Contact.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.Contact";
        };

        return Contact;
    })();

    ech.NamedPersonId = (function() {

        /**
         * Properties of a NamedPersonId.
         * @memberof ech
         * @interface INamedPersonId
         * @property {string|null} [category] NamedPersonId category
         * @property {string|null} [id] NamedPersonId id
         */

        /**
         * Constructs a new NamedPersonId.
         * @memberof ech
         * @classdesc Represents a NamedPersonId.
         * @implements INamedPersonId
         * @constructor
         * @param {ech.INamedPersonId=} [properties] Properties to set
         */
        function NamedPersonId(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NamedPersonId category.
         * @member {string} category
         * @memberof ech.NamedPersonId
         * @instance
         */
        NamedPersonId.prototype.category = "";

        /**
         * NamedPersonId id.
         * @member {string} id
         * @memberof ech.NamedPersonId
         * @instance
         */
        NamedPersonId.prototype.id = "";

        /**
         * Gets the default type url for NamedPersonId
         * @function getTypeUrl
         * @memberof ech.NamedPersonId
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NamedPersonId.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.NamedPersonId";
        };

        return NamedPersonId;
    })();

    ech.ContactItemMailAddressBaseCategory = (function() {

        /**
         * Properties of a ContactItemMailAddressBaseCategory.
         * @memberof ech
         * @interface IContactItemMailAddressBaseCategory
         * @property {ech.IMailAddress|null} [item] ContactItemMailAddressBaseCategory item
         * @property {ech.IContactCategoryBaseCategory|null} [category] ContactItemMailAddressBaseCategory category
         * @property {google.type.IDateRange|null} [validity] ContactItemMailAddressBaseCategory validity
         */

        /**
         * Constructs a new ContactItemMailAddressBaseCategory.
         * @memberof ech
         * @classdesc Represents a ContactItemMailAddressBaseCategory.
         * @implements IContactItemMailAddressBaseCategory
         * @constructor
         * @param {ech.IContactItemMailAddressBaseCategory=} [properties] Properties to set
         */
        function ContactItemMailAddressBaseCategory(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContactItemMailAddressBaseCategory item.
         * @member {ech.IMailAddress|null|undefined} item
         * @memberof ech.ContactItemMailAddressBaseCategory
         * @instance
         */
        ContactItemMailAddressBaseCategory.prototype.item = null;

        /**
         * ContactItemMailAddressBaseCategory category.
         * @member {ech.IContactCategoryBaseCategory|null|undefined} category
         * @memberof ech.ContactItemMailAddressBaseCategory
         * @instance
         */
        ContactItemMailAddressBaseCategory.prototype.category = null;

        /**
         * ContactItemMailAddressBaseCategory validity.
         * @member {google.type.IDateRange|null|undefined} validity
         * @memberof ech.ContactItemMailAddressBaseCategory
         * @instance
         */
        ContactItemMailAddressBaseCategory.prototype.validity = null;

        /**
         * Gets the default type url for ContactItemMailAddressBaseCategory
         * @function getTypeUrl
         * @memberof ech.ContactItemMailAddressBaseCategory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ContactItemMailAddressBaseCategory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.ContactItemMailAddressBaseCategory";
        };

        return ContactItemMailAddressBaseCategory;
    })();

    ech.MailAddress = (function() {

        /**
         * Properties of a MailAddress.
         * @memberof ech
         * @interface IMailAddress
         * @property {ech.IMailAddressInfo|null} [info] MailAddress info
         * @property {ech.IAddressInformation|null} [address] MailAddress address
         */

        /**
         * Constructs a new MailAddress.
         * @memberof ech
         * @classdesc Represents a MailAddress.
         * @implements IMailAddress
         * @constructor
         * @param {ech.IMailAddress=} [properties] Properties to set
         */
        function MailAddress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailAddress info.
         * @member {ech.IMailAddressInfo|null|undefined} info
         * @memberof ech.MailAddress
         * @instance
         */
        MailAddress.prototype.info = null;

        /**
         * MailAddress address.
         * @member {ech.IAddressInformation|null|undefined} address
         * @memberof ech.MailAddress
         * @instance
         */
        MailAddress.prototype.address = null;

        /**
         * Gets the default type url for MailAddress
         * @function getTypeUrl
         * @memberof ech.MailAddress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailAddress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.MailAddress";
        };

        return MailAddress;
    })();

    ech.MailAddressInfo = (function() {

        /**
         * Properties of a MailAddressInfo.
         * @memberof ech
         * @interface IMailAddressInfo
         * @property {ech.IGenericPersonInfoString|null} [person] MailAddressInfo person
         * @property {ech.IOrganisationInfo|null} [organisation] MailAddressInfo organisation
         */

        /**
         * Constructs a new MailAddressInfo.
         * @memberof ech
         * @classdesc Represents a MailAddressInfo.
         * @implements IMailAddressInfo
         * @constructor
         * @param {ech.IMailAddressInfo=} [properties] Properties to set
         */
        function MailAddressInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailAddressInfo person.
         * @member {ech.IGenericPersonInfoString|null|undefined} person
         * @memberof ech.MailAddressInfo
         * @instance
         */
        MailAddressInfo.prototype.person = null;

        /**
         * MailAddressInfo organisation.
         * @member {ech.IOrganisationInfo|null|undefined} organisation
         * @memberof ech.MailAddressInfo
         * @instance
         */
        MailAddressInfo.prototype.organisation = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MailAddressInfo union.
         * @member {"person"|"organisation"|undefined} union
         * @memberof ech.MailAddressInfo
         * @instance
         */
        Object.defineProperty(MailAddressInfo.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["person", "organisation"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for MailAddressInfo
         * @function getTypeUrl
         * @memberof ech.MailAddressInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailAddressInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.MailAddressInfo";
        };

        return MailAddressInfo;
    })();

    ech.GenericPersonInfoString = (function() {

        /**
         * Properties of a GenericPersonInfoString.
         * @memberof ech
         * @interface IGenericPersonInfoString
         * @property {ech.MrMrs|null} [mrMrs] GenericPersonInfoString mrMrs
         * @property {google.protobuf.IStringValue|null} [title] GenericPersonInfoString title
         * @property {google.protobuf.IStringValue|null} [firstName] GenericPersonInfoString firstName
         * @property {google.protobuf.IStringValue|null} [lastName] GenericPersonInfoString lastName
         */

        /**
         * Constructs a new GenericPersonInfoString.
         * @memberof ech
         * @classdesc Represents a GenericPersonInfoString.
         * @implements IGenericPersonInfoString
         * @constructor
         * @param {ech.IGenericPersonInfoString=} [properties] Properties to set
         */
        function GenericPersonInfoString(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenericPersonInfoString mrMrs.
         * @member {ech.MrMrs} mrMrs
         * @memberof ech.GenericPersonInfoString
         * @instance
         */
        GenericPersonInfoString.prototype.mrMrs = 0;

        /**
         * GenericPersonInfoString title.
         * @member {google.protobuf.IStringValue|null|undefined} title
         * @memberof ech.GenericPersonInfoString
         * @instance
         */
        GenericPersonInfoString.prototype.title = null;

        /**
         * GenericPersonInfoString firstName.
         * @member {google.protobuf.IStringValue|null|undefined} firstName
         * @memberof ech.GenericPersonInfoString
         * @instance
         */
        GenericPersonInfoString.prototype.firstName = null;

        /**
         * GenericPersonInfoString lastName.
         * @member {google.protobuf.IStringValue|null|undefined} lastName
         * @memberof ech.GenericPersonInfoString
         * @instance
         */
        GenericPersonInfoString.prototype.lastName = null;

        /**
         * Gets the default type url for GenericPersonInfoString
         * @function getTypeUrl
         * @memberof ech.GenericPersonInfoString
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GenericPersonInfoString.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.GenericPersonInfoString";
        };

        return GenericPersonInfoString;
    })();

    /**
     * MrMrs enum.
     * @name ech.MrMrs
     * @enum {number}
     * @property {number} MR_MRS_UNSPECIFIED=0 MR_MRS_UNSPECIFIED value
     * @property {number} FRAU=1 FRAU value
     * @property {number} HERR=2 HERR value
     * @property {number} FRAEULEIN=3 FRAEULEIN value
     */
    ech.MrMrs = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MR_MRS_UNSPECIFIED"] = 0;
        values[valuesById[1] = "FRAU"] = 1;
        values[valuesById[2] = "HERR"] = 2;
        values[valuesById[3] = "FRAEULEIN"] = 3;
        return values;
    })();

    ech.OrganisationInfo = (function() {

        /**
         * Properties of an OrganisationInfo.
         * @memberof ech
         * @interface IOrganisationInfo
         * @property {string|null} [name] OrganisationInfo name
         * @property {google.protobuf.IStringValue|null} [nameAddOn1] OrganisationInfo nameAddOn1
         * @property {google.protobuf.IStringValue|null} [nameAddOn2] OrganisationInfo nameAddOn2
         * @property {ech.IGenericPersonInfoString|null} [person] OrganisationInfo person
         */

        /**
         * Constructs a new OrganisationInfo.
         * @memberof ech
         * @classdesc Represents an OrganisationInfo.
         * @implements IOrganisationInfo
         * @constructor
         * @param {ech.IOrganisationInfo=} [properties] Properties to set
         */
        function OrganisationInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrganisationInfo name.
         * @member {string} name
         * @memberof ech.OrganisationInfo
         * @instance
         */
        OrganisationInfo.prototype.name = "";

        /**
         * OrganisationInfo nameAddOn1.
         * @member {google.protobuf.IStringValue|null|undefined} nameAddOn1
         * @memberof ech.OrganisationInfo
         * @instance
         */
        OrganisationInfo.prototype.nameAddOn1 = null;

        /**
         * OrganisationInfo nameAddOn2.
         * @member {google.protobuf.IStringValue|null|undefined} nameAddOn2
         * @memberof ech.OrganisationInfo
         * @instance
         */
        OrganisationInfo.prototype.nameAddOn2 = null;

        /**
         * OrganisationInfo person.
         * @member {ech.IGenericPersonInfoString|null|undefined} person
         * @memberof ech.OrganisationInfo
         * @instance
         */
        OrganisationInfo.prototype.person = null;

        /**
         * Gets the default type url for OrganisationInfo
         * @function getTypeUrl
         * @memberof ech.OrganisationInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OrganisationInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.OrganisationInfo";
        };

        return OrganisationInfo;
    })();

    ech.AddressInformation = (function() {

        /**
         * Properties of an AddressInformation.
         * @memberof ech
         * @interface IAddressInformation
         * @property {google.protobuf.IStringValue|null} [addressLine1] AddressInformation addressLine1
         * @property {google.protobuf.IStringValue|null} [addressLine2] AddressInformation addressLine2
         * @property {ech.ILocalisation|null} [localisation] AddressInformation localisation
         * @property {ech.IPostOfficeBox|null} [postOfficeBox] AddressInformation postOfficeBox
         * @property {google.protobuf.IStringValue|null} [locality] AddressInformation locality
         * @property {string|null} [town] AddressInformation town
         * @property {ech.IZipCode|null} [zipCode] AddressInformation zipCode
         * @property {ech.ICountry|null} [country] AddressInformation country
         */

        /**
         * Constructs a new AddressInformation.
         * @memberof ech
         * @classdesc Represents an AddressInformation.
         * @implements IAddressInformation
         * @constructor
         * @param {ech.IAddressInformation=} [properties] Properties to set
         */
        function AddressInformation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddressInformation addressLine1.
         * @member {google.protobuf.IStringValue|null|undefined} addressLine1
         * @memberof ech.AddressInformation
         * @instance
         */
        AddressInformation.prototype.addressLine1 = null;

        /**
         * AddressInformation addressLine2.
         * @member {google.protobuf.IStringValue|null|undefined} addressLine2
         * @memberof ech.AddressInformation
         * @instance
         */
        AddressInformation.prototype.addressLine2 = null;

        /**
         * AddressInformation localisation.
         * @member {ech.ILocalisation|null|undefined} localisation
         * @memberof ech.AddressInformation
         * @instance
         */
        AddressInformation.prototype.localisation = null;

        /**
         * AddressInformation postOfficeBox.
         * @member {ech.IPostOfficeBox|null|undefined} postOfficeBox
         * @memberof ech.AddressInformation
         * @instance
         */
        AddressInformation.prototype.postOfficeBox = null;

        /**
         * AddressInformation locality.
         * @member {google.protobuf.IStringValue|null|undefined} locality
         * @memberof ech.AddressInformation
         * @instance
         */
        AddressInformation.prototype.locality = null;

        /**
         * AddressInformation town.
         * @member {string} town
         * @memberof ech.AddressInformation
         * @instance
         */
        AddressInformation.prototype.town = "";

        /**
         * AddressInformation zipCode.
         * @member {ech.IZipCode|null|undefined} zipCode
         * @memberof ech.AddressInformation
         * @instance
         */
        AddressInformation.prototype.zipCode = null;

        /**
         * AddressInformation country.
         * @member {ech.ICountry|null|undefined} country
         * @memberof ech.AddressInformation
         * @instance
         */
        AddressInformation.prototype.country = null;

        /**
         * Gets the default type url for AddressInformation
         * @function getTypeUrl
         * @memberof ech.AddressInformation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AddressInformation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.AddressInformation";
        };

        return AddressInformation;
    })();

    ech.Localisation = (function() {

        /**
         * Properties of a Localisation.
         * @memberof ech
         * @interface ILocalisation
         * @property {string|null} [street] Localisation street
         * @property {google.protobuf.IStringValue|null} [houseNumber] Localisation houseNumber
         * @property {google.protobuf.IStringValue|null} [dwellingNumber] Localisation dwellingNumber
         */

        /**
         * Constructs a new Localisation.
         * @memberof ech
         * @classdesc Represents a Localisation.
         * @implements ILocalisation
         * @constructor
         * @param {ech.ILocalisation=} [properties] Properties to set
         */
        function Localisation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Localisation street.
         * @member {string} street
         * @memberof ech.Localisation
         * @instance
         */
        Localisation.prototype.street = "";

        /**
         * Localisation houseNumber.
         * @member {google.protobuf.IStringValue|null|undefined} houseNumber
         * @memberof ech.Localisation
         * @instance
         */
        Localisation.prototype.houseNumber = null;

        /**
         * Localisation dwellingNumber.
         * @member {google.protobuf.IStringValue|null|undefined} dwellingNumber
         * @memberof ech.Localisation
         * @instance
         */
        Localisation.prototype.dwellingNumber = null;

        /**
         * Gets the default type url for Localisation
         * @function getTypeUrl
         * @memberof ech.Localisation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Localisation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.Localisation";
        };

        return Localisation;
    })();

    ech.PostOfficeBox = (function() {

        /**
         * Properties of a PostOfficeBox.
         * @memberof ech
         * @interface IPostOfficeBox
         * @property {google.protobuf.IUInt32Value|null} [number] PostOfficeBox number
         * @property {string|null} [text] PostOfficeBox text
         */

        /**
         * Constructs a new PostOfficeBox.
         * @memberof ech
         * @classdesc Represents a PostOfficeBox.
         * @implements IPostOfficeBox
         * @constructor
         * @param {ech.IPostOfficeBox=} [properties] Properties to set
         */
        function PostOfficeBox(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PostOfficeBox number.
         * @member {google.protobuf.IUInt32Value|null|undefined} number
         * @memberof ech.PostOfficeBox
         * @instance
         */
        PostOfficeBox.prototype.number = null;

        /**
         * PostOfficeBox text.
         * @member {string} text
         * @memberof ech.PostOfficeBox
         * @instance
         */
        PostOfficeBox.prototype.text = "";

        /**
         * Gets the default type url for PostOfficeBox
         * @function getTypeUrl
         * @memberof ech.PostOfficeBox
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PostOfficeBox.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.PostOfficeBox";
        };

        return PostOfficeBox;
    })();

    ech.ZipCode = (function() {

        /**
         * Properties of a ZipCode.
         * @memberof ech
         * @interface IZipCode
         * @property {ech.ISwissZipCode|null} [swiss] ZipCode swiss
         * @property {ech.IForeignZipCode|null} [foreign] ZipCode foreign
         */

        /**
         * Constructs a new ZipCode.
         * @memberof ech
         * @classdesc Represents a ZipCode.
         * @implements IZipCode
         * @constructor
         * @param {ech.IZipCode=} [properties] Properties to set
         */
        function ZipCode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ZipCode swiss.
         * @member {ech.ISwissZipCode|null|undefined} swiss
         * @memberof ech.ZipCode
         * @instance
         */
        ZipCode.prototype.swiss = null;

        /**
         * ZipCode foreign.
         * @member {ech.IForeignZipCode|null|undefined} foreign
         * @memberof ech.ZipCode
         * @instance
         */
        ZipCode.prototype.foreign = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ZipCode union.
         * @member {"swiss"|"foreign"|undefined} union
         * @memberof ech.ZipCode
         * @instance
         */
        Object.defineProperty(ZipCode.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["swiss", "foreign"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for ZipCode
         * @function getTypeUrl
         * @memberof ech.ZipCode
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ZipCode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.ZipCode";
        };

        return ZipCode;
    })();

    ech.SwissZipCode = (function() {

        /**
         * Properties of a SwissZipCode.
         * @memberof ech
         * @interface ISwissZipCode
         * @property {number|null} [code] SwissZipCode code
         * @property {google.protobuf.IStringValue|null} [codeAddOn] SwissZipCode codeAddOn
         * @property {google.protobuf.IInt32Value|null} [codeId] SwissZipCode codeId
         */

        /**
         * Constructs a new SwissZipCode.
         * @memberof ech
         * @classdesc Represents a SwissZipCode.
         * @implements ISwissZipCode
         * @constructor
         * @param {ech.ISwissZipCode=} [properties] Properties to set
         */
        function SwissZipCode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SwissZipCode code.
         * @member {number} code
         * @memberof ech.SwissZipCode
         * @instance
         */
        SwissZipCode.prototype.code = 0;

        /**
         * SwissZipCode codeAddOn.
         * @member {google.protobuf.IStringValue|null|undefined} codeAddOn
         * @memberof ech.SwissZipCode
         * @instance
         */
        SwissZipCode.prototype.codeAddOn = null;

        /**
         * SwissZipCode codeId.
         * @member {google.protobuf.IInt32Value|null|undefined} codeId
         * @memberof ech.SwissZipCode
         * @instance
         */
        SwissZipCode.prototype.codeId = null;

        /**
         * Gets the default type url for SwissZipCode
         * @function getTypeUrl
         * @memberof ech.SwissZipCode
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SwissZipCode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.SwissZipCode";
        };

        return SwissZipCode;
    })();

    ech.ForeignZipCode = (function() {

        /**
         * Properties of a ForeignZipCode.
         * @memberof ech
         * @interface IForeignZipCode
         * @property {string|null} [code] ForeignZipCode code
         */

        /**
         * Constructs a new ForeignZipCode.
         * @memberof ech
         * @classdesc Represents a ForeignZipCode.
         * @implements IForeignZipCode
         * @constructor
         * @param {ech.IForeignZipCode=} [properties] Properties to set
         */
        function ForeignZipCode(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ForeignZipCode code.
         * @member {string} code
         * @memberof ech.ForeignZipCode
         * @instance
         */
        ForeignZipCode.prototype.code = "";

        /**
         * Gets the default type url for ForeignZipCode
         * @function getTypeUrl
         * @memberof ech.ForeignZipCode
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ForeignZipCode.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.ForeignZipCode";
        };

        return ForeignZipCode;
    })();

    ech.Country = (function() {

        /**
         * Properties of a Country.
         * @memberof ech
         * @interface ICountry
         * @property {google.protobuf.IInt32Value|null} [id] Country id
         * @property {google.protobuf.IStringValue|null} [idIso2] Country idIso2
         * @property {string|null} [nameShort] Country nameShort
         */

        /**
         * Constructs a new Country.
         * @memberof ech
         * @classdesc Represents a Country.
         * @implements ICountry
         * @constructor
         * @param {ech.ICountry=} [properties] Properties to set
         */
        function Country(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Country id.
         * @member {google.protobuf.IInt32Value|null|undefined} id
         * @memberof ech.Country
         * @instance
         */
        Country.prototype.id = null;

        /**
         * Country idIso2.
         * @member {google.protobuf.IStringValue|null|undefined} idIso2
         * @memberof ech.Country
         * @instance
         */
        Country.prototype.idIso2 = null;

        /**
         * Country nameShort.
         * @member {string} nameShort
         * @memberof ech.Country
         * @instance
         */
        Country.prototype.nameShort = "";

        /**
         * Gets the default type url for Country
         * @function getTypeUrl
         * @memberof ech.Country
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Country.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.Country";
        };

        return Country;
    })();

    ech.ContactCategoryBaseCategory = (function() {

        /**
         * Properties of a ContactCategoryBaseCategory.
         * @memberof ech
         * @interface IContactCategoryBaseCategory
         * @property {ech.BaseCategory|null} [basic] ContactCategoryBaseCategory basic
         * @property {string|null} [other] ContactCategoryBaseCategory other
         */

        /**
         * Constructs a new ContactCategoryBaseCategory.
         * @memberof ech
         * @classdesc Represents a ContactCategoryBaseCategory.
         * @implements IContactCategoryBaseCategory
         * @constructor
         * @param {ech.IContactCategoryBaseCategory=} [properties] Properties to set
         */
        function ContactCategoryBaseCategory(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContactCategoryBaseCategory basic.
         * @member {ech.BaseCategory|null|undefined} basic
         * @memberof ech.ContactCategoryBaseCategory
         * @instance
         */
        ContactCategoryBaseCategory.prototype.basic = null;

        /**
         * ContactCategoryBaseCategory other.
         * @member {string|null|undefined} other
         * @memberof ech.ContactCategoryBaseCategory
         * @instance
         */
        ContactCategoryBaseCategory.prototype.other = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ContactCategoryBaseCategory union.
         * @member {"basic"|"other"|undefined} union
         * @memberof ech.ContactCategoryBaseCategory
         * @instance
         */
        Object.defineProperty(ContactCategoryBaseCategory.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["basic", "other"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for ContactCategoryBaseCategory
         * @function getTypeUrl
         * @memberof ech.ContactCategoryBaseCategory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ContactCategoryBaseCategory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.ContactCategoryBaseCategory";
        };

        return ContactCategoryBaseCategory;
    })();

    /**
     * BaseCategory enum.
     * @name ech.BaseCategory
     * @enum {number}
     * @property {number} BASE_CATEGORY_UNSPECIFIED=0 BASE_CATEGORY_UNSPECIFIED value
     * @property {number} PRIVATE=1 PRIVATE value
     * @property {number} BUSINESS=2 BUSINESS value
     */
    ech.BaseCategory = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "BASE_CATEGORY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "PRIVATE"] = 1;
        values[valuesById[2] = "BUSINESS"] = 2;
        return values;
    })();

    ech.ContactItemStringBaseCategory = (function() {

        /**
         * Properties of a ContactItemStringBaseCategory.
         * @memberof ech
         * @interface IContactItemStringBaseCategory
         * @property {string|null} [item] ContactItemStringBaseCategory item
         * @property {ech.IContactCategoryBaseCategory|null} [category] ContactItemStringBaseCategory category
         * @property {google.type.IDateRange|null} [validity] ContactItemStringBaseCategory validity
         */

        /**
         * Constructs a new ContactItemStringBaseCategory.
         * @memberof ech
         * @classdesc Represents a ContactItemStringBaseCategory.
         * @implements IContactItemStringBaseCategory
         * @constructor
         * @param {ech.IContactItemStringBaseCategory=} [properties] Properties to set
         */
        function ContactItemStringBaseCategory(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContactItemStringBaseCategory item.
         * @member {string} item
         * @memberof ech.ContactItemStringBaseCategory
         * @instance
         */
        ContactItemStringBaseCategory.prototype.item = "";

        /**
         * ContactItemStringBaseCategory category.
         * @member {ech.IContactCategoryBaseCategory|null|undefined} category
         * @memberof ech.ContactItemStringBaseCategory
         * @instance
         */
        ContactItemStringBaseCategory.prototype.category = null;

        /**
         * ContactItemStringBaseCategory validity.
         * @member {google.type.IDateRange|null|undefined} validity
         * @memberof ech.ContactItemStringBaseCategory
         * @instance
         */
        ContactItemStringBaseCategory.prototype.validity = null;

        /**
         * Gets the default type url for ContactItemStringBaseCategory
         * @function getTypeUrl
         * @memberof ech.ContactItemStringBaseCategory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ContactItemStringBaseCategory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.ContactItemStringBaseCategory";
        };

        return ContactItemStringBaseCategory;
    })();

    ech.ContactItemStringPhoneCategory = (function() {

        /**
         * Properties of a ContactItemStringPhoneCategory.
         * @memberof ech
         * @interface IContactItemStringPhoneCategory
         * @property {string|null} [item] ContactItemStringPhoneCategory item
         * @property {ech.IContactCategoryPhoneCategory|null} [category] ContactItemStringPhoneCategory category
         * @property {google.type.IDateRange|null} [validity] ContactItemStringPhoneCategory validity
         */

        /**
         * Constructs a new ContactItemStringPhoneCategory.
         * @memberof ech
         * @classdesc Represents a ContactItemStringPhoneCategory.
         * @implements IContactItemStringPhoneCategory
         * @constructor
         * @param {ech.IContactItemStringPhoneCategory=} [properties] Properties to set
         */
        function ContactItemStringPhoneCategory(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContactItemStringPhoneCategory item.
         * @member {string} item
         * @memberof ech.ContactItemStringPhoneCategory
         * @instance
         */
        ContactItemStringPhoneCategory.prototype.item = "";

        /**
         * ContactItemStringPhoneCategory category.
         * @member {ech.IContactCategoryPhoneCategory|null|undefined} category
         * @memberof ech.ContactItemStringPhoneCategory
         * @instance
         */
        ContactItemStringPhoneCategory.prototype.category = null;

        /**
         * ContactItemStringPhoneCategory validity.
         * @member {google.type.IDateRange|null|undefined} validity
         * @memberof ech.ContactItemStringPhoneCategory
         * @instance
         */
        ContactItemStringPhoneCategory.prototype.validity = null;

        /**
         * Gets the default type url for ContactItemStringPhoneCategory
         * @function getTypeUrl
         * @memberof ech.ContactItemStringPhoneCategory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ContactItemStringPhoneCategory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.ContactItemStringPhoneCategory";
        };

        return ContactItemStringPhoneCategory;
    })();

    ech.ContactCategoryPhoneCategory = (function() {

        /**
         * Properties of a ContactCategoryPhoneCategory.
         * @memberof ech
         * @interface IContactCategoryPhoneCategory
         * @property {ech.PhoneCategory|null} [basic] ContactCategoryPhoneCategory basic
         * @property {google.protobuf.IStringValue|null} [other] ContactCategoryPhoneCategory other
         */

        /**
         * Constructs a new ContactCategoryPhoneCategory.
         * @memberof ech
         * @classdesc Represents a ContactCategoryPhoneCategory.
         * @implements IContactCategoryPhoneCategory
         * @constructor
         * @param {ech.IContactCategoryPhoneCategory=} [properties] Properties to set
         */
        function ContactCategoryPhoneCategory(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContactCategoryPhoneCategory basic.
         * @member {ech.PhoneCategory|null|undefined} basic
         * @memberof ech.ContactCategoryPhoneCategory
         * @instance
         */
        ContactCategoryPhoneCategory.prototype.basic = null;

        /**
         * ContactCategoryPhoneCategory other.
         * @member {google.protobuf.IStringValue|null|undefined} other
         * @memberof ech.ContactCategoryPhoneCategory
         * @instance
         */
        ContactCategoryPhoneCategory.prototype.other = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ContactCategoryPhoneCategory union.
         * @member {"basic"|"other"|undefined} union
         * @memberof ech.ContactCategoryPhoneCategory
         * @instance
         */
        Object.defineProperty(ContactCategoryPhoneCategory.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["basic", "other"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for ContactCategoryPhoneCategory
         * @function getTypeUrl
         * @memberof ech.ContactCategoryPhoneCategory
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ContactCategoryPhoneCategory.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ech.ContactCategoryPhoneCategory";
        };

        return ContactCategoryPhoneCategory;
    })();

    /**
     * PhoneCategory enum.
     * @name ech.PhoneCategory
     * @enum {number}
     * @property {number} PHONE_CATEGORY_UNSPECIFIED=0 PHONE_CATEGORY_UNSPECIFIED value
     * @property {number} PRIVATE_PHONE=1 PRIVATE_PHONE value
     * @property {number} PRIVATE_MOBILE=2 PRIVATE_MOBILE value
     * @property {number} PRIVATE_FAX=3 PRIVATE_FAX value
     * @property {number} PRIVATE_INTERNET_VOICE=4 PRIVATE_INTERNET_VOICE value
     * @property {number} BUSINESS_CENTRAL=5 BUSINESS_CENTRAL value
     * @property {number} BUSINESS_DIRECT=6 BUSINESS_DIRECT value
     * @property {number} BUSINESS_MOBILE=7 BUSINESS_MOBILE value
     * @property {number} BUSINESS_FAX=8 BUSINESS_FAX value
     * @property {number} BUSINESS_INTERNET_VOICE=9 BUSINESS_INTERNET_VOICE value
     * @property {number} PAGER=10 PAGER value
     */
    ech.PhoneCategory = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PHONE_CATEGORY_UNSPECIFIED"] = 0;
        values[valuesById[1] = "PRIVATE_PHONE"] = 1;
        values[valuesById[2] = "PRIVATE_MOBILE"] = 2;
        values[valuesById[3] = "PRIVATE_FAX"] = 3;
        values[valuesById[4] = "PRIVATE_INTERNET_VOICE"] = 4;
        values[valuesById[5] = "BUSINESS_CENTRAL"] = 5;
        values[valuesById[6] = "BUSINESS_DIRECT"] = 6;
        values[valuesById[7] = "BUSINESS_MOBILE"] = 7;
        values[valuesById[8] = "BUSINESS_FAX"] = 8;
        values[valuesById[9] = "BUSINESS_INTERNET_VOICE"] = 9;
        values[valuesById[10] = "PAGER"] = 10;
        return values;
    })();

    return ech;
})();

export const mp = $root.mp = (() => {

    /**
     * Namespace mp.
     * @exports mp
     * @namespace
     */
    const mp = {};

    mp.MeteringPoint = (function() {

        /**
         * Properties of a MeteringPoint.
         * @memberof mp
         * @interface IMeteringPoint
         * @property {mp.IMeteringPointParties|null} [parties] MeteringPoint parties
         * @property {mp.GridBillingMethod|null} [gridBillingMethod] MeteringPoint gridBillingMethod
         * @property {ech.IContact|null} [consumer] MeteringPoint consumer
         * @property {mp.IMeteringPointCharacteristics|null} [characteristics] MeteringPoint characteristics
         * @property {mp.IMeteringPointAddress|null} [address] MeteringPoint address
         */

        /**
         * Constructs a new MeteringPoint.
         * @memberof mp
         * @classdesc Represents a MeteringPoint.
         * @implements IMeteringPoint
         * @constructor
         * @param {mp.IMeteringPoint=} [properties] Properties to set
         */
        function MeteringPoint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPoint parties.
         * @member {mp.IMeteringPointParties|null|undefined} parties
         * @memberof mp.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.parties = null;

        /**
         * MeteringPoint gridBillingMethod.
         * @member {mp.GridBillingMethod} gridBillingMethod
         * @memberof mp.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.gridBillingMethod = 0;

        /**
         * MeteringPoint consumer.
         * @member {ech.IContact|null|undefined} consumer
         * @memberof mp.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.consumer = null;

        /**
         * MeteringPoint characteristics.
         * @member {mp.IMeteringPointCharacteristics|null|undefined} characteristics
         * @memberof mp.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.characteristics = null;

        /**
         * MeteringPoint address.
         * @member {mp.IMeteringPointAddress|null|undefined} address
         * @memberof mp.MeteringPoint
         * @instance
         */
        MeteringPoint.prototype.address = null;

        /**
         * Gets the default type url for MeteringPoint
         * @function getTypeUrl
         * @memberof mp.MeteringPoint
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPoint.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mp.MeteringPoint";
        };

        return MeteringPoint;
    })();

    mp.MeteringPointParties = (function() {

        /**
         * Properties of a MeteringPointParties.
         * @memberof mp
         * @interface IMeteringPointParties
         * @property {mp.IBalanceParties|null} [balance] MeteringPointParties balance
         * @property {Array.<string>|null} [ancillaryServiceProviders] MeteringPointParties ancillaryServiceProviders
         */

        /**
         * Constructs a new MeteringPointParties.
         * @memberof mp
         * @classdesc Represents a MeteringPointParties.
         * @implements IMeteringPointParties
         * @constructor
         * @param {mp.IMeteringPointParties=} [properties] Properties to set
         */
        function MeteringPointParties(properties) {
            this.ancillaryServiceProviders = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointParties balance.
         * @member {mp.IBalanceParties|null|undefined} balance
         * @memberof mp.MeteringPointParties
         * @instance
         */
        MeteringPointParties.prototype.balance = null;

        /**
         * MeteringPointParties ancillaryServiceProviders.
         * @member {Array.<string>} ancillaryServiceProviders
         * @memberof mp.MeteringPointParties
         * @instance
         */
        MeteringPointParties.prototype.ancillaryServiceProviders = $util.emptyArray;

        /**
         * Gets the default type url for MeteringPointParties
         * @function getTypeUrl
         * @memberof mp.MeteringPointParties
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointParties.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mp.MeteringPointParties";
        };

        return MeteringPointParties;
    })();

    mp.BalanceParties = (function() {

        /**
         * Properties of a BalanceParties.
         * @memberof mp
         * @interface IBalanceParties
         * @property {string|null} [supplier] BalanceParties supplier
         * @property {string|null} [responsible] BalanceParties responsible
         */

        /**
         * Constructs a new BalanceParties.
         * @memberof mp
         * @classdesc Represents a BalanceParties.
         * @implements IBalanceParties
         * @constructor
         * @param {mp.IBalanceParties=} [properties] Properties to set
         */
        function BalanceParties(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BalanceParties supplier.
         * @member {string} supplier
         * @memberof mp.BalanceParties
         * @instance
         */
        BalanceParties.prototype.supplier = "";

        /**
         * BalanceParties responsible.
         * @member {string} responsible
         * @memberof mp.BalanceParties
         * @instance
         */
        BalanceParties.prototype.responsible = "";

        /**
         * Gets the default type url for BalanceParties
         * @function getTypeUrl
         * @memberof mp.BalanceParties
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        BalanceParties.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mp.BalanceParties";
        };

        return BalanceParties;
    })();

    /**
     * GridBillingMethod enum.
     * @name mp.GridBillingMethod
     * @enum {number}
     * @property {number} GRID_BILLING_METHOD_UNSPECIFIED=0 GRID_BILLING_METHOD_UNSPECIFIED value
     * @property {number} DSO=1 DSO value
     * @property {number} SUPPLIER=2 SUPPLIER value
     */
    mp.GridBillingMethod = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "GRID_BILLING_METHOD_UNSPECIFIED"] = 0;
        values[valuesById[1] = "DSO"] = 1;
        values[valuesById[2] = "SUPPLIER"] = 2;
        return values;
    })();

    mp.MeteringPointCharacteristics = (function() {

        /**
         * Properties of a MeteringPointCharacteristics.
         * @memberof mp
         * @interface IMeteringPointCharacteristics
         * @property {mp.MeteringPointType|null} [type] MeteringPointCharacteristics type
         * @property {mp.MeteringMethod|null} [meteringMethod] MeteringPointCharacteristics meteringMethod
         * @property {mp.SettlementMethod|null} [settlementMethod] MeteringPointCharacteristics settlementMethod
         * @property {mp.IMeteringPointResolution|null} [meterReadingPeriod] MeteringPointCharacteristics meterReadingPeriod
         */

        /**
         * Constructs a new MeteringPointCharacteristics.
         * @memberof mp
         * @classdesc Represents a MeteringPointCharacteristics.
         * @implements IMeteringPointCharacteristics
         * @constructor
         * @param {mp.IMeteringPointCharacteristics=} [properties] Properties to set
         */
        function MeteringPointCharacteristics(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointCharacteristics type.
         * @member {mp.MeteringPointType} type
         * @memberof mp.MeteringPointCharacteristics
         * @instance
         */
        MeteringPointCharacteristics.prototype.type = 0;

        /**
         * MeteringPointCharacteristics meteringMethod.
         * @member {mp.MeteringMethod} meteringMethod
         * @memberof mp.MeteringPointCharacteristics
         * @instance
         */
        MeteringPointCharacteristics.prototype.meteringMethod = 0;

        /**
         * MeteringPointCharacteristics settlementMethod.
         * @member {mp.SettlementMethod} settlementMethod
         * @memberof mp.MeteringPointCharacteristics
         * @instance
         */
        MeteringPointCharacteristics.prototype.settlementMethod = 0;

        /**
         * MeteringPointCharacteristics meterReadingPeriod.
         * @member {mp.IMeteringPointResolution|null|undefined} meterReadingPeriod
         * @memberof mp.MeteringPointCharacteristics
         * @instance
         */
        MeteringPointCharacteristics.prototype.meterReadingPeriod = null;

        /**
         * Gets the default type url for MeteringPointCharacteristics
         * @function getTypeUrl
         * @memberof mp.MeteringPointCharacteristics
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointCharacteristics.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mp.MeteringPointCharacteristics";
        };

        return MeteringPointCharacteristics;
    })();

    /**
     * MeteringPointType enum.
     * @name mp.MeteringPointType
     * @enum {number}
     * @property {number} METERING_POINT_TYPE_UNSPECIFIED=0 METERING_POINT_TYPE_UNSPECIFIED value
     * @property {number} CONSUMPTION=1 CONSUMPTION value
     * @property {number} PRODUCTION=2 PRODUCTION value
     * @property {number} EXCHANGE=3 EXCHANGE value
     */
    mp.MeteringPointType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "METERING_POINT_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "CONSUMPTION"] = 1;
        values[valuesById[2] = "PRODUCTION"] = 2;
        values[valuesById[3] = "EXCHANGE"] = 3;
        return values;
    })();

    /**
     * MeteringMethod enum.
     * @name mp.MeteringMethod
     * @enum {number}
     * @property {number} METERING_METHOD_UNSPECIFIED=0 METERING_METHOD_UNSPECIFIED value
     * @property {number} CONTINUOUS=1 CONTINUOUS value
     * @property {number} NON_CONTINUOUS=2 NON_CONTINUOUS value
     * @property {number} NOT_METERED=3 NOT_METERED value
     * @property {number} CALCULATED=4 CALCULATED value
     */
    mp.MeteringMethod = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "METERING_METHOD_UNSPECIFIED"] = 0;
        values[valuesById[1] = "CONTINUOUS"] = 1;
        values[valuesById[2] = "NON_CONTINUOUS"] = 2;
        values[valuesById[3] = "NOT_METERED"] = 3;
        values[valuesById[4] = "CALCULATED"] = 4;
        return values;
    })();

    /**
     * SettlementMethod enum.
     * @name mp.SettlementMethod
     * @enum {number}
     * @property {number} SETTLEMENT_METHOD_UNSPECIFIED=0 SETTLEMENT_METHOD_UNSPECIFIED value
     * @property {number} PROFILED=1 PROFILED value
     * @property {number} NON_PROFILED=2 NON_PROFILED value
     */
    mp.SettlementMethod = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SETTLEMENT_METHOD_UNSPECIFIED"] = 0;
        values[valuesById[1] = "PROFILED"] = 1;
        values[valuesById[2] = "NON_PROFILED"] = 2;
        return values;
    })();

    mp.MeteringPointResolution = (function() {

        /**
         * Properties of a MeteringPointResolution.
         * @memberof mp
         * @interface IMeteringPointResolution
         * @property {number|null} [resolution] MeteringPointResolution resolution
         * @property {string|null} [unit] MeteringPointResolution unit
         */

        /**
         * Constructs a new MeteringPointResolution.
         * @memberof mp
         * @classdesc Represents a MeteringPointResolution.
         * @implements IMeteringPointResolution
         * @constructor
         * @param {mp.IMeteringPointResolution=} [properties] Properties to set
         */
        function MeteringPointResolution(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointResolution resolution.
         * @member {number} resolution
         * @memberof mp.MeteringPointResolution
         * @instance
         */
        MeteringPointResolution.prototype.resolution = 0;

        /**
         * MeteringPointResolution unit.
         * @member {string} unit
         * @memberof mp.MeteringPointResolution
         * @instance
         */
        MeteringPointResolution.prototype.unit = "";

        /**
         * Gets the default type url for MeteringPointResolution
         * @function getTypeUrl
         * @memberof mp.MeteringPointResolution
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointResolution.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mp.MeteringPointResolution";
        };

        return MeteringPointResolution;
    })();

    mp.MeteringPointAddress = (function() {

        /**
         * Properties of a MeteringPointAddress.
         * @memberof mp
         * @interface IMeteringPointAddress
         * @property {google.protobuf.IUInt32Value|null} [egId] MeteringPointAddress egId
         * @property {google.protobuf.IUInt32Value|null} [ewId] MeteringPointAddress ewId
         * @property {google.protobuf.IUInt32Value|null} [floorId] MeteringPointAddress floorId
         * @property {google.protobuf.IStringValue|null} [floorAddOn] MeteringPointAddress floorAddOn
         * @property {google.protobuf.IStringValue|null} [parcelId] MeteringPointAddress parcelId
         * @property {ech.IAddressInformation|null} [address] MeteringPointAddress address
         */

        /**
         * Constructs a new MeteringPointAddress.
         * @memberof mp
         * @classdesc Represents a MeteringPointAddress.
         * @implements IMeteringPointAddress
         * @constructor
         * @param {mp.IMeteringPointAddress=} [properties] Properties to set
         */
        function MeteringPointAddress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointAddress egId.
         * @member {google.protobuf.IUInt32Value|null|undefined} egId
         * @memberof mp.MeteringPointAddress
         * @instance
         */
        MeteringPointAddress.prototype.egId = null;

        /**
         * MeteringPointAddress ewId.
         * @member {google.protobuf.IUInt32Value|null|undefined} ewId
         * @memberof mp.MeteringPointAddress
         * @instance
         */
        MeteringPointAddress.prototype.ewId = null;

        /**
         * MeteringPointAddress floorId.
         * @member {google.protobuf.IUInt32Value|null|undefined} floorId
         * @memberof mp.MeteringPointAddress
         * @instance
         */
        MeteringPointAddress.prototype.floorId = null;

        /**
         * MeteringPointAddress floorAddOn.
         * @member {google.protobuf.IStringValue|null|undefined} floorAddOn
         * @memberof mp.MeteringPointAddress
         * @instance
         */
        MeteringPointAddress.prototype.floorAddOn = null;

        /**
         * MeteringPointAddress parcelId.
         * @member {google.protobuf.IStringValue|null|undefined} parcelId
         * @memberof mp.MeteringPointAddress
         * @instance
         */
        MeteringPointAddress.prototype.parcelId = null;

        /**
         * MeteringPointAddress address.
         * @member {ech.IAddressInformation|null|undefined} address
         * @memberof mp.MeteringPointAddress
         * @instance
         */
        MeteringPointAddress.prototype.address = null;

        /**
         * Gets the default type url for MeteringPointAddress
         * @function getTypeUrl
         * @memberof mp.MeteringPointAddress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointAddress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mp.MeteringPointAddress";
        };

        return MeteringPointAddress;
    })();

    return mp;
})();

export const MPDirectory = $root.MPDirectory = (() => {

    /**
     * Namespace MPDirectory.
     * @exports MPDirectory
     * @namespace
     */
    const MPDirectory = {};

    MPDirectory.MPEventContent = (function() {

        /**
         * Properties of a MPEventContent.
         * @memberof MPDirectory
         * @interface IMPEventContent
         * @property {MPDirectory.IMeteringPointData|null} [create] MPEventContent create
         * @property {MPDirectory.IMeteringPointDataUpdate|null} [update] MPEventContent update
         * @property {google.protobuf.IEmpty|null} ["delete"] MPEventContent delete
         * @property {google.protobuf.IInt32Value|null} [invalidate] MPEventContent invalidate
         */

        /**
         * Constructs a new MPEventContent.
         * @memberof MPDirectory
         * @classdesc Represents a MPEventContent.
         * @implements IMPEventContent
         * @constructor
         * @param {MPDirectory.IMPEventContent=} [properties] Properties to set
         */
        function MPEventContent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MPEventContent create.
         * @member {MPDirectory.IMeteringPointData|null|undefined} create
         * @memberof MPDirectory.MPEventContent
         * @instance
         */
        MPEventContent.prototype.create = null;

        /**
         * MPEventContent update.
         * @member {MPDirectory.IMeteringPointDataUpdate|null|undefined} update
         * @memberof MPDirectory.MPEventContent
         * @instance
         */
        MPEventContent.prototype.update = null;

        /**
         * MPEventContent delete.
         * @member {google.protobuf.IEmpty|null|undefined} delete
         * @memberof MPDirectory.MPEventContent
         * @instance
         */
        MPEventContent.prototype["delete"] = null;

        /**
         * MPEventContent invalidate.
         * @member {google.protobuf.IInt32Value|null|undefined} invalidate
         * @memberof MPDirectory.MPEventContent
         * @instance
         */
        MPEventContent.prototype.invalidate = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MPEventContent union.
         * @member {"create"|"update"|"delete"|"invalidate"|undefined} union
         * @memberof MPDirectory.MPEventContent
         * @instance
         */
        Object.defineProperty(MPEventContent.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["create", "update", "delete", "invalidate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for MPEventContent
         * @function getTypeUrl
         * @memberof MPDirectory.MPEventContent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MPEventContent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MPDirectory.MPEventContent";
        };

        return MPEventContent;
    })();

    MPDirectory.MeteringPointData = (function() {

        /**
         * Properties of a MeteringPointData.
         * @memberof MPDirectory
         * @interface IMeteringPointData
         * @property {boolean|null} [isEligibleForSwitch] MeteringPointData isEligibleForSwitch
         * @property {mp.IMeteringPoint|null} [sdat] MeteringPointData sdat
         */

        /**
         * Constructs a new MeteringPointData.
         * @memberof MPDirectory
         * @classdesc Represents a MeteringPointData.
         * @implements IMeteringPointData
         * @constructor
         * @param {MPDirectory.IMeteringPointData=} [properties] Properties to set
         */
        function MeteringPointData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointData isEligibleForSwitch.
         * @member {boolean} isEligibleForSwitch
         * @memberof MPDirectory.MeteringPointData
         * @instance
         */
        MeteringPointData.prototype.isEligibleForSwitch = false;

        /**
         * MeteringPointData sdat.
         * @member {mp.IMeteringPoint|null|undefined} sdat
         * @memberof MPDirectory.MeteringPointData
         * @instance
         */
        MeteringPointData.prototype.sdat = null;

        /**
         * Gets the default type url for MeteringPointData
         * @function getTypeUrl
         * @memberof MPDirectory.MeteringPointData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MPDirectory.MeteringPointData";
        };

        return MeteringPointData;
    })();

    /**
     * ItemOp enum.
     * @name MPDirectory.ItemOp
     * @enum {number}
     * @property {number} ITEM_OP_UNSPECIFIED=0 ITEM_OP_UNSPECIFIED value
     * @property {number} ITEM_OP_SET=1 ITEM_OP_SET value
     * @property {number} ITEM_OP_REMOVE=2 ITEM_OP_REMOVE value
     */
    MPDirectory.ItemOp = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ITEM_OP_UNSPECIFIED"] = 0;
        values[valuesById[1] = "ITEM_OP_SET"] = 1;
        values[valuesById[2] = "ITEM_OP_REMOVE"] = 2;
        return values;
    })();

    /**
     * ListOp enum.
     * @name MPDirectory.ListOp
     * @enum {number}
     * @property {number} LIST_OP_UNSPECIFIED=0 LIST_OP_UNSPECIFIED value
     * @property {number} LIST_OP_ADD=1 LIST_OP_ADD value
     * @property {number} LIST_OP_REMOVE=2 LIST_OP_REMOVE value
     */
    MPDirectory.ListOp = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LIST_OP_UNSPECIFIED"] = 0;
        values[valuesById[1] = "LIST_OP_ADD"] = 1;
        values[valuesById[2] = "LIST_OP_REMOVE"] = 2;
        return values;
    })();

    MPDirectory.PartiesUpdate = (function() {

        /**
         * Properties of a PartiesUpdate.
         * @memberof MPDirectory
         * @interface IPartiesUpdate
         * @property {MPDirectory.PartiesUpdate.IItemDiff|null} [balance] PartiesUpdate balance
         * @property {Array.<MPDirectory.PartiesUpdate.IListDiff>|null} [ancillaryServiceProviders] PartiesUpdate ancillaryServiceProviders
         */

        /**
         * Constructs a new PartiesUpdate.
         * @memberof MPDirectory
         * @classdesc Represents a PartiesUpdate.
         * @implements IPartiesUpdate
         * @constructor
         * @param {MPDirectory.IPartiesUpdate=} [properties] Properties to set
         */
        function PartiesUpdate(properties) {
            this.ancillaryServiceProviders = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PartiesUpdate balance.
         * @member {MPDirectory.PartiesUpdate.IItemDiff|null|undefined} balance
         * @memberof MPDirectory.PartiesUpdate
         * @instance
         */
        PartiesUpdate.prototype.balance = null;

        /**
         * PartiesUpdate ancillaryServiceProviders.
         * @member {Array.<MPDirectory.PartiesUpdate.IListDiff>} ancillaryServiceProviders
         * @memberof MPDirectory.PartiesUpdate
         * @instance
         */
        PartiesUpdate.prototype.ancillaryServiceProviders = $util.emptyArray;

        /**
         * Gets the default type url for PartiesUpdate
         * @function getTypeUrl
         * @memberof MPDirectory.PartiesUpdate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PartiesUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MPDirectory.PartiesUpdate";
        };

        PartiesUpdate.ItemDiff = (function() {

            /**
             * Properties of an ItemDiff.
             * @memberof MPDirectory.PartiesUpdate
             * @interface IItemDiff
             * @property {MPDirectory.ItemOp|null} [op] ItemDiff op
             * @property {mp.IBalanceParties|null} [item] ItemDiff item
             */

            /**
             * Constructs a new ItemDiff.
             * @memberof MPDirectory.PartiesUpdate
             * @classdesc Represents an ItemDiff.
             * @implements IItemDiff
             * @constructor
             * @param {MPDirectory.PartiesUpdate.IItemDiff=} [properties] Properties to set
             */
            function ItemDiff(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ItemDiff op.
             * @member {MPDirectory.ItemOp} op
             * @memberof MPDirectory.PartiesUpdate.ItemDiff
             * @instance
             */
            ItemDiff.prototype.op = 0;

            /**
             * ItemDiff item.
             * @member {mp.IBalanceParties|null|undefined} item
             * @memberof MPDirectory.PartiesUpdate.ItemDiff
             * @instance
             */
            ItemDiff.prototype.item = null;

            /**
             * Gets the default type url for ItemDiff
             * @function getTypeUrl
             * @memberof MPDirectory.PartiesUpdate.ItemDiff
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ItemDiff.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/MPDirectory.PartiesUpdate.ItemDiff";
            };

            return ItemDiff;
        })();

        PartiesUpdate.ListDiff = (function() {

            /**
             * Properties of a ListDiff.
             * @memberof MPDirectory.PartiesUpdate
             * @interface IListDiff
             * @property {MPDirectory.ListOp|null} [op] ListDiff op
             * @property {string|null} [item] ListDiff item
             */

            /**
             * Constructs a new ListDiff.
             * @memberof MPDirectory.PartiesUpdate
             * @classdesc Represents a ListDiff.
             * @implements IListDiff
             * @constructor
             * @param {MPDirectory.PartiesUpdate.IListDiff=} [properties] Properties to set
             */
            function ListDiff(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ListDiff op.
             * @member {MPDirectory.ListOp} op
             * @memberof MPDirectory.PartiesUpdate.ListDiff
             * @instance
             */
            ListDiff.prototype.op = 0;

            /**
             * ListDiff item.
             * @member {string} item
             * @memberof MPDirectory.PartiesUpdate.ListDiff
             * @instance
             */
            ListDiff.prototype.item = "";

            /**
             * Gets the default type url for ListDiff
             * @function getTypeUrl
             * @memberof MPDirectory.PartiesUpdate.ListDiff
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ListDiff.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/MPDirectory.PartiesUpdate.ListDiff";
            };

            return ListDiff;
        })();

        return PartiesUpdate;
    })();

    MPDirectory.MeteringPointDataUpdate = (function() {

        /**
         * Properties of a MeteringPointDataUpdate.
         * @memberof MPDirectory
         * @interface IMeteringPointDataUpdate
         * @property {google.protobuf.IBoolValue|null} [isEligibleForSwitch] MeteringPointDataUpdate isEligibleForSwitch
         * @property {MPDirectory.IPartiesUpdate|null} [parties] MeteringPointDataUpdate parties
         * @property {mp.GridBillingMethod|null} [gridBillingMethod] MeteringPointDataUpdate gridBillingMethod
         * @property {MPDirectory.MeteringPointDataUpdate.IContactValue|null} [consumer] MeteringPointDataUpdate consumer
         * @property {mp.IMeteringPointCharacteristics|null} [characteristics] MeteringPointDataUpdate characteristics
         * @property {mp.IMeteringPointAddress|null} [address] MeteringPointDataUpdate address
         */

        /**
         * Constructs a new MeteringPointDataUpdate.
         * @memberof MPDirectory
         * @classdesc Represents a MeteringPointDataUpdate.
         * @implements IMeteringPointDataUpdate
         * @constructor
         * @param {MPDirectory.IMeteringPointDataUpdate=} [properties] Properties to set
         */
        function MeteringPointDataUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointDataUpdate isEligibleForSwitch.
         * @member {google.protobuf.IBoolValue|null|undefined} isEligibleForSwitch
         * @memberof MPDirectory.MeteringPointDataUpdate
         * @instance
         */
        MeteringPointDataUpdate.prototype.isEligibleForSwitch = null;

        /**
         * MeteringPointDataUpdate parties.
         * @member {MPDirectory.IPartiesUpdate|null|undefined} parties
         * @memberof MPDirectory.MeteringPointDataUpdate
         * @instance
         */
        MeteringPointDataUpdate.prototype.parties = null;

        /**
         * MeteringPointDataUpdate gridBillingMethod.
         * @member {mp.GridBillingMethod} gridBillingMethod
         * @memberof MPDirectory.MeteringPointDataUpdate
         * @instance
         */
        MeteringPointDataUpdate.prototype.gridBillingMethod = 0;

        /**
         * MeteringPointDataUpdate consumer.
         * @member {MPDirectory.MeteringPointDataUpdate.IContactValue|null|undefined} consumer
         * @memberof MPDirectory.MeteringPointDataUpdate
         * @instance
         */
        MeteringPointDataUpdate.prototype.consumer = null;

        /**
         * MeteringPointDataUpdate characteristics.
         * @member {mp.IMeteringPointCharacteristics|null|undefined} characteristics
         * @memberof MPDirectory.MeteringPointDataUpdate
         * @instance
         */
        MeteringPointDataUpdate.prototype.characteristics = null;

        /**
         * MeteringPointDataUpdate address.
         * @member {mp.IMeteringPointAddress|null|undefined} address
         * @memberof MPDirectory.MeteringPointDataUpdate
         * @instance
         */
        MeteringPointDataUpdate.prototype.address = null;

        /**
         * Gets the default type url for MeteringPointDataUpdate
         * @function getTypeUrl
         * @memberof MPDirectory.MeteringPointDataUpdate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointDataUpdate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MPDirectory.MeteringPointDataUpdate";
        };

        MeteringPointDataUpdate.ContactValue = (function() {

            /**
             * Properties of a ContactValue.
             * @memberof MPDirectory.MeteringPointDataUpdate
             * @interface IContactValue
             * @property {ech.IContact|null} [value] ContactValue value
             */

            /**
             * Constructs a new ContactValue.
             * @memberof MPDirectory.MeteringPointDataUpdate
             * @classdesc Represents a ContactValue.
             * @implements IContactValue
             * @constructor
             * @param {MPDirectory.MeteringPointDataUpdate.IContactValue=} [properties] Properties to set
             */
            function ContactValue(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ContactValue value.
             * @member {ech.IContact|null|undefined} value
             * @memberof MPDirectory.MeteringPointDataUpdate.ContactValue
             * @instance
             */
            ContactValue.prototype.value = null;

            /**
             * Gets the default type url for ContactValue
             * @function getTypeUrl
             * @memberof MPDirectory.MeteringPointDataUpdate.ContactValue
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ContactValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/MPDirectory.MeteringPointDataUpdate.ContactValue";
            };

            return ContactValue;
        })();

        return MeteringPointDataUpdate;
    })();

    MPDirectory.MPEventSource = (function() {

        /**
         * Properties of a MPEventSource.
         * @memberof MPDirectory
         * @interface IMPEventSource
         * @property {MPDirectory.ISdatSource|null} [sdat] MPEventSource sdat
         * @property {MPDirectory.IImportSource|null} ["import"] MPEventSource import
         * @property {MPDirectory.IWebUiSource|null} [webUi] MPEventSource webUi
         */

        /**
         * Constructs a new MPEventSource.
         * @memberof MPDirectory
         * @classdesc Represents a MPEventSource.
         * @implements IMPEventSource
         * @constructor
         * @param {MPDirectory.IMPEventSource=} [properties] Properties to set
         */
        function MPEventSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MPEventSource sdat.
         * @member {MPDirectory.ISdatSource|null|undefined} sdat
         * @memberof MPDirectory.MPEventSource
         * @instance
         */
        MPEventSource.prototype.sdat = null;

        /**
         * MPEventSource import.
         * @member {MPDirectory.IImportSource|null|undefined} import
         * @memberof MPDirectory.MPEventSource
         * @instance
         */
        MPEventSource.prototype["import"] = null;

        /**
         * MPEventSource webUi.
         * @member {MPDirectory.IWebUiSource|null|undefined} webUi
         * @memberof MPDirectory.MPEventSource
         * @instance
         */
        MPEventSource.prototype.webUi = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MPEventSource union.
         * @member {"sdat"|"import"|"webUi"|undefined} union
         * @memberof MPDirectory.MPEventSource
         * @instance
         */
        Object.defineProperty(MPEventSource.prototype, "union", {
            get: $util.oneOfGetter($oneOfFields = ["sdat", "import", "webUi"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for MPEventSource
         * @function getTypeUrl
         * @memberof MPDirectory.MPEventSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MPEventSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MPDirectory.MPEventSource";
        };

        return MPEventSource;
    })();

    MPDirectory.SdatSource = (function() {

        /**
         * Properties of a SdatSource.
         * @memberof MPDirectory
         * @interface ISdatSource
         * @property {MPDirectory.ProcessType|null} [processType] SdatSource processType
         * @property {google.protobuf.IStringValue|null} [messageId] SdatSource messageId
         * @property {google.protobuf.IStringValue|null} [documentId] SdatSource documentId
         * @property {google.protobuf.IStringValue|null} [processId] SdatSource processId
         */

        /**
         * Constructs a new SdatSource.
         * @memberof MPDirectory
         * @classdesc Represents a SdatSource.
         * @implements ISdatSource
         * @constructor
         * @param {MPDirectory.ISdatSource=} [properties] Properties to set
         */
        function SdatSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SdatSource processType.
         * @member {MPDirectory.ProcessType} processType
         * @memberof MPDirectory.SdatSource
         * @instance
         */
        SdatSource.prototype.processType = 0;

        /**
         * SdatSource messageId.
         * @member {google.protobuf.IStringValue|null|undefined} messageId
         * @memberof MPDirectory.SdatSource
         * @instance
         */
        SdatSource.prototype.messageId = null;

        /**
         * SdatSource documentId.
         * @member {google.protobuf.IStringValue|null|undefined} documentId
         * @memberof MPDirectory.SdatSource
         * @instance
         */
        SdatSource.prototype.documentId = null;

        /**
         * SdatSource processId.
         * @member {google.protobuf.IStringValue|null|undefined} processId
         * @memberof MPDirectory.SdatSource
         * @instance
         */
        SdatSource.prototype.processId = null;

        /**
         * Gets the default type url for SdatSource
         * @function getTypeUrl
         * @memberof MPDirectory.SdatSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SdatSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MPDirectory.SdatSource";
        };

        return SdatSource;
    })();

    /**
     * ProcessType enum.
     * @name MPDirectory.ProcessType
     * @enum {number}
     * @property {number} PROCESS_TYPE_UNSPECIFIED=0 PROCESS_TYPE_UNSPECIFIED value
     * @property {number} SUPPLIER_CHANGE=1 SUPPLIER_CHANGE value
     * @property {number} SUPPLIER_END=2 SUPPLIER_END value
     * @property {number} CONSUMER_CHANGE=3 CONSUMER_CHANGE value
     * @property {number} CONSUMER_END=4 CONSUMER_END value
     * @property {number} ASP_START=5 ASP_START value
     * @property {number} ASP_END=6 ASP_END value
     * @property {number} BASE_SUPPLY_REGISTRATION_BASE=7 BASE_SUPPLY_REGISTRATION_BASE value
     * @property {number} BASE_SUPPLY_REGISTRATION_SUBSTITUTE=8 BASE_SUPPLY_REGISTRATION_SUBSTITUTE value
     * @property {number} CANCELLATION=9 CANCELLATION value
     * @property {number} CONSUMER_MASTER_DATA_CHANGE=10 CONSUMER_MASTER_DATA_CHANGE value
     * @property {number} MP_MASTER_DATA_CHANGE=11 MP_MASTER_DATA_CHANGE value
     * @property {number} MP_REGISTRATION=12 MP_REGISTRATION value
     * @property {number} MP_DEREGISTRATION=13 MP_DEREGISTRATION value
     * @property {number} MP_DATA_CHANGE=14 MP_DATA_CHANGE value
     */
    MPDirectory.ProcessType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PROCESS_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SUPPLIER_CHANGE"] = 1;
        values[valuesById[2] = "SUPPLIER_END"] = 2;
        values[valuesById[3] = "CONSUMER_CHANGE"] = 3;
        values[valuesById[4] = "CONSUMER_END"] = 4;
        values[valuesById[5] = "ASP_START"] = 5;
        values[valuesById[6] = "ASP_END"] = 6;
        values[valuesById[7] = "BASE_SUPPLY_REGISTRATION_BASE"] = 7;
        values[valuesById[8] = "BASE_SUPPLY_REGISTRATION_SUBSTITUTE"] = 8;
        values[valuesById[9] = "CANCELLATION"] = 9;
        values[valuesById[10] = "CONSUMER_MASTER_DATA_CHANGE"] = 10;
        values[valuesById[11] = "MP_MASTER_DATA_CHANGE"] = 11;
        values[valuesById[12] = "MP_REGISTRATION"] = 12;
        values[valuesById[13] = "MP_DEREGISTRATION"] = 13;
        values[valuesById[14] = "MP_DATA_CHANGE"] = 14;
        return values;
    })();

    MPDirectory.ImportSource = (function() {

        /**
         * Properties of an ImportSource.
         * @memberof MPDirectory
         * @interface IImportSource
         * @property {string|null} [author] ImportSource author
         */

        /**
         * Constructs a new ImportSource.
         * @memberof MPDirectory
         * @classdesc Represents an ImportSource.
         * @implements IImportSource
         * @constructor
         * @param {MPDirectory.IImportSource=} [properties] Properties to set
         */
        function ImportSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImportSource author.
         * @member {string} author
         * @memberof MPDirectory.ImportSource
         * @instance
         */
        ImportSource.prototype.author = "";

        /**
         * Gets the default type url for ImportSource
         * @function getTypeUrl
         * @memberof MPDirectory.ImportSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ImportSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MPDirectory.ImportSource";
        };

        return ImportSource;
    })();

    MPDirectory.WebUiSource = (function() {

        /**
         * Properties of a WebUiSource.
         * @memberof MPDirectory
         * @interface IWebUiSource
         * @property {string|null} [author] WebUiSource author
         */

        /**
         * Constructs a new WebUiSource.
         * @memberof MPDirectory
         * @classdesc Represents a WebUiSource.
         * @implements IWebUiSource
         * @constructor
         * @param {MPDirectory.IWebUiSource=} [properties] Properties to set
         */
        function WebUiSource(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WebUiSource author.
         * @member {string} author
         * @memberof MPDirectory.WebUiSource
         * @instance
         */
        WebUiSource.prototype.author = "";

        /**
         * Gets the default type url for WebUiSource
         * @function getTypeUrl
         * @memberof MPDirectory.WebUiSource
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        WebUiSource.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/MPDirectory.WebUiSource";
        };

        return WebUiSource;
    })();

    return MPDirectory;
})();

export const companypartner = $root.companypartner = (() => {

    /**
     * Namespace companypartner.
     * @exports companypartner
     * @namespace
     */
    const companypartner = {};

    companypartner.CompanyPartnerService = (function() {

        /**
         * Constructs a new CompanyPartnerService service.
         * @memberof companypartner
         * @classdesc Represents a CompanyPartnerService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CompanyPartnerService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CompanyPartnerService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CompanyPartnerService;

        /**
         * Callback as used by {@link companypartner.CompanyPartnerService#createNewCompanyPartner}.
         * @memberof companypartner.CompanyPartnerService
         * @typedef CreateNewCompanyPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companypartner.CompanyPartnerResponse} [response] CompanyPartnerResponse
         */

        /**
         * Calls CreateNewCompanyPartner.
         * @function createNewCompanyPartner
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {companypartner.ICreateNewCompanyPartnerRequest} request CreateNewCompanyPartnerRequest message or plain object
         * @param {companypartner.CompanyPartnerService.CreateNewCompanyPartnerCallback} callback Node-style callback called with the error, if any, and CompanyPartnerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyPartnerService.prototype.createNewCompanyPartner = function createNewCompanyPartner(request, callback) {
            return this.rpcCall(createNewCompanyPartner, $root.companypartner.CreateNewCompanyPartnerRequest, $root.companypartner.CompanyPartnerResponse, request, callback);
        }, "name", { value: "CreateNewCompanyPartner" });

        /**
         * Calls CreateNewCompanyPartner.
         * @function createNewCompanyPartner
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {companypartner.ICreateNewCompanyPartnerRequest} request CreateNewCompanyPartnerRequest message or plain object
         * @returns {Promise<companypartner.CompanyPartnerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companypartner.CompanyPartnerService#getCompanyPartners}.
         * @memberof companypartner.CompanyPartnerService
         * @typedef GetCompanyPartnersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companypartner.CompanyPartnersResponse} [response] CompanyPartnersResponse
         */

        /**
         * Calls GetCompanyPartners.
         * @function getCompanyPartners
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {companypartner.CompanyPartnerService.GetCompanyPartnersCallback} callback Node-style callback called with the error, if any, and CompanyPartnersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyPartnerService.prototype.getCompanyPartners = function getCompanyPartners(request, callback) {
            return this.rpcCall(getCompanyPartners, $root.google.protobuf.Empty, $root.companypartner.CompanyPartnersResponse, request, callback);
        }, "name", { value: "GetCompanyPartners" });

        /**
         * Calls GetCompanyPartners.
         * @function getCompanyPartners
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<companypartner.CompanyPartnersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companypartner.CompanyPartnerService#getCompanyPartner}.
         * @memberof companypartner.CompanyPartnerService
         * @typedef GetCompanyPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companypartner.CompanyPartnerResponse} [response] CompanyPartnerResponse
         */

        /**
         * Calls GetCompanyPartner.
         * @function getCompanyPartner
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {companypartner.CompanyPartnerService.GetCompanyPartnerCallback} callback Node-style callback called with the error, if any, and CompanyPartnerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyPartnerService.prototype.getCompanyPartner = function getCompanyPartner(request, callback) {
            return this.rpcCall(getCompanyPartner, $root.base.PartnerRequest, $root.companypartner.CompanyPartnerResponse, request, callback);
        }, "name", { value: "GetCompanyPartner" });

        /**
         * Calls GetCompanyPartner.
         * @function getCompanyPartner
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<companypartner.CompanyPartnerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companypartner.CompanyPartnerService#searchCompanyPartners}.
         * @memberof companypartner.CompanyPartnerService
         * @typedef SearchCompanyPartnersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companypartner.PaginatedCompanyPartnersResponse} [response] PaginatedCompanyPartnersResponse
         */

        /**
         * Calls SearchCompanyPartners.
         * @function searchCompanyPartners
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {companypartner.ISearchCompanyPartnersRequest} request SearchCompanyPartnersRequest message or plain object
         * @param {companypartner.CompanyPartnerService.SearchCompanyPartnersCallback} callback Node-style callback called with the error, if any, and PaginatedCompanyPartnersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyPartnerService.prototype.searchCompanyPartners = function searchCompanyPartners(request, callback) {
            return this.rpcCall(searchCompanyPartners, $root.companypartner.SearchCompanyPartnersRequest, $root.companypartner.PaginatedCompanyPartnersResponse, request, callback);
        }, "name", { value: "SearchCompanyPartners" });

        /**
         * Calls SearchCompanyPartners.
         * @function searchCompanyPartners
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {companypartner.ISearchCompanyPartnersRequest} request SearchCompanyPartnersRequest message or plain object
         * @returns {Promise<companypartner.PaginatedCompanyPartnersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companypartner.CompanyPartnerService#updateCompanyPartnerAllServices}.
         * @memberof companypartner.CompanyPartnerService
         * @typedef UpdateCompanyPartnerAllServicesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companypartner.UpdateCompanyPartnerAllServicesResponse} [response] UpdateCompanyPartnerAllServicesResponse
         */

        /**
         * Calls UpdateCompanyPartnerAllServices.
         * @function updateCompanyPartnerAllServices
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {companypartner.IUpdateCompanyPartnerAllServicesRequest} request UpdateCompanyPartnerAllServicesRequest message or plain object
         * @param {companypartner.CompanyPartnerService.UpdateCompanyPartnerAllServicesCallback} callback Node-style callback called with the error, if any, and UpdateCompanyPartnerAllServicesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyPartnerService.prototype.updateCompanyPartnerAllServices = function updateCompanyPartnerAllServices(request, callback) {
            return this.rpcCall(updateCompanyPartnerAllServices, $root.companypartner.UpdateCompanyPartnerAllServicesRequest, $root.companypartner.UpdateCompanyPartnerAllServicesResponse, request, callback);
        }, "name", { value: "UpdateCompanyPartnerAllServices" });

        /**
         * Calls UpdateCompanyPartnerAllServices.
         * @function updateCompanyPartnerAllServices
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {companypartner.IUpdateCompanyPartnerAllServicesRequest} request UpdateCompanyPartnerAllServicesRequest message or plain object
         * @returns {Promise<companypartner.UpdateCompanyPartnerAllServicesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companypartner.CompanyPartnerService#updateCompanyPartnerSettings}.
         * @memberof companypartner.CompanyPartnerService
         * @typedef UpdateCompanyPartnerSettingsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companypartner.CompanyPartnerSettings} [response] CompanyPartnerSettings
         */

        /**
         * Calls UpdateCompanyPartnerSettings.
         * @function updateCompanyPartnerSettings
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {companypartner.IUpdateCompanyPartnerSettingsRequest} request UpdateCompanyPartnerSettingsRequest message or plain object
         * @param {companypartner.CompanyPartnerService.UpdateCompanyPartnerSettingsCallback} callback Node-style callback called with the error, if any, and CompanyPartnerSettings
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyPartnerService.prototype.updateCompanyPartnerSettings = function updateCompanyPartnerSettings(request, callback) {
            return this.rpcCall(updateCompanyPartnerSettings, $root.companypartner.UpdateCompanyPartnerSettingsRequest, $root.companypartner.CompanyPartnerSettings, request, callback);
        }, "name", { value: "UpdateCompanyPartnerSettings" });

        /**
         * Calls UpdateCompanyPartnerSettings.
         * @function updateCompanyPartnerSettings
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {companypartner.IUpdateCompanyPartnerSettingsRequest} request UpdateCompanyPartnerSettingsRequest message or plain object
         * @returns {Promise<companypartner.CompanyPartnerSettings>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companypartner.CompanyPartnerService#deleteCompanyPartnerByPartnerId}.
         * @memberof companypartner.CompanyPartnerService
         * @typedef DeleteCompanyPartnerByPartnerIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteCompanyPartnerByPartnerId.
         * @function deleteCompanyPartnerByPartnerId
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {companypartner.CompanyPartnerService.DeleteCompanyPartnerByPartnerIdCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyPartnerService.prototype.deleteCompanyPartnerByPartnerId = function deleteCompanyPartnerByPartnerId(request, callback) {
            return this.rpcCall(deleteCompanyPartnerByPartnerId, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteCompanyPartnerByPartnerId" });

        /**
         * Calls DeleteCompanyPartnerByPartnerId.
         * @function deleteCompanyPartnerByPartnerId
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link companypartner.CompanyPartnerService#fuseCompanyPartner}.
         * @memberof companypartner.CompanyPartnerService
         * @typedef FuseCompanyPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {companypartner.FuseCompanyPartnerResponse} [response] FuseCompanyPartnerResponse
         */

        /**
         * Calls FuseCompanyPartner.
         * @function fuseCompanyPartner
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {companypartner.IFuseCompanyPartnerRequest} request FuseCompanyPartnerRequest message or plain object
         * @param {companypartner.CompanyPartnerService.FuseCompanyPartnerCallback} callback Node-style callback called with the error, if any, and FuseCompanyPartnerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CompanyPartnerService.prototype.fuseCompanyPartner = function fuseCompanyPartner(request, callback) {
            return this.rpcCall(fuseCompanyPartner, $root.companypartner.FuseCompanyPartnerRequest, $root.companypartner.FuseCompanyPartnerResponse, request, callback);
        }, "name", { value: "FuseCompanyPartner" });

        /**
         * Calls FuseCompanyPartner.
         * @function fuseCompanyPartner
         * @memberof companypartner.CompanyPartnerService
         * @instance
         * @param {companypartner.IFuseCompanyPartnerRequest} request FuseCompanyPartnerRequest message or plain object
         * @returns {Promise<companypartner.FuseCompanyPartnerResponse>} Promise
         * @variation 2
         */

        return CompanyPartnerService;
    })();

    companypartner.SearchCompanyPartnersRequest = (function() {

        /**
         * Properties of a SearchCompanyPartnersRequest.
         * @memberof companypartner
         * @interface ISearchCompanyPartnersRequest
         * @property {query.IQuery|null} [query] SearchCompanyPartnersRequest query
         */

        /**
         * Constructs a new SearchCompanyPartnersRequest.
         * @memberof companypartner
         * @classdesc Represents a SearchCompanyPartnersRequest.
         * @implements ISearchCompanyPartnersRequest
         * @constructor
         * @param {companypartner.ISearchCompanyPartnersRequest=} [properties] Properties to set
         */
        function SearchCompanyPartnersRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchCompanyPartnersRequest query.
         * @member {query.IQuery|null|undefined} query
         * @memberof companypartner.SearchCompanyPartnersRequest
         * @instance
         */
        SearchCompanyPartnersRequest.prototype.query = null;

        /**
         * Gets the default type url for SearchCompanyPartnersRequest
         * @function getTypeUrl
         * @memberof companypartner.SearchCompanyPartnersRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchCompanyPartnersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.SearchCompanyPartnersRequest";
        };

        return SearchCompanyPartnersRequest;
    })();

    companypartner.UpdateCompanyPartnerSettingsRequest = (function() {

        /**
         * Properties of an UpdateCompanyPartnerSettingsRequest.
         * @memberof companypartner
         * @interface IUpdateCompanyPartnerSettingsRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] UpdateCompanyPartnerSettingsRequest partnerId
         * @property {companypartner.ICompanyPartnerSettings|null} [companyPartnerSettings] UpdateCompanyPartnerSettingsRequest companyPartnerSettings
         */

        /**
         * Constructs a new UpdateCompanyPartnerSettingsRequest.
         * @memberof companypartner
         * @classdesc Represents an UpdateCompanyPartnerSettingsRequest.
         * @implements IUpdateCompanyPartnerSettingsRequest
         * @constructor
         * @param {companypartner.IUpdateCompanyPartnerSettingsRequest=} [properties] Properties to set
         */
        function UpdateCompanyPartnerSettingsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateCompanyPartnerSettingsRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companypartner.UpdateCompanyPartnerSettingsRequest
         * @instance
         */
        UpdateCompanyPartnerSettingsRequest.prototype.partnerId = null;

        /**
         * UpdateCompanyPartnerSettingsRequest companyPartnerSettings.
         * @member {companypartner.ICompanyPartnerSettings|null|undefined} companyPartnerSettings
         * @memberof companypartner.UpdateCompanyPartnerSettingsRequest
         * @instance
         */
        UpdateCompanyPartnerSettingsRequest.prototype.companyPartnerSettings = null;

        /**
         * Gets the default type url for UpdateCompanyPartnerSettingsRequest
         * @function getTypeUrl
         * @memberof companypartner.UpdateCompanyPartnerSettingsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateCompanyPartnerSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.UpdateCompanyPartnerSettingsRequest";
        };

        return UpdateCompanyPartnerSettingsRequest;
    })();

    companypartner.PaginatedCompanyPartnersResponse = (function() {

        /**
         * Properties of a PaginatedCompanyPartnersResponse.
         * @memberof companypartner
         * @interface IPaginatedCompanyPartnersResponse
         * @property {Array.<companypartner.ICompanyPartnerResponse>|null} [companyPartners] PaginatedCompanyPartnersResponse companyPartners
         * @property {query.IPageInfo|null} [pageInfo] PaginatedCompanyPartnersResponse pageInfo
         */

        /**
         * Constructs a new PaginatedCompanyPartnersResponse.
         * @memberof companypartner
         * @classdesc Represents a PaginatedCompanyPartnersResponse.
         * @implements IPaginatedCompanyPartnersResponse
         * @constructor
         * @param {companypartner.IPaginatedCompanyPartnersResponse=} [properties] Properties to set
         */
        function PaginatedCompanyPartnersResponse(properties) {
            this.companyPartners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginatedCompanyPartnersResponse companyPartners.
         * @member {Array.<companypartner.ICompanyPartnerResponse>} companyPartners
         * @memberof companypartner.PaginatedCompanyPartnersResponse
         * @instance
         */
        PaginatedCompanyPartnersResponse.prototype.companyPartners = $util.emptyArray;

        /**
         * PaginatedCompanyPartnersResponse pageInfo.
         * @member {query.IPageInfo|null|undefined} pageInfo
         * @memberof companypartner.PaginatedCompanyPartnersResponse
         * @instance
         */
        PaginatedCompanyPartnersResponse.prototype.pageInfo = null;

        /**
         * Gets the default type url for PaginatedCompanyPartnersResponse
         * @function getTypeUrl
         * @memberof companypartner.PaginatedCompanyPartnersResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaginatedCompanyPartnersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.PaginatedCompanyPartnersResponse";
        };

        return PaginatedCompanyPartnersResponse;
    })();

    companypartner.CompanyPartnerResponse = (function() {

        /**
         * Properties of a CompanyPartnerResponse.
         * @memberof companypartner
         * @interface ICompanyPartnerResponse
         * @property {companypartner.ICompanyPartnerSettings|null} [companyPartnerSettings] CompanyPartnerResponse companyPartnerSettings
         */

        /**
         * Constructs a new CompanyPartnerResponse.
         * @memberof companypartner
         * @classdesc Represents a CompanyPartnerResponse.
         * @implements ICompanyPartnerResponse
         * @constructor
         * @param {companypartner.ICompanyPartnerResponse=} [properties] Properties to set
         */
        function CompanyPartnerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompanyPartnerResponse companyPartnerSettings.
         * @member {companypartner.ICompanyPartnerSettings|null|undefined} companyPartnerSettings
         * @memberof companypartner.CompanyPartnerResponse
         * @instance
         */
        CompanyPartnerResponse.prototype.companyPartnerSettings = null;

        /**
         * Gets the default type url for CompanyPartnerResponse
         * @function getTypeUrl
         * @memberof companypartner.CompanyPartnerResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CompanyPartnerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.CompanyPartnerResponse";
        };

        return CompanyPartnerResponse;
    })();

    companypartner.CompanyPartnerSettings = (function() {

        /**
         * Properties of a CompanyPartnerSettings.
         * @memberof companypartner
         * @interface ICompanyPartnerSettings
         * @property {google.protobuf.IInt32Value|null} [partnerId] CompanyPartnerSettings partnerId
         * @property {google.protobuf.IStringValue|null} [name] CompanyPartnerSettings name
         * @property {google.protobuf.IStringValue|null} [contactPhone] CompanyPartnerSettings contactPhone
         * @property {google.protobuf.IStringValue|null} [contactEmail] CompanyPartnerSettings contactEmail
         * @property {google.protobuf.IStringValue|null} [isoLanguageCode] CompanyPartnerSettings isoLanguageCode
         */

        /**
         * Constructs a new CompanyPartnerSettings.
         * @memberof companypartner
         * @classdesc Represents a CompanyPartnerSettings.
         * @implements ICompanyPartnerSettings
         * @constructor
         * @param {companypartner.ICompanyPartnerSettings=} [properties] Properties to set
         */
        function CompanyPartnerSettings(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompanyPartnerSettings partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companypartner.CompanyPartnerSettings
         * @instance
         */
        CompanyPartnerSettings.prototype.partnerId = null;

        /**
         * CompanyPartnerSettings name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof companypartner.CompanyPartnerSettings
         * @instance
         */
        CompanyPartnerSettings.prototype.name = null;

        /**
         * CompanyPartnerSettings contactPhone.
         * @member {google.protobuf.IStringValue|null|undefined} contactPhone
         * @memberof companypartner.CompanyPartnerSettings
         * @instance
         */
        CompanyPartnerSettings.prototype.contactPhone = null;

        /**
         * CompanyPartnerSettings contactEmail.
         * @member {google.protobuf.IStringValue|null|undefined} contactEmail
         * @memberof companypartner.CompanyPartnerSettings
         * @instance
         */
        CompanyPartnerSettings.prototype.contactEmail = null;

        /**
         * CompanyPartnerSettings isoLanguageCode.
         * @member {google.protobuf.IStringValue|null|undefined} isoLanguageCode
         * @memberof companypartner.CompanyPartnerSettings
         * @instance
         */
        CompanyPartnerSettings.prototype.isoLanguageCode = null;

        /**
         * Gets the default type url for CompanyPartnerSettings
         * @function getTypeUrl
         * @memberof companypartner.CompanyPartnerSettings
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CompanyPartnerSettings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.CompanyPartnerSettings";
        };

        return CompanyPartnerSettings;
    })();

    companypartner.CompanyPartnersResponse = (function() {

        /**
         * Properties of a CompanyPartnersResponse.
         * @memberof companypartner
         * @interface ICompanyPartnersResponse
         * @property {Array.<companypartner.ICompanyPartnerSettings>|null} [companyPartnerSettings] CompanyPartnersResponse companyPartnerSettings
         */

        /**
         * Constructs a new CompanyPartnersResponse.
         * @memberof companypartner
         * @classdesc Represents a CompanyPartnersResponse.
         * @implements ICompanyPartnersResponse
         * @constructor
         * @param {companypartner.ICompanyPartnersResponse=} [properties] Properties to set
         */
        function CompanyPartnersResponse(properties) {
            this.companyPartnerSettings = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompanyPartnersResponse companyPartnerSettings.
         * @member {Array.<companypartner.ICompanyPartnerSettings>} companyPartnerSettings
         * @memberof companypartner.CompanyPartnersResponse
         * @instance
         */
        CompanyPartnersResponse.prototype.companyPartnerSettings = $util.emptyArray;

        /**
         * Gets the default type url for CompanyPartnersResponse
         * @function getTypeUrl
         * @memberof companypartner.CompanyPartnersResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CompanyPartnersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.CompanyPartnersResponse";
        };

        return CompanyPartnersResponse;
    })();

    companypartner.CreateNewCompanyPartnerRequest = (function() {

        /**
         * Properties of a CreateNewCompanyPartnerRequest.
         * @memberof companypartner
         * @interface ICreateNewCompanyPartnerRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] CreateNewCompanyPartnerRequest partnerId
         * @property {google.protobuf.IStringValue|null} [name] CreateNewCompanyPartnerRequest name
         * @property {google.protobuf.IStringValue|null} [contactFirstName] CreateNewCompanyPartnerRequest contactFirstName
         * @property {google.protobuf.IStringValue|null} [contactName] CreateNewCompanyPartnerRequest contactName
         * @property {google.protobuf.IStringValue|null} [contactPhone] CreateNewCompanyPartnerRequest contactPhone
         * @property {google.protobuf.IStringValue|null} [contactEmail] CreateNewCompanyPartnerRequest contactEmail
         * @property {google.protobuf.IStringValue|null} [isoLanguageCode] CreateNewCompanyPartnerRequest isoLanguageCode
         */

        /**
         * Constructs a new CreateNewCompanyPartnerRequest.
         * @memberof companypartner
         * @classdesc Represents a CreateNewCompanyPartnerRequest.
         * @implements ICreateNewCompanyPartnerRequest
         * @constructor
         * @param {companypartner.ICreateNewCompanyPartnerRequest=} [properties] Properties to set
         */
        function CreateNewCompanyPartnerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateNewCompanyPartnerRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companypartner.CreateNewCompanyPartnerRequest
         * @instance
         */
        CreateNewCompanyPartnerRequest.prototype.partnerId = null;

        /**
         * CreateNewCompanyPartnerRequest name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof companypartner.CreateNewCompanyPartnerRequest
         * @instance
         */
        CreateNewCompanyPartnerRequest.prototype.name = null;

        /**
         * CreateNewCompanyPartnerRequest contactFirstName.
         * @member {google.protobuf.IStringValue|null|undefined} contactFirstName
         * @memberof companypartner.CreateNewCompanyPartnerRequest
         * @instance
         */
        CreateNewCompanyPartnerRequest.prototype.contactFirstName = null;

        /**
         * CreateNewCompanyPartnerRequest contactName.
         * @member {google.protobuf.IStringValue|null|undefined} contactName
         * @memberof companypartner.CreateNewCompanyPartnerRequest
         * @instance
         */
        CreateNewCompanyPartnerRequest.prototype.contactName = null;

        /**
         * CreateNewCompanyPartnerRequest contactPhone.
         * @member {google.protobuf.IStringValue|null|undefined} contactPhone
         * @memberof companypartner.CreateNewCompanyPartnerRequest
         * @instance
         */
        CreateNewCompanyPartnerRequest.prototype.contactPhone = null;

        /**
         * CreateNewCompanyPartnerRequest contactEmail.
         * @member {google.protobuf.IStringValue|null|undefined} contactEmail
         * @memberof companypartner.CreateNewCompanyPartnerRequest
         * @instance
         */
        CreateNewCompanyPartnerRequest.prototype.contactEmail = null;

        /**
         * CreateNewCompanyPartnerRequest isoLanguageCode.
         * @member {google.protobuf.IStringValue|null|undefined} isoLanguageCode
         * @memberof companypartner.CreateNewCompanyPartnerRequest
         * @instance
         */
        CreateNewCompanyPartnerRequest.prototype.isoLanguageCode = null;

        /**
         * Gets the default type url for CreateNewCompanyPartnerRequest
         * @function getTypeUrl
         * @memberof companypartner.CreateNewCompanyPartnerRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateNewCompanyPartnerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.CreateNewCompanyPartnerRequest";
        };

        return CreateNewCompanyPartnerRequest;
    })();

    companypartner.UpdateCompanyPartnerAllServicesRequest = (function() {

        /**
         * Properties of an UpdateCompanyPartnerAllServicesRequest.
         * @memberof companypartner
         * @interface IUpdateCompanyPartnerAllServicesRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] UpdateCompanyPartnerAllServicesRequest partnerId
         * @property {google.protobuf.IStringValue|null} [name] UpdateCompanyPartnerAllServicesRequest name
         * @property {google.protobuf.IStringValue|null} [contactPhone] UpdateCompanyPartnerAllServicesRequest contactPhone
         * @property {google.protobuf.IStringValue|null} [contactEmail] UpdateCompanyPartnerAllServicesRequest contactEmail
         * @property {google.protobuf.IStringValue|null} [isoLanguageCode] UpdateCompanyPartnerAllServicesRequest isoLanguageCode
         * @property {google.protobuf.IStringValue|null} [contactFirstName] UpdateCompanyPartnerAllServicesRequest contactFirstName
         * @property {google.protobuf.IStringValue|null} [contactName] UpdateCompanyPartnerAllServicesRequest contactName
         * @property {google.protobuf.IStringValue|null} [addressStreet] UpdateCompanyPartnerAllServicesRequest addressStreet
         * @property {google.protobuf.IStringValue|null} [addressHouseNumber] UpdateCompanyPartnerAllServicesRequest addressHouseNumber
         * @property {google.protobuf.IStringValue|null} [addressZipCode] UpdateCompanyPartnerAllServicesRequest addressZipCode
         * @property {google.protobuf.IStringValue|null} [addressTown] UpdateCompanyPartnerAllServicesRequest addressTown
         */

        /**
         * Constructs a new UpdateCompanyPartnerAllServicesRequest.
         * @memberof companypartner
         * @classdesc Represents an UpdateCompanyPartnerAllServicesRequest.
         * @implements IUpdateCompanyPartnerAllServicesRequest
         * @constructor
         * @param {companypartner.IUpdateCompanyPartnerAllServicesRequest=} [properties] Properties to set
         */
        function UpdateCompanyPartnerAllServicesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateCompanyPartnerAllServicesRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.partnerId = null;

        /**
         * UpdateCompanyPartnerAllServicesRequest name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.name = null;

        /**
         * UpdateCompanyPartnerAllServicesRequest contactPhone.
         * @member {google.protobuf.IStringValue|null|undefined} contactPhone
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.contactPhone = null;

        /**
         * UpdateCompanyPartnerAllServicesRequest contactEmail.
         * @member {google.protobuf.IStringValue|null|undefined} contactEmail
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.contactEmail = null;

        /**
         * UpdateCompanyPartnerAllServicesRequest isoLanguageCode.
         * @member {google.protobuf.IStringValue|null|undefined} isoLanguageCode
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.isoLanguageCode = null;

        /**
         * UpdateCompanyPartnerAllServicesRequest contactFirstName.
         * @member {google.protobuf.IStringValue|null|undefined} contactFirstName
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.contactFirstName = null;

        /**
         * UpdateCompanyPartnerAllServicesRequest contactName.
         * @member {google.protobuf.IStringValue|null|undefined} contactName
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.contactName = null;

        /**
         * UpdateCompanyPartnerAllServicesRequest addressStreet.
         * @member {google.protobuf.IStringValue|null|undefined} addressStreet
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.addressStreet = null;

        /**
         * UpdateCompanyPartnerAllServicesRequest addressHouseNumber.
         * @member {google.protobuf.IStringValue|null|undefined} addressHouseNumber
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.addressHouseNumber = null;

        /**
         * UpdateCompanyPartnerAllServicesRequest addressZipCode.
         * @member {google.protobuf.IStringValue|null|undefined} addressZipCode
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.addressZipCode = null;

        /**
         * UpdateCompanyPartnerAllServicesRequest addressTown.
         * @member {google.protobuf.IStringValue|null|undefined} addressTown
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @instance
         */
        UpdateCompanyPartnerAllServicesRequest.prototype.addressTown = null;

        /**
         * Gets the default type url for UpdateCompanyPartnerAllServicesRequest
         * @function getTypeUrl
         * @memberof companypartner.UpdateCompanyPartnerAllServicesRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateCompanyPartnerAllServicesRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.UpdateCompanyPartnerAllServicesRequest";
        };

        return UpdateCompanyPartnerAllServicesRequest;
    })();

    companypartner.UpdateCompanyPartnerAllServicesResponse = (function() {

        /**
         * Properties of an UpdateCompanyPartnerAllServicesResponse.
         * @memberof companypartner
         * @interface IUpdateCompanyPartnerAllServicesResponse
         * @property {base.ILocalizedResult|null} [result] UpdateCompanyPartnerAllServicesResponse result
         */

        /**
         * Constructs a new UpdateCompanyPartnerAllServicesResponse.
         * @memberof companypartner
         * @classdesc Represents an UpdateCompanyPartnerAllServicesResponse.
         * @implements IUpdateCompanyPartnerAllServicesResponse
         * @constructor
         * @param {companypartner.IUpdateCompanyPartnerAllServicesResponse=} [properties] Properties to set
         */
        function UpdateCompanyPartnerAllServicesResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateCompanyPartnerAllServicesResponse result.
         * @member {base.ILocalizedResult|null|undefined} result
         * @memberof companypartner.UpdateCompanyPartnerAllServicesResponse
         * @instance
         */
        UpdateCompanyPartnerAllServicesResponse.prototype.result = null;

        /**
         * Gets the default type url for UpdateCompanyPartnerAllServicesResponse
         * @function getTypeUrl
         * @memberof companypartner.UpdateCompanyPartnerAllServicesResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateCompanyPartnerAllServicesResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.UpdateCompanyPartnerAllServicesResponse";
        };

        return UpdateCompanyPartnerAllServicesResponse;
    })();

    companypartner.FuseCompanyPartnerRequest = (function() {

        /**
         * Properties of a FuseCompanyPartnerRequest.
         * @memberof companypartner
         * @interface IFuseCompanyPartnerRequest
         * @property {google.protobuf.IInt32Value|null} [sourcePartnerId] FuseCompanyPartnerRequest sourcePartnerId
         * @property {google.protobuf.IInt32Value|null} [targetPartnerId] FuseCompanyPartnerRequest targetPartnerId
         */

        /**
         * Constructs a new FuseCompanyPartnerRequest.
         * @memberof companypartner
         * @classdesc Represents a FuseCompanyPartnerRequest.
         * @implements IFuseCompanyPartnerRequest
         * @constructor
         * @param {companypartner.IFuseCompanyPartnerRequest=} [properties] Properties to set
         */
        function FuseCompanyPartnerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FuseCompanyPartnerRequest sourcePartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} sourcePartnerId
         * @memberof companypartner.FuseCompanyPartnerRequest
         * @instance
         */
        FuseCompanyPartnerRequest.prototype.sourcePartnerId = null;

        /**
         * FuseCompanyPartnerRequest targetPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} targetPartnerId
         * @memberof companypartner.FuseCompanyPartnerRequest
         * @instance
         */
        FuseCompanyPartnerRequest.prototype.targetPartnerId = null;

        /**
         * Gets the default type url for FuseCompanyPartnerRequest
         * @function getTypeUrl
         * @memberof companypartner.FuseCompanyPartnerRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FuseCompanyPartnerRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.FuseCompanyPartnerRequest";
        };

        return FuseCompanyPartnerRequest;
    })();

    companypartner.FuseCompanyPartnerResponse = (function() {

        /**
         * Properties of a FuseCompanyPartnerResponse.
         * @memberof companypartner
         * @interface IFuseCompanyPartnerResponse
         * @property {base.ILocalizedResult|null} [result] FuseCompanyPartnerResponse result
         * @property {google.protobuf.IInt32Value|null} [numberOfTransferredUsers] FuseCompanyPartnerResponse numberOfTransferredUsers
         */

        /**
         * Constructs a new FuseCompanyPartnerResponse.
         * @memberof companypartner
         * @classdesc Represents a FuseCompanyPartnerResponse.
         * @implements IFuseCompanyPartnerResponse
         * @constructor
         * @param {companypartner.IFuseCompanyPartnerResponse=} [properties] Properties to set
         */
        function FuseCompanyPartnerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FuseCompanyPartnerResponse result.
         * @member {base.ILocalizedResult|null|undefined} result
         * @memberof companypartner.FuseCompanyPartnerResponse
         * @instance
         */
        FuseCompanyPartnerResponse.prototype.result = null;

        /**
         * FuseCompanyPartnerResponse numberOfTransferredUsers.
         * @member {google.protobuf.IInt32Value|null|undefined} numberOfTransferredUsers
         * @memberof companypartner.FuseCompanyPartnerResponse
         * @instance
         */
        FuseCompanyPartnerResponse.prototype.numberOfTransferredUsers = null;

        /**
         * Gets the default type url for FuseCompanyPartnerResponse
         * @function getTypeUrl
         * @memberof companypartner.FuseCompanyPartnerResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FuseCompanyPartnerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/companypartner.FuseCompanyPartnerResponse";
        };

        return FuseCompanyPartnerResponse;
    })();

    return companypartner;
})();

export const ftpaccount = $root.ftpaccount = (() => {

    /**
     * Namespace ftpaccount.
     * @exports ftpaccount
     * @namespace
     */
    const ftpaccount = {};

    ftpaccount.FtpAccountService = (function() {

        /**
         * Constructs a new FtpAccountService service.
         * @memberof ftpaccount
         * @classdesc Represents a FtpAccountService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function FtpAccountService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (FtpAccountService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = FtpAccountService;

        /**
         * Callback as used by {@link ftpaccount.FtpAccountService#getFtpAccountBySenderAndRecipient}.
         * @memberof ftpaccount.FtpAccountService
         * @typedef GetFtpAccountBySenderAndRecipientCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {common.FtpAccount} [response] FtpAccount
         */

        /**
         * Calls GetFtpAccountBySenderAndRecipient.
         * @function getFtpAccountBySenderAndRecipient
         * @memberof ftpaccount.FtpAccountService
         * @instance
         * @param {ftpaccount.ISenderAndRecipientRequest} request SenderAndRecipientRequest message or plain object
         * @param {ftpaccount.FtpAccountService.GetFtpAccountBySenderAndRecipientCallback} callback Node-style callback called with the error, if any, and FtpAccount
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(FtpAccountService.prototype.getFtpAccountBySenderAndRecipient = function getFtpAccountBySenderAndRecipient(request, callback) {
            return this.rpcCall(getFtpAccountBySenderAndRecipient, $root.ftpaccount.SenderAndRecipientRequest, $root.common.FtpAccount, request, callback);
        }, "name", { value: "GetFtpAccountBySenderAndRecipient" });

        /**
         * Calls GetFtpAccountBySenderAndRecipient.
         * @function getFtpAccountBySenderAndRecipient
         * @memberof ftpaccount.FtpAccountService
         * @instance
         * @param {ftpaccount.ISenderAndRecipientRequest} request SenderAndRecipientRequest message or plain object
         * @returns {Promise<common.FtpAccount>} Promise
         * @variation 2
         */

        return FtpAccountService;
    })();

    ftpaccount.SenderAndRecipientRequest = (function() {

        /**
         * Properties of a SenderAndRecipientRequest.
         * @memberof ftpaccount
         * @interface ISenderAndRecipientRequest
         * @property {google.protobuf.IInt32Value|null} [sender] SenderAndRecipientRequest sender
         * @property {google.protobuf.IInt32Value|null} [recipient] SenderAndRecipientRequest recipient
         */

        /**
         * Constructs a new SenderAndRecipientRequest.
         * @memberof ftpaccount
         * @classdesc Represents a SenderAndRecipientRequest.
         * @implements ISenderAndRecipientRequest
         * @constructor
         * @param {ftpaccount.ISenderAndRecipientRequest=} [properties] Properties to set
         */
        function SenderAndRecipientRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SenderAndRecipientRequest sender.
         * @member {google.protobuf.IInt32Value|null|undefined} sender
         * @memberof ftpaccount.SenderAndRecipientRequest
         * @instance
         */
        SenderAndRecipientRequest.prototype.sender = null;

        /**
         * SenderAndRecipientRequest recipient.
         * @member {google.protobuf.IInt32Value|null|undefined} recipient
         * @memberof ftpaccount.SenderAndRecipientRequest
         * @instance
         */
        SenderAndRecipientRequest.prototype.recipient = null;

        /**
         * Gets the default type url for SenderAndRecipientRequest
         * @function getTypeUrl
         * @memberof ftpaccount.SenderAndRecipientRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SenderAndRecipientRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/ftpaccount.SenderAndRecipientRequest";
        };

        return SenderAndRecipientRequest;
    })();

    return ftpaccount;
})();

export const partner = $root.partner = (() => {

    /**
     * Namespace partner.
     * @exports partner
     * @namespace
     */
    const partner = {};

    partner.PartnerService = (function() {

        /**
         * Constructs a new PartnerService service.
         * @memberof partner
         * @classdesc Represents a PartnerService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function PartnerService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (PartnerService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PartnerService;

        /**
         * Callback as used by {@link partner.PartnerService#createPartnerExternalFtpAccount}.
         * @memberof partner.PartnerService
         * @typedef CreatePartnerExternalFtpAccountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {common.ExternalFtpAccount} [response] ExternalFtpAccount
         */

        /**
         * Calls CreatePartnerExternalFtpAccount.
         * @function createPartnerExternalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ICreatePartnerExternalFtpAccountRequest} request CreatePartnerExternalFtpAccountRequest message or plain object
         * @param {partner.PartnerService.CreatePartnerExternalFtpAccountCallback} callback Node-style callback called with the error, if any, and ExternalFtpAccount
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.createPartnerExternalFtpAccount = function createPartnerExternalFtpAccount(request, callback) {
            return this.rpcCall(createPartnerExternalFtpAccount, $root.partner.CreatePartnerExternalFtpAccountRequest, $root.common.ExternalFtpAccount, request, callback);
        }, "name", { value: "CreatePartnerExternalFtpAccount" });

        /**
         * Calls CreatePartnerExternalFtpAccount.
         * @function createPartnerExternalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ICreatePartnerExternalFtpAccountRequest} request CreatePartnerExternalFtpAccountRequest message or plain object
         * @returns {Promise<common.ExternalFtpAccount>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#createPartnerLocalFtpAccount}.
         * @memberof partner.PartnerService
         * @typedef CreatePartnerLocalFtpAccountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.NewlyCreatedLocalFtpAccount} [response] NewlyCreatedLocalFtpAccount
         */

        /**
         * Calls CreatePartnerLocalFtpAccount.
         * @function createPartnerLocalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {partner.PartnerService.CreatePartnerLocalFtpAccountCallback} callback Node-style callback called with the error, if any, and NewlyCreatedLocalFtpAccount
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.createPartnerLocalFtpAccount = function createPartnerLocalFtpAccount(request, callback) {
            return this.rpcCall(createPartnerLocalFtpAccount, $root.base.PartnerRequest, $root.partner.NewlyCreatedLocalFtpAccount, request, callback);
        }, "name", { value: "CreatePartnerLocalFtpAccount" });

        /**
         * Calls CreatePartnerLocalFtpAccount.
         * @function createPartnerLocalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<partner.NewlyCreatedLocalFtpAccount>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#getPartner}.
         * @memberof partner.PartnerService
         * @typedef GetPartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.PartnerResponse} [response] PartnerResponse
         */

        /**
         * Calls GetPartner.
         * @function getPartner
         * @memberof partner.PartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {partner.PartnerService.GetPartnerCallback} callback Node-style callback called with the error, if any, and PartnerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.getPartner = function getPartner(request, callback) {
            return this.rpcCall(getPartner, $root.base.PartnerRequest, $root.partner.PartnerResponse, request, callback);
        }, "name", { value: "GetPartner" });

        /**
         * Calls GetPartner.
         * @function getPartner
         * @memberof partner.PartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<partner.PartnerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#getPartners}.
         * @memberof partner.PartnerService
         * @typedef GetPartnersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.PartnersResponse} [response] PartnersResponse
         */

        /**
         * Calls GetPartners.
         * @function getPartners
         * @memberof partner.PartnerService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {partner.PartnerService.GetPartnersCallback} callback Node-style callback called with the error, if any, and PartnersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.getPartners = function getPartners(request, callback) {
            return this.rpcCall(getPartners, $root.google.protobuf.Empty, $root.partner.PartnersResponse, request, callback);
        }, "name", { value: "GetPartners" });

        /**
         * Calls GetPartners.
         * @function getPartners
         * @memberof partner.PartnerService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<partner.PartnersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#searchPartners}.
         * @memberof partner.PartnerService
         * @typedef SearchPartnersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.PaginatedPartnersResponse} [response] PaginatedPartnersResponse
         */

        /**
         * Calls SearchPartners.
         * @function searchPartners
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ISearchPartnersRequest} request SearchPartnersRequest message or plain object
         * @param {partner.PartnerService.SearchPartnersCallback} callback Node-style callback called with the error, if any, and PaginatedPartnersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.searchPartners = function searchPartners(request, callback) {
            return this.rpcCall(searchPartners, $root.partner.SearchPartnersRequest, $root.partner.PaginatedPartnersResponse, request, callback);
        }, "name", { value: "SearchPartners" });

        /**
         * Calls SearchPartners.
         * @function searchPartners
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ISearchPartnersRequest} request SearchPartnersRequest message or plain object
         * @returns {Promise<partner.PaginatedPartnersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#searchPartnersContactDetails}.
         * @memberof partner.PartnerService
         * @typedef SearchPartnersContactDetailsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.PaginatedPartnersContactDetailsResponse} [response] PaginatedPartnersContactDetailsResponse
         */

        /**
         * Calls SearchPartnersContactDetails.
         * @function searchPartnersContactDetails
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ISearchPartnersRequest} request SearchPartnersRequest message or plain object
         * @param {partner.PartnerService.SearchPartnersContactDetailsCallback} callback Node-style callback called with the error, if any, and PaginatedPartnersContactDetailsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.searchPartnersContactDetails = function searchPartnersContactDetails(request, callback) {
            return this.rpcCall(searchPartnersContactDetails, $root.partner.SearchPartnersRequest, $root.partner.PaginatedPartnersContactDetailsResponse, request, callback);
        }, "name", { value: "SearchPartnersContactDetails" });

        /**
         * Calls SearchPartnersContactDetails.
         * @function searchPartnersContactDetails
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ISearchPartnersRequest} request SearchPartnersRequest message or plain object
         * @returns {Promise<partner.PaginatedPartnersContactDetailsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#updatePartnerSettings}.
         * @memberof partner.PartnerService
         * @typedef UpdatePartnerSettingsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.PartnerSettings} [response] PartnerSettings
         */

        /**
         * Calls UpdatePartnerSettings.
         * @function updatePartnerSettings
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdatePartnerSettingsRequest} request UpdatePartnerSettingsRequest message or plain object
         * @param {partner.PartnerService.UpdatePartnerSettingsCallback} callback Node-style callback called with the error, if any, and PartnerSettings
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.updatePartnerSettings = function updatePartnerSettings(request, callback) {
            return this.rpcCall(updatePartnerSettings, $root.partner.UpdatePartnerSettingsRequest, $root.partner.PartnerSettings, request, callback);
        }, "name", { value: "UpdatePartnerSettings" });

        /**
         * Calls UpdatePartnerSettings.
         * @function updatePartnerSettings
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdatePartnerSettingsRequest} request UpdatePartnerSettingsRequest message or plain object
         * @returns {Promise<partner.PartnerSettings>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#updatePartnerReceiveMessageLocation}.
         * @memberof partner.PartnerService
         * @typedef UpdatePartnerReceiveMessageLocationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.CommunicationSettingsResponse} [response] CommunicationSettingsResponse
         */

        /**
         * Calls UpdatePartnerReceiveMessageLocation.
         * @function updatePartnerReceiveMessageLocation
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdatePartnerReceiveMessageLocationRequest} request UpdatePartnerReceiveMessageLocationRequest message or plain object
         * @param {partner.PartnerService.UpdatePartnerReceiveMessageLocationCallback} callback Node-style callback called with the error, if any, and CommunicationSettingsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.updatePartnerReceiveMessageLocation = function updatePartnerReceiveMessageLocation(request, callback) {
            return this.rpcCall(updatePartnerReceiveMessageLocation, $root.partner.UpdatePartnerReceiveMessageLocationRequest, $root.partner.CommunicationSettingsResponse, request, callback);
        }, "name", { value: "UpdatePartnerReceiveMessageLocation" });

        /**
         * Calls UpdatePartnerReceiveMessageLocation.
         * @function updatePartnerReceiveMessageLocation
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdatePartnerReceiveMessageLocationRequest} request UpdatePartnerReceiveMessageLocationRequest message or plain object
         * @returns {Promise<partner.CommunicationSettingsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#updatePartnerExternalFtpAccount}.
         * @memberof partner.PartnerService
         * @typedef UpdatePartnerExternalFtpAccountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {common.ExternalFtpAccount} [response] ExternalFtpAccount
         */

        /**
         * Calls UpdatePartnerExternalFtpAccount.
         * @function updatePartnerExternalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdatePartnerExternalFtpAccountRequest} request UpdatePartnerExternalFtpAccountRequest message or plain object
         * @param {partner.PartnerService.UpdatePartnerExternalFtpAccountCallback} callback Node-style callback called with the error, if any, and ExternalFtpAccount
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.updatePartnerExternalFtpAccount = function updatePartnerExternalFtpAccount(request, callback) {
            return this.rpcCall(updatePartnerExternalFtpAccount, $root.partner.UpdatePartnerExternalFtpAccountRequest, $root.common.ExternalFtpAccount, request, callback);
        }, "name", { value: "UpdatePartnerExternalFtpAccount" });

        /**
         * Calls UpdatePartnerExternalFtpAccount.
         * @function updatePartnerExternalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdatePartnerExternalFtpAccountRequest} request UpdatePartnerExternalFtpAccountRequest message or plain object
         * @returns {Promise<common.ExternalFtpAccount>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#updatePartnerSdatWebClientSettings}.
         * @memberof partner.PartnerService
         * @typedef UpdatePartnerSdatWebClientSettingsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.SdatWebClientSettings} [response] SdatWebClientSettings
         */

        /**
         * Calls UpdatePartnerSdatWebClientSettings.
         * @function updatePartnerSdatWebClientSettings
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdatePartnerSdatWebClientSettingsRequest} request UpdatePartnerSdatWebClientSettingsRequest message or plain object
         * @param {partner.PartnerService.UpdatePartnerSdatWebClientSettingsCallback} callback Node-style callback called with the error, if any, and SdatWebClientSettings
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.updatePartnerSdatWebClientSettings = function updatePartnerSdatWebClientSettings(request, callback) {
            return this.rpcCall(updatePartnerSdatWebClientSettings, $root.partner.UpdatePartnerSdatWebClientSettingsRequest, $root.partner.SdatWebClientSettings, request, callback);
        }, "name", { value: "UpdatePartnerSdatWebClientSettings" });

        /**
         * Calls UpdatePartnerSdatWebClientSettings.
         * @function updatePartnerSdatWebClientSettings
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdatePartnerSdatWebClientSettingsRequest} request UpdatePartnerSdatWebClientSettingsRequest message or plain object
         * @returns {Promise<partner.SdatWebClientSettings>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#updateMeteringPointsDirectorySettings}.
         * @memberof partner.PartnerService
         * @typedef UpdateMeteringPointsDirectorySettingsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.MeteringPointsDirectorySettings} [response] MeteringPointsDirectorySettings
         */

        /**
         * Calls UpdateMeteringPointsDirectorySettings.
         * @function updateMeteringPointsDirectorySettings
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IMeteringPointsDirectorySettings} request MeteringPointsDirectorySettings message or plain object
         * @param {partner.PartnerService.UpdateMeteringPointsDirectorySettingsCallback} callback Node-style callback called with the error, if any, and MeteringPointsDirectorySettings
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.updateMeteringPointsDirectorySettings = function updateMeteringPointsDirectorySettings(request, callback) {
            return this.rpcCall(updateMeteringPointsDirectorySettings, $root.partner.MeteringPointsDirectorySettings, $root.partner.MeteringPointsDirectorySettings, request, callback);
        }, "name", { value: "UpdateMeteringPointsDirectorySettings" });

        /**
         * Calls UpdateMeteringPointsDirectorySettings.
         * @function updateMeteringPointsDirectorySettings
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IMeteringPointsDirectorySettings} request MeteringPointsDirectorySettings message or plain object
         * @returns {Promise<partner.MeteringPointsDirectorySettings>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#deletePartner}.
         * @memberof partner.PartnerService
         * @typedef DeletePartnerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeletePartner.
         * @function deletePartner
         * @memberof partner.PartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {partner.PartnerService.DeletePartnerCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.deletePartner = function deletePartner(request, callback) {
            return this.rpcCall(deletePartner, $root.base.PartnerRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeletePartner" });

        /**
         * Calls DeletePartner.
         * @function deletePartner
         * @memberof partner.PartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#deletePartnerExternalFtpAccount}.
         * @memberof partner.PartnerService
         * @typedef DeletePartnerExternalFtpAccountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeletePartnerExternalFtpAccount.
         * @function deletePartnerExternalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IDeletePartnerExternalFtpAccountRequest} request DeletePartnerExternalFtpAccountRequest message or plain object
         * @param {partner.PartnerService.DeletePartnerExternalFtpAccountCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.deletePartnerExternalFtpAccount = function deletePartnerExternalFtpAccount(request, callback) {
            return this.rpcCall(deletePartnerExternalFtpAccount, $root.partner.DeletePartnerExternalFtpAccountRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeletePartnerExternalFtpAccount" });

        /**
         * Calls DeletePartnerExternalFtpAccount.
         * @function deletePartnerExternalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IDeletePartnerExternalFtpAccountRequest} request DeletePartnerExternalFtpAccountRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#deletePartnerLocalFtpAccount}.
         * @memberof partner.PartnerService
         * @typedef DeletePartnerLocalFtpAccountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeletePartnerLocalFtpAccount.
         * @function deletePartnerLocalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IDeletePartnerLocalFtpAccountRequest} request DeletePartnerLocalFtpAccountRequest message or plain object
         * @param {partner.PartnerService.DeletePartnerLocalFtpAccountCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.deletePartnerLocalFtpAccount = function deletePartnerLocalFtpAccount(request, callback) {
            return this.rpcCall(deletePartnerLocalFtpAccount, $root.partner.DeletePartnerLocalFtpAccountRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeletePartnerLocalFtpAccount" });

        /**
         * Calls DeletePartnerLocalFtpAccount.
         * @function deletePartnerLocalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IDeletePartnerLocalFtpAccountRequest} request DeletePartnerLocalFtpAccountRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#updatePartnerFeatures}.
         * @memberof partner.PartnerService
         * @typedef UpdatePartnerFeaturesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.FeatureList} [response] FeatureList
         */

        /**
         * Calls UpdatePartnerFeatures.
         * @function updatePartnerFeatures
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdatePartnerFeatureRequest} request UpdatePartnerFeatureRequest message or plain object
         * @param {partner.PartnerService.UpdatePartnerFeaturesCallback} callback Node-style callback called with the error, if any, and FeatureList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.updatePartnerFeatures = function updatePartnerFeatures(request, callback) {
            return this.rpcCall(updatePartnerFeatures, $root.partner.UpdatePartnerFeatureRequest, $root.partner.FeatureList, request, callback);
        }, "name", { value: "UpdatePartnerFeatures" });

        /**
         * Calls UpdatePartnerFeatures.
         * @function updatePartnerFeatures
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdatePartnerFeatureRequest} request UpdatePartnerFeatureRequest message or plain object
         * @returns {Promise<partner.FeatureList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#updateFeaturesForAll}.
         * @memberof partner.PartnerService
         * @typedef UpdateFeaturesForAllCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.FeatureList} [response] FeatureList
         */

        /**
         * Calls UpdateFeaturesForAll.
         * @function updateFeaturesForAll
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdateFeatureRequest} request UpdateFeatureRequest message or plain object
         * @param {partner.PartnerService.UpdateFeaturesForAllCallback} callback Node-style callback called with the error, if any, and FeatureList
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.updateFeaturesForAll = function updateFeaturesForAll(request, callback) {
            return this.rpcCall(updateFeaturesForAll, $root.partner.UpdateFeatureRequest, $root.partner.FeatureList, request, callback);
        }, "name", { value: "UpdateFeaturesForAll" });

        /**
         * Calls UpdateFeaturesForAll.
         * @function updateFeaturesForAll
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.IUpdateFeatureRequest} request UpdateFeatureRequest message or plain object
         * @returns {Promise<partner.FeatureList>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#listLocalFtpAccountsAvailableForSharing}.
         * @memberof partner.PartnerService
         * @typedef ListLocalFtpAccountsAvailableForSharingCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.LocalFtpAccounts} [response] LocalFtpAccounts
         */

        /**
         * Calls ListLocalFtpAccountsAvailableForSharing.
         * @function listLocalFtpAccountsAvailableForSharing
         * @memberof partner.PartnerService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {partner.PartnerService.ListLocalFtpAccountsAvailableForSharingCallback} callback Node-style callback called with the error, if any, and LocalFtpAccounts
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.listLocalFtpAccountsAvailableForSharing = function listLocalFtpAccountsAvailableForSharing(request, callback) {
            return this.rpcCall(listLocalFtpAccountsAvailableForSharing, $root.google.protobuf.Empty, $root.partner.LocalFtpAccounts, request, callback);
        }, "name", { value: "ListLocalFtpAccountsAvailableForSharing" });

        /**
         * Calls ListLocalFtpAccountsAvailableForSharing.
         * @function listLocalFtpAccountsAvailableForSharing
         * @memberof partner.PartnerService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<partner.LocalFtpAccounts>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#shareLocalFtpAccount}.
         * @memberof partner.PartnerService
         * @typedef ShareLocalFtpAccountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls ShareLocalFtpAccount.
         * @function shareLocalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ISharedFtpAccountRequest} request SharedFtpAccountRequest message or plain object
         * @param {partner.PartnerService.ShareLocalFtpAccountCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.shareLocalFtpAccount = function shareLocalFtpAccount(request, callback) {
            return this.rpcCall(shareLocalFtpAccount, $root.partner.SharedFtpAccountRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "ShareLocalFtpAccount" });

        /**
         * Calls ShareLocalFtpAccount.
         * @function shareLocalFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ISharedFtpAccountRequest} request SharedFtpAccountRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#listSharedFtpAccounts}.
         * @memberof partner.PartnerService
         * @typedef ListSharedFtpAccountsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.LocalFtpAccounts} [response] LocalFtpAccounts
         */

        /**
         * Calls ListSharedFtpAccounts.
         * @function listSharedFtpAccounts
         * @memberof partner.PartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {partner.PartnerService.ListSharedFtpAccountsCallback} callback Node-style callback called with the error, if any, and LocalFtpAccounts
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.listSharedFtpAccounts = function listSharedFtpAccounts(request, callback) {
            return this.rpcCall(listSharedFtpAccounts, $root.base.PartnerRequest, $root.partner.LocalFtpAccounts, request, callback);
        }, "name", { value: "ListSharedFtpAccounts" });

        /**
         * Calls ListSharedFtpAccounts.
         * @function listSharedFtpAccounts
         * @memberof partner.PartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<partner.LocalFtpAccounts>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#unshareSharedFtpAccount}.
         * @memberof partner.PartnerService
         * @typedef UnshareSharedFtpAccountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls UnshareSharedFtpAccount.
         * @function unshareSharedFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ISharedFtpAccountRequest} request SharedFtpAccountRequest message or plain object
         * @param {partner.PartnerService.UnshareSharedFtpAccountCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.unshareSharedFtpAccount = function unshareSharedFtpAccount(request, callback) {
            return this.rpcCall(unshareSharedFtpAccount, $root.partner.SharedFtpAccountRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "UnshareSharedFtpAccount" });

        /**
         * Calls UnshareSharedFtpAccount.
         * @function unshareSharedFtpAccount
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ISharedFtpAccountRequest} request SharedFtpAccountRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#isFtpAccountShared}.
         * @memberof partner.PartnerService
         * @typedef IsFtpAccountSharedCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.IsFtpAccountSharedResponse} [response] IsFtpAccountSharedResponse
         */

        /**
         * Calls IsFtpAccountShared.
         * @function isFtpAccountShared
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ISharedFtpAccountRequest} request SharedFtpAccountRequest message or plain object
         * @param {partner.PartnerService.IsFtpAccountSharedCallback} callback Node-style callback called with the error, if any, and IsFtpAccountSharedResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.isFtpAccountShared = function isFtpAccountShared(request, callback) {
            return this.rpcCall(isFtpAccountShared, $root.partner.SharedFtpAccountRequest, $root.partner.IsFtpAccountSharedResponse, request, callback);
        }, "name", { value: "IsFtpAccountShared" });

        /**
         * Calls IsFtpAccountShared.
         * @function isFtpAccountShared
         * @memberof partner.PartnerService
         * @instance
         * @param {partner.ISharedFtpAccountRequest} request SharedFtpAccountRequest message or plain object
         * @returns {Promise<partner.IsFtpAccountSharedResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#getAllPartnersCore}.
         * @memberof partner.PartnerService
         * @typedef GetAllPartnersCoreCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.PartnersResponse} [response] PartnersResponse
         */

        /**
         * Calls GetAllPartnersCore.
         * @function getAllPartnersCore
         * @memberof partner.PartnerService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {partner.PartnerService.GetAllPartnersCoreCallback} callback Node-style callback called with the error, if any, and PartnersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.getAllPartnersCore = function getAllPartnersCore(request, callback) {
            return this.rpcCall(getAllPartnersCore, $root.google.protobuf.Empty, $root.partner.PartnersResponse, request, callback);
        }, "name", { value: "GetAllPartnersCore" });

        /**
         * Calls GetAllPartnersCore.
         * @function getAllPartnersCore
         * @memberof partner.PartnerService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<partner.PartnersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link partner.PartnerService#getPartnerType}.
         * @memberof partner.PartnerService
         * @typedef GetPartnerTypeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {partner.PartnerTypeResponse} [response] PartnerTypeResponse
         */

        /**
         * Calls GetPartnerType.
         * @function getPartnerType
         * @memberof partner.PartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {partner.PartnerService.GetPartnerTypeCallback} callback Node-style callback called with the error, if any, and PartnerTypeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(PartnerService.prototype.getPartnerType = function getPartnerType(request, callback) {
            return this.rpcCall(getPartnerType, $root.base.PartnerRequest, $root.partner.PartnerTypeResponse, request, callback);
        }, "name", { value: "GetPartnerType" });

        /**
         * Calls GetPartnerType.
         * @function getPartnerType
         * @memberof partner.PartnerService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<partner.PartnerTypeResponse>} Promise
         * @variation 2
         */

        return PartnerService;
    })();

    partner.CreatePartnerExternalFtpAccountRequest = (function() {

        /**
         * Properties of a CreatePartnerExternalFtpAccountRequest.
         * @memberof partner
         * @interface ICreatePartnerExternalFtpAccountRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] CreatePartnerExternalFtpAccountRequest partnerId
         * @property {common.IExternalFtpAccount|null} [externalFtpAccount] CreatePartnerExternalFtpAccountRequest externalFtpAccount
         */

        /**
         * Constructs a new CreatePartnerExternalFtpAccountRequest.
         * @memberof partner
         * @classdesc Represents a CreatePartnerExternalFtpAccountRequest.
         * @implements ICreatePartnerExternalFtpAccountRequest
         * @constructor
         * @param {partner.ICreatePartnerExternalFtpAccountRequest=} [properties] Properties to set
         */
        function CreatePartnerExternalFtpAccountRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreatePartnerExternalFtpAccountRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.CreatePartnerExternalFtpAccountRequest
         * @instance
         */
        CreatePartnerExternalFtpAccountRequest.prototype.partnerId = null;

        /**
         * CreatePartnerExternalFtpAccountRequest externalFtpAccount.
         * @member {common.IExternalFtpAccount|null|undefined} externalFtpAccount
         * @memberof partner.CreatePartnerExternalFtpAccountRequest
         * @instance
         */
        CreatePartnerExternalFtpAccountRequest.prototype.externalFtpAccount = null;

        /**
         * Gets the default type url for CreatePartnerExternalFtpAccountRequest
         * @function getTypeUrl
         * @memberof partner.CreatePartnerExternalFtpAccountRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreatePartnerExternalFtpAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.CreatePartnerExternalFtpAccountRequest";
        };

        return CreatePartnerExternalFtpAccountRequest;
    })();

    partner.SearchPartnersRequest = (function() {

        /**
         * Properties of a SearchPartnersRequest.
         * @memberof partner
         * @interface ISearchPartnersRequest
         * @property {query.IQuery|null} [query] SearchPartnersRequest query
         */

        /**
         * Constructs a new SearchPartnersRequest.
         * @memberof partner
         * @classdesc Represents a SearchPartnersRequest.
         * @implements ISearchPartnersRequest
         * @constructor
         * @param {partner.ISearchPartnersRequest=} [properties] Properties to set
         */
        function SearchPartnersRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPartnersRequest query.
         * @member {query.IQuery|null|undefined} query
         * @memberof partner.SearchPartnersRequest
         * @instance
         */
        SearchPartnersRequest.prototype.query = null;

        /**
         * Gets the default type url for SearchPartnersRequest
         * @function getTypeUrl
         * @memberof partner.SearchPartnersRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SearchPartnersRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.SearchPartnersRequest";
        };

        return SearchPartnersRequest;
    })();

    partner.UpdatePartnerSettingsRequest = (function() {

        /**
         * Properties of an UpdatePartnerSettingsRequest.
         * @memberof partner
         * @interface IUpdatePartnerSettingsRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] UpdatePartnerSettingsRequest partnerId
         * @property {partner.IPartnerSettings|null} [partnerSettings] UpdatePartnerSettingsRequest partnerSettings
         */

        /**
         * Constructs a new UpdatePartnerSettingsRequest.
         * @memberof partner
         * @classdesc Represents an UpdatePartnerSettingsRequest.
         * @implements IUpdatePartnerSettingsRequest
         * @constructor
         * @param {partner.IUpdatePartnerSettingsRequest=} [properties] Properties to set
         */
        function UpdatePartnerSettingsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePartnerSettingsRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.UpdatePartnerSettingsRequest
         * @instance
         */
        UpdatePartnerSettingsRequest.prototype.partnerId = null;

        /**
         * UpdatePartnerSettingsRequest partnerSettings.
         * @member {partner.IPartnerSettings|null|undefined} partnerSettings
         * @memberof partner.UpdatePartnerSettingsRequest
         * @instance
         */
        UpdatePartnerSettingsRequest.prototype.partnerSettings = null;

        /**
         * Gets the default type url for UpdatePartnerSettingsRequest
         * @function getTypeUrl
         * @memberof partner.UpdatePartnerSettingsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdatePartnerSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.UpdatePartnerSettingsRequest";
        };

        return UpdatePartnerSettingsRequest;
    })();

    partner.UpdatePartnerReceiveMessageLocationRequest = (function() {

        /**
         * Properties of an UpdatePartnerReceiveMessageLocationRequest.
         * @memberof partner
         * @interface IUpdatePartnerReceiveMessageLocationRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] UpdatePartnerReceiveMessageLocationRequest partnerId
         * @property {partner.ReceiveMessageLocation|null} [receiveMessageLocation] UpdatePartnerReceiveMessageLocationRequest receiveMessageLocation
         */

        /**
         * Constructs a new UpdatePartnerReceiveMessageLocationRequest.
         * @memberof partner
         * @classdesc Represents an UpdatePartnerReceiveMessageLocationRequest.
         * @implements IUpdatePartnerReceiveMessageLocationRequest
         * @constructor
         * @param {partner.IUpdatePartnerReceiveMessageLocationRequest=} [properties] Properties to set
         */
        function UpdatePartnerReceiveMessageLocationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePartnerReceiveMessageLocationRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.UpdatePartnerReceiveMessageLocationRequest
         * @instance
         */
        UpdatePartnerReceiveMessageLocationRequest.prototype.partnerId = null;

        /**
         * UpdatePartnerReceiveMessageLocationRequest receiveMessageLocation.
         * @member {partner.ReceiveMessageLocation} receiveMessageLocation
         * @memberof partner.UpdatePartnerReceiveMessageLocationRequest
         * @instance
         */
        UpdatePartnerReceiveMessageLocationRequest.prototype.receiveMessageLocation = 0;

        /**
         * Gets the default type url for UpdatePartnerReceiveMessageLocationRequest
         * @function getTypeUrl
         * @memberof partner.UpdatePartnerReceiveMessageLocationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdatePartnerReceiveMessageLocationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.UpdatePartnerReceiveMessageLocationRequest";
        };

        return UpdatePartnerReceiveMessageLocationRequest;
    })();

    partner.UpdatePartnerExternalFtpAccountRequest = (function() {

        /**
         * Properties of an UpdatePartnerExternalFtpAccountRequest.
         * @memberof partner
         * @interface IUpdatePartnerExternalFtpAccountRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] UpdatePartnerExternalFtpAccountRequest partnerId
         * @property {common.IExternalFtpAccount|null} [externalFtpAccount] UpdatePartnerExternalFtpAccountRequest externalFtpAccount
         */

        /**
         * Constructs a new UpdatePartnerExternalFtpAccountRequest.
         * @memberof partner
         * @classdesc Represents an UpdatePartnerExternalFtpAccountRequest.
         * @implements IUpdatePartnerExternalFtpAccountRequest
         * @constructor
         * @param {partner.IUpdatePartnerExternalFtpAccountRequest=} [properties] Properties to set
         */
        function UpdatePartnerExternalFtpAccountRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePartnerExternalFtpAccountRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.UpdatePartnerExternalFtpAccountRequest
         * @instance
         */
        UpdatePartnerExternalFtpAccountRequest.prototype.partnerId = null;

        /**
         * UpdatePartnerExternalFtpAccountRequest externalFtpAccount.
         * @member {common.IExternalFtpAccount|null|undefined} externalFtpAccount
         * @memberof partner.UpdatePartnerExternalFtpAccountRequest
         * @instance
         */
        UpdatePartnerExternalFtpAccountRequest.prototype.externalFtpAccount = null;

        /**
         * Gets the default type url for UpdatePartnerExternalFtpAccountRequest
         * @function getTypeUrl
         * @memberof partner.UpdatePartnerExternalFtpAccountRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdatePartnerExternalFtpAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.UpdatePartnerExternalFtpAccountRequest";
        };

        return UpdatePartnerExternalFtpAccountRequest;
    })();

    partner.UpdatePartnerSdatWebClientSettingsRequest = (function() {

        /**
         * Properties of an UpdatePartnerSdatWebClientSettingsRequest.
         * @memberof partner
         * @interface IUpdatePartnerSdatWebClientSettingsRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] UpdatePartnerSdatWebClientSettingsRequest partnerId
         * @property {partner.ISdatWebClientSettings|null} [sdatWebClientSettings] UpdatePartnerSdatWebClientSettingsRequest sdatWebClientSettings
         */

        /**
         * Constructs a new UpdatePartnerSdatWebClientSettingsRequest.
         * @memberof partner
         * @classdesc Represents an UpdatePartnerSdatWebClientSettingsRequest.
         * @implements IUpdatePartnerSdatWebClientSettingsRequest
         * @constructor
         * @param {partner.IUpdatePartnerSdatWebClientSettingsRequest=} [properties] Properties to set
         */
        function UpdatePartnerSdatWebClientSettingsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePartnerSdatWebClientSettingsRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.UpdatePartnerSdatWebClientSettingsRequest
         * @instance
         */
        UpdatePartnerSdatWebClientSettingsRequest.prototype.partnerId = null;

        /**
         * UpdatePartnerSdatWebClientSettingsRequest sdatWebClientSettings.
         * @member {partner.ISdatWebClientSettings|null|undefined} sdatWebClientSettings
         * @memberof partner.UpdatePartnerSdatWebClientSettingsRequest
         * @instance
         */
        UpdatePartnerSdatWebClientSettingsRequest.prototype.sdatWebClientSettings = null;

        /**
         * Gets the default type url for UpdatePartnerSdatWebClientSettingsRequest
         * @function getTypeUrl
         * @memberof partner.UpdatePartnerSdatWebClientSettingsRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdatePartnerSdatWebClientSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.UpdatePartnerSdatWebClientSettingsRequest";
        };

        return UpdatePartnerSdatWebClientSettingsRequest;
    })();

    partner.DeletePartnerExternalFtpAccountRequest = (function() {

        /**
         * Properties of a DeletePartnerExternalFtpAccountRequest.
         * @memberof partner
         * @interface IDeletePartnerExternalFtpAccountRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] DeletePartnerExternalFtpAccountRequest partnerId
         * @property {google.protobuf.IInt32Value|null} [id] DeletePartnerExternalFtpAccountRequest id
         */

        /**
         * Constructs a new DeletePartnerExternalFtpAccountRequest.
         * @memberof partner
         * @classdesc Represents a DeletePartnerExternalFtpAccountRequest.
         * @implements IDeletePartnerExternalFtpAccountRequest
         * @constructor
         * @param {partner.IDeletePartnerExternalFtpAccountRequest=} [properties] Properties to set
         */
        function DeletePartnerExternalFtpAccountRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeletePartnerExternalFtpAccountRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.DeletePartnerExternalFtpAccountRequest
         * @instance
         */
        DeletePartnerExternalFtpAccountRequest.prototype.partnerId = null;

        /**
         * DeletePartnerExternalFtpAccountRequest id.
         * @member {google.protobuf.IInt32Value|null|undefined} id
         * @memberof partner.DeletePartnerExternalFtpAccountRequest
         * @instance
         */
        DeletePartnerExternalFtpAccountRequest.prototype.id = null;

        /**
         * Gets the default type url for DeletePartnerExternalFtpAccountRequest
         * @function getTypeUrl
         * @memberof partner.DeletePartnerExternalFtpAccountRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeletePartnerExternalFtpAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.DeletePartnerExternalFtpAccountRequest";
        };

        return DeletePartnerExternalFtpAccountRequest;
    })();

    partner.DeletePartnerLocalFtpAccountRequest = (function() {

        /**
         * Properties of a DeletePartnerLocalFtpAccountRequest.
         * @memberof partner
         * @interface IDeletePartnerLocalFtpAccountRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] DeletePartnerLocalFtpAccountRequest partnerId
         * @property {google.protobuf.IStringValue|null} [username] DeletePartnerLocalFtpAccountRequest username
         */

        /**
         * Constructs a new DeletePartnerLocalFtpAccountRequest.
         * @memberof partner
         * @classdesc Represents a DeletePartnerLocalFtpAccountRequest.
         * @implements IDeletePartnerLocalFtpAccountRequest
         * @constructor
         * @param {partner.IDeletePartnerLocalFtpAccountRequest=} [properties] Properties to set
         */
        function DeletePartnerLocalFtpAccountRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeletePartnerLocalFtpAccountRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.DeletePartnerLocalFtpAccountRequest
         * @instance
         */
        DeletePartnerLocalFtpAccountRequest.prototype.partnerId = null;

        /**
         * DeletePartnerLocalFtpAccountRequest username.
         * @member {google.protobuf.IStringValue|null|undefined} username
         * @memberof partner.DeletePartnerLocalFtpAccountRequest
         * @instance
         */
        DeletePartnerLocalFtpAccountRequest.prototype.username = null;

        /**
         * Gets the default type url for DeletePartnerLocalFtpAccountRequest
         * @function getTypeUrl
         * @memberof partner.DeletePartnerLocalFtpAccountRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeletePartnerLocalFtpAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.DeletePartnerLocalFtpAccountRequest";
        };

        return DeletePartnerLocalFtpAccountRequest;
    })();

    partner.UpdatePartnerFeatureRequest = (function() {

        /**
         * Properties of an UpdatePartnerFeatureRequest.
         * @memberof partner
         * @interface IUpdatePartnerFeatureRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] UpdatePartnerFeatureRequest partnerId
         * @property {partner.IUpdateFeatureRequest|null} [features] UpdatePartnerFeatureRequest features
         */

        /**
         * Constructs a new UpdatePartnerFeatureRequest.
         * @memberof partner
         * @classdesc Represents an UpdatePartnerFeatureRequest.
         * @implements IUpdatePartnerFeatureRequest
         * @constructor
         * @param {partner.IUpdatePartnerFeatureRequest=} [properties] Properties to set
         */
        function UpdatePartnerFeatureRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePartnerFeatureRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.UpdatePartnerFeatureRequest
         * @instance
         */
        UpdatePartnerFeatureRequest.prototype.partnerId = null;

        /**
         * UpdatePartnerFeatureRequest features.
         * @member {partner.IUpdateFeatureRequest|null|undefined} features
         * @memberof partner.UpdatePartnerFeatureRequest
         * @instance
         */
        UpdatePartnerFeatureRequest.prototype.features = null;

        /**
         * Gets the default type url for UpdatePartnerFeatureRequest
         * @function getTypeUrl
         * @memberof partner.UpdatePartnerFeatureRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdatePartnerFeatureRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.UpdatePartnerFeatureRequest";
        };

        return UpdatePartnerFeatureRequest;
    })();

    partner.UpdateFeatureRequest = (function() {

        /**
         * Properties of an UpdateFeatureRequest.
         * @memberof partner
         * @interface IUpdateFeatureRequest
         * @property {Array.<partner.Feature>|null} [toAdd] UpdateFeatureRequest toAdd
         * @property {Array.<partner.Feature>|null} [toRemove] UpdateFeatureRequest toRemove
         */

        /**
         * Constructs a new UpdateFeatureRequest.
         * @memberof partner
         * @classdesc Represents an UpdateFeatureRequest.
         * @implements IUpdateFeatureRequest
         * @constructor
         * @param {partner.IUpdateFeatureRequest=} [properties] Properties to set
         */
        function UpdateFeatureRequest(properties) {
            this.toAdd = [];
            this.toRemove = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateFeatureRequest toAdd.
         * @member {Array.<partner.Feature>} toAdd
         * @memberof partner.UpdateFeatureRequest
         * @instance
         */
        UpdateFeatureRequest.prototype.toAdd = $util.emptyArray;

        /**
         * UpdateFeatureRequest toRemove.
         * @member {Array.<partner.Feature>} toRemove
         * @memberof partner.UpdateFeatureRequest
         * @instance
         */
        UpdateFeatureRequest.prototype.toRemove = $util.emptyArray;

        /**
         * Gets the default type url for UpdateFeatureRequest
         * @function getTypeUrl
         * @memberof partner.UpdateFeatureRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateFeatureRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.UpdateFeatureRequest";
        };

        return UpdateFeatureRequest;
    })();

    partner.SharedFtpAccountRequest = (function() {

        /**
         * Properties of a SharedFtpAccountRequest.
         * @memberof partner
         * @interface ISharedFtpAccountRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] SharedFtpAccountRequest partnerId
         * @property {google.protobuf.IStringValue|null} [username] SharedFtpAccountRequest username
         */

        /**
         * Constructs a new SharedFtpAccountRequest.
         * @memberof partner
         * @classdesc Represents a SharedFtpAccountRequest.
         * @implements ISharedFtpAccountRequest
         * @constructor
         * @param {partner.ISharedFtpAccountRequest=} [properties] Properties to set
         */
        function SharedFtpAccountRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SharedFtpAccountRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.SharedFtpAccountRequest
         * @instance
         */
        SharedFtpAccountRequest.prototype.partnerId = null;

        /**
         * SharedFtpAccountRequest username.
         * @member {google.protobuf.IStringValue|null|undefined} username
         * @memberof partner.SharedFtpAccountRequest
         * @instance
         */
        SharedFtpAccountRequest.prototype.username = null;

        /**
         * Gets the default type url for SharedFtpAccountRequest
         * @function getTypeUrl
         * @memberof partner.SharedFtpAccountRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SharedFtpAccountRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.SharedFtpAccountRequest";
        };

        return SharedFtpAccountRequest;
    })();

    partner.PartnersResponse = (function() {

        /**
         * Properties of a PartnersResponse.
         * @memberof partner
         * @interface IPartnersResponse
         * @property {Array.<partner.IPartnerResponse>|null} [partners] PartnersResponse partners
         */

        /**
         * Constructs a new PartnersResponse.
         * @memberof partner
         * @classdesc Represents a PartnersResponse.
         * @implements IPartnersResponse
         * @constructor
         * @param {partner.IPartnersResponse=} [properties] Properties to set
         */
        function PartnersResponse(properties) {
            this.partners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PartnersResponse partners.
         * @member {Array.<partner.IPartnerResponse>} partners
         * @memberof partner.PartnersResponse
         * @instance
         */
        PartnersResponse.prototype.partners = $util.emptyArray;

        /**
         * Gets the default type url for PartnersResponse
         * @function getTypeUrl
         * @memberof partner.PartnersResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PartnersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.PartnersResponse";
        };

        return PartnersResponse;
    })();

    partner.PaginatedPartnersResponse = (function() {

        /**
         * Properties of a PaginatedPartnersResponse.
         * @memberof partner
         * @interface IPaginatedPartnersResponse
         * @property {Array.<partner.IPartnerResponse>|null} [partners] PaginatedPartnersResponse partners
         * @property {query.IPageInfo|null} [pageInfo] PaginatedPartnersResponse pageInfo
         */

        /**
         * Constructs a new PaginatedPartnersResponse.
         * @memberof partner
         * @classdesc Represents a PaginatedPartnersResponse.
         * @implements IPaginatedPartnersResponse
         * @constructor
         * @param {partner.IPaginatedPartnersResponse=} [properties] Properties to set
         */
        function PaginatedPartnersResponse(properties) {
            this.partners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginatedPartnersResponse partners.
         * @member {Array.<partner.IPartnerResponse>} partners
         * @memberof partner.PaginatedPartnersResponse
         * @instance
         */
        PaginatedPartnersResponse.prototype.partners = $util.emptyArray;

        /**
         * PaginatedPartnersResponse pageInfo.
         * @member {query.IPageInfo|null|undefined} pageInfo
         * @memberof partner.PaginatedPartnersResponse
         * @instance
         */
        PaginatedPartnersResponse.prototype.pageInfo = null;

        /**
         * Gets the default type url for PaginatedPartnersResponse
         * @function getTypeUrl
         * @memberof partner.PaginatedPartnersResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaginatedPartnersResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.PaginatedPartnersResponse";
        };

        return PaginatedPartnersResponse;
    })();

    partner.PaginatedPartnersContactDetailsResponse = (function() {

        /**
         * Properties of a PaginatedPartnersContactDetailsResponse.
         * @memberof partner
         * @interface IPaginatedPartnersContactDetailsResponse
         * @property {Array.<partner.IPartnerContactDetailsResponse>|null} [partners] PaginatedPartnersContactDetailsResponse partners
         * @property {query.IPageInfo|null} [pageInfo] PaginatedPartnersContactDetailsResponse pageInfo
         */

        /**
         * Constructs a new PaginatedPartnersContactDetailsResponse.
         * @memberof partner
         * @classdesc Represents a PaginatedPartnersContactDetailsResponse.
         * @implements IPaginatedPartnersContactDetailsResponse
         * @constructor
         * @param {partner.IPaginatedPartnersContactDetailsResponse=} [properties] Properties to set
         */
        function PaginatedPartnersContactDetailsResponse(properties) {
            this.partners = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginatedPartnersContactDetailsResponse partners.
         * @member {Array.<partner.IPartnerContactDetailsResponse>} partners
         * @memberof partner.PaginatedPartnersContactDetailsResponse
         * @instance
         */
        PaginatedPartnersContactDetailsResponse.prototype.partners = $util.emptyArray;

        /**
         * PaginatedPartnersContactDetailsResponse pageInfo.
         * @member {query.IPageInfo|null|undefined} pageInfo
         * @memberof partner.PaginatedPartnersContactDetailsResponse
         * @instance
         */
        PaginatedPartnersContactDetailsResponse.prototype.pageInfo = null;

        /**
         * Gets the default type url for PaginatedPartnersContactDetailsResponse
         * @function getTypeUrl
         * @memberof partner.PaginatedPartnersContactDetailsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaginatedPartnersContactDetailsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.PaginatedPartnersContactDetailsResponse";
        };

        return PaginatedPartnersContactDetailsResponse;
    })();

    partner.PartnerResponse = (function() {

        /**
         * Properties of a PartnerResponse.
         * @memberof partner
         * @interface IPartnerResponse
         * @property {partner.IPartnerSettings|null} [partnerSettings] PartnerResponse partnerSettings
         * @property {partner.ICommunicationSettingsResponse|null} [communicationData] PartnerResponse communicationData
         * @property {partner.ISdatWebClientSettings|null} [sdatWebClientSettings] PartnerResponse sdatWebClientSettings
         * @property {partner.IMeteringPointsDirectorySettings|null} [meteringPointsDirectorySettings] PartnerResponse meteringPointsDirectorySettings
         * @property {Array.<partner.Feature>|null} [availableFeatures] PartnerResponse availableFeatures
         */

        /**
         * Constructs a new PartnerResponse.
         * @memberof partner
         * @classdesc Represents a PartnerResponse.
         * @implements IPartnerResponse
         * @constructor
         * @param {partner.IPartnerResponse=} [properties] Properties to set
         */
        function PartnerResponse(properties) {
            this.availableFeatures = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PartnerResponse partnerSettings.
         * @member {partner.IPartnerSettings|null|undefined} partnerSettings
         * @memberof partner.PartnerResponse
         * @instance
         */
        PartnerResponse.prototype.partnerSettings = null;

        /**
         * PartnerResponse communicationData.
         * @member {partner.ICommunicationSettingsResponse|null|undefined} communicationData
         * @memberof partner.PartnerResponse
         * @instance
         */
        PartnerResponse.prototype.communicationData = null;

        /**
         * PartnerResponse sdatWebClientSettings.
         * @member {partner.ISdatWebClientSettings|null|undefined} sdatWebClientSettings
         * @memberof partner.PartnerResponse
         * @instance
         */
        PartnerResponse.prototype.sdatWebClientSettings = null;

        /**
         * PartnerResponse meteringPointsDirectorySettings.
         * @member {partner.IMeteringPointsDirectorySettings|null|undefined} meteringPointsDirectorySettings
         * @memberof partner.PartnerResponse
         * @instance
         */
        PartnerResponse.prototype.meteringPointsDirectorySettings = null;

        /**
         * PartnerResponse availableFeatures.
         * @member {Array.<partner.Feature>} availableFeatures
         * @memberof partner.PartnerResponse
         * @instance
         */
        PartnerResponse.prototype.availableFeatures = $util.emptyArray;

        /**
         * Gets the default type url for PartnerResponse
         * @function getTypeUrl
         * @memberof partner.PartnerResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PartnerResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.PartnerResponse";
        };

        return PartnerResponse;
    })();

    partner.PartnerCreated = (function() {

        /**
         * Properties of a PartnerCreated.
         * @memberof partner
         * @interface IPartnerCreated
         * @property {google.protobuf.IInt32Value|null} [partnerId] PartnerCreated partnerId
         */

        /**
         * Constructs a new PartnerCreated.
         * @memberof partner
         * @classdesc Represents a PartnerCreated.
         * @implements IPartnerCreated
         * @constructor
         * @param {partner.IPartnerCreated=} [properties] Properties to set
         */
        function PartnerCreated(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PartnerCreated partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.PartnerCreated
         * @instance
         */
        PartnerCreated.prototype.partnerId = null;

        /**
         * Gets the default type url for PartnerCreated
         * @function getTypeUrl
         * @memberof partner.PartnerCreated
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PartnerCreated.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.PartnerCreated";
        };

        return PartnerCreated;
    })();

    partner.PartnerDeleted = (function() {

        /**
         * Properties of a PartnerDeleted.
         * @memberof partner
         * @interface IPartnerDeleted
         * @property {google.protobuf.IInt32Value|null} [partnerId] PartnerDeleted partnerId
         */

        /**
         * Constructs a new PartnerDeleted.
         * @memberof partner
         * @classdesc Represents a PartnerDeleted.
         * @implements IPartnerDeleted
         * @constructor
         * @param {partner.IPartnerDeleted=} [properties] Properties to set
         */
        function PartnerDeleted(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PartnerDeleted partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.PartnerDeleted
         * @instance
         */
        PartnerDeleted.prototype.partnerId = null;

        /**
         * Gets the default type url for PartnerDeleted
         * @function getTypeUrl
         * @memberof partner.PartnerDeleted
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PartnerDeleted.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.PartnerDeleted";
        };

        return PartnerDeleted;
    })();

    partner.PartnerContactDetailsResponse = (function() {

        /**
         * Properties of a PartnerContactDetailsResponse.
         * @memberof partner
         * @interface IPartnerContactDetailsResponse
         * @property {google.protobuf.IInt32Value|null} [partnerId] PartnerContactDetailsResponse partnerId
         * @property {google.protobuf.IStringValue|null} [name] PartnerContactDetailsResponse name
         * @property {google.protobuf.IStringValue|null} [eic] PartnerContactDetailsResponse eic
         * @property {base.Role|null} [role] PartnerContactDetailsResponse role
         * @property {google.protobuf.IStringValue|null} [contactEmail] PartnerContactDetailsResponse contactEmail
         * @property {google.protobuf.IStringValue|null} [contactPhone] PartnerContactDetailsResponse contactPhone
         * @property {partner.MeteringPointsCompleteFlag|null} [meteringPointsCompleteFlag] PartnerContactDetailsResponse meteringPointsCompleteFlag
         */

        /**
         * Constructs a new PartnerContactDetailsResponse.
         * @memberof partner
         * @classdesc Represents a PartnerContactDetailsResponse.
         * @implements IPartnerContactDetailsResponse
         * @constructor
         * @param {partner.IPartnerContactDetailsResponse=} [properties] Properties to set
         */
        function PartnerContactDetailsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PartnerContactDetailsResponse partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.PartnerContactDetailsResponse
         * @instance
         */
        PartnerContactDetailsResponse.prototype.partnerId = null;

        /**
         * PartnerContactDetailsResponse name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof partner.PartnerContactDetailsResponse
         * @instance
         */
        PartnerContactDetailsResponse.prototype.name = null;

        /**
         * PartnerContactDetailsResponse eic.
         * @member {google.protobuf.IStringValue|null|undefined} eic
         * @memberof partner.PartnerContactDetailsResponse
         * @instance
         */
        PartnerContactDetailsResponse.prototype.eic = null;

        /**
         * PartnerContactDetailsResponse role.
         * @member {base.Role} role
         * @memberof partner.PartnerContactDetailsResponse
         * @instance
         */
        PartnerContactDetailsResponse.prototype.role = 0;

        /**
         * PartnerContactDetailsResponse contactEmail.
         * @member {google.protobuf.IStringValue|null|undefined} contactEmail
         * @memberof partner.PartnerContactDetailsResponse
         * @instance
         */
        PartnerContactDetailsResponse.prototype.contactEmail = null;

        /**
         * PartnerContactDetailsResponse contactPhone.
         * @member {google.protobuf.IStringValue|null|undefined} contactPhone
         * @memberof partner.PartnerContactDetailsResponse
         * @instance
         */
        PartnerContactDetailsResponse.prototype.contactPhone = null;

        /**
         * PartnerContactDetailsResponse meteringPointsCompleteFlag.
         * @member {partner.MeteringPointsCompleteFlag} meteringPointsCompleteFlag
         * @memberof partner.PartnerContactDetailsResponse
         * @instance
         */
        PartnerContactDetailsResponse.prototype.meteringPointsCompleteFlag = 0;

        /**
         * Gets the default type url for PartnerContactDetailsResponse
         * @function getTypeUrl
         * @memberof partner.PartnerContactDetailsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PartnerContactDetailsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.PartnerContactDetailsResponse";
        };

        return PartnerContactDetailsResponse;
    })();

    partner.PartnerSettings = (function() {

        /**
         * Properties of a PartnerSettings.
         * @memberof partner
         * @interface IPartnerSettings
         * @property {google.protobuf.IInt32Value|null} [partnerId] PartnerSettings partnerId
         * @property {google.protobuf.IStringValue|null} [eic] PartnerSettings eic
         * @property {base.Role|null} [role] PartnerSettings role
         * @property {google.protobuf.IStringValue|null} [name] PartnerSettings name
         * @property {partner.ContractStatus|null} [contractStatus] PartnerSettings contractStatus
         * @property {google.protobuf.IBoolValue|null} [autoSyntaxCheckForIncomingMessages] PartnerSettings autoSyntaxCheckForIncomingMessages
         * @property {google.protobuf.IStringValue|null} [contactPhone] PartnerSettings contactPhone
         * @property {google.protobuf.IStringValue|null} [contactEmail] PartnerSettings contactEmail
         * @property {google.protobuf.IStringValue|null} [isoLanguageCode] PartnerSettings isoLanguageCode
         */

        /**
         * Constructs a new PartnerSettings.
         * @memberof partner
         * @classdesc Represents a PartnerSettings.
         * @implements IPartnerSettings
         * @constructor
         * @param {partner.IPartnerSettings=} [properties] Properties to set
         */
        function PartnerSettings(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PartnerSettings partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof partner.PartnerSettings
         * @instance
         */
        PartnerSettings.prototype.partnerId = null;

        /**
         * PartnerSettings eic.
         * @member {google.protobuf.IStringValue|null|undefined} eic
         * @memberof partner.PartnerSettings
         * @instance
         */
        PartnerSettings.prototype.eic = null;

        /**
         * PartnerSettings role.
         * @member {base.Role} role
         * @memberof partner.PartnerSettings
         * @instance
         */
        PartnerSettings.prototype.role = 0;

        /**
         * PartnerSettings name.
         * @member {google.protobuf.IStringValue|null|undefined} name
         * @memberof partner.PartnerSettings
         * @instance
         */
        PartnerSettings.prototype.name = null;

        /**
         * PartnerSettings contractStatus.
         * @member {partner.ContractStatus} contractStatus
         * @memberof partner.PartnerSettings
         * @instance
         */
        PartnerSettings.prototype.contractStatus = 0;

        /**
         * PartnerSettings autoSyntaxCheckForIncomingMessages.
         * @member {google.protobuf.IBoolValue|null|undefined} autoSyntaxCheckForIncomingMessages
         * @memberof partner.PartnerSettings
         * @instance
         */
        PartnerSettings.prototype.autoSyntaxCheckForIncomingMessages = null;

        /**
         * PartnerSettings contactPhone.
         * @member {google.protobuf.IStringValue|null|undefined} contactPhone
         * @memberof partner.PartnerSettings
         * @instance
         */
        PartnerSettings.prototype.contactPhone = null;

        /**
         * PartnerSettings contactEmail.
         * @member {google.protobuf.IStringValue|null|undefined} contactEmail
         * @memberof partner.PartnerSettings
         * @instance
         */
        PartnerSettings.prototype.contactEmail = null;

        /**
         * PartnerSettings isoLanguageCode.
         * @member {google.protobuf.IStringValue|null|undefined} isoLanguageCode
         * @memberof partner.PartnerSettings
         * @instance
         */
        PartnerSettings.prototype.isoLanguageCode = null;

        /**
         * Gets the default type url for PartnerSettings
         * @function getTypeUrl
         * @memberof partner.PartnerSettings
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PartnerSettings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.PartnerSettings";
        };

        return PartnerSettings;
    })();

    partner.CommunicationSettingsResponse = (function() {

        /**
         * Properties of a CommunicationSettingsResponse.
         * @memberof partner
         * @interface ICommunicationSettingsResponse
         * @property {partner.ReceiveMessageLocation|null} [receiveMessageLocation] CommunicationSettingsResponse receiveMessageLocation
         * @property {Array.<common.IExternalFtpAccount>|null} [externalFtpAccounts] CommunicationSettingsResponse externalFtpAccounts
         * @property {Array.<partner.ILocalFtpAccount>|null} [localFtpAccounts] CommunicationSettingsResponse localFtpAccounts
         */

        /**
         * Constructs a new CommunicationSettingsResponse.
         * @memberof partner
         * @classdesc Represents a CommunicationSettingsResponse.
         * @implements ICommunicationSettingsResponse
         * @constructor
         * @param {partner.ICommunicationSettingsResponse=} [properties] Properties to set
         */
        function CommunicationSettingsResponse(properties) {
            this.externalFtpAccounts = [];
            this.localFtpAccounts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommunicationSettingsResponse receiveMessageLocation.
         * @member {partner.ReceiveMessageLocation} receiveMessageLocation
         * @memberof partner.CommunicationSettingsResponse
         * @instance
         */
        CommunicationSettingsResponse.prototype.receiveMessageLocation = 0;

        /**
         * CommunicationSettingsResponse externalFtpAccounts.
         * @member {Array.<common.IExternalFtpAccount>} externalFtpAccounts
         * @memberof partner.CommunicationSettingsResponse
         * @instance
         */
        CommunicationSettingsResponse.prototype.externalFtpAccounts = $util.emptyArray;

        /**
         * CommunicationSettingsResponse localFtpAccounts.
         * @member {Array.<partner.ILocalFtpAccount>} localFtpAccounts
         * @memberof partner.CommunicationSettingsResponse
         * @instance
         */
        CommunicationSettingsResponse.prototype.localFtpAccounts = $util.emptyArray;

        /**
         * Gets the default type url for CommunicationSettingsResponse
         * @function getTypeUrl
         * @memberof partner.CommunicationSettingsResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommunicationSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.CommunicationSettingsResponse";
        };

        return CommunicationSettingsResponse;
    })();

    partner.LocalFtpAccount = (function() {

        /**
         * Properties of a LocalFtpAccount.
         * @memberof partner
         * @interface ILocalFtpAccount
         * @property {google.protobuf.IStringValue|null} [username] LocalFtpAccount username
         * @property {google.protobuf.IStringValue|null} [createdBy] LocalFtpAccount createdBy
         */

        /**
         * Constructs a new LocalFtpAccount.
         * @memberof partner
         * @classdesc Represents a LocalFtpAccount.
         * @implements ILocalFtpAccount
         * @constructor
         * @param {partner.ILocalFtpAccount=} [properties] Properties to set
         */
        function LocalFtpAccount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocalFtpAccount username.
         * @member {google.protobuf.IStringValue|null|undefined} username
         * @memberof partner.LocalFtpAccount
         * @instance
         */
        LocalFtpAccount.prototype.username = null;

        /**
         * LocalFtpAccount createdBy.
         * @member {google.protobuf.IStringValue|null|undefined} createdBy
         * @memberof partner.LocalFtpAccount
         * @instance
         */
        LocalFtpAccount.prototype.createdBy = null;

        /**
         * Gets the default type url for LocalFtpAccount
         * @function getTypeUrl
         * @memberof partner.LocalFtpAccount
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocalFtpAccount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.LocalFtpAccount";
        };

        return LocalFtpAccount;
    })();

    partner.LocalFtpAccounts = (function() {

        /**
         * Properties of a LocalFtpAccounts.
         * @memberof partner
         * @interface ILocalFtpAccounts
         * @property {Array.<partner.ILocalFtpAccount>|null} [localFtpAccounts] LocalFtpAccounts localFtpAccounts
         */

        /**
         * Constructs a new LocalFtpAccounts.
         * @memberof partner
         * @classdesc Represents a LocalFtpAccounts.
         * @implements ILocalFtpAccounts
         * @constructor
         * @param {partner.ILocalFtpAccounts=} [properties] Properties to set
         */
        function LocalFtpAccounts(properties) {
            this.localFtpAccounts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LocalFtpAccounts localFtpAccounts.
         * @member {Array.<partner.ILocalFtpAccount>} localFtpAccounts
         * @memberof partner.LocalFtpAccounts
         * @instance
         */
        LocalFtpAccounts.prototype.localFtpAccounts = $util.emptyArray;

        /**
         * Gets the default type url for LocalFtpAccounts
         * @function getTypeUrl
         * @memberof partner.LocalFtpAccounts
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LocalFtpAccounts.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.LocalFtpAccounts";
        };

        return LocalFtpAccounts;
    })();

    partner.NewlyCreatedLocalFtpAccount = (function() {

        /**
         * Properties of a NewlyCreatedLocalFtpAccount.
         * @memberof partner
         * @interface INewlyCreatedLocalFtpAccount
         * @property {google.protobuf.IStringValue|null} [username] NewlyCreatedLocalFtpAccount username
         * @property {google.protobuf.IStringValue|null} [password] NewlyCreatedLocalFtpAccount password
         * @property {google.protobuf.IStringValue|null} [createdBy] NewlyCreatedLocalFtpAccount createdBy
         */

        /**
         * Constructs a new NewlyCreatedLocalFtpAccount.
         * @memberof partner
         * @classdesc Represents a NewlyCreatedLocalFtpAccount.
         * @implements INewlyCreatedLocalFtpAccount
         * @constructor
         * @param {partner.INewlyCreatedLocalFtpAccount=} [properties] Properties to set
         */
        function NewlyCreatedLocalFtpAccount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NewlyCreatedLocalFtpAccount username.
         * @member {google.protobuf.IStringValue|null|undefined} username
         * @memberof partner.NewlyCreatedLocalFtpAccount
         * @instance
         */
        NewlyCreatedLocalFtpAccount.prototype.username = null;

        /**
         * NewlyCreatedLocalFtpAccount password.
         * @member {google.protobuf.IStringValue|null|undefined} password
         * @memberof partner.NewlyCreatedLocalFtpAccount
         * @instance
         */
        NewlyCreatedLocalFtpAccount.prototype.password = null;

        /**
         * NewlyCreatedLocalFtpAccount createdBy.
         * @member {google.protobuf.IStringValue|null|undefined} createdBy
         * @memberof partner.NewlyCreatedLocalFtpAccount
         * @instance
         */
        NewlyCreatedLocalFtpAccount.prototype.createdBy = null;

        /**
         * Gets the default type url for NewlyCreatedLocalFtpAccount
         * @function getTypeUrl
         * @memberof partner.NewlyCreatedLocalFtpAccount
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NewlyCreatedLocalFtpAccount.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.NewlyCreatedLocalFtpAccount";
        };

        return NewlyCreatedLocalFtpAccount;
    })();

    partner.SdatWebClientSettings = (function() {

        /**
         * Properties of a SdatWebClientSettings.
         * @memberof partner
         * @interface ISdatWebClientSettings
         * @property {Array.<sdat.BusinessReason>|null} [changeProcessWebOverride] SdatWebClientSettings changeProcessWebOverride
         */

        /**
         * Constructs a new SdatWebClientSettings.
         * @memberof partner
         * @classdesc Represents a SdatWebClientSettings.
         * @implements ISdatWebClientSettings
         * @constructor
         * @param {partner.ISdatWebClientSettings=} [properties] Properties to set
         */
        function SdatWebClientSettings(properties) {
            this.changeProcessWebOverride = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SdatWebClientSettings changeProcessWebOverride.
         * @member {Array.<sdat.BusinessReason>} changeProcessWebOverride
         * @memberof partner.SdatWebClientSettings
         * @instance
         */
        SdatWebClientSettings.prototype.changeProcessWebOverride = $util.emptyArray;

        /**
         * Gets the default type url for SdatWebClientSettings
         * @function getTypeUrl
         * @memberof partner.SdatWebClientSettings
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SdatWebClientSettings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.SdatWebClientSettings";
        };

        return SdatWebClientSettings;
    })();

    /**
     * ReceiveMessageLocation enum.
     * @name partner.ReceiveMessageLocation
     * @enum {number}
     * @property {number} RECEIVE_MESSAGE_LOCATION_UNSPECIFIED=0 RECEIVE_MESSAGE_LOCATION_UNSPECIFIED value
     * @property {number} LOCAL=1 LOCAL value
     * @property {number} EXTERNAL=2 EXTERNAL value
     */
    partner.ReceiveMessageLocation = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RECEIVE_MESSAGE_LOCATION_UNSPECIFIED"] = 0;
        values[valuesById[1] = "LOCAL"] = 1;
        values[valuesById[2] = "EXTERNAL"] = 2;
        return values;
    })();

    /**
     * ContractStatus enum.
     * @name partner.ContractStatus
     * @enum {number}
     * @property {number} CONTRACT_STATUS_UNSPECIFIED=0 CONTRACT_STATUS_UNSPECIFIED value
     * @property {number} NO_CONTRACT=1 NO_CONTRACT value
     * @property {number} PARTNER_CONTRACT=2 PARTNER_CONTRACT value
     */
    partner.ContractStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "CONTRACT_STATUS_UNSPECIFIED"] = 0;
        values[valuesById[1] = "NO_CONTRACT"] = 1;
        values[valuesById[2] = "PARTNER_CONTRACT"] = 2;
        return values;
    })();

    partner.MeteringPointsDirectorySettings = (function() {

        /**
         * Properties of a MeteringPointsDirectorySettings.
         * @memberof partner
         * @interface IMeteringPointsDirectorySettings
         * @property {partner.MeteringPointsCompleteFlag|null} [meteringPointsCompleteFlag] MeteringPointsDirectorySettings meteringPointsCompleteFlag
         * @property {google.protobuf.IBoolValue|null} [useChangeProcessShortcuts] MeteringPointsDirectorySettings useChangeProcessShortcuts
         */

        /**
         * Constructs a new MeteringPointsDirectorySettings.
         * @memberof partner
         * @classdesc Represents a MeteringPointsDirectorySettings.
         * @implements IMeteringPointsDirectorySettings
         * @constructor
         * @param {partner.IMeteringPointsDirectorySettings=} [properties] Properties to set
         */
        function MeteringPointsDirectorySettings(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeteringPointsDirectorySettings meteringPointsCompleteFlag.
         * @member {partner.MeteringPointsCompleteFlag} meteringPointsCompleteFlag
         * @memberof partner.MeteringPointsDirectorySettings
         * @instance
         */
        MeteringPointsDirectorySettings.prototype.meteringPointsCompleteFlag = 0;

        /**
         * MeteringPointsDirectorySettings useChangeProcessShortcuts.
         * @member {google.protobuf.IBoolValue|null|undefined} useChangeProcessShortcuts
         * @memberof partner.MeteringPointsDirectorySettings
         * @instance
         */
        MeteringPointsDirectorySettings.prototype.useChangeProcessShortcuts = null;

        /**
         * Gets the default type url for MeteringPointsDirectorySettings
         * @function getTypeUrl
         * @memberof partner.MeteringPointsDirectorySettings
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeteringPointsDirectorySettings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.MeteringPointsDirectorySettings";
        };

        return MeteringPointsDirectorySettings;
    })();

    /**
     * MeteringPointsCompleteFlag enum.
     * @name partner.MeteringPointsCompleteFlag
     * @enum {number}
     * @property {number} METERING_POINTS_COMPLETE_FLAG_UNSPECIFIED=0 METERING_POINTS_COMPLETE_FLAG_UNSPECIFIED value
     * @property {number} COMPLETE=1 COMPLETE value
     * @property {number} INCOMPLETE=2 INCOMPLETE value
     */
    partner.MeteringPointsCompleteFlag = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "METERING_POINTS_COMPLETE_FLAG_UNSPECIFIED"] = 0;
        values[valuesById[1] = "COMPLETE"] = 1;
        values[valuesById[2] = "INCOMPLETE"] = 2;
        return values;
    })();

    partner.FeatureList = (function() {

        /**
         * Properties of a FeatureList.
         * @memberof partner
         * @interface IFeatureList
         * @property {Array.<partner.Feature>|null} [features] FeatureList features
         */

        /**
         * Constructs a new FeatureList.
         * @memberof partner
         * @classdesc Represents a FeatureList.
         * @implements IFeatureList
         * @constructor
         * @param {partner.IFeatureList=} [properties] Properties to set
         */
        function FeatureList(properties) {
            this.features = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FeatureList features.
         * @member {Array.<partner.Feature>} features
         * @memberof partner.FeatureList
         * @instance
         */
        FeatureList.prototype.features = $util.emptyArray;

        /**
         * Gets the default type url for FeatureList
         * @function getTypeUrl
         * @memberof partner.FeatureList
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FeatureList.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.FeatureList";
        };

        return FeatureList;
    })();

    /**
     * Feature enum.
     * @name partner.Feature
     * @enum {number}
     * @property {number} FEATURE_UNSPECIFIED=0 FEATURE_UNSPECIFIED value
     * @property {number} UNUSED_02=1 UNUSED_02 value
     * @property {number} UNUSED_01=2 UNUSED_01 value
     * @property {number} UNUSED_03=3 UNUSED_03 value
     * @property {number} OSTRAL=4 OSTRAL value
     */
    partner.Feature = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "FEATURE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "UNUSED_02"] = 1;
        values[valuesById[2] = "UNUSED_01"] = 2;
        values[valuesById[3] = "UNUSED_03"] = 3;
        values[valuesById[4] = "OSTRAL"] = 4;
        return values;
    })();

    partner.IsFtpAccountSharedResponse = (function() {

        /**
         * Properties of an IsFtpAccountSharedResponse.
         * @memberof partner
         * @interface IIsFtpAccountSharedResponse
         * @property {Array.<google.protobuf.IStringValue>|null} [partnerNames] IsFtpAccountSharedResponse partnerNames
         */

        /**
         * Constructs a new IsFtpAccountSharedResponse.
         * @memberof partner
         * @classdesc Represents an IsFtpAccountSharedResponse.
         * @implements IIsFtpAccountSharedResponse
         * @constructor
         * @param {partner.IIsFtpAccountSharedResponse=} [properties] Properties to set
         */
        function IsFtpAccountSharedResponse(properties) {
            this.partnerNames = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IsFtpAccountSharedResponse partnerNames.
         * @member {Array.<google.protobuf.IStringValue>} partnerNames
         * @memberof partner.IsFtpAccountSharedResponse
         * @instance
         */
        IsFtpAccountSharedResponse.prototype.partnerNames = $util.emptyArray;

        /**
         * Gets the default type url for IsFtpAccountSharedResponse
         * @function getTypeUrl
         * @memberof partner.IsFtpAccountSharedResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IsFtpAccountSharedResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.IsFtpAccountSharedResponse";
        };

        return IsFtpAccountSharedResponse;
    })();

    /**
     * PartnerType enum.
     * @name partner.PartnerType
     * @enum {number}
     * @property {number} PARTNER_TYPE_UNSPECIFIED=0 PARTNER_TYPE_UNSPECIFIED value
     * @property {number} MARKETPARTNER=1 MARKETPARTNER value
     * @property {number} COMPANYPARTNER=2 COMPANYPARTNER value
     */
    partner.PartnerType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PARTNER_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "MARKETPARTNER"] = 1;
        values[valuesById[2] = "COMPANYPARTNER"] = 2;
        return values;
    })();

    partner.PartnerTypeResponse = (function() {

        /**
         * Properties of a PartnerTypeResponse.
         * @memberof partner
         * @interface IPartnerTypeResponse
         * @property {partner.PartnerType|null} [partnerType] PartnerTypeResponse partnerType
         */

        /**
         * Constructs a new PartnerTypeResponse.
         * @memberof partner
         * @classdesc Represents a PartnerTypeResponse.
         * @implements IPartnerTypeResponse
         * @constructor
         * @param {partner.IPartnerTypeResponse=} [properties] Properties to set
         */
        function PartnerTypeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PartnerTypeResponse partnerType.
         * @member {partner.PartnerType} partnerType
         * @memberof partner.PartnerTypeResponse
         * @instance
         */
        PartnerTypeResponse.prototype.partnerType = 0;

        /**
         * Gets the default type url for PartnerTypeResponse
         * @function getTypeUrl
         * @memberof partner.PartnerTypeResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PartnerTypeResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/partner.PartnerTypeResponse";
        };

        return PartnerTypeResponse;
    })();

    return partner;
})();

export const processingcore = $root.processingcore = (() => {

    /**
     * Namespace processingcore.
     * @exports processingcore
     * @namespace
     */
    const processingcore = {};

    processingcore.ProcessingCoreService = (function() {

        /**
         * Constructs a new ProcessingCoreService service.
         * @memberof processingcore
         * @classdesc Represents a ProcessingCoreService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ProcessingCoreService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ProcessingCoreService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProcessingCoreService;

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#requestSupplierChange}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef RequestSupplierChangeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls RequestSupplierChange.
         * @function requestSupplierChange
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplierChangeRequest} request SupplierChangeRequest message or plain object
         * @param {processingcore.ProcessingCoreService.RequestSupplierChangeCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.requestSupplierChange = function requestSupplierChange(request, callback) {
            return this.rpcCall(requestSupplierChange, $root.processingcore.SupplierChangeRequest, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "RequestSupplierChange" });

        /**
         * Calls RequestSupplierChange.
         * @function requestSupplierChange
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplierChangeRequest} request SupplierChangeRequest message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#requestSupplierEnd}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef RequestSupplierEndCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls RequestSupplierEnd.
         * @function requestSupplierEnd
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplierEndRequest} request SupplierEndRequest message or plain object
         * @param {processingcore.ProcessingCoreService.RequestSupplierEndCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.requestSupplierEnd = function requestSupplierEnd(request, callback) {
            return this.rpcCall(requestSupplierEnd, $root.processingcore.SupplierEndRequest, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "RequestSupplierEnd" });

        /**
         * Calls RequestSupplierEnd.
         * @function requestSupplierEnd
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplierEndRequest} request SupplierEndRequest message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#requestConsumerChange}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef RequestConsumerChangeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls RequestConsumerChange.
         * @function requestConsumerChange
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IConsumerChangeRequest} request ConsumerChangeRequest message or plain object
         * @param {processingcore.ProcessingCoreService.RequestConsumerChangeCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.requestConsumerChange = function requestConsumerChange(request, callback) {
            return this.rpcCall(requestConsumerChange, $root.processingcore.ConsumerChangeRequest, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "RequestConsumerChange" });

        /**
         * Calls RequestConsumerChange.
         * @function requestConsumerChange
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IConsumerChangeRequest} request ConsumerChangeRequest message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#requestConsumerEnd}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef RequestConsumerEndCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls RequestConsumerEnd.
         * @function requestConsumerEnd
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IConsumerEndRequest} request ConsumerEndRequest message or plain object
         * @param {processingcore.ProcessingCoreService.RequestConsumerEndCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.requestConsumerEnd = function requestConsumerEnd(request, callback) {
            return this.rpcCall(requestConsumerEnd, $root.processingcore.ConsumerEndRequest, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "RequestConsumerEnd" });

        /**
         * Calls RequestConsumerEnd.
         * @function requestConsumerEnd
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IConsumerEndRequest} request ConsumerEndRequest message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#changeConsumerMasterData}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef ChangeConsumerMasterDataCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls ChangeConsumerMasterData.
         * @function changeConsumerMasterData
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IConsumerMasterDataChange} request ConsumerMasterDataChange message or plain object
         * @param {processingcore.ProcessingCoreService.ChangeConsumerMasterDataCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.changeConsumerMasterData = function changeConsumerMasterData(request, callback) {
            return this.rpcCall(changeConsumerMasterData, $root.processingcore.ConsumerMasterDataChange, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "ChangeConsumerMasterData" });

        /**
         * Calls ChangeConsumerMasterData.
         * @function changeConsumerMasterData
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IConsumerMasterDataChange} request ConsumerMasterDataChange message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#registerASP}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef RegisterASPCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls RegisterASP.
         * @function registerASP
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IASPRequest} request ASPRequest message or plain object
         * @param {processingcore.ProcessingCoreService.RegisterASPCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.registerASP = function registerASP(request, callback) {
            return this.rpcCall(registerASP, $root.processingcore.ASPRequest, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "RegisterASP" });

        /**
         * Calls RegisterASP.
         * @function registerASP
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IASPRequest} request ASPRequest message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#deregisterASP}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef DeregisterASPCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls DeregisterASP.
         * @function deregisterASP
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IASPRequest} request ASPRequest message or plain object
         * @param {processingcore.ProcessingCoreService.DeregisterASPCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.deregisterASP = function deregisterASP(request, callback) {
            return this.rpcCall(deregisterASP, $root.processingcore.ASPRequest, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "DeregisterASP" });

        /**
         * Calls DeregisterASP.
         * @function deregisterASP
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IASPRequest} request ASPRequest message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#registerBaseSupply}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef RegisterBaseSupplyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls RegisterBaseSupply.
         * @function registerBaseSupply
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplyRegistration} request SupplyRegistration message or plain object
         * @param {processingcore.ProcessingCoreService.RegisterBaseSupplyCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.registerBaseSupply = function registerBaseSupply(request, callback) {
            return this.rpcCall(registerBaseSupply, $root.processingcore.SupplyRegistration, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "RegisterBaseSupply" });

        /**
         * Calls RegisterBaseSupply.
         * @function registerBaseSupply
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplyRegistration} request SupplyRegistration message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#registerSubstituteSupply}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef RegisterSubstituteSupplyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls RegisterSubstituteSupply.
         * @function registerSubstituteSupply
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplyRegistration} request SupplyRegistration message or plain object
         * @param {processingcore.ProcessingCoreService.RegisterSubstituteSupplyCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.registerSubstituteSupply = function registerSubstituteSupply(request, callback) {
            return this.rpcCall(registerSubstituteSupply, $root.processingcore.SupplyRegistration, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "RegisterSubstituteSupply" });

        /**
         * Calls RegisterSubstituteSupply.
         * @function registerSubstituteSupply
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplyRegistration} request SupplyRegistration message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#changeMPMasterData}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef ChangeMPMasterDataCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls ChangeMPMasterData.
         * @function changeMPMasterData
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IMPMasterDataChange} request MPMasterDataChange message or plain object
         * @param {processingcore.ProcessingCoreService.ChangeMPMasterDataCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.changeMPMasterData = function changeMPMasterData(request, callback) {
            return this.rpcCall(changeMPMasterData, $root.processingcore.MPMasterDataChange, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "ChangeMPMasterData" });

        /**
         * Calls ChangeMPMasterData.
         * @function changeMPMasterData
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IMPMasterDataChange} request MPMasterDataChange message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#queryMPInfo}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef QueryMPInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls QueryMPInfo.
         * @function queryMPInfo
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IMPInfoQuery} request MPInfoQuery message or plain object
         * @param {processingcore.ProcessingCoreService.QueryMPInfoCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.queryMPInfo = function queryMPInfo(request, callback) {
            return this.rpcCall(queryMPInfo, $root.processingcore.MPInfoQuery, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "QueryMPInfo" });

        /**
         * Calls QueryMPInfo.
         * @function queryMPInfo
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IMPInfoQuery} request MPInfoQuery message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#querySwitchInfo}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef QuerySwitchInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls QuerySwitchInfo.
         * @function querySwitchInfo
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISwitchInfoQuery} request SwitchInfoQuery message or plain object
         * @param {processingcore.ProcessingCoreService.QuerySwitchInfoCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.querySwitchInfo = function querySwitchInfo(request, callback) {
            return this.rpcCall(querySwitchInfo, $root.processingcore.SwitchInfoQuery, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "QuerySwitchInfo" });

        /**
         * Calls QuerySwitchInfo.
         * @function querySwitchInfo
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISwitchInfoQuery} request SwitchInfoQuery message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#queryMeasurements}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef QueryMeasurementsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls QueryMeasurements.
         * @function queryMeasurements
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IMeasurementsQuery} request MeasurementsQuery message or plain object
         * @param {processingcore.ProcessingCoreService.QueryMeasurementsCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.queryMeasurements = function queryMeasurements(request, callback) {
            return this.rpcCall(queryMeasurements, $root.processingcore.MeasurementsQuery, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "QueryMeasurements" });

        /**
         * Calls QueryMeasurements.
         * @function queryMeasurements
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IMeasurementsQuery} request MeasurementsQuery message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#queryMeasurementAggregates}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef QueryMeasurementAggregatesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls QueryMeasurementAggregates.
         * @function queryMeasurementAggregates
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IMeasurementAggregatesQuery} request MeasurementAggregatesQuery message or plain object
         * @param {processingcore.ProcessingCoreService.QueryMeasurementAggregatesCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.queryMeasurementAggregates = function queryMeasurementAggregates(request, callback) {
            return this.rpcCall(queryMeasurementAggregates, $root.processingcore.MeasurementAggregatesQuery, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "QueryMeasurementAggregates" });

        /**
         * Calls QueryMeasurementAggregates.
         * @function queryMeasurementAggregates
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IMeasurementAggregatesQuery} request MeasurementAggregatesQuery message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#requestProcessCancellation}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef RequestProcessCancellationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls RequestProcessCancellation.
         * @function requestProcessCancellation
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IProcessCancellationRequest} request ProcessCancellationRequest message or plain object
         * @param {processingcore.ProcessingCoreService.RequestProcessCancellationCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.requestProcessCancellation = function requestProcessCancellation(request, callback) {
            return this.rpcCall(requestProcessCancellation, $root.processingcore.ProcessCancellationRequest, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "RequestProcessCancellation" });

        /**
         * Calls RequestProcessCancellation.
         * @function requestProcessCancellation
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IProcessCancellationRequest} request ProcessCancellationRequest message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#confirmSupplierChange}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef ConfirmSupplierChangeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls ConfirmSupplierChange.
         * @function confirmSupplierChange
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplierChangeConfirmation} request SupplierChangeConfirmation message or plain object
         * @param {processingcore.ProcessingCoreService.ConfirmSupplierChangeCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.confirmSupplierChange = function confirmSupplierChange(request, callback) {
            return this.rpcCall(confirmSupplierChange, $root.processingcore.SupplierChangeConfirmation, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "ConfirmSupplierChange" });

        /**
         * Calls ConfirmSupplierChange.
         * @function confirmSupplierChange
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplierChangeConfirmation} request SupplierChangeConfirmation message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#confirmSupplierEnd}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef ConfirmSupplierEndCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls ConfirmSupplierEnd.
         * @function confirmSupplierEnd
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplierEndConfirmation} request SupplierEndConfirmation message or plain object
         * @param {processingcore.ProcessingCoreService.ConfirmSupplierEndCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.confirmSupplierEnd = function confirmSupplierEnd(request, callback) {
            return this.rpcCall(confirmSupplierEnd, $root.processingcore.SupplierEndConfirmation, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "ConfirmSupplierEnd" });

        /**
         * Calls ConfirmSupplierEnd.
         * @function confirmSupplierEnd
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISupplierEndConfirmation} request SupplierEndConfirmation message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#confirmConsumerChange}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef ConfirmConsumerChangeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls ConfirmConsumerChange.
         * @function confirmConsumerChange
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IConsumerChangeConfirmation} request ConsumerChangeConfirmation message or plain object
         * @param {processingcore.ProcessingCoreService.ConfirmConsumerChangeCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.confirmConsumerChange = function confirmConsumerChange(request, callback) {
            return this.rpcCall(confirmConsumerChange, $root.processingcore.ConsumerChangeConfirmation, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "ConfirmConsumerChange" });

        /**
         * Calls ConfirmConsumerChange.
         * @function confirmConsumerChange
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IConsumerChangeConfirmation} request ConsumerChangeConfirmation message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#confirmConsumerEnd}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef ConfirmConsumerEndCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls ConfirmConsumerEnd.
         * @function confirmConsumerEnd
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IConsumerEndConfirmation} request ConsumerEndConfirmation message or plain object
         * @param {processingcore.ProcessingCoreService.ConfirmConsumerEndCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.confirmConsumerEnd = function confirmConsumerEnd(request, callback) {
            return this.rpcCall(confirmConsumerEnd, $root.processingcore.ConsumerEndConfirmation, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "ConfirmConsumerEnd" });

        /**
         * Calls ConfirmConsumerEnd.
         * @function confirmConsumerEnd
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IConsumerEndConfirmation} request ConsumerEndConfirmation message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#confirmASPStart}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef ConfirmASPStartCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls ConfirmASPStart.
         * @function confirmASPStart
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IASPStartConfirmation} request ASPStartConfirmation message or plain object
         * @param {processingcore.ProcessingCoreService.ConfirmASPStartCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.confirmASPStart = function confirmASPStart(request, callback) {
            return this.rpcCall(confirmASPStart, $root.processingcore.ASPStartConfirmation, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "ConfirmASPStart" });

        /**
         * Calls ConfirmASPStart.
         * @function confirmASPStart
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IASPStartConfirmation} request ASPStartConfirmation message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#confirmASPEnd}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef ConfirmASPEndCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls ConfirmASPEnd.
         * @function confirmASPEnd
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IASPEndConfirmation} request ASPEndConfirmation message or plain object
         * @param {processingcore.ProcessingCoreService.ConfirmASPEndCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.confirmASPEnd = function confirmASPEnd(request, callback) {
            return this.rpcCall(confirmASPEnd, $root.processingcore.ASPEndConfirmation, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "ConfirmASPEnd" });

        /**
         * Calls ConfirmASPEnd.
         * @function confirmASPEnd
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IASPEndConfirmation} request ASPEndConfirmation message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#answerMPInfoQuery}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef AnswerMPInfoQueryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls AnswerMPInfoQuery.
         * @function answerMPInfoQuery
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IMPInfoResponse} request MPInfoResponse message or plain object
         * @param {processingcore.ProcessingCoreService.AnswerMPInfoQueryCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.answerMPInfoQuery = function answerMPInfoQuery(request, callback) {
            return this.rpcCall(answerMPInfoQuery, $root.processingcore.MPInfoResponse, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "AnswerMPInfoQuery" });

        /**
         * Calls AnswerMPInfoQuery.
         * @function answerMPInfoQuery
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IMPInfoResponse} request MPInfoResponse message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#answerSwitchInfoQuery}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef AnswerSwitchInfoQueryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls AnswerSwitchInfoQuery.
         * @function answerSwitchInfoQuery
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISwitchInfoResponse} request SwitchInfoResponse message or plain object
         * @param {processingcore.ProcessingCoreService.AnswerSwitchInfoQueryCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.answerSwitchInfoQuery = function answerSwitchInfoQuery(request, callback) {
            return this.rpcCall(answerSwitchInfoQuery, $root.processingcore.SwitchInfoResponse, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "AnswerSwitchInfoQuery" });

        /**
         * Calls AnswerSwitchInfoQuery.
         * @function answerSwitchInfoQuery
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.ISwitchInfoResponse} request SwitchInfoResponse message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#confirmProcessCancellation}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef ConfirmProcessCancellationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls ConfirmProcessCancellation.
         * @function confirmProcessCancellation
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IProcessCancellationConfirmation} request ProcessCancellationConfirmation message or plain object
         * @param {processingcore.ProcessingCoreService.ConfirmProcessCancellationCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.confirmProcessCancellation = function confirmProcessCancellation(request, callback) {
            return this.rpcCall(confirmProcessCancellation, $root.processingcore.ProcessCancellationConfirmation, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "ConfirmProcessCancellation" });

        /**
         * Calls ConfirmProcessCancellation.
         * @function confirmProcessCancellation
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IProcessCancellationConfirmation} request ProcessCancellationConfirmation message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#rejectRequest}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef RejectRequestCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls RejectRequest.
         * @function rejectRequest
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IRequestRejection} request RequestRejection message or plain object
         * @param {processingcore.ProcessingCoreService.RejectRequestCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.rejectRequest = function rejectRequest(request, callback) {
            return this.rpcCall(rejectRequest, $root.processingcore.RequestRejection, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "RejectRequest" });

        /**
         * Calls RejectRequest.
         * @function rejectRequest
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IRequestRejection} request RequestRejection message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#cancelDocument}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef CancelDocumentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ProcessResult} [response] ProcessResult
         */

        /**
         * Calls CancelDocument.
         * @function cancelDocument
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IDocumentCancellation} request DocumentCancellation message or plain object
         * @param {processingcore.ProcessingCoreService.CancelDocumentCallback} callback Node-style callback called with the error, if any, and ProcessResult
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.cancelDocument = function cancelDocument(request, callback) {
            return this.rpcCall(cancelDocument, $root.processingcore.DocumentCancellation, $root.processingcore.ProcessResult, request, callback);
        }, "name", { value: "CancelDocument" });

        /**
         * Calls CancelDocument.
         * @function cancelDocument
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IDocumentCancellation} request DocumentCancellation message or plain object
         * @returns {Promise<processingcore.ProcessResult>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link processingcore.ProcessingCoreService#retrievePreviousConfirmation}.
         * @memberof processingcore.ProcessingCoreService
         * @typedef RetrievePreviousConfirmationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {processingcore.ConfirmationRequest} [response] ConfirmationRequest
         */

        /**
         * Calls RetrievePreviousConfirmation.
         * @function retrievePreviousConfirmation
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IPreviousConfirmation} request PreviousConfirmation message or plain object
         * @param {processingcore.ProcessingCoreService.RetrievePreviousConfirmationCallback} callback Node-style callback called with the error, if any, and ConfirmationRequest
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProcessingCoreService.prototype.retrievePreviousConfirmation = function retrievePreviousConfirmation(request, callback) {
            return this.rpcCall(retrievePreviousConfirmation, $root.processingcore.PreviousConfirmation, $root.processingcore.ConfirmationRequest, request, callback);
        }, "name", { value: "RetrievePreviousConfirmation" });

        /**
         * Calls RetrievePreviousConfirmation.
         * @function retrievePreviousConfirmation
         * @memberof processingcore.ProcessingCoreService
         * @instance
         * @param {processingcore.IPreviousConfirmation} request PreviousConfirmation message or plain object
         * @returns {Promise<processingcore.ConfirmationRequest>} Promise
         * @variation 2
         */

        return ProcessingCoreService;
    })();

    processingcore.ProcessResult = (function() {

        /**
         * Properties of a ProcessResult.
         * @memberof processingcore
         * @interface IProcessResult
         * @property {base.Status|null} [status] ProcessResult status
         * @property {google.protobuf.IStringValue|null} [processId] ProcessResult processId
         * @property {base.ILocalizedMessage|null} [errorMessage] ProcessResult errorMessage
         * @property {base.IDownloadFileResponse|null} [messageForNotRegisteredRecipient] ProcessResult messageForNotRegisteredRecipient
         * @property {processingcore.ProcessType|null} [executedAs] ProcessResult executedAs
         */

        /**
         * Constructs a new ProcessResult.
         * @memberof processingcore
         * @classdesc Represents a ProcessResult.
         * @implements IProcessResult
         * @constructor
         * @param {processingcore.IProcessResult=} [properties] Properties to set
         */
        function ProcessResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProcessResult status.
         * @member {base.Status} status
         * @memberof processingcore.ProcessResult
         * @instance
         */
        ProcessResult.prototype.status = 0;

        /**
         * ProcessResult processId.
         * @member {google.protobuf.IStringValue|null|undefined} processId
         * @memberof processingcore.ProcessResult
         * @instance
         */
        ProcessResult.prototype.processId = null;

        /**
         * ProcessResult errorMessage.
         * @member {base.ILocalizedMessage|null|undefined} errorMessage
         * @memberof processingcore.ProcessResult
         * @instance
         */
        ProcessResult.prototype.errorMessage = null;

        /**
         * ProcessResult messageForNotRegisteredRecipient.
         * @member {base.IDownloadFileResponse|null|undefined} messageForNotRegisteredRecipient
         * @memberof processingcore.ProcessResult
         * @instance
         */
        ProcessResult.prototype.messageForNotRegisteredRecipient = null;

        /**
         * ProcessResult executedAs.
         * @member {processingcore.ProcessType} executedAs
         * @memberof processingcore.ProcessResult
         * @instance
         */
        ProcessResult.prototype.executedAs = 0;

        /**
         * Gets the default type url for ProcessResult
         * @function getTypeUrl
         * @memberof processingcore.ProcessResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProcessResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ProcessResult";
        };

        return ProcessResult;
    })();

    /**
     * ProcessType enum.
     * @name processingcore.ProcessType
     * @enum {number}
     * @property {number} PROCESS_TYPE_UNSPECIFIED=0 PROCESS_TYPE_UNSPECIFIED value
     * @property {number} SHORTCUT=1 SHORTCUT value
     * @property {number} SDAT_MESSAGE_SENT=2 SDAT_MESSAGE_SENT value
     */
    processingcore.ProcessType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "PROCESS_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "SHORTCUT"] = 1;
        values[valuesById[2] = "SDAT_MESSAGE_SENT"] = 2;
        return values;
    })();

    processingcore.SupplierChangeRequest = (function() {

        /**
         * Properties of a SupplierChangeRequest.
         * @memberof processingcore
         * @interface ISupplierChangeRequest
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] SupplierChangeRequest requestingPartnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] SupplierChangeRequest meteringPoint
         * @property {google.type.IDate|null} [date] SupplierChangeRequest date
         * @property {google.protobuf.IStringValue|null} [balanceResponsibleEic] SupplierChangeRequest balanceResponsibleEic
         * @property {sdat.GridBillingMethod|null} [gridBillingMethod] SupplierChangeRequest gridBillingMethod
         */

        /**
         * Constructs a new SupplierChangeRequest.
         * @memberof processingcore
         * @classdesc Represents a SupplierChangeRequest.
         * @implements ISupplierChangeRequest
         * @constructor
         * @param {processingcore.ISupplierChangeRequest=} [properties] Properties to set
         */
        function SupplierChangeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SupplierChangeRequest requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.SupplierChangeRequest
         * @instance
         */
        SupplierChangeRequest.prototype.requestingPartnerId = null;

        /**
         * SupplierChangeRequest meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof processingcore.SupplierChangeRequest
         * @instance
         */
        SupplierChangeRequest.prototype.meteringPoint = null;

        /**
         * SupplierChangeRequest date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof processingcore.SupplierChangeRequest
         * @instance
         */
        SupplierChangeRequest.prototype.date = null;

        /**
         * SupplierChangeRequest balanceResponsibleEic.
         * @member {google.protobuf.IStringValue|null|undefined} balanceResponsibleEic
         * @memberof processingcore.SupplierChangeRequest
         * @instance
         */
        SupplierChangeRequest.prototype.balanceResponsibleEic = null;

        /**
         * SupplierChangeRequest gridBillingMethod.
         * @member {sdat.GridBillingMethod} gridBillingMethod
         * @memberof processingcore.SupplierChangeRequest
         * @instance
         */
        SupplierChangeRequest.prototype.gridBillingMethod = 0;

        /**
         * Gets the default type url for SupplierChangeRequest
         * @function getTypeUrl
         * @memberof processingcore.SupplierChangeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SupplierChangeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.SupplierChangeRequest";
        };

        return SupplierChangeRequest;
    })();

    processingcore.SupplierEndRequest = (function() {

        /**
         * Properties of a SupplierEndRequest.
         * @memberof processingcore
         * @interface ISupplierEndRequest
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] SupplierEndRequest requestingPartnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] SupplierEndRequest meteringPoint
         * @property {google.type.IDate|null} [date] SupplierEndRequest date
         */

        /**
         * Constructs a new SupplierEndRequest.
         * @memberof processingcore
         * @classdesc Represents a SupplierEndRequest.
         * @implements ISupplierEndRequest
         * @constructor
         * @param {processingcore.ISupplierEndRequest=} [properties] Properties to set
         */
        function SupplierEndRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SupplierEndRequest requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.SupplierEndRequest
         * @instance
         */
        SupplierEndRequest.prototype.requestingPartnerId = null;

        /**
         * SupplierEndRequest meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof processingcore.SupplierEndRequest
         * @instance
         */
        SupplierEndRequest.prototype.meteringPoint = null;

        /**
         * SupplierEndRequest date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof processingcore.SupplierEndRequest
         * @instance
         */
        SupplierEndRequest.prototype.date = null;

        /**
         * Gets the default type url for SupplierEndRequest
         * @function getTypeUrl
         * @memberof processingcore.SupplierEndRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SupplierEndRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.SupplierEndRequest";
        };

        return SupplierEndRequest;
    })();

    processingcore.ConsumerChangeRequest = (function() {

        /**
         * Properties of a ConsumerChangeRequest.
         * @memberof processingcore
         * @interface IConsumerChangeRequest
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] ConsumerChangeRequest requestingPartnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] ConsumerChangeRequest meteringPoint
         * @property {google.type.IDate|null} [date] ConsumerChangeRequest date
         * @property {google.protobuf.IStringValue|null} [balanceResponsibleEic] ConsumerChangeRequest balanceResponsibleEic
         * @property {sdat.GridBillingMethod|null} [gridBillingMethod] ConsumerChangeRequest gridBillingMethod
         * @property {ech.IContact|null} [consumer] ConsumerChangeRequest consumer
         */

        /**
         * Constructs a new ConsumerChangeRequest.
         * @memberof processingcore
         * @classdesc Represents a ConsumerChangeRequest.
         * @implements IConsumerChangeRequest
         * @constructor
         * @param {processingcore.IConsumerChangeRequest=} [properties] Properties to set
         */
        function ConsumerChangeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumerChangeRequest requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.ConsumerChangeRequest
         * @instance
         */
        ConsumerChangeRequest.prototype.requestingPartnerId = null;

        /**
         * ConsumerChangeRequest meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof processingcore.ConsumerChangeRequest
         * @instance
         */
        ConsumerChangeRequest.prototype.meteringPoint = null;

        /**
         * ConsumerChangeRequest date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof processingcore.ConsumerChangeRequest
         * @instance
         */
        ConsumerChangeRequest.prototype.date = null;

        /**
         * ConsumerChangeRequest balanceResponsibleEic.
         * @member {google.protobuf.IStringValue|null|undefined} balanceResponsibleEic
         * @memberof processingcore.ConsumerChangeRequest
         * @instance
         */
        ConsumerChangeRequest.prototype.balanceResponsibleEic = null;

        /**
         * ConsumerChangeRequest gridBillingMethod.
         * @member {sdat.GridBillingMethod} gridBillingMethod
         * @memberof processingcore.ConsumerChangeRequest
         * @instance
         */
        ConsumerChangeRequest.prototype.gridBillingMethod = 0;

        /**
         * ConsumerChangeRequest consumer.
         * @member {ech.IContact|null|undefined} consumer
         * @memberof processingcore.ConsumerChangeRequest
         * @instance
         */
        ConsumerChangeRequest.prototype.consumer = null;

        /**
         * Gets the default type url for ConsumerChangeRequest
         * @function getTypeUrl
         * @memberof processingcore.ConsumerChangeRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumerChangeRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ConsumerChangeRequest";
        };

        return ConsumerChangeRequest;
    })();

    processingcore.ConsumerEndRequest = (function() {

        /**
         * Properties of a ConsumerEndRequest.
         * @memberof processingcore
         * @interface IConsumerEndRequest
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] ConsumerEndRequest requestingPartnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] ConsumerEndRequest meteringPoint
         * @property {google.type.IDate|null} [date] ConsumerEndRequest date
         */

        /**
         * Constructs a new ConsumerEndRequest.
         * @memberof processingcore
         * @classdesc Represents a ConsumerEndRequest.
         * @implements IConsumerEndRequest
         * @constructor
         * @param {processingcore.IConsumerEndRequest=} [properties] Properties to set
         */
        function ConsumerEndRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumerEndRequest requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.ConsumerEndRequest
         * @instance
         */
        ConsumerEndRequest.prototype.requestingPartnerId = null;

        /**
         * ConsumerEndRequest meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof processingcore.ConsumerEndRequest
         * @instance
         */
        ConsumerEndRequest.prototype.meteringPoint = null;

        /**
         * ConsumerEndRequest date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof processingcore.ConsumerEndRequest
         * @instance
         */
        ConsumerEndRequest.prototype.date = null;

        /**
         * Gets the default type url for ConsumerEndRequest
         * @function getTypeUrl
         * @memberof processingcore.ConsumerEndRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumerEndRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ConsumerEndRequest";
        };

        return ConsumerEndRequest;
    })();

    processingcore.ConsumerMasterDataChange = (function() {

        /**
         * Properties of a ConsumerMasterDataChange.
         * @memberof processingcore
         * @interface IConsumerMasterDataChange
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] ConsumerMasterDataChange requestingPartnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] ConsumerMasterDataChange meteringPoint
         * @property {google.type.IDate|null} [date] ConsumerMasterDataChange date
         * @property {sdat.GridBillingMethod|null} [gridBillingMethod] ConsumerMasterDataChange gridBillingMethod
         * @property {ech.IContact|null} [consumer] ConsumerMasterDataChange consumer
         */

        /**
         * Constructs a new ConsumerMasterDataChange.
         * @memberof processingcore
         * @classdesc Represents a ConsumerMasterDataChange.
         * @implements IConsumerMasterDataChange
         * @constructor
         * @param {processingcore.IConsumerMasterDataChange=} [properties] Properties to set
         */
        function ConsumerMasterDataChange(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumerMasterDataChange requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.ConsumerMasterDataChange
         * @instance
         */
        ConsumerMasterDataChange.prototype.requestingPartnerId = null;

        /**
         * ConsumerMasterDataChange meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof processingcore.ConsumerMasterDataChange
         * @instance
         */
        ConsumerMasterDataChange.prototype.meteringPoint = null;

        /**
         * ConsumerMasterDataChange date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof processingcore.ConsumerMasterDataChange
         * @instance
         */
        ConsumerMasterDataChange.prototype.date = null;

        /**
         * ConsumerMasterDataChange gridBillingMethod.
         * @member {sdat.GridBillingMethod} gridBillingMethod
         * @memberof processingcore.ConsumerMasterDataChange
         * @instance
         */
        ConsumerMasterDataChange.prototype.gridBillingMethod = 0;

        /**
         * ConsumerMasterDataChange consumer.
         * @member {ech.IContact|null|undefined} consumer
         * @memberof processingcore.ConsumerMasterDataChange
         * @instance
         */
        ConsumerMasterDataChange.prototype.consumer = null;

        /**
         * Gets the default type url for ConsumerMasterDataChange
         * @function getTypeUrl
         * @memberof processingcore.ConsumerMasterDataChange
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumerMasterDataChange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ConsumerMasterDataChange";
        };

        return ConsumerMasterDataChange;
    })();

    processingcore.ProcessCancellationRequest = (function() {

        /**
         * Properties of a ProcessCancellationRequest.
         * @memberof processingcore
         * @interface IProcessCancellationRequest
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] ProcessCancellationRequest requestingPartnerId
         * @property {google.protobuf.IStringValue|null} [meteringPointAdministratorEic] ProcessCancellationRequest meteringPointAdministratorEic
         * @property {google.protobuf.IStringValue|null} [processId] ProcessCancellationRequest processId
         */

        /**
         * Constructs a new ProcessCancellationRequest.
         * @memberof processingcore
         * @classdesc Represents a ProcessCancellationRequest.
         * @implements IProcessCancellationRequest
         * @constructor
         * @param {processingcore.IProcessCancellationRequest=} [properties] Properties to set
         */
        function ProcessCancellationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProcessCancellationRequest requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.ProcessCancellationRequest
         * @instance
         */
        ProcessCancellationRequest.prototype.requestingPartnerId = null;

        /**
         * ProcessCancellationRequest meteringPointAdministratorEic.
         * @member {google.protobuf.IStringValue|null|undefined} meteringPointAdministratorEic
         * @memberof processingcore.ProcessCancellationRequest
         * @instance
         */
        ProcessCancellationRequest.prototype.meteringPointAdministratorEic = null;

        /**
         * ProcessCancellationRequest processId.
         * @member {google.protobuf.IStringValue|null|undefined} processId
         * @memberof processingcore.ProcessCancellationRequest
         * @instance
         */
        ProcessCancellationRequest.prototype.processId = null;

        /**
         * Gets the default type url for ProcessCancellationRequest
         * @function getTypeUrl
         * @memberof processingcore.ProcessCancellationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProcessCancellationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ProcessCancellationRequest";
        };

        return ProcessCancellationRequest;
    })();

    processingcore.ASPRequest = (function() {

        /**
         * Properties of a ASPRequest.
         * @memberof processingcore
         * @interface IASPRequest
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] ASPRequest requestingPartnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] ASPRequest meteringPoint
         * @property {google.type.IDate|null} [date] ASPRequest date
         */

        /**
         * Constructs a new ASPRequest.
         * @memberof processingcore
         * @classdesc Represents a ASPRequest.
         * @implements IASPRequest
         * @constructor
         * @param {processingcore.IASPRequest=} [properties] Properties to set
         */
        function ASPRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ASPRequest requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.ASPRequest
         * @instance
         */
        ASPRequest.prototype.requestingPartnerId = null;

        /**
         * ASPRequest meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof processingcore.ASPRequest
         * @instance
         */
        ASPRequest.prototype.meteringPoint = null;

        /**
         * ASPRequest date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof processingcore.ASPRequest
         * @instance
         */
        ASPRequest.prototype.date = null;

        /**
         * Gets the default type url for ASPRequest
         * @function getTypeUrl
         * @memberof processingcore.ASPRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ASPRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ASPRequest";
        };

        return ASPRequest;
    })();

    processingcore.SupplyRegistration = (function() {

        /**
         * Properties of a SupplyRegistration.
         * @memberof processingcore
         * @interface ISupplyRegistration
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] SupplyRegistration requestingPartnerId
         * @property {google.protobuf.IStringValue|null} [meteringPointId] SupplyRegistration meteringPointId
         * @property {google.type.IDate|null} [date] SupplyRegistration date
         * @property {google.protobuf.IStringValue|null} [balanceSupplierEic] SupplyRegistration balanceSupplierEic
         * @property {google.protobuf.IStringValue|null} [balanceResponsibleEic] SupplyRegistration balanceResponsibleEic
         * @property {Array.<google.protobuf.IStringValue>|null} [ancillaryServiceProviderEic] SupplyRegistration ancillaryServiceProviderEic
         */

        /**
         * Constructs a new SupplyRegistration.
         * @memberof processingcore
         * @classdesc Represents a SupplyRegistration.
         * @implements ISupplyRegistration
         * @constructor
         * @param {processingcore.ISupplyRegistration=} [properties] Properties to set
         */
        function SupplyRegistration(properties) {
            this.ancillaryServiceProviderEic = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SupplyRegistration requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.SupplyRegistration
         * @instance
         */
        SupplyRegistration.prototype.requestingPartnerId = null;

        /**
         * SupplyRegistration meteringPointId.
         * @member {google.protobuf.IStringValue|null|undefined} meteringPointId
         * @memberof processingcore.SupplyRegistration
         * @instance
         */
        SupplyRegistration.prototype.meteringPointId = null;

        /**
         * SupplyRegistration date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof processingcore.SupplyRegistration
         * @instance
         */
        SupplyRegistration.prototype.date = null;

        /**
         * SupplyRegistration balanceSupplierEic.
         * @member {google.protobuf.IStringValue|null|undefined} balanceSupplierEic
         * @memberof processingcore.SupplyRegistration
         * @instance
         */
        SupplyRegistration.prototype.balanceSupplierEic = null;

        /**
         * SupplyRegistration balanceResponsibleEic.
         * @member {google.protobuf.IStringValue|null|undefined} balanceResponsibleEic
         * @memberof processingcore.SupplyRegistration
         * @instance
         */
        SupplyRegistration.prototype.balanceResponsibleEic = null;

        /**
         * SupplyRegistration ancillaryServiceProviderEic.
         * @member {Array.<google.protobuf.IStringValue>} ancillaryServiceProviderEic
         * @memberof processingcore.SupplyRegistration
         * @instance
         */
        SupplyRegistration.prototype.ancillaryServiceProviderEic = $util.emptyArray;

        /**
         * Gets the default type url for SupplyRegistration
         * @function getTypeUrl
         * @memberof processingcore.SupplyRegistration
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SupplyRegistration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.SupplyRegistration";
        };

        return SupplyRegistration;
    })();

    processingcore.MPMasterDataChange = (function() {

        /**
         * Properties of a MPMasterDataChange.
         * @memberof processingcore
         * @interface IMPMasterDataChange
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] MPMasterDataChange requestingPartnerId
         * @property {google.protobuf.IStringValue|null} [meteringPointId] MPMasterDataChange meteringPointId
         * @property {google.type.IDate|null} [date] MPMasterDataChange date
         * @property {mp.IMeteringPointCharacteristics|null} [characteristics] MPMasterDataChange characteristics
         * @property {mp.IMeteringPointAddress|null} [address] MPMasterDataChange address
         * @property {google.protobuf.IStringValue|null} [balanceSupplierEic] MPMasterDataChange balanceSupplierEic
         */

        /**
         * Constructs a new MPMasterDataChange.
         * @memberof processingcore
         * @classdesc Represents a MPMasterDataChange.
         * @implements IMPMasterDataChange
         * @constructor
         * @param {processingcore.IMPMasterDataChange=} [properties] Properties to set
         */
        function MPMasterDataChange(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MPMasterDataChange requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.MPMasterDataChange
         * @instance
         */
        MPMasterDataChange.prototype.requestingPartnerId = null;

        /**
         * MPMasterDataChange meteringPointId.
         * @member {google.protobuf.IStringValue|null|undefined} meteringPointId
         * @memberof processingcore.MPMasterDataChange
         * @instance
         */
        MPMasterDataChange.prototype.meteringPointId = null;

        /**
         * MPMasterDataChange date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof processingcore.MPMasterDataChange
         * @instance
         */
        MPMasterDataChange.prototype.date = null;

        /**
         * MPMasterDataChange characteristics.
         * @member {mp.IMeteringPointCharacteristics|null|undefined} characteristics
         * @memberof processingcore.MPMasterDataChange
         * @instance
         */
        MPMasterDataChange.prototype.characteristics = null;

        /**
         * MPMasterDataChange address.
         * @member {mp.IMeteringPointAddress|null|undefined} address
         * @memberof processingcore.MPMasterDataChange
         * @instance
         */
        MPMasterDataChange.prototype.address = null;

        /**
         * MPMasterDataChange balanceSupplierEic.
         * @member {google.protobuf.IStringValue|null|undefined} balanceSupplierEic
         * @memberof processingcore.MPMasterDataChange
         * @instance
         */
        MPMasterDataChange.prototype.balanceSupplierEic = null;

        /**
         * Gets the default type url for MPMasterDataChange
         * @function getTypeUrl
         * @memberof processingcore.MPMasterDataChange
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MPMasterDataChange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.MPMasterDataChange";
        };

        return MPMasterDataChange;
    })();

    processingcore.MPInfoQuery = (function() {

        /**
         * Properties of a MPInfoQuery.
         * @memberof processingcore
         * @interface IMPInfoQuery
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] MPInfoQuery requestingPartnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] MPInfoQuery meteringPoint
         */

        /**
         * Constructs a new MPInfoQuery.
         * @memberof processingcore
         * @classdesc Represents a MPInfoQuery.
         * @implements IMPInfoQuery
         * @constructor
         * @param {processingcore.IMPInfoQuery=} [properties] Properties to set
         */
        function MPInfoQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MPInfoQuery requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.MPInfoQuery
         * @instance
         */
        MPInfoQuery.prototype.requestingPartnerId = null;

        /**
         * MPInfoQuery meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof processingcore.MPInfoQuery
         * @instance
         */
        MPInfoQuery.prototype.meteringPoint = null;

        /**
         * Gets the default type url for MPInfoQuery
         * @function getTypeUrl
         * @memberof processingcore.MPInfoQuery
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MPInfoQuery.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.MPInfoQuery";
        };

        return MPInfoQuery;
    })();

    processingcore.SwitchInfoQuery = (function() {

        /**
         * Properties of a SwitchInfoQuery.
         * @memberof processingcore
         * @interface ISwitchInfoQuery
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] SwitchInfoQuery requestingPartnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] SwitchInfoQuery meteringPoint
         * @property {google.protobuf.IStringValue|null} [currentBalanceSupplierEic] SwitchInfoQuery currentBalanceSupplierEic
         */

        /**
         * Constructs a new SwitchInfoQuery.
         * @memberof processingcore
         * @classdesc Represents a SwitchInfoQuery.
         * @implements ISwitchInfoQuery
         * @constructor
         * @param {processingcore.ISwitchInfoQuery=} [properties] Properties to set
         */
        function SwitchInfoQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SwitchInfoQuery requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.SwitchInfoQuery
         * @instance
         */
        SwitchInfoQuery.prototype.requestingPartnerId = null;

        /**
         * SwitchInfoQuery meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof processingcore.SwitchInfoQuery
         * @instance
         */
        SwitchInfoQuery.prototype.meteringPoint = null;

        /**
         * SwitchInfoQuery currentBalanceSupplierEic.
         * @member {google.protobuf.IStringValue|null|undefined} currentBalanceSupplierEic
         * @memberof processingcore.SwitchInfoQuery
         * @instance
         */
        SwitchInfoQuery.prototype.currentBalanceSupplierEic = null;

        /**
         * Gets the default type url for SwitchInfoQuery
         * @function getTypeUrl
         * @memberof processingcore.SwitchInfoQuery
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SwitchInfoQuery.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.SwitchInfoQuery";
        };

        return SwitchInfoQuery;
    })();

    processingcore.DateTime = (function() {

        /**
         * Properties of a DateTime.
         * @memberof processingcore
         * @interface IDateTime
         * @property {number|null} [year] DateTime year
         * @property {number|null} [month] DateTime month
         * @property {number|null} [day] DateTime day
         * @property {number|null} [hours] DateTime hours
         * @property {number|null} [minutes] DateTime minutes
         * @property {number|null} [seconds] DateTime seconds
         */

        /**
         * Constructs a new DateTime.
         * @memberof processingcore
         * @classdesc Represents a DateTime.
         * @implements IDateTime
         * @constructor
         * @param {processingcore.IDateTime=} [properties] Properties to set
         */
        function DateTime(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DateTime year.
         * @member {number} year
         * @memberof processingcore.DateTime
         * @instance
         */
        DateTime.prototype.year = 0;

        /**
         * DateTime month.
         * @member {number} month
         * @memberof processingcore.DateTime
         * @instance
         */
        DateTime.prototype.month = 0;

        /**
         * DateTime day.
         * @member {number} day
         * @memberof processingcore.DateTime
         * @instance
         */
        DateTime.prototype.day = 0;

        /**
         * DateTime hours.
         * @member {number} hours
         * @memberof processingcore.DateTime
         * @instance
         */
        DateTime.prototype.hours = 0;

        /**
         * DateTime minutes.
         * @member {number} minutes
         * @memberof processingcore.DateTime
         * @instance
         */
        DateTime.prototype.minutes = 0;

        /**
         * DateTime seconds.
         * @member {number} seconds
         * @memberof processingcore.DateTime
         * @instance
         */
        DateTime.prototype.seconds = 0;

        /**
         * Gets the default type url for DateTime
         * @function getTypeUrl
         * @memberof processingcore.DateTime
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DateTime.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.DateTime";
        };

        return DateTime;
    })();

    processingcore.DateTimePeriod = (function() {

        /**
         * Properties of a DateTimePeriod.
         * @memberof processingcore
         * @interface IDateTimePeriod
         * @property {processingcore.IDateTime|null} [startDateTime] DateTimePeriod startDateTime
         * @property {processingcore.IDateTime|null} [endDateTime] DateTimePeriod endDateTime
         */

        /**
         * Constructs a new DateTimePeriod.
         * @memberof processingcore
         * @classdesc Represents a DateTimePeriod.
         * @implements IDateTimePeriod
         * @constructor
         * @param {processingcore.IDateTimePeriod=} [properties] Properties to set
         */
        function DateTimePeriod(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DateTimePeriod startDateTime.
         * @member {processingcore.IDateTime|null|undefined} startDateTime
         * @memberof processingcore.DateTimePeriod
         * @instance
         */
        DateTimePeriod.prototype.startDateTime = null;

        /**
         * DateTimePeriod endDateTime.
         * @member {processingcore.IDateTime|null|undefined} endDateTime
         * @memberof processingcore.DateTimePeriod
         * @instance
         */
        DateTimePeriod.prototype.endDateTime = null;

        /**
         * Gets the default type url for DateTimePeriod
         * @function getTypeUrl
         * @memberof processingcore.DateTimePeriod
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DateTimePeriod.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.DateTimePeriod";
        };

        return DateTimePeriod;
    })();

    processingcore.MeasurementsQuery = (function() {

        /**
         * Properties of a MeasurementsQuery.
         * @memberof processingcore
         * @interface IMeasurementsQuery
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] MeasurementsQuery requestingPartnerId
         * @property {base.IMeteringPointSelector|null} [meteringPoint] MeasurementsQuery meteringPoint
         * @property {processingcore.IDateTimePeriod|null} [interval] MeasurementsQuery interval
         */

        /**
         * Constructs a new MeasurementsQuery.
         * @memberof processingcore
         * @classdesc Represents a MeasurementsQuery.
         * @implements IMeasurementsQuery
         * @constructor
         * @param {processingcore.IMeasurementsQuery=} [properties] Properties to set
         */
        function MeasurementsQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeasurementsQuery requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.MeasurementsQuery
         * @instance
         */
        MeasurementsQuery.prototype.requestingPartnerId = null;

        /**
         * MeasurementsQuery meteringPoint.
         * @member {base.IMeteringPointSelector|null|undefined} meteringPoint
         * @memberof processingcore.MeasurementsQuery
         * @instance
         */
        MeasurementsQuery.prototype.meteringPoint = null;

        /**
         * MeasurementsQuery interval.
         * @member {processingcore.IDateTimePeriod|null|undefined} interval
         * @memberof processingcore.MeasurementsQuery
         * @instance
         */
        MeasurementsQuery.prototype.interval = null;

        /**
         * Gets the default type url for MeasurementsQuery
         * @function getTypeUrl
         * @memberof processingcore.MeasurementsQuery
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeasurementsQuery.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.MeasurementsQuery";
        };

        return MeasurementsQuery;
    })();

    processingcore.AggregationCriteria = (function() {

        /**
         * Properties of an AggregationCriteria.
         * @memberof processingcore
         * @interface IAggregationCriteria
         * @property {mp.MeteringPointType|null} [meteringPointType] AggregationCriteria meteringPointType
         * @property {mp.SettlementMethod|null} [settlementMethod] AggregationCriteria settlementMethod
         * @property {google.protobuf.IStringValue|null} [balanceSupplier] AggregationCriteria balanceSupplier
         * @property {google.protobuf.IStringValue|null} [balanceResponsible] AggregationCriteria balanceResponsible
         */

        /**
         * Constructs a new AggregationCriteria.
         * @memberof processingcore
         * @classdesc Represents an AggregationCriteria.
         * @implements IAggregationCriteria
         * @constructor
         * @param {processingcore.IAggregationCriteria=} [properties] Properties to set
         */
        function AggregationCriteria(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AggregationCriteria meteringPointType.
         * @member {mp.MeteringPointType} meteringPointType
         * @memberof processingcore.AggregationCriteria
         * @instance
         */
        AggregationCriteria.prototype.meteringPointType = 0;

        /**
         * AggregationCriteria settlementMethod.
         * @member {mp.SettlementMethod} settlementMethod
         * @memberof processingcore.AggregationCriteria
         * @instance
         */
        AggregationCriteria.prototype.settlementMethod = 0;

        /**
         * AggregationCriteria balanceSupplier.
         * @member {google.protobuf.IStringValue|null|undefined} balanceSupplier
         * @memberof processingcore.AggregationCriteria
         * @instance
         */
        AggregationCriteria.prototype.balanceSupplier = null;

        /**
         * AggregationCriteria balanceResponsible.
         * @member {google.protobuf.IStringValue|null|undefined} balanceResponsible
         * @memberof processingcore.AggregationCriteria
         * @instance
         */
        AggregationCriteria.prototype.balanceResponsible = null;

        /**
         * Gets the default type url for AggregationCriteria
         * @function getTypeUrl
         * @memberof processingcore.AggregationCriteria
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AggregationCriteria.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.AggregationCriteria";
        };

        return AggregationCriteria;
    })();

    processingcore.MeasurementAggregatesQuery = (function() {

        /**
         * Properties of a MeasurementAggregatesQuery.
         * @memberof processingcore
         * @interface IMeasurementAggregatesQuery
         * @property {google.protobuf.IInt32Value|null} [requestingPartnerId] MeasurementAggregatesQuery requestingPartnerId
         * @property {google.protobuf.IStringValue|null} [meteringPointAdministratorEic] MeasurementAggregatesQuery meteringPointAdministratorEic
         * @property {processingcore.IDateTimePeriod|null} [interval] MeasurementAggregatesQuery interval
         * @property {google.protobuf.IStringValue|null} [areaEic] MeasurementAggregatesQuery areaEic
         * @property {processingcore.IAggregationCriteria|null} [aggregationCriteria] MeasurementAggregatesQuery aggregationCriteria
         */

        /**
         * Constructs a new MeasurementAggregatesQuery.
         * @memberof processingcore
         * @classdesc Represents a MeasurementAggregatesQuery.
         * @implements IMeasurementAggregatesQuery
         * @constructor
         * @param {processingcore.IMeasurementAggregatesQuery=} [properties] Properties to set
         */
        function MeasurementAggregatesQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MeasurementAggregatesQuery requestingPartnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} requestingPartnerId
         * @memberof processingcore.MeasurementAggregatesQuery
         * @instance
         */
        MeasurementAggregatesQuery.prototype.requestingPartnerId = null;

        /**
         * MeasurementAggregatesQuery meteringPointAdministratorEic.
         * @member {google.protobuf.IStringValue|null|undefined} meteringPointAdministratorEic
         * @memberof processingcore.MeasurementAggregatesQuery
         * @instance
         */
        MeasurementAggregatesQuery.prototype.meteringPointAdministratorEic = null;

        /**
         * MeasurementAggregatesQuery interval.
         * @member {processingcore.IDateTimePeriod|null|undefined} interval
         * @memberof processingcore.MeasurementAggregatesQuery
         * @instance
         */
        MeasurementAggregatesQuery.prototype.interval = null;

        /**
         * MeasurementAggregatesQuery areaEic.
         * @member {google.protobuf.IStringValue|null|undefined} areaEic
         * @memberof processingcore.MeasurementAggregatesQuery
         * @instance
         */
        MeasurementAggregatesQuery.prototype.areaEic = null;

        /**
         * MeasurementAggregatesQuery aggregationCriteria.
         * @member {processingcore.IAggregationCriteria|null|undefined} aggregationCriteria
         * @memberof processingcore.MeasurementAggregatesQuery
         * @instance
         */
        MeasurementAggregatesQuery.prototype.aggregationCriteria = null;

        /**
         * Gets the default type url for MeasurementAggregatesQuery
         * @function getTypeUrl
         * @memberof processingcore.MeasurementAggregatesQuery
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MeasurementAggregatesQuery.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.MeasurementAggregatesQuery";
        };

        return MeasurementAggregatesQuery;
    })();

    processingcore.SupplierChangeConfirmation = (function() {

        /**
         * Properties of a SupplierChangeConfirmation.
         * @memberof processingcore
         * @interface ISupplierChangeConfirmation
         * @property {base.IUuidAndPartnerId|null} [reference] SupplierChangeConfirmation reference
         * @property {mp.IBalanceParties|null} [previousBalance] SupplierChangeConfirmation previousBalance
         * @property {Array.<google.protobuf.IStringValue>|null} [ancillaryServiceProviderEic] SupplierChangeConfirmation ancillaryServiceProviderEic
         * @property {sdat.GridBillingMethod|null} [unused] SupplierChangeConfirmation unused
         */

        /**
         * Constructs a new SupplierChangeConfirmation.
         * @memberof processingcore
         * @classdesc Represents a SupplierChangeConfirmation.
         * @implements ISupplierChangeConfirmation
         * @constructor
         * @param {processingcore.ISupplierChangeConfirmation=} [properties] Properties to set
         */
        function SupplierChangeConfirmation(properties) {
            this.ancillaryServiceProviderEic = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SupplierChangeConfirmation reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.SupplierChangeConfirmation
         * @instance
         */
        SupplierChangeConfirmation.prototype.reference = null;

        /**
         * SupplierChangeConfirmation previousBalance.
         * @member {mp.IBalanceParties|null|undefined} previousBalance
         * @memberof processingcore.SupplierChangeConfirmation
         * @instance
         */
        SupplierChangeConfirmation.prototype.previousBalance = null;

        /**
         * SupplierChangeConfirmation ancillaryServiceProviderEic.
         * @member {Array.<google.protobuf.IStringValue>} ancillaryServiceProviderEic
         * @memberof processingcore.SupplierChangeConfirmation
         * @instance
         */
        SupplierChangeConfirmation.prototype.ancillaryServiceProviderEic = $util.emptyArray;

        /**
         * SupplierChangeConfirmation unused.
         * @member {sdat.GridBillingMethod} unused
         * @memberof processingcore.SupplierChangeConfirmation
         * @instance
         */
        SupplierChangeConfirmation.prototype.unused = 0;

        /**
         * Gets the default type url for SupplierChangeConfirmation
         * @function getTypeUrl
         * @memberof processingcore.SupplierChangeConfirmation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SupplierChangeConfirmation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.SupplierChangeConfirmation";
        };

        return SupplierChangeConfirmation;
    })();

    processingcore.SupplierEndConfirmation = (function() {

        /**
         * Properties of a SupplierEndConfirmation.
         * @memberof processingcore
         * @interface ISupplierEndConfirmation
         * @property {base.IUuidAndPartnerId|null} [reference] SupplierEndConfirmation reference
         * @property {google.protobuf.IStringValue|null} [previousBalanceResponsibleEic] SupplierEndConfirmation previousBalanceResponsibleEic
         * @property {Array.<google.protobuf.IStringValue>|null} [ancillaryServiceProviderEic] SupplierEndConfirmation ancillaryServiceProviderEic
         */

        /**
         * Constructs a new SupplierEndConfirmation.
         * @memberof processingcore
         * @classdesc Represents a SupplierEndConfirmation.
         * @implements ISupplierEndConfirmation
         * @constructor
         * @param {processingcore.ISupplierEndConfirmation=} [properties] Properties to set
         */
        function SupplierEndConfirmation(properties) {
            this.ancillaryServiceProviderEic = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SupplierEndConfirmation reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.SupplierEndConfirmation
         * @instance
         */
        SupplierEndConfirmation.prototype.reference = null;

        /**
         * SupplierEndConfirmation previousBalanceResponsibleEic.
         * @member {google.protobuf.IStringValue|null|undefined} previousBalanceResponsibleEic
         * @memberof processingcore.SupplierEndConfirmation
         * @instance
         */
        SupplierEndConfirmation.prototype.previousBalanceResponsibleEic = null;

        /**
         * SupplierEndConfirmation ancillaryServiceProviderEic.
         * @member {Array.<google.protobuf.IStringValue>} ancillaryServiceProviderEic
         * @memberof processingcore.SupplierEndConfirmation
         * @instance
         */
        SupplierEndConfirmation.prototype.ancillaryServiceProviderEic = $util.emptyArray;

        /**
         * Gets the default type url for SupplierEndConfirmation
         * @function getTypeUrl
         * @memberof processingcore.SupplierEndConfirmation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SupplierEndConfirmation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.SupplierEndConfirmation";
        };

        return SupplierEndConfirmation;
    })();

    processingcore.ConsumerChangeConfirmation = (function() {

        /**
         * Properties of a ConsumerChangeConfirmation.
         * @memberof processingcore
         * @interface IConsumerChangeConfirmation
         * @property {base.IUuidAndPartnerId|null} [reference] ConsumerChangeConfirmation reference
         * @property {mp.IBalanceParties|null} [previousBalance] ConsumerChangeConfirmation previousBalance
         * @property {Array.<google.protobuf.IStringValue>|null} [ancillaryServiceProviderEic] ConsumerChangeConfirmation ancillaryServiceProviderEic
         * @property {sdat.GridBillingMethod|null} [unused] ConsumerChangeConfirmation unused
         */

        /**
         * Constructs a new ConsumerChangeConfirmation.
         * @memberof processingcore
         * @classdesc Represents a ConsumerChangeConfirmation.
         * @implements IConsumerChangeConfirmation
         * @constructor
         * @param {processingcore.IConsumerChangeConfirmation=} [properties] Properties to set
         */
        function ConsumerChangeConfirmation(properties) {
            this.ancillaryServiceProviderEic = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumerChangeConfirmation reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.ConsumerChangeConfirmation
         * @instance
         */
        ConsumerChangeConfirmation.prototype.reference = null;

        /**
         * ConsumerChangeConfirmation previousBalance.
         * @member {mp.IBalanceParties|null|undefined} previousBalance
         * @memberof processingcore.ConsumerChangeConfirmation
         * @instance
         */
        ConsumerChangeConfirmation.prototype.previousBalance = null;

        /**
         * ConsumerChangeConfirmation ancillaryServiceProviderEic.
         * @member {Array.<google.protobuf.IStringValue>} ancillaryServiceProviderEic
         * @memberof processingcore.ConsumerChangeConfirmation
         * @instance
         */
        ConsumerChangeConfirmation.prototype.ancillaryServiceProviderEic = $util.emptyArray;

        /**
         * ConsumerChangeConfirmation unused.
         * @member {sdat.GridBillingMethod} unused
         * @memberof processingcore.ConsumerChangeConfirmation
         * @instance
         */
        ConsumerChangeConfirmation.prototype.unused = 0;

        /**
         * Gets the default type url for ConsumerChangeConfirmation
         * @function getTypeUrl
         * @memberof processingcore.ConsumerChangeConfirmation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumerChangeConfirmation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ConsumerChangeConfirmation";
        };

        return ConsumerChangeConfirmation;
    })();

    processingcore.ConsumerEndConfirmation = (function() {

        /**
         * Properties of a ConsumerEndConfirmation.
         * @memberof processingcore
         * @interface IConsumerEndConfirmation
         * @property {base.IUuidAndPartnerId|null} [reference] ConsumerEndConfirmation reference
         * @property {Array.<google.protobuf.IStringValue>|null} [ancillaryServiceProviderEic] ConsumerEndConfirmation ancillaryServiceProviderEic
         */

        /**
         * Constructs a new ConsumerEndConfirmation.
         * @memberof processingcore
         * @classdesc Represents a ConsumerEndConfirmation.
         * @implements IConsumerEndConfirmation
         * @constructor
         * @param {processingcore.IConsumerEndConfirmation=} [properties] Properties to set
         */
        function ConsumerEndConfirmation(properties) {
            this.ancillaryServiceProviderEic = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConsumerEndConfirmation reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.ConsumerEndConfirmation
         * @instance
         */
        ConsumerEndConfirmation.prototype.reference = null;

        /**
         * ConsumerEndConfirmation ancillaryServiceProviderEic.
         * @member {Array.<google.protobuf.IStringValue>} ancillaryServiceProviderEic
         * @memberof processingcore.ConsumerEndConfirmation
         * @instance
         */
        ConsumerEndConfirmation.prototype.ancillaryServiceProviderEic = $util.emptyArray;

        /**
         * Gets the default type url for ConsumerEndConfirmation
         * @function getTypeUrl
         * @memberof processingcore.ConsumerEndConfirmation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConsumerEndConfirmation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ConsumerEndConfirmation";
        };

        return ConsumerEndConfirmation;
    })();

    processingcore.ASPStartConfirmation = (function() {

        /**
         * Properties of a ASPStartConfirmation.
         * @memberof processingcore
         * @interface IASPStartConfirmation
         * @property {base.IUuidAndPartnerId|null} [reference] ASPStartConfirmation reference
         * @property {mp.IBalanceParties|null} [currentBalance] ASPStartConfirmation currentBalance
         */

        /**
         * Constructs a new ASPStartConfirmation.
         * @memberof processingcore
         * @classdesc Represents a ASPStartConfirmation.
         * @implements IASPStartConfirmation
         * @constructor
         * @param {processingcore.IASPStartConfirmation=} [properties] Properties to set
         */
        function ASPStartConfirmation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ASPStartConfirmation reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.ASPStartConfirmation
         * @instance
         */
        ASPStartConfirmation.prototype.reference = null;

        /**
         * ASPStartConfirmation currentBalance.
         * @member {mp.IBalanceParties|null|undefined} currentBalance
         * @memberof processingcore.ASPStartConfirmation
         * @instance
         */
        ASPStartConfirmation.prototype.currentBalance = null;

        /**
         * Gets the default type url for ASPStartConfirmation
         * @function getTypeUrl
         * @memberof processingcore.ASPStartConfirmation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ASPStartConfirmation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ASPStartConfirmation";
        };

        return ASPStartConfirmation;
    })();

    processingcore.ASPEndConfirmation = (function() {

        /**
         * Properties of a ASPEndConfirmation.
         * @memberof processingcore
         * @interface IASPEndConfirmation
         * @property {base.IUuidAndPartnerId|null} [reference] ASPEndConfirmation reference
         * @property {google.protobuf.IStringValue|null} [currentBalanceSupplierEic] ASPEndConfirmation currentBalanceSupplierEic
         */

        /**
         * Constructs a new ASPEndConfirmation.
         * @memberof processingcore
         * @classdesc Represents a ASPEndConfirmation.
         * @implements IASPEndConfirmation
         * @constructor
         * @param {processingcore.IASPEndConfirmation=} [properties] Properties to set
         */
        function ASPEndConfirmation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ASPEndConfirmation reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.ASPEndConfirmation
         * @instance
         */
        ASPEndConfirmation.prototype.reference = null;

        /**
         * ASPEndConfirmation currentBalanceSupplierEic.
         * @member {google.protobuf.IStringValue|null|undefined} currentBalanceSupplierEic
         * @memberof processingcore.ASPEndConfirmation
         * @instance
         */
        ASPEndConfirmation.prototype.currentBalanceSupplierEic = null;

        /**
         * Gets the default type url for ASPEndConfirmation
         * @function getTypeUrl
         * @memberof processingcore.ASPEndConfirmation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ASPEndConfirmation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ASPEndConfirmation";
        };

        return ASPEndConfirmation;
    })();

    processingcore.MPInfoResponse = (function() {

        /**
         * Properties of a MPInfoResponse.
         * @memberof processingcore
         * @interface IMPInfoResponse
         * @property {base.IUuidAndPartnerId|null} [reference] MPInfoResponse reference
         * @property {mp.IMeteringPointCharacteristics|null} [characteristics] MPInfoResponse characteristics
         * @property {mp.IMeteringPointAddress|null} [address] MPInfoResponse address
         * @property {mp.IMeteringPointParties|null} [parties] MPInfoResponse parties
         */

        /**
         * Constructs a new MPInfoResponse.
         * @memberof processingcore
         * @classdesc Represents a MPInfoResponse.
         * @implements IMPInfoResponse
         * @constructor
         * @param {processingcore.IMPInfoResponse=} [properties] Properties to set
         */
        function MPInfoResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MPInfoResponse reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.MPInfoResponse
         * @instance
         */
        MPInfoResponse.prototype.reference = null;

        /**
         * MPInfoResponse characteristics.
         * @member {mp.IMeteringPointCharacteristics|null|undefined} characteristics
         * @memberof processingcore.MPInfoResponse
         * @instance
         */
        MPInfoResponse.prototype.characteristics = null;

        /**
         * MPInfoResponse address.
         * @member {mp.IMeteringPointAddress|null|undefined} address
         * @memberof processingcore.MPInfoResponse
         * @instance
         */
        MPInfoResponse.prototype.address = null;

        /**
         * MPInfoResponse parties.
         * @member {mp.IMeteringPointParties|null|undefined} parties
         * @memberof processingcore.MPInfoResponse
         * @instance
         */
        MPInfoResponse.prototype.parties = null;

        /**
         * Gets the default type url for MPInfoResponse
         * @function getTypeUrl
         * @memberof processingcore.MPInfoResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MPInfoResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.MPInfoResponse";
        };

        return MPInfoResponse;
    })();

    processingcore.ContractTerminationInfo = (function() {

        /**
         * Properties of a ContractTerminationInfo.
         * @memberof processingcore
         * @interface IContractTerminationInfo
         * @property {google.type.IDate|null} [date] ContractTerminationInfo date
         * @property {processingcore.ContractTerminationInfo.IContractTerminationResolution|null} [timeOfNotice] ContractTerminationInfo timeOfNotice
         */

        /**
         * Constructs a new ContractTerminationInfo.
         * @memberof processingcore
         * @classdesc Represents a ContractTerminationInfo.
         * @implements IContractTerminationInfo
         * @constructor
         * @param {processingcore.IContractTerminationInfo=} [properties] Properties to set
         */
        function ContractTerminationInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContractTerminationInfo date.
         * @member {google.type.IDate|null|undefined} date
         * @memberof processingcore.ContractTerminationInfo
         * @instance
         */
        ContractTerminationInfo.prototype.date = null;

        /**
         * ContractTerminationInfo timeOfNotice.
         * @member {processingcore.ContractTerminationInfo.IContractTerminationResolution|null|undefined} timeOfNotice
         * @memberof processingcore.ContractTerminationInfo
         * @instance
         */
        ContractTerminationInfo.prototype.timeOfNotice = null;

        /**
         * Gets the default type url for ContractTerminationInfo
         * @function getTypeUrl
         * @memberof processingcore.ContractTerminationInfo
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ContractTerminationInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ContractTerminationInfo";
        };

        ContractTerminationInfo.ContractTerminationResolution = (function() {

            /**
             * Properties of a ContractTerminationResolution.
             * @memberof processingcore.ContractTerminationInfo
             * @interface IContractTerminationResolution
             * @property {number|null} [resolution] ContractTerminationResolution resolution
             * @property {processingcore.ContractTerminationInfo.ContractTerminationResolution.ContractTerminationUnit|null} [unit] ContractTerminationResolution unit
             */

            /**
             * Constructs a new ContractTerminationResolution.
             * @memberof processingcore.ContractTerminationInfo
             * @classdesc Represents a ContractTerminationResolution.
             * @implements IContractTerminationResolution
             * @constructor
             * @param {processingcore.ContractTerminationInfo.IContractTerminationResolution=} [properties] Properties to set
             */
            function ContractTerminationResolution(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ContractTerminationResolution resolution.
             * @member {number} resolution
             * @memberof processingcore.ContractTerminationInfo.ContractTerminationResolution
             * @instance
             */
            ContractTerminationResolution.prototype.resolution = 0;

            /**
             * ContractTerminationResolution unit.
             * @member {processingcore.ContractTerminationInfo.ContractTerminationResolution.ContractTerminationUnit} unit
             * @memberof processingcore.ContractTerminationInfo.ContractTerminationResolution
             * @instance
             */
            ContractTerminationResolution.prototype.unit = 0;

            /**
             * Gets the default type url for ContractTerminationResolution
             * @function getTypeUrl
             * @memberof processingcore.ContractTerminationInfo.ContractTerminationResolution
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ContractTerminationResolution.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/processingcore.ContractTerminationInfo.ContractTerminationResolution";
            };

            /**
             * ContractTerminationUnit enum.
             * @name processingcore.ContractTerminationInfo.ContractTerminationResolution.ContractTerminationUnit
             * @enum {number}
             * @property {number} CONTRACT_TERMINATION_UNIT_UNSPECIFIED=0 CONTRACT_TERMINATION_UNIT_UNSPECIFIED value
             * @property {number} DAY=1 DAY value
             * @property {number} MONTH=2 MONTH value
             */
            ContractTerminationResolution.ContractTerminationUnit = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CONTRACT_TERMINATION_UNIT_UNSPECIFIED"] = 0;
                values[valuesById[1] = "DAY"] = 1;
                values[valuesById[2] = "MONTH"] = 2;
                return values;
            })();

            return ContractTerminationResolution;
        })();

        return ContractTerminationInfo;
    })();

    processingcore.SwitchInfoResponse = (function() {

        /**
         * Properties of a SwitchInfoResponse.
         * @memberof processingcore
         * @interface ISwitchInfoResponse
         * @property {base.IUuidAndPartnerId|null} [reference] SwitchInfoResponse reference
         * @property {processingcore.IContractTerminationInfo|null} [contractTerminationInfo] SwitchInfoResponse contractTerminationInfo
         */

        /**
         * Constructs a new SwitchInfoResponse.
         * @memberof processingcore
         * @classdesc Represents a SwitchInfoResponse.
         * @implements ISwitchInfoResponse
         * @constructor
         * @param {processingcore.ISwitchInfoResponse=} [properties] Properties to set
         */
        function SwitchInfoResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SwitchInfoResponse reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.SwitchInfoResponse
         * @instance
         */
        SwitchInfoResponse.prototype.reference = null;

        /**
         * SwitchInfoResponse contractTerminationInfo.
         * @member {processingcore.IContractTerminationInfo|null|undefined} contractTerminationInfo
         * @memberof processingcore.SwitchInfoResponse
         * @instance
         */
        SwitchInfoResponse.prototype.contractTerminationInfo = null;

        /**
         * Gets the default type url for SwitchInfoResponse
         * @function getTypeUrl
         * @memberof processingcore.SwitchInfoResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SwitchInfoResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.SwitchInfoResponse";
        };

        return SwitchInfoResponse;
    })();

    processingcore.ProcessCancellationConfirmation = (function() {

        /**
         * Properties of a ProcessCancellationConfirmation.
         * @memberof processingcore
         * @interface IProcessCancellationConfirmation
         * @property {base.IUuidAndPartnerId|null} [reference] ProcessCancellationConfirmation reference
         */

        /**
         * Constructs a new ProcessCancellationConfirmation.
         * @memberof processingcore
         * @classdesc Represents a ProcessCancellationConfirmation.
         * @implements IProcessCancellationConfirmation
         * @constructor
         * @param {processingcore.IProcessCancellationConfirmation=} [properties] Properties to set
         */
        function ProcessCancellationConfirmation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProcessCancellationConfirmation reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.ProcessCancellationConfirmation
         * @instance
         */
        ProcessCancellationConfirmation.prototype.reference = null;

        /**
         * Gets the default type url for ProcessCancellationConfirmation
         * @function getTypeUrl
         * @memberof processingcore.ProcessCancellationConfirmation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProcessCancellationConfirmation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ProcessCancellationConfirmation";
        };

        return ProcessCancellationConfirmation;
    })();

    processingcore.RequestRejection = (function() {

        /**
         * Properties of a RequestRejection.
         * @memberof processingcore
         * @interface IRequestRejection
         * @property {base.IUuidAndPartnerId|null} [reference] RequestRejection reference
         * @property {sdat.RejectionReason|null} [rejectionReason] RequestRejection rejectionReason
         */

        /**
         * Constructs a new RequestRejection.
         * @memberof processingcore
         * @classdesc Represents a RequestRejection.
         * @implements IRequestRejection
         * @constructor
         * @param {processingcore.IRequestRejection=} [properties] Properties to set
         */
        function RequestRejection(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestRejection reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.RequestRejection
         * @instance
         */
        RequestRejection.prototype.reference = null;

        /**
         * RequestRejection rejectionReason.
         * @member {sdat.RejectionReason} rejectionReason
         * @memberof processingcore.RequestRejection
         * @instance
         */
        RequestRejection.prototype.rejectionReason = 0;

        /**
         * Gets the default type url for RequestRejection
         * @function getTypeUrl
         * @memberof processingcore.RequestRejection
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RequestRejection.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.RequestRejection";
        };

        return RequestRejection;
    })();

    processingcore.DocumentCancellation = (function() {

        /**
         * Properties of a DocumentCancellation.
         * @memberof processingcore
         * @interface IDocumentCancellation
         * @property {base.IUuidAndPartnerId|null} [reference] DocumentCancellation reference
         */

        /**
         * Constructs a new DocumentCancellation.
         * @memberof processingcore
         * @classdesc Represents a DocumentCancellation.
         * @implements IDocumentCancellation
         * @constructor
         * @param {processingcore.IDocumentCancellation=} [properties] Properties to set
         */
        function DocumentCancellation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DocumentCancellation reference.
         * @member {base.IUuidAndPartnerId|null|undefined} reference
         * @memberof processingcore.DocumentCancellation
         * @instance
         */
        DocumentCancellation.prototype.reference = null;

        /**
         * Gets the default type url for DocumentCancellation
         * @function getTypeUrl
         * @memberof processingcore.DocumentCancellation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DocumentCancellation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.DocumentCancellation";
        };

        return DocumentCancellation;
    })();

    processingcore.PreviousConfirmation = (function() {

        /**
         * Properties of a PreviousConfirmation.
         * @memberof processingcore
         * @interface IPreviousConfirmation
         * @property {google.protobuf.IInt32Value|null} [partnerId] PreviousConfirmation partnerId
         * @property {google.protobuf.IStringValue|null} [processId] PreviousConfirmation processId
         */

        /**
         * Constructs a new PreviousConfirmation.
         * @memberof processingcore
         * @classdesc Represents a PreviousConfirmation.
         * @implements IPreviousConfirmation
         * @constructor
         * @param {processingcore.IPreviousConfirmation=} [properties] Properties to set
         */
        function PreviousConfirmation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PreviousConfirmation partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof processingcore.PreviousConfirmation
         * @instance
         */
        PreviousConfirmation.prototype.partnerId = null;

        /**
         * PreviousConfirmation processId.
         * @member {google.protobuf.IStringValue|null|undefined} processId
         * @memberof processingcore.PreviousConfirmation
         * @instance
         */
        PreviousConfirmation.prototype.processId = null;

        /**
         * Gets the default type url for PreviousConfirmation
         * @function getTypeUrl
         * @memberof processingcore.PreviousConfirmation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PreviousConfirmation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.PreviousConfirmation";
        };

        return PreviousConfirmation;
    })();

    processingcore.ConfirmationRequest = (function() {

        /**
         * Properties of a ConfirmationRequest.
         * @memberof processingcore
         * @interface IConfirmationRequest
         * @property {processingcore.ISupplierChangeConfirmation|null} [supplierChange] ConfirmationRequest supplierChange
         * @property {processingcore.ISupplierEndConfirmation|null} [supplierEnd] ConfirmationRequest supplierEnd
         * @property {processingcore.IConsumerChangeConfirmation|null} [consumerChange] ConfirmationRequest consumerChange
         * @property {processingcore.IConsumerEndConfirmation|null} [consumerEnd] ConfirmationRequest consumerEnd
         * @property {processingcore.IASPStartConfirmation|null} [aspStart] ConfirmationRequest aspStart
         * @property {processingcore.IASPEndConfirmation|null} [aspEnd] ConfirmationRequest aspEnd
         * @property {processingcore.IMPInfoResponse|null} [mpInfo] ConfirmationRequest mpInfo
         * @property {processingcore.ISwitchInfoResponse|null} [switchInfo] ConfirmationRequest switchInfo
         */

        /**
         * Constructs a new ConfirmationRequest.
         * @memberof processingcore
         * @classdesc Represents a ConfirmationRequest.
         * @implements IConfirmationRequest
         * @constructor
         * @param {processingcore.IConfirmationRequest=} [properties] Properties to set
         */
        function ConfirmationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfirmationRequest supplierChange.
         * @member {processingcore.ISupplierChangeConfirmation|null|undefined} supplierChange
         * @memberof processingcore.ConfirmationRequest
         * @instance
         */
        ConfirmationRequest.prototype.supplierChange = null;

        /**
         * ConfirmationRequest supplierEnd.
         * @member {processingcore.ISupplierEndConfirmation|null|undefined} supplierEnd
         * @memberof processingcore.ConfirmationRequest
         * @instance
         */
        ConfirmationRequest.prototype.supplierEnd = null;

        /**
         * ConfirmationRequest consumerChange.
         * @member {processingcore.IConsumerChangeConfirmation|null|undefined} consumerChange
         * @memberof processingcore.ConfirmationRequest
         * @instance
         */
        ConfirmationRequest.prototype.consumerChange = null;

        /**
         * ConfirmationRequest consumerEnd.
         * @member {processingcore.IConsumerEndConfirmation|null|undefined} consumerEnd
         * @memberof processingcore.ConfirmationRequest
         * @instance
         */
        ConfirmationRequest.prototype.consumerEnd = null;

        /**
         * ConfirmationRequest aspStart.
         * @member {processingcore.IASPStartConfirmation|null|undefined} aspStart
         * @memberof processingcore.ConfirmationRequest
         * @instance
         */
        ConfirmationRequest.prototype.aspStart = null;

        /**
         * ConfirmationRequest aspEnd.
         * @member {processingcore.IASPEndConfirmation|null|undefined} aspEnd
         * @memberof processingcore.ConfirmationRequest
         * @instance
         */
        ConfirmationRequest.prototype.aspEnd = null;

        /**
         * ConfirmationRequest mpInfo.
         * @member {processingcore.IMPInfoResponse|null|undefined} mpInfo
         * @memberof processingcore.ConfirmationRequest
         * @instance
         */
        ConfirmationRequest.prototype.mpInfo = null;

        /**
         * ConfirmationRequest switchInfo.
         * @member {processingcore.ISwitchInfoResponse|null|undefined} switchInfo
         * @memberof processingcore.ConfirmationRequest
         * @instance
         */
        ConfirmationRequest.prototype.switchInfo = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ConfirmationRequest confirmation.
         * @member {"supplierChange"|"supplierEnd"|"consumerChange"|"consumerEnd"|"aspStart"|"aspEnd"|"mpInfo"|"switchInfo"|undefined} confirmation
         * @memberof processingcore.ConfirmationRequest
         * @instance
         */
        Object.defineProperty(ConfirmationRequest.prototype, "confirmation", {
            get: $util.oneOfGetter($oneOfFields = ["supplierChange", "supplierEnd", "consumerChange", "consumerEnd", "aspStart", "aspEnd", "mpInfo", "switchInfo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Gets the default type url for ConfirmationRequest
         * @function getTypeUrl
         * @memberof processingcore.ConfirmationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ConfirmationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/processingcore.ConfirmationRequest";
        };

        return ConfirmationRequest;
    })();

    return processingcore;
})();

export const sdatmessageunion = $root.sdatmessageunion = (() => {

    /**
     * Namespace sdatmessageunion.
     * @exports sdatmessageunion
     * @namespace
     */
    const sdatmessageunion = {};

    sdatmessageunion.MessageUnionHeader = (function() {

        /**
         * Properties of a MessageUnionHeader.
         * @memberof sdatmessageunion
         * @interface IMessageUnionHeader
         * @property {sdat.IParty|null} [sender] MessageUnionHeader sender
         * @property {sdat.IParty|null} [recipient] MessageUnionHeader recipient
         * @property {google.protobuf.IStringValue|null} [id] MessageUnionHeader id
         * @property {processingcore.IDateTime|null} [creation] MessageUnionHeader creation
         * @property {sdat.SdatMessageType|null} [type] MessageUnionHeader type
         */

        /**
         * Constructs a new MessageUnionHeader.
         * @memberof sdatmessageunion
         * @classdesc Represents a MessageUnionHeader.
         * @implements IMessageUnionHeader
         * @constructor
         * @param {sdatmessageunion.IMessageUnionHeader=} [properties] Properties to set
         */
        function MessageUnionHeader(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessageUnionHeader sender.
         * @member {sdat.IParty|null|undefined} sender
         * @memberof sdatmessageunion.MessageUnionHeader
         * @instance
         */
        MessageUnionHeader.prototype.sender = null;

        /**
         * MessageUnionHeader recipient.
         * @member {sdat.IParty|null|undefined} recipient
         * @memberof sdatmessageunion.MessageUnionHeader
         * @instance
         */
        MessageUnionHeader.prototype.recipient = null;

        /**
         * MessageUnionHeader id.
         * @member {google.protobuf.IStringValue|null|undefined} id
         * @memberof sdatmessageunion.MessageUnionHeader
         * @instance
         */
        MessageUnionHeader.prototype.id = null;

        /**
         * MessageUnionHeader creation.
         * @member {processingcore.IDateTime|null|undefined} creation
         * @memberof sdatmessageunion.MessageUnionHeader
         * @instance
         */
        MessageUnionHeader.prototype.creation = null;

        /**
         * MessageUnionHeader type.
         * @member {sdat.SdatMessageType} type
         * @memberof sdatmessageunion.MessageUnionHeader
         * @instance
         */
        MessageUnionHeader.prototype.type = 0;

        /**
         * Gets the default type url for MessageUnionHeader
         * @function getTypeUrl
         * @memberof sdatmessageunion.MessageUnionHeader
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessageUnionHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sdatmessageunion.MessageUnionHeader";
        };

        return MessageUnionHeader;
    })();

    sdatmessageunion.DocUnionHeader = (function() {

        /**
         * Properties of a DocUnionHeader.
         * @memberof sdatmessageunion
         * @interface IDocUnionHeader
         * @property {google.protobuf.IStringValue|null} [documentId] DocUnionHeader documentId
         * @property {google.protobuf.IStringValue|null} [requestingDocumentId] DocUnionHeader requestingDocumentId
         * @property {google.protobuf.IStringValue|null} [processId] DocUnionHeader processId
         * @property {sdatmessageunion.DocUnionHeader.IRejectionReasonUnionWrapper|null} [reason] DocUnionHeader reason
         */

        /**
         * Constructs a new DocUnionHeader.
         * @memberof sdatmessageunion
         * @classdesc Represents a DocUnionHeader.
         * @implements IDocUnionHeader
         * @constructor
         * @param {sdatmessageunion.IDocUnionHeader=} [properties] Properties to set
         */
        function DocUnionHeader(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DocUnionHeader documentId.
         * @member {google.protobuf.IStringValue|null|undefined} documentId
         * @memberof sdatmessageunion.DocUnionHeader
         * @instance
         */
        DocUnionHeader.prototype.documentId = null;

        /**
         * DocUnionHeader requestingDocumentId.
         * @member {google.protobuf.IStringValue|null|undefined} requestingDocumentId
         * @memberof sdatmessageunion.DocUnionHeader
         * @instance
         */
        DocUnionHeader.prototype.requestingDocumentId = null;

        /**
         * DocUnionHeader processId.
         * @member {google.protobuf.IStringValue|null|undefined} processId
         * @memberof sdatmessageunion.DocUnionHeader
         * @instance
         */
        DocUnionHeader.prototype.processId = null;

        /**
         * DocUnionHeader reason.
         * @member {sdatmessageunion.DocUnionHeader.IRejectionReasonUnionWrapper|null|undefined} reason
         * @memberof sdatmessageunion.DocUnionHeader
         * @instance
         */
        DocUnionHeader.prototype.reason = null;

        /**
         * Gets the default type url for DocUnionHeader
         * @function getTypeUrl
         * @memberof sdatmessageunion.DocUnionHeader
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DocUnionHeader.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sdatmessageunion.DocUnionHeader";
        };

        DocUnionHeader.RejectionReasonUnionWrapper = (function() {

            /**
             * Properties of a RejectionReasonUnionWrapper.
             * @memberof sdatmessageunion.DocUnionHeader
             * @interface IRejectionReasonUnionWrapper
             * @property {sdat.RejectionReason|null} [value] RejectionReasonUnionWrapper value
             */

            /**
             * Constructs a new RejectionReasonUnionWrapper.
             * @memberof sdatmessageunion.DocUnionHeader
             * @classdesc Represents a RejectionReasonUnionWrapper.
             * @implements IRejectionReasonUnionWrapper
             * @constructor
             * @param {sdatmessageunion.DocUnionHeader.IRejectionReasonUnionWrapper=} [properties] Properties to set
             */
            function RejectionReasonUnionWrapper(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RejectionReasonUnionWrapper value.
             * @member {sdat.RejectionReason} value
             * @memberof sdatmessageunion.DocUnionHeader.RejectionReasonUnionWrapper
             * @instance
             */
            RejectionReasonUnionWrapper.prototype.value = 0;

            /**
             * Gets the default type url for RejectionReasonUnionWrapper
             * @function getTypeUrl
             * @memberof sdatmessageunion.DocUnionHeader.RejectionReasonUnionWrapper
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RejectionReasonUnionWrapper.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/sdatmessageunion.DocUnionHeader.RejectionReasonUnionWrapper";
            };

            return RejectionReasonUnionWrapper;
        })();

        return DocUnionHeader;
    })();

    sdatmessageunion.DocUnionBody = (function() {

        /**
         * Properties of a DocUnionBody.
         * @memberof sdatmessageunion
         * @interface IDocUnionBody
         * @property {google.protobuf.IStringValue|null} [meteringPointId] DocUnionBody meteringPointId
         * @property {google.type.IDate|null} [startDate] DocUnionBody startDate
         * @property {google.type.IDate|null} [endDate] DocUnionBody endDate
         * @property {google.protobuf.IStringValue|null} [balanceResponsible] DocUnionBody balanceResponsible
         * @property {google.protobuf.IStringValue|null} [balanceSupplier] DocUnionBody balanceSupplier
         * @property {google.protobuf.IStringValue|null} [ancillaryServiceProvider] DocUnionBody ancillaryServiceProvider
         * @property {sdatmessageunion.DocUnionBody.IAncillaryServiceProvidersWrapper|null} [ancillaryServiceProviders] DocUnionBody ancillaryServiceProviders
         * @property {sdatmessageunion.DocUnionBody.IGridBillingMethodWrapper|null} [gridBillingMethod] DocUnionBody gridBillingMethod
         * @property {ech.IContact|null} [consumer] DocUnionBody consumer
         * @property {mp.IMeteringPointCharacteristics|null} [mpCharacteristics] DocUnionBody mpCharacteristics
         * @property {mp.IMeteringPointAddress|null} [mpAddress] DocUnionBody mpAddress
         * @property {processingcore.IContractTerminationInfo|null} [contractTerminationInfo] DocUnionBody contractTerminationInfo
         * @property {processingcore.IDateTimePeriod|null} [interval] DocUnionBody interval
         * @property {google.protobuf.IStringValue|null} [area] DocUnionBody area
         * @property {processingcore.IAggregationCriteria|null} [aggregationCriteria] DocUnionBody aggregationCriteria
         * @property {google.protobuf.IStringValue|null} [referenceDocumentId] DocUnionBody referenceDocumentId
         */

        /**
         * Constructs a new DocUnionBody.
         * @memberof sdatmessageunion
         * @classdesc Represents a DocUnionBody.
         * @implements IDocUnionBody
         * @constructor
         * @param {sdatmessageunion.IDocUnionBody=} [properties] Properties to set
         */
        function DocUnionBody(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DocUnionBody meteringPointId.
         * @member {google.protobuf.IStringValue|null|undefined} meteringPointId
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.meteringPointId = null;

        /**
         * DocUnionBody startDate.
         * @member {google.type.IDate|null|undefined} startDate
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.startDate = null;

        /**
         * DocUnionBody endDate.
         * @member {google.type.IDate|null|undefined} endDate
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.endDate = null;

        /**
         * DocUnionBody balanceResponsible.
         * @member {google.protobuf.IStringValue|null|undefined} balanceResponsible
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.balanceResponsible = null;

        /**
         * DocUnionBody balanceSupplier.
         * @member {google.protobuf.IStringValue|null|undefined} balanceSupplier
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.balanceSupplier = null;

        /**
         * DocUnionBody ancillaryServiceProvider.
         * @member {google.protobuf.IStringValue|null|undefined} ancillaryServiceProvider
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.ancillaryServiceProvider = null;

        /**
         * DocUnionBody ancillaryServiceProviders.
         * @member {sdatmessageunion.DocUnionBody.IAncillaryServiceProvidersWrapper|null|undefined} ancillaryServiceProviders
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.ancillaryServiceProviders = null;

        /**
         * DocUnionBody gridBillingMethod.
         * @member {sdatmessageunion.DocUnionBody.IGridBillingMethodWrapper|null|undefined} gridBillingMethod
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.gridBillingMethod = null;

        /**
         * DocUnionBody consumer.
         * @member {ech.IContact|null|undefined} consumer
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.consumer = null;

        /**
         * DocUnionBody mpCharacteristics.
         * @member {mp.IMeteringPointCharacteristics|null|undefined} mpCharacteristics
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.mpCharacteristics = null;

        /**
         * DocUnionBody mpAddress.
         * @member {mp.IMeteringPointAddress|null|undefined} mpAddress
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.mpAddress = null;

        /**
         * DocUnionBody contractTerminationInfo.
         * @member {processingcore.IContractTerminationInfo|null|undefined} contractTerminationInfo
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.contractTerminationInfo = null;

        /**
         * DocUnionBody interval.
         * @member {processingcore.IDateTimePeriod|null|undefined} interval
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.interval = null;

        /**
         * DocUnionBody area.
         * @member {google.protobuf.IStringValue|null|undefined} area
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.area = null;

        /**
         * DocUnionBody aggregationCriteria.
         * @member {processingcore.IAggregationCriteria|null|undefined} aggregationCriteria
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.aggregationCriteria = null;

        /**
         * DocUnionBody referenceDocumentId.
         * @member {google.protobuf.IStringValue|null|undefined} referenceDocumentId
         * @memberof sdatmessageunion.DocUnionBody
         * @instance
         */
        DocUnionBody.prototype.referenceDocumentId = null;

        /**
         * Gets the default type url for DocUnionBody
         * @function getTypeUrl
         * @memberof sdatmessageunion.DocUnionBody
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DocUnionBody.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sdatmessageunion.DocUnionBody";
        };

        DocUnionBody.AncillaryServiceProvidersWrapper = (function() {

            /**
             * Properties of an AncillaryServiceProvidersWrapper.
             * @memberof sdatmessageunion.DocUnionBody
             * @interface IAncillaryServiceProvidersWrapper
             * @property {Array.<google.protobuf.IStringValue>|null} [value] AncillaryServiceProvidersWrapper value
             */

            /**
             * Constructs a new AncillaryServiceProvidersWrapper.
             * @memberof sdatmessageunion.DocUnionBody
             * @classdesc Represents an AncillaryServiceProvidersWrapper.
             * @implements IAncillaryServiceProvidersWrapper
             * @constructor
             * @param {sdatmessageunion.DocUnionBody.IAncillaryServiceProvidersWrapper=} [properties] Properties to set
             */
            function AncillaryServiceProvidersWrapper(properties) {
                this.value = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AncillaryServiceProvidersWrapper value.
             * @member {Array.<google.protobuf.IStringValue>} value
             * @memberof sdatmessageunion.DocUnionBody.AncillaryServiceProvidersWrapper
             * @instance
             */
            AncillaryServiceProvidersWrapper.prototype.value = $util.emptyArray;

            /**
             * Gets the default type url for AncillaryServiceProvidersWrapper
             * @function getTypeUrl
             * @memberof sdatmessageunion.DocUnionBody.AncillaryServiceProvidersWrapper
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AncillaryServiceProvidersWrapper.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/sdatmessageunion.DocUnionBody.AncillaryServiceProvidersWrapper";
            };

            return AncillaryServiceProvidersWrapper;
        })();

        DocUnionBody.GridBillingMethodWrapper = (function() {

            /**
             * Properties of a GridBillingMethodWrapper.
             * @memberof sdatmessageunion.DocUnionBody
             * @interface IGridBillingMethodWrapper
             * @property {mp.GridBillingMethod|null} [value] GridBillingMethodWrapper value
             */

            /**
             * Constructs a new GridBillingMethodWrapper.
             * @memberof sdatmessageunion.DocUnionBody
             * @classdesc Represents a GridBillingMethodWrapper.
             * @implements IGridBillingMethodWrapper
             * @constructor
             * @param {sdatmessageunion.DocUnionBody.IGridBillingMethodWrapper=} [properties] Properties to set
             */
            function GridBillingMethodWrapper(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GridBillingMethodWrapper value.
             * @member {mp.GridBillingMethod} value
             * @memberof sdatmessageunion.DocUnionBody.GridBillingMethodWrapper
             * @instance
             */
            GridBillingMethodWrapper.prototype.value = 0;

            /**
             * Gets the default type url for GridBillingMethodWrapper
             * @function getTypeUrl
             * @memberof sdatmessageunion.DocUnionBody.GridBillingMethodWrapper
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GridBillingMethodWrapper.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/sdatmessageunion.DocUnionBody.GridBillingMethodWrapper";
            };

            return GridBillingMethodWrapper;
        })();

        return DocUnionBody;
    })();

    sdatmessageunion.DocUnion = (function() {

        /**
         * Properties of a DocUnion.
         * @memberof sdatmessageunion
         * @interface IDocUnion
         * @property {sdatmessageunion.IDocUnionHeader|null} [header] DocUnion header
         * @property {sdatmessageunion.IDocUnionBody|null} [body] DocUnion body
         */

        /**
         * Constructs a new DocUnion.
         * @memberof sdatmessageunion
         * @classdesc Represents a DocUnion.
         * @implements IDocUnion
         * @constructor
         * @param {sdatmessageunion.IDocUnion=} [properties] Properties to set
         */
        function DocUnion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DocUnion header.
         * @member {sdatmessageunion.IDocUnionHeader|null|undefined} header
         * @memberof sdatmessageunion.DocUnion
         * @instance
         */
        DocUnion.prototype.header = null;

        /**
         * DocUnion body.
         * @member {sdatmessageunion.IDocUnionBody|null|undefined} body
         * @memberof sdatmessageunion.DocUnion
         * @instance
         */
        DocUnion.prototype.body = null;

        /**
         * Gets the default type url for DocUnion
         * @function getTypeUrl
         * @memberof sdatmessageunion.DocUnion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DocUnion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sdatmessageunion.DocUnion";
        };

        return DocUnion;
    })();

    sdatmessageunion.SdatMessageUnion = (function() {

        /**
         * Properties of a SdatMessageUnion.
         * @memberof sdatmessageunion
         * @interface ISdatMessageUnion
         * @property {sdatmessageunion.IMessageUnionHeader|null} [header] SdatMessageUnion header
         * @property {sdatmessageunion.IDocUnion|null} [document] SdatMessageUnion document
         */

        /**
         * Constructs a new SdatMessageUnion.
         * @memberof sdatmessageunion
         * @classdesc Represents a SdatMessageUnion.
         * @implements ISdatMessageUnion
         * @constructor
         * @param {sdatmessageunion.ISdatMessageUnion=} [properties] Properties to set
         */
        function SdatMessageUnion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SdatMessageUnion header.
         * @member {sdatmessageunion.IMessageUnionHeader|null|undefined} header
         * @memberof sdatmessageunion.SdatMessageUnion
         * @instance
         */
        SdatMessageUnion.prototype.header = null;

        /**
         * SdatMessageUnion document.
         * @member {sdatmessageunion.IDocUnion|null|undefined} document
         * @memberof sdatmessageunion.SdatMessageUnion
         * @instance
         */
        SdatMessageUnion.prototype.document = null;

        /**
         * Gets the default type url for SdatMessageUnion
         * @function getTypeUrl
         * @memberof sdatmessageunion.SdatMessageUnion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SdatMessageUnion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sdatmessageunion.SdatMessageUnion";
        };

        return SdatMessageUnion;
    })();

    return sdatmessageunion;
})();

export const sdatwebclient = $root.sdatwebclient = (() => {

    /**
     * Namespace sdatwebclient.
     * @exports sdatwebclient
     * @namespace
     */
    const sdatwebclient = {};

    sdatwebclient.SdatWebClientDocumentService = (function() {

        /**
         * Constructs a new SdatWebClientDocumentService service.
         * @memberof sdatwebclient
         * @classdesc Represents a SdatWebClientDocumentService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function SdatWebClientDocumentService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (SdatWebClientDocumentService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SdatWebClientDocumentService;

        /**
         * Callback as used by {@link sdatwebclient.SdatWebClientDocumentService#getProcessDocumentDetails}.
         * @memberof sdatwebclient.SdatWebClientDocumentService
         * @typedef GetProcessDocumentDetailsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {sdatwebclient.ProcessDocumentDetails} [response] ProcessDocumentDetails
         */

        /**
         * Calls GetProcessDocumentDetails.
         * @function getProcessDocumentDetails
         * @memberof sdatwebclient.SdatWebClientDocumentService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @param {sdatwebclient.SdatWebClientDocumentService.GetProcessDocumentDetailsCallback} callback Node-style callback called with the error, if any, and ProcessDocumentDetails
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(SdatWebClientDocumentService.prototype.getProcessDocumentDetails = function getProcessDocumentDetails(request, callback) {
            return this.rpcCall(getProcessDocumentDetails, $root.base.UuidAndPartnerId, $root.sdatwebclient.ProcessDocumentDetails, request, callback);
        }, "name", { value: "GetProcessDocumentDetails" });

        /**
         * Calls GetProcessDocumentDetails.
         * @function getProcessDocumentDetails
         * @memberof sdatwebclient.SdatWebClientDocumentService
         * @instance
         * @param {base.IUuidAndPartnerId} request UuidAndPartnerId message or plain object
         * @returns {Promise<sdatwebclient.ProcessDocumentDetails>} Promise
         * @variation 2
         */

        return SdatWebClientDocumentService;
    })();

    sdatwebclient.ProcessDocumentDetails = (function() {

        /**
         * Properties of a ProcessDocumentDetails.
         * @memberof sdatwebclient
         * @interface IProcessDocumentDetails
         * @property {Array.<sdatwebclient.IDocumentDetails>|null} [messages] ProcessDocumentDetails messages
         */

        /**
         * Constructs a new ProcessDocumentDetails.
         * @memberof sdatwebclient
         * @classdesc Represents a ProcessDocumentDetails.
         * @implements IProcessDocumentDetails
         * @constructor
         * @param {sdatwebclient.IProcessDocumentDetails=} [properties] Properties to set
         */
        function ProcessDocumentDetails(properties) {
            this.messages = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProcessDocumentDetails messages.
         * @member {Array.<sdatwebclient.IDocumentDetails>} messages
         * @memberof sdatwebclient.ProcessDocumentDetails
         * @instance
         */
        ProcessDocumentDetails.prototype.messages = $util.emptyArray;

        /**
         * Gets the default type url for ProcessDocumentDetails
         * @function getTypeUrl
         * @memberof sdatwebclient.ProcessDocumentDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ProcessDocumentDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sdatwebclient.ProcessDocumentDetails";
        };

        return ProcessDocumentDetails;
    })();

    sdatwebclient.DocumentDetails = (function() {

        /**
         * Properties of a DocumentDetails.
         * @memberof sdatwebclient
         * @interface IDocumentDetails
         * @property {messagestore.IWebClientDocument|null} [document] DocumentDetails document
         * @property {sdatmessageunion.ISdatMessageUnion|null} [content] DocumentDetails content
         */

        /**
         * Constructs a new DocumentDetails.
         * @memberof sdatwebclient
         * @classdesc Represents a DocumentDetails.
         * @implements IDocumentDetails
         * @constructor
         * @param {sdatwebclient.IDocumentDetails=} [properties] Properties to set
         */
        function DocumentDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DocumentDetails document.
         * @member {messagestore.IWebClientDocument|null|undefined} document
         * @memberof sdatwebclient.DocumentDetails
         * @instance
         */
        DocumentDetails.prototype.document = null;

        /**
         * DocumentDetails content.
         * @member {sdatmessageunion.ISdatMessageUnion|null|undefined} content
         * @memberof sdatwebclient.DocumentDetails
         * @instance
         */
        DocumentDetails.prototype.content = null;

        /**
         * Gets the default type url for DocumentDetails
         * @function getTypeUrl
         * @memberof sdatwebclient.DocumentDetails
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DocumentDetails.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sdatwebclient.DocumentDetails";
        };

        return DocumentDetails;
    })();

    return sdatwebclient;
})();

export const queue = $root.queue = (() => {

    /**
     * Namespace queue.
     * @exports queue
     * @namespace
     */
    const queue = {};

    /**
     * EchoTrackingDirection enum.
     * @name queue.EchoTrackingDirection
     * @enum {number}
     * @property {number} SHORTCUT_MESSAGE_DIRECTION_UNSPECIFIED=0 SHORTCUT_MESSAGE_DIRECTION_UNSPECIFIED value
     * @property {number} OUTGOING=1 OUTGOING value
     * @property {number} INCOMING_ACKNOWLEDGEMENT_OF_ACCEPTANCE=2 INCOMING_ACKNOWLEDGEMENT_OF_ACCEPTANCE value
     * @property {number} INCOMING_MODEL_ERROR_REPORT=3 INCOMING_MODEL_ERROR_REPORT value
     */
    queue.EchoTrackingDirection = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SHORTCUT_MESSAGE_DIRECTION_UNSPECIFIED"] = 0;
        values[valuesById[1] = "OUTGOING"] = 1;
        values[valuesById[2] = "INCOMING_ACKNOWLEDGEMENT_OF_ACCEPTANCE"] = 2;
        values[valuesById[3] = "INCOMING_MODEL_ERROR_REPORT"] = 3;
        return values;
    })();

    queue.EchoTrackingEvent = (function() {

        /**
         * Properties of an EchoTrackingEvent.
         * @memberof queue
         * @interface IEchoTrackingEvent
         * @property {queue.IMessageMetadata|null} [metadata] EchoTrackingEvent metadata
         * @property {queue.EchoTrackingDirection|null} [direction] EchoTrackingEvent direction
         * @property {queue.Queue|null} [forwardQueue] EchoTrackingEvent forwardQueue
         * @property {sdat.ISdatMessageReference|null} [reference] EchoTrackingEvent reference
         */

        /**
         * Constructs a new EchoTrackingEvent.
         * @memberof queue
         * @classdesc Represents an EchoTrackingEvent.
         * @implements IEchoTrackingEvent
         * @constructor
         * @param {queue.IEchoTrackingEvent=} [properties] Properties to set
         */
        function EchoTrackingEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EchoTrackingEvent metadata.
         * @member {queue.IMessageMetadata|null|undefined} metadata
         * @memberof queue.EchoTrackingEvent
         * @instance
         */
        EchoTrackingEvent.prototype.metadata = null;

        /**
         * EchoTrackingEvent direction.
         * @member {queue.EchoTrackingDirection} direction
         * @memberof queue.EchoTrackingEvent
         * @instance
         */
        EchoTrackingEvent.prototype.direction = 0;

        /**
         * EchoTrackingEvent forwardQueue.
         * @member {queue.Queue} forwardQueue
         * @memberof queue.EchoTrackingEvent
         * @instance
         */
        EchoTrackingEvent.prototype.forwardQueue = 0;

        /**
         * EchoTrackingEvent reference.
         * @member {sdat.ISdatMessageReference|null|undefined} reference
         * @memberof queue.EchoTrackingEvent
         * @instance
         */
        EchoTrackingEvent.prototype.reference = null;

        /**
         * Gets the default type url for EchoTrackingEvent
         * @function getTypeUrl
         * @memberof queue.EchoTrackingEvent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        EchoTrackingEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.EchoTrackingEvent";
        };

        return EchoTrackingEvent;
    })();

    queue.MessageMetadata = (function() {

        /**
         * Properties of a MessageMetadata.
         * @memberof queue
         * @interface IMessageMetadata
         * @property {google.protobuf.IStringValue|null} [uuid] MessageMetadata uuid
         * @property {google.protobuf.ITimestamp|null} [timestamp] MessageMetadata timestamp
         * @property {google.protobuf.IInt32Value|null} [senderId] MessageMetadata senderId
         * @property {google.protobuf.IInt32Value|null} [recipientId] MessageMetadata recipientId
         * @property {google.protobuf.IStringValue|null} [originalJwt] MessageMetadata originalJwt
         * @property {google.protobuf.IStringValue|null} [originalFilename] MessageMetadata originalFilename
         * @property {google.protobuf.IBytesValue|null} [key] MessageMetadata key
         * @property {common.SupportedCipherAlgorithm|null} [encryptionMode] MessageMetadata encryptionMode
         * @property {google.protobuf.IBytesValue|null} [iv] MessageMetadata iv
         */

        /**
         * Constructs a new MessageMetadata.
         * @memberof queue
         * @classdesc Represents a MessageMetadata.
         * @implements IMessageMetadata
         * @constructor
         * @param {queue.IMessageMetadata=} [properties] Properties to set
         */
        function MessageMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MessageMetadata uuid.
         * @member {google.protobuf.IStringValue|null|undefined} uuid
         * @memberof queue.MessageMetadata
         * @instance
         */
        MessageMetadata.prototype.uuid = null;

        /**
         * MessageMetadata timestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} timestamp
         * @memberof queue.MessageMetadata
         * @instance
         */
        MessageMetadata.prototype.timestamp = null;

        /**
         * MessageMetadata senderId.
         * @member {google.protobuf.IInt32Value|null|undefined} senderId
         * @memberof queue.MessageMetadata
         * @instance
         */
        MessageMetadata.prototype.senderId = null;

        /**
         * MessageMetadata recipientId.
         * @member {google.protobuf.IInt32Value|null|undefined} recipientId
         * @memberof queue.MessageMetadata
         * @instance
         */
        MessageMetadata.prototype.recipientId = null;

        /**
         * MessageMetadata originalJwt.
         * @member {google.protobuf.IStringValue|null|undefined} originalJwt
         * @memberof queue.MessageMetadata
         * @instance
         */
        MessageMetadata.prototype.originalJwt = null;

        /**
         * MessageMetadata originalFilename.
         * @member {google.protobuf.IStringValue|null|undefined} originalFilename
         * @memberof queue.MessageMetadata
         * @instance
         */
        MessageMetadata.prototype.originalFilename = null;

        /**
         * MessageMetadata key.
         * @member {google.protobuf.IBytesValue|null|undefined} key
         * @memberof queue.MessageMetadata
         * @instance
         */
        MessageMetadata.prototype.key = null;

        /**
         * MessageMetadata encryptionMode.
         * @member {common.SupportedCipherAlgorithm} encryptionMode
         * @memberof queue.MessageMetadata
         * @instance
         */
        MessageMetadata.prototype.encryptionMode = 0;

        /**
         * MessageMetadata iv.
         * @member {google.protobuf.IBytesValue|null|undefined} iv
         * @memberof queue.MessageMetadata
         * @instance
         */
        MessageMetadata.prototype.iv = null;

        /**
         * Gets the default type url for MessageMetadata
         * @function getTypeUrl
         * @memberof queue.MessageMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MessageMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.MessageMetadata";
        };

        return MessageMetadata;
    })();

    queue.ResentMessageMetadata = (function() {

        /**
         * Properties of a ResentMessageMetadata.
         * @memberof queue
         * @interface IResentMessageMetadata
         * @property {google.protobuf.ITimestamp|null} [timestamp] ResentMessageMetadata timestamp
         * @property {queue.IMessageMetadata|null} [messageMetadata] ResentMessageMetadata messageMetadata
         */

        /**
         * Constructs a new ResentMessageMetadata.
         * @memberof queue
         * @classdesc Represents a ResentMessageMetadata.
         * @implements IResentMessageMetadata
         * @constructor
         * @param {queue.IResentMessageMetadata=} [properties] Properties to set
         */
        function ResentMessageMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResentMessageMetadata timestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} timestamp
         * @memberof queue.ResentMessageMetadata
         * @instance
         */
        ResentMessageMetadata.prototype.timestamp = null;

        /**
         * ResentMessageMetadata messageMetadata.
         * @member {queue.IMessageMetadata|null|undefined} messageMetadata
         * @memberof queue.ResentMessageMetadata
         * @instance
         */
        ResentMessageMetadata.prototype.messageMetadata = null;

        /**
         * Gets the default type url for ResentMessageMetadata
         * @function getTypeUrl
         * @memberof queue.ResentMessageMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResentMessageMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.ResentMessageMetadata";
        };

        return ResentMessageMetadata;
    })();

    queue.ErrorNotification = (function() {

        /**
         * Properties of an ErrorNotification.
         * @memberof queue
         * @interface IErrorNotification
         * @property {queue.IMessageMetadata|null} [messageMetadata] ErrorNotification messageMetadata
         * @property {queue.ErrorOwner|null} [owner] ErrorNotification owner
         * @property {queue.IUserMessage|null} [userMessage] ErrorNotification userMessage
         * @property {queue.Queue|null} [resendQueue] ErrorNotification resendQueue
         */

        /**
         * Constructs a new ErrorNotification.
         * @memberof queue
         * @classdesc Represents an ErrorNotification.
         * @implements IErrorNotification
         * @constructor
         * @param {queue.IErrorNotification=} [properties] Properties to set
         */
        function ErrorNotification(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ErrorNotification messageMetadata.
         * @member {queue.IMessageMetadata|null|undefined} messageMetadata
         * @memberof queue.ErrorNotification
         * @instance
         */
        ErrorNotification.prototype.messageMetadata = null;

        /**
         * ErrorNotification owner.
         * @member {queue.ErrorOwner} owner
         * @memberof queue.ErrorNotification
         * @instance
         */
        ErrorNotification.prototype.owner = 0;

        /**
         * ErrorNotification userMessage.
         * @member {queue.IUserMessage|null|undefined} userMessage
         * @memberof queue.ErrorNotification
         * @instance
         */
        ErrorNotification.prototype.userMessage = null;

        /**
         * ErrorNotification resendQueue.
         * @member {queue.Queue} resendQueue
         * @memberof queue.ErrorNotification
         * @instance
         */
        ErrorNotification.prototype.resendQueue = 0;

        /**
         * Gets the default type url for ErrorNotification
         * @function getTypeUrl
         * @memberof queue.ErrorNotification
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ErrorNotification.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.ErrorNotification";
        };

        return ErrorNotification;
    })();

    queue.UserMessage = (function() {

        /**
         * Properties of a UserMessage.
         * @memberof queue
         * @interface IUserMessage
         * @property {Object.<string,queue.IMailContent>|null} [mapField] UserMessage mapField
         */

        /**
         * Constructs a new UserMessage.
         * @memberof queue
         * @classdesc Represents a UserMessage.
         * @implements IUserMessage
         * @constructor
         * @param {queue.IUserMessage=} [properties] Properties to set
         */
        function UserMessage(properties) {
            this.mapField = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserMessage mapField.
         * @member {Object.<string,queue.IMailContent>} mapField
         * @memberof queue.UserMessage
         * @instance
         */
        UserMessage.prototype.mapField = $util.emptyObject;

        /**
         * Gets the default type url for UserMessage
         * @function getTypeUrl
         * @memberof queue.UserMessage
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.UserMessage";
        };

        return UserMessage;
    })();

    queue.MailContent = (function() {

        /**
         * Properties of a MailContent.
         * @memberof queue
         * @interface IMailContent
         * @property {google.protobuf.IStringValue|null} [subject] MailContent subject
         * @property {google.protobuf.IStringValue|null} [body] MailContent body
         */

        /**
         * Constructs a new MailContent.
         * @memberof queue
         * @classdesc Represents a MailContent.
         * @implements IMailContent
         * @constructor
         * @param {queue.IMailContent=} [properties] Properties to set
         */
        function MailContent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MailContent subject.
         * @member {google.protobuf.IStringValue|null|undefined} subject
         * @memberof queue.MailContent
         * @instance
         */
        MailContent.prototype.subject = null;

        /**
         * MailContent body.
         * @member {google.protobuf.IStringValue|null|undefined} body
         * @memberof queue.MailContent
         * @instance
         */
        MailContent.prototype.body = null;

        /**
         * Gets the default type url for MailContent
         * @function getTypeUrl
         * @memberof queue.MailContent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MailContent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.MailContent";
        };

        return MailContent;
    })();

    /**
     * ErrorOwner enum.
     * @name queue.ErrorOwner
     * @enum {number}
     * @property {number} OPERATOR=0 OPERATOR value
     * @property {number} SENDER=1 SENDER value
     * @property {number} RECIPIENT=2 RECIPIENT value
     */
    queue.ErrorOwner = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OPERATOR"] = 0;
        values[valuesById[1] = "SENDER"] = 1;
        values[valuesById[2] = "RECIPIENT"] = 2;
        return values;
    })();

    /**
     * Queue enum.
     * @name queue.Queue
     * @enum {number}
     * @property {number} QUEUE_UNSPECIFIED=0 QUEUE_UNSPECIFIED value
     * @property {number} ROUTING=1 ROUTING value
     * @property {number} PROCESSING=2 PROCESSING value
     * @property {number} UPLOADS=3 UPLOADS value
     * @property {number} MESSAGE_STORE=4 MESSAGE_STORE value
     * @property {number} ERROR_MESSAGE=5 ERROR_MESSAGE value
     * @property {number} PARTNER_UPDATES=6 PARTNER_UPDATES value
     */
    queue.Queue = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "QUEUE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "ROUTING"] = 1;
        values[valuesById[2] = "PROCESSING"] = 2;
        values[valuesById[3] = "UPLOADS"] = 3;
        values[valuesById[4] = "MESSAGE_STORE"] = 4;
        values[valuesById[5] = "ERROR_MESSAGE"] = 5;
        values[valuesById[6] = "PARTNER_UPDATES"] = 6;
        return values;
    })();

    queue.TransferLogMetadata = (function() {

        /**
         * Properties of a TransferLogMetadata.
         * @memberof queue
         * @interface ITransferLogMetadata
         * @property {queue.IReportingMetadata|null} [metadata] TransferLogMetadata metadata
         * @property {queue.MessageEndPoint|null} [origin] TransferLogMetadata origin
         * @property {sdat.SdatMessageType|null} [messageType] TransferLogMetadata messageType
         * @property {queue.ISdatMessageData|null} [sdat] TransferLogMetadata sdat
         * @property {queue.IDeliveredData|null} [delivered] TransferLogMetadata delivered
         */

        /**
         * Constructs a new TransferLogMetadata.
         * @memberof queue
         * @classdesc Represents a TransferLogMetadata.
         * @implements ITransferLogMetadata
         * @constructor
         * @param {queue.ITransferLogMetadata=} [properties] Properties to set
         */
        function TransferLogMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TransferLogMetadata metadata.
         * @member {queue.IReportingMetadata|null|undefined} metadata
         * @memberof queue.TransferLogMetadata
         * @instance
         */
        TransferLogMetadata.prototype.metadata = null;

        /**
         * TransferLogMetadata origin.
         * @member {queue.MessageEndPoint} origin
         * @memberof queue.TransferLogMetadata
         * @instance
         */
        TransferLogMetadata.prototype.origin = 0;

        /**
         * TransferLogMetadata messageType.
         * @member {sdat.SdatMessageType} messageType
         * @memberof queue.TransferLogMetadata
         * @instance
         */
        TransferLogMetadata.prototype.messageType = 0;

        /**
         * TransferLogMetadata sdat.
         * @member {queue.ISdatMessageData|null|undefined} sdat
         * @memberof queue.TransferLogMetadata
         * @instance
         */
        TransferLogMetadata.prototype.sdat = null;

        /**
         * TransferLogMetadata delivered.
         * @member {queue.IDeliveredData|null|undefined} delivered
         * @memberof queue.TransferLogMetadata
         * @instance
         */
        TransferLogMetadata.prototype.delivered = null;

        /**
         * Gets the default type url for TransferLogMetadata
         * @function getTypeUrl
         * @memberof queue.TransferLogMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TransferLogMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.TransferLogMetadata";
        };

        return TransferLogMetadata;
    })();

    queue.TransferLogDelivered = (function() {

        /**
         * Properties of a TransferLogDelivered.
         * @memberof queue
         * @interface ITransferLogDelivered
         * @property {google.protobuf.IStringValue|null} [uuid] TransferLogDelivered uuid
         * @property {queue.IDeliveredData|null} [delivered] TransferLogDelivered delivered
         */

        /**
         * Constructs a new TransferLogDelivered.
         * @memberof queue
         * @classdesc Represents a TransferLogDelivered.
         * @implements ITransferLogDelivered
         * @constructor
         * @param {queue.ITransferLogDelivered=} [properties] Properties to set
         */
        function TransferLogDelivered(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TransferLogDelivered uuid.
         * @member {google.protobuf.IStringValue|null|undefined} uuid
         * @memberof queue.TransferLogDelivered
         * @instance
         */
        TransferLogDelivered.prototype.uuid = null;

        /**
         * TransferLogDelivered delivered.
         * @member {queue.IDeliveredData|null|undefined} delivered
         * @memberof queue.TransferLogDelivered
         * @instance
         */
        TransferLogDelivered.prototype.delivered = null;

        /**
         * Gets the default type url for TransferLogDelivered
         * @function getTypeUrl
         * @memberof queue.TransferLogDelivered
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TransferLogDelivered.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.TransferLogDelivered";
        };

        return TransferLogDelivered;
    })();

    queue.ReportingMetadata = (function() {

        /**
         * Properties of a ReportingMetadata.
         * @memberof queue
         * @interface IReportingMetadata
         * @property {google.protobuf.IStringValue|null} [uuid] ReportingMetadata uuid
         * @property {google.protobuf.ITimestamp|null} [timestamp] ReportingMetadata timestamp
         * @property {sdat.IParty|null} [sender] ReportingMetadata sender
         * @property {sdat.IParty|null} [recipient] ReportingMetadata recipient
         * @property {google.protobuf.IStringValue|null} [originalFilename] ReportingMetadata originalFilename
         */

        /**
         * Constructs a new ReportingMetadata.
         * @memberof queue
         * @classdesc Represents a ReportingMetadata.
         * @implements IReportingMetadata
         * @constructor
         * @param {queue.IReportingMetadata=} [properties] Properties to set
         */
        function ReportingMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReportingMetadata uuid.
         * @member {google.protobuf.IStringValue|null|undefined} uuid
         * @memberof queue.ReportingMetadata
         * @instance
         */
        ReportingMetadata.prototype.uuid = null;

        /**
         * ReportingMetadata timestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} timestamp
         * @memberof queue.ReportingMetadata
         * @instance
         */
        ReportingMetadata.prototype.timestamp = null;

        /**
         * ReportingMetadata sender.
         * @member {sdat.IParty|null|undefined} sender
         * @memberof queue.ReportingMetadata
         * @instance
         */
        ReportingMetadata.prototype.sender = null;

        /**
         * ReportingMetadata recipient.
         * @member {sdat.IParty|null|undefined} recipient
         * @memberof queue.ReportingMetadata
         * @instance
         */
        ReportingMetadata.prototype.recipient = null;

        /**
         * ReportingMetadata originalFilename.
         * @member {google.protobuf.IStringValue|null|undefined} originalFilename
         * @memberof queue.ReportingMetadata
         * @instance
         */
        ReportingMetadata.prototype.originalFilename = null;

        /**
         * Gets the default type url for ReportingMetadata
         * @function getTypeUrl
         * @memberof queue.ReportingMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ReportingMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.ReportingMetadata";
        };

        return ReportingMetadata;
    })();

    /**
     * MessageEndPoint enum.
     * @name queue.MessageEndPoint
     * @enum {number}
     * @property {number} MESSAGE_END_POINT_UNSPECIFIED=0 MESSAGE_END_POINT_UNSPECIFIED value
     * @property {number} FTP_EXTERNAL=1 FTP_EXTERNAL value
     * @property {number} FTP_LOCAL=2 FTP_LOCAL value
     * @property {number} SHORTCUT=3 SHORTCUT value
     * @property {number} SDAT_WEB_CLIENT=4 SDAT_WEB_CLIENT value
     * @property {number} ERROR_SENDER=5 ERROR_SENDER value
     * @property {number} ERROR_RECIPIENT=6 ERROR_RECIPIENT value
     * @property {number} ERROR_OPERATOR=7 ERROR_OPERATOR value
     * @property {number} METERING_DATA_SERVICE=8 METERING_DATA_SERVICE value
     */
    queue.MessageEndPoint = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MESSAGE_END_POINT_UNSPECIFIED"] = 0;
        values[valuesById[1] = "FTP_EXTERNAL"] = 1;
        values[valuesById[2] = "FTP_LOCAL"] = 2;
        values[valuesById[3] = "SHORTCUT"] = 3;
        values[valuesById[4] = "SDAT_WEB_CLIENT"] = 4;
        values[valuesById[5] = "ERROR_SENDER"] = 5;
        values[valuesById[6] = "ERROR_RECIPIENT"] = 6;
        values[valuesById[7] = "ERROR_OPERATOR"] = 7;
        values[valuesById[8] = "METERING_DATA_SERVICE"] = 8;
        return values;
    })();

    queue.SdatMessageData = (function() {

        /**
         * Properties of a SdatMessageData.
         * @memberof queue
         * @interface ISdatMessageData
         * @property {sdat.BusinessReason|null} [businessReason] SdatMessageData businessReason
         * @property {google.protobuf.IStringValue|null} [messageId] SdatMessageData messageId
         * @property {Array.<queue.ISdatDocumentData>|null} [documents] SdatMessageData documents
         * @property {google.protobuf.IStringValue|null} [messageReference] SdatMessageData messageReference
         */

        /**
         * Constructs a new SdatMessageData.
         * @memberof queue
         * @classdesc Represents a SdatMessageData.
         * @implements ISdatMessageData
         * @constructor
         * @param {queue.ISdatMessageData=} [properties] Properties to set
         */
        function SdatMessageData(properties) {
            this.documents = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SdatMessageData businessReason.
         * @member {sdat.BusinessReason} businessReason
         * @memberof queue.SdatMessageData
         * @instance
         */
        SdatMessageData.prototype.businessReason = 0;

        /**
         * SdatMessageData messageId.
         * @member {google.protobuf.IStringValue|null|undefined} messageId
         * @memberof queue.SdatMessageData
         * @instance
         */
        SdatMessageData.prototype.messageId = null;

        /**
         * SdatMessageData documents.
         * @member {Array.<queue.ISdatDocumentData>} documents
         * @memberof queue.SdatMessageData
         * @instance
         */
        SdatMessageData.prototype.documents = $util.emptyArray;

        /**
         * SdatMessageData messageReference.
         * @member {google.protobuf.IStringValue|null|undefined} messageReference
         * @memberof queue.SdatMessageData
         * @instance
         */
        SdatMessageData.prototype.messageReference = null;

        /**
         * Gets the default type url for SdatMessageData
         * @function getTypeUrl
         * @memberof queue.SdatMessageData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SdatMessageData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.SdatMessageData";
        };

        return SdatMessageData;
    })();

    queue.SdatDocumentData = (function() {

        /**
         * Properties of a SdatDocumentData.
         * @memberof queue
         * @interface ISdatDocumentData
         * @property {google.protobuf.IStringValue|null} [documentId] SdatDocumentData documentId
         * @property {google.protobuf.IStringValue|null} [sdatProcessId] SdatDocumentData sdatProcessId
         * @property {google.protobuf.IStringValue|null} [documentReference] SdatDocumentData documentReference
         */

        /**
         * Constructs a new SdatDocumentData.
         * @memberof queue
         * @classdesc Represents a SdatDocumentData.
         * @implements ISdatDocumentData
         * @constructor
         * @param {queue.ISdatDocumentData=} [properties] Properties to set
         */
        function SdatDocumentData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SdatDocumentData documentId.
         * @member {google.protobuf.IStringValue|null|undefined} documentId
         * @memberof queue.SdatDocumentData
         * @instance
         */
        SdatDocumentData.prototype.documentId = null;

        /**
         * SdatDocumentData sdatProcessId.
         * @member {google.protobuf.IStringValue|null|undefined} sdatProcessId
         * @memberof queue.SdatDocumentData
         * @instance
         */
        SdatDocumentData.prototype.sdatProcessId = null;

        /**
         * SdatDocumentData documentReference.
         * @member {google.protobuf.IStringValue|null|undefined} documentReference
         * @memberof queue.SdatDocumentData
         * @instance
         */
        SdatDocumentData.prototype.documentReference = null;

        /**
         * Gets the default type url for SdatDocumentData
         * @function getTypeUrl
         * @memberof queue.SdatDocumentData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SdatDocumentData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.SdatDocumentData";
        };

        return SdatDocumentData;
    })();

    queue.DeliveredData = (function() {

        /**
         * Properties of a DeliveredData.
         * @memberof queue
         * @interface IDeliveredData
         * @property {queue.MessageEndPoint|null} [destination] DeliveredData destination
         * @property {google.protobuf.ITimestamp|null} [timestamp] DeliveredData timestamp
         */

        /**
         * Constructs a new DeliveredData.
         * @memberof queue
         * @classdesc Represents a DeliveredData.
         * @implements IDeliveredData
         * @constructor
         * @param {queue.IDeliveredData=} [properties] Properties to set
         */
        function DeliveredData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeliveredData destination.
         * @member {queue.MessageEndPoint} destination
         * @memberof queue.DeliveredData
         * @instance
         */
        DeliveredData.prototype.destination = 0;

        /**
         * DeliveredData timestamp.
         * @member {google.protobuf.ITimestamp|null|undefined} timestamp
         * @memberof queue.DeliveredData
         * @instance
         */
        DeliveredData.prototype.timestamp = null;

        /**
         * Gets the default type url for DeliveredData
         * @function getTypeUrl
         * @memberof queue.DeliveredData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeliveredData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.DeliveredData";
        };

        return DeliveredData;
    })();

    queue.DocumentMetadata = (function() {

        /**
         * Properties of a DocumentMetadata.
         * @memberof queue
         * @interface IDocumentMetadata
         * @property {queue.IMessageMetadata|null} [metadata] DocumentMetadata metadata
         * @property {google.protobuf.IStringValue|null} [documentId] DocumentMetadata documentId
         * @property {sdat.BusinessReason|null} [businessReason] DocumentMetadata businessReason
         * @property {sdat.MessageClass|null} [messageClass] DocumentMetadata messageClass
         * @property {queue.NewProcessType|null} [newProcessType] DocumentMetadata newProcessType
         * @property {google.protobuf.IStringValue|null} [sdatProcessId] DocumentMetadata sdatProcessId
         * @property {google.protobuf.IStringValue|null} [documentReference] DocumentMetadata documentReference
         * @property {base.Direction|null} [direction] DocumentMetadata direction
         * @property {google.protobuf.IStringValue|null} [messageId] DocumentMetadata messageId
         * @property {google.protobuf.IBoolValue|null} [ackOfAcc] DocumentMetadata ackOfAcc
         */

        /**
         * Constructs a new DocumentMetadata.
         * @memberof queue
         * @classdesc Represents a DocumentMetadata.
         * @implements IDocumentMetadata
         * @constructor
         * @param {queue.IDocumentMetadata=} [properties] Properties to set
         */
        function DocumentMetadata(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DocumentMetadata metadata.
         * @member {queue.IMessageMetadata|null|undefined} metadata
         * @memberof queue.DocumentMetadata
         * @instance
         */
        DocumentMetadata.prototype.metadata = null;

        /**
         * DocumentMetadata documentId.
         * @member {google.protobuf.IStringValue|null|undefined} documentId
         * @memberof queue.DocumentMetadata
         * @instance
         */
        DocumentMetadata.prototype.documentId = null;

        /**
         * DocumentMetadata businessReason.
         * @member {sdat.BusinessReason} businessReason
         * @memberof queue.DocumentMetadata
         * @instance
         */
        DocumentMetadata.prototype.businessReason = 0;

        /**
         * DocumentMetadata messageClass.
         * @member {sdat.MessageClass} messageClass
         * @memberof queue.DocumentMetadata
         * @instance
         */
        DocumentMetadata.prototype.messageClass = 0;

        /**
         * DocumentMetadata newProcessType.
         * @member {queue.NewProcessType} newProcessType
         * @memberof queue.DocumentMetadata
         * @instance
         */
        DocumentMetadata.prototype.newProcessType = 0;

        /**
         * DocumentMetadata sdatProcessId.
         * @member {google.protobuf.IStringValue|null|undefined} sdatProcessId
         * @memberof queue.DocumentMetadata
         * @instance
         */
        DocumentMetadata.prototype.sdatProcessId = null;

        /**
         * DocumentMetadata documentReference.
         * @member {google.protobuf.IStringValue|null|undefined} documentReference
         * @memberof queue.DocumentMetadata
         * @instance
         */
        DocumentMetadata.prototype.documentReference = null;

        /**
         * DocumentMetadata direction.
         * @member {base.Direction} direction
         * @memberof queue.DocumentMetadata
         * @instance
         */
        DocumentMetadata.prototype.direction = 0;

        /**
         * DocumentMetadata messageId.
         * @member {google.protobuf.IStringValue|null|undefined} messageId
         * @memberof queue.DocumentMetadata
         * @instance
         */
        DocumentMetadata.prototype.messageId = null;

        /**
         * DocumentMetadata ackOfAcc.
         * @member {google.protobuf.IBoolValue|null|undefined} ackOfAcc
         * @memberof queue.DocumentMetadata
         * @instance
         */
        DocumentMetadata.prototype.ackOfAcc = null;

        /**
         * Gets the default type url for DocumentMetadata
         * @function getTypeUrl
         * @memberof queue.DocumentMetadata
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DocumentMetadata.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/queue.DocumentMetadata";
        };

        return DocumentMetadata;
    })();

    /**
     * NewProcessType enum.
     * @name queue.NewProcessType
     * @enum {number}
     * @property {number} NEW_PROCESS_TYPE_UNSPECIFIED=0 NEW_PROCESS_TYPE_UNSPECIFIED value
     * @property {number} NEW_PROCESS=1 NEW_PROCESS value
     * @property {number} TRY_TO_ATTACH_TO_EXISTING=2 TRY_TO_ATTACH_TO_EXISTING value
     */
    queue.NewProcessType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NEW_PROCESS_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "NEW_PROCESS"] = 1;
        values[valuesById[2] = "TRY_TO_ATTACH_TO_EXISTING"] = 2;
        return values;
    })();

    return queue;
})();

export const reporting = $root.reporting = (() => {

    /**
     * Namespace reporting.
     * @exports reporting
     * @namespace
     */
    const reporting = {};

    reporting.ReportingService = (function() {

        /**
         * Constructs a new ReportingService service.
         * @memberof reporting
         * @classdesc Represents a ReportingService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ReportingService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ReportingService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ReportingService;

        /**
         * Callback as used by {@link reporting.ReportingService#getReport}.
         * @memberof reporting.ReportingService
         * @typedef GetReportCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {base.DownloadFileResponse} [response] DownloadFileResponse
         */

        /**
         * Calls GetReport.
         * @function getReport
         * @memberof reporting.ReportingService
         * @instance
         * @param {reporting.IGetReportRequest} request GetReportRequest message or plain object
         * @param {reporting.ReportingService.GetReportCallback} callback Node-style callback called with the error, if any, and DownloadFileResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ReportingService.prototype.getReport = function getReport(request, callback) {
            return this.rpcCall(getReport, $root.reporting.GetReportRequest, $root.base.DownloadFileResponse, request, callback);
        }, "name", { value: "GetReport" });

        /**
         * Calls GetReport.
         * @function getReport
         * @memberof reporting.ReportingService
         * @instance
         * @param {reporting.IGetReportRequest} request GetReportRequest message or plain object
         * @returns {Promise<base.DownloadFileResponse>} Promise
         * @variation 2
         */

        return ReportingService;
    })();

    reporting.GetReportRequest = (function() {

        /**
         * Properties of a GetReportRequest.
         * @memberof reporting
         * @interface IGetReportRequest
         * @property {google.protobuf.IInt32Value|null} [partnerId] GetReportRequest partnerId
         * @property {google.type.IDate|null} [startDate] GetReportRequest startDate
         * @property {google.type.IDate|null} [endDate] GetReportRequest endDate
         * @property {base.Language|null} [language] GetReportRequest language
         * @property {reporting.ReportType|null} [reportType] GetReportRequest reportType
         */

        /**
         * Constructs a new GetReportRequest.
         * @memberof reporting
         * @classdesc Represents a GetReportRequest.
         * @implements IGetReportRequest
         * @constructor
         * @param {reporting.IGetReportRequest=} [properties] Properties to set
         */
        function GetReportRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetReportRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof reporting.GetReportRequest
         * @instance
         */
        GetReportRequest.prototype.partnerId = null;

        /**
         * GetReportRequest startDate.
         * @member {google.type.IDate|null|undefined} startDate
         * @memberof reporting.GetReportRequest
         * @instance
         */
        GetReportRequest.prototype.startDate = null;

        /**
         * GetReportRequest endDate.
         * @member {google.type.IDate|null|undefined} endDate
         * @memberof reporting.GetReportRequest
         * @instance
         */
        GetReportRequest.prototype.endDate = null;

        /**
         * GetReportRequest language.
         * @member {base.Language} language
         * @memberof reporting.GetReportRequest
         * @instance
         */
        GetReportRequest.prototype.language = 0;

        /**
         * GetReportRequest reportType.
         * @member {reporting.ReportType} reportType
         * @memberof reporting.GetReportRequest
         * @instance
         */
        GetReportRequest.prototype.reportType = 0;

        /**
         * Gets the default type url for GetReportRequest
         * @function getTypeUrl
         * @memberof reporting.GetReportRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetReportRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/reporting.GetReportRequest";
        };

        return GetReportRequest;
    })();

    /**
     * ReportType enum.
     * @name reporting.ReportType
     * @enum {number}
     * @property {number} REPORT_TYPE_UNSPECIFIED=0 REPORT_TYPE_UNSPECIFIED value
     * @property {number} STATISTICS=1 STATISTICS value
     * @property {number} TRANSFER_LOG=2 TRANSFER_LOG value
     */
    reporting.ReportType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "REPORT_TYPE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "STATISTICS"] = 1;
        values[valuesById[2] = "TRANSFER_LOG"] = 2;
        return values;
    })();

    return reporting;
})();

export const routingcore = $root.routingcore = (() => {

    /**
     * Namespace routingcore.
     * @exports routingcore
     * @namespace
     */
    const routingcore = {};

    routingcore.RoutingCoreService = (function() {

        /**
         * Constructs a new RoutingCoreService service.
         * @memberof routingcore
         * @classdesc Represents a RoutingCoreService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function RoutingCoreService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (RoutingCoreService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RoutingCoreService;

        return RoutingCoreService;
    })();

    return routingcore;
})();

export const user = $root.user = (() => {

    /**
     * Namespace user.
     * @exports user
     * @namespace
     */
    const user = {};

    user.UserService = (function() {

        /**
         * Constructs a new UserService service.
         * @memberof user
         * @classdesc Represents a UserService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function UserService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (UserService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = UserService;

        /**
         * Callback as used by {@link user.UserService#inviteMarketPartnerUser}.
         * @memberof user.UserService
         * @typedef InviteMarketPartnerUserCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls InviteMarketPartnerUser.
         * @function inviteMarketPartnerUser
         * @memberof user.UserService
         * @instance
         * @param {user.IInvitePartnerUserRequest} request InvitePartnerUserRequest message or plain object
         * @param {user.UserService.InviteMarketPartnerUserCallback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.inviteMarketPartnerUser = function inviteMarketPartnerUser(request, callback) {
            return this.rpcCall(inviteMarketPartnerUser, $root.user.InvitePartnerUserRequest, $root.user.User, request, callback);
        }, "name", { value: "InviteMarketPartnerUser" });

        /**
         * Calls InviteMarketPartnerUser.
         * @function inviteMarketPartnerUser
         * @memberof user.UserService
         * @instance
         * @param {user.IInvitePartnerUserRequest} request InvitePartnerUserRequest message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#inviteCompanyPartnerUser}.
         * @memberof user.UserService
         * @typedef InviteCompanyPartnerUserCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls InviteCompanyPartnerUser.
         * @function inviteCompanyPartnerUser
         * @memberof user.UserService
         * @instance
         * @param {user.IInvitePartnerUserRequest} request InvitePartnerUserRequest message or plain object
         * @param {user.UserService.InviteCompanyPartnerUserCallback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.inviteCompanyPartnerUser = function inviteCompanyPartnerUser(request, callback) {
            return this.rpcCall(inviteCompanyPartnerUser, $root.user.InvitePartnerUserRequest, $root.user.User, request, callback);
        }, "name", { value: "InviteCompanyPartnerUser" });

        /**
         * Calls InviteCompanyPartnerUser.
         * @function inviteCompanyPartnerUser
         * @memberof user.UserService
         * @instance
         * @param {user.IInvitePartnerUserRequest} request InvitePartnerUserRequest message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#createOperatorUser}.
         * @memberof user.UserService
         * @typedef CreateOperatorUserCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls CreateOperatorUser.
         * @function createOperatorUser
         * @memberof user.UserService
         * @instance
         * @param {user.ICreateOperatorUserRequest} request CreateOperatorUserRequest message or plain object
         * @param {user.UserService.CreateOperatorUserCallback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.createOperatorUser = function createOperatorUser(request, callback) {
            return this.rpcCall(createOperatorUser, $root.user.CreateOperatorUserRequest, $root.user.User, request, callback);
        }, "name", { value: "CreateOperatorUser" });

        /**
         * Calls CreateOperatorUser.
         * @function createOperatorUser
         * @memberof user.UserService
         * @instance
         * @param {user.ICreateOperatorUserRequest} request CreateOperatorUserRequest message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#getUser}.
         * @memberof user.UserService
         * @typedef GetUserCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls GetUser.
         * @function getUser
         * @memberof user.UserService
         * @instance
         * @param {user.IGetUserRequest} request GetUserRequest message or plain object
         * @param {user.UserService.GetUserCallback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.getUser = function getUser(request, callback) {
            return this.rpcCall(getUser, $root.user.GetUserRequest, $root.user.User, request, callback);
        }, "name", { value: "GetUser" });

        /**
         * Calls GetUser.
         * @function getUser
         * @memberof user.UserService
         * @instance
         * @param {user.IGetUserRequest} request GetUserRequest message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#getUsers}.
         * @memberof user.UserService
         * @typedef GetUsersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.Users} [response] Users
         */

        /**
         * Calls GetUsers.
         * @function getUsers
         * @memberof user.UserService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {user.UserService.GetUsersCallback} callback Node-style callback called with the error, if any, and Users
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.getUsers = function getUsers(request, callback) {
            return this.rpcCall(getUsers, $root.google.protobuf.Empty, $root.user.Users, request, callback);
        }, "name", { value: "GetUsers" });

        /**
         * Calls GetUsers.
         * @function getUsers
         * @memberof user.UserService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<user.Users>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#updateUserProfile}.
         * @memberof user.UserService
         * @typedef UpdateUserProfileCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls UpdateUserProfile.
         * @function updateUserProfile
         * @memberof user.UserService
         * @instance
         * @param {user.IUpdateUserRequest} request UpdateUserRequest message or plain object
         * @param {user.UserService.UpdateUserProfileCallback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.updateUserProfile = function updateUserProfile(request, callback) {
            return this.rpcCall(updateUserProfile, $root.user.UpdateUserRequest, $root.user.User, request, callback);
        }, "name", { value: "UpdateUserProfile" });

        /**
         * Calls UpdateUserProfile.
         * @function updateUserProfile
         * @memberof user.UserService
         * @instance
         * @param {user.IUpdateUserRequest} request UpdateUserRequest message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#updateUserPassword}.
         * @memberof user.UserService
         * @typedef UpdateUserPasswordCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls UpdateUserPassword.
         * @function updateUserPassword
         * @memberof user.UserService
         * @instance
         * @param {user.IUpdateUserPasswordRequest} request UpdateUserPasswordRequest message or plain object
         * @param {user.UserService.UpdateUserPasswordCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.updateUserPassword = function updateUserPassword(request, callback) {
            return this.rpcCall(updateUserPassword, $root.user.UpdateUserPasswordRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "UpdateUserPassword" });

        /**
         * Calls UpdateUserPassword.
         * @function updateUserPassword
         * @memberof user.UserService
         * @instance
         * @param {user.IUpdateUserPasswordRequest} request UpdateUserPasswordRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#deleteUser}.
         * @memberof user.UserService
         * @typedef DeleteUserCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteUser.
         * @function deleteUser
         * @memberof user.UserService
         * @instance
         * @param {user.IDeleteUserRequest} request DeleteUserRequest message or plain object
         * @param {user.UserService.DeleteUserCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.deleteUser = function deleteUser(request, callback) {
            return this.rpcCall(deleteUser, $root.user.DeleteUserRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteUser" });

        /**
         * Calls DeleteUser.
         * @function deleteUser
         * @memberof user.UserService
         * @instance
         * @param {user.IDeleteUserRequest} request DeleteUserRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#sendOtpForPasswordReset}.
         * @memberof user.UserService
         * @typedef SendOtpForPasswordResetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls SendOtpForPasswordReset.
         * @function sendOtpForPasswordReset
         * @memberof user.UserService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {user.UserService.SendOtpForPasswordResetCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.sendOtpForPasswordReset = function sendOtpForPasswordReset(request, callback) {
            return this.rpcCall(sendOtpForPasswordReset, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "SendOtpForPasswordReset" });

        /**
         * Calls SendOtpForPasswordReset.
         * @function sendOtpForPasswordReset
         * @memberof user.UserService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#resetUserPassword}.
         * @memberof user.UserService
         * @typedef ResetUserPasswordCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls ResetUserPassword.
         * @function resetUserPassword
         * @memberof user.UserService
         * @instance
         * @param {user.IResetUserPasswordRequest} request ResetUserPasswordRequest message or plain object
         * @param {user.UserService.ResetUserPasswordCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.resetUserPassword = function resetUserPassword(request, callback) {
            return this.rpcCall(resetUserPassword, $root.user.ResetUserPasswordRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "ResetUserPassword" });

        /**
         * Calls ResetUserPassword.
         * @function resetUserPassword
         * @memberof user.UserService
         * @instance
         * @param {user.IResetUserPasswordRequest} request ResetUserPasswordRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#resetUserPhoneNumber}.
         * @memberof user.UserService
         * @typedef ResetUserPhoneNumberCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls ResetUserPhoneNumber.
         * @function resetUserPhoneNumber
         * @memberof user.UserService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @param {user.UserService.ResetUserPhoneNumberCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.resetUserPhoneNumber = function resetUserPhoneNumber(request, callback) {
            return this.rpcCall(resetUserPhoneNumber, $root.google.protobuf.Empty, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "ResetUserPhoneNumber" });

        /**
         * Calls ResetUserPhoneNumber.
         * @function resetUserPhoneNumber
         * @memberof user.UserService
         * @instance
         * @param {google.protobuf.IEmpty} request Empty message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#completeUserInvitation}.
         * @memberof user.UserService
         * @typedef CompleteUserInvitationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls CompleteUserInvitation.
         * @function completeUserInvitation
         * @memberof user.UserService
         * @instance
         * @param {user.ICompleteUserInvitationRequest} request CompleteUserInvitationRequest message or plain object
         * @param {user.UserService.CompleteUserInvitationCallback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.completeUserInvitation = function completeUserInvitation(request, callback) {
            return this.rpcCall(completeUserInvitation, $root.user.CompleteUserInvitationRequest, $root.user.User, request, callback);
        }, "name", { value: "CompleteUserInvitation" });

        /**
         * Calls CompleteUserInvitation.
         * @function completeUserInvitation
         * @memberof user.UserService
         * @instance
         * @param {user.ICompleteUserInvitationRequest} request CompleteUserInvitationRequest message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#completeUserPasswordReset}.
         * @memberof user.UserService
         * @typedef CompleteUserPasswordResetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls CompleteUserPasswordReset.
         * @function completeUserPasswordReset
         * @memberof user.UserService
         * @instance
         * @param {user.IUpdateUserPasswordRequest} request UpdateUserPasswordRequest message or plain object
         * @param {user.UserService.CompleteUserPasswordResetCallback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.completeUserPasswordReset = function completeUserPasswordReset(request, callback) {
            return this.rpcCall(completeUserPasswordReset, $root.user.UpdateUserPasswordRequest, $root.user.User, request, callback);
        }, "name", { value: "CompleteUserPasswordReset" });

        /**
         * Calls CompleteUserPasswordReset.
         * @function completeUserPasswordReset
         * @memberof user.UserService
         * @instance
         * @param {user.IUpdateUserPasswordRequest} request UpdateUserPasswordRequest message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#completeUserPhoneNumberReset}.
         * @memberof user.UserService
         * @typedef CompleteUserPhoneNumberResetCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls CompleteUserPhoneNumberReset.
         * @function completeUserPhoneNumberReset
         * @memberof user.UserService
         * @instance
         * @param {user.IUpdateUserPhoneNumberRequest} request UpdateUserPhoneNumberRequest message or plain object
         * @param {user.UserService.CompleteUserPhoneNumberResetCallback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.completeUserPhoneNumberReset = function completeUserPhoneNumberReset(request, callback) {
            return this.rpcCall(completeUserPhoneNumberReset, $root.user.UpdateUserPhoneNumberRequest, $root.user.User, request, callback);
        }, "name", { value: "CompleteUserPhoneNumberReset" });

        /**
         * Calls CompleteUserPhoneNumberReset.
         * @function completeUserPhoneNumberReset
         * @memberof user.UserService
         * @instance
         * @param {user.IUpdateUserPhoneNumberRequest} request UpdateUserPhoneNumberRequest message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#resetUser}.
         * @memberof user.UserService
         * @typedef ResetUserCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls ResetUser.
         * @function resetUser
         * @memberof user.UserService
         * @instance
         * @param {user.IGetUserRequest} request GetUserRequest message or plain object
         * @param {user.UserService.ResetUserCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.resetUser = function resetUser(request, callback) {
            return this.rpcCall(resetUser, $root.user.GetUserRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "ResetUser" });

        /**
         * Calls ResetUser.
         * @function resetUser
         * @memberof user.UserService
         * @instance
         * @param {user.IGetUserRequest} request GetUserRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#getUserPartnerRelationship}.
         * @memberof user.UserService
         * @typedef GetUserPartnerRelationshipCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.UserPartnerRelationship} [response] UserPartnerRelationship
         */

        /**
         * Calls GetUserPartnerRelationship.
         * @function getUserPartnerRelationship
         * @memberof user.UserService
         * @instance
         * @param {user.IUserPartnerRelationshipRequest} request UserPartnerRelationshipRequest message or plain object
         * @param {user.UserService.GetUserPartnerRelationshipCallback} callback Node-style callback called with the error, if any, and UserPartnerRelationship
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.getUserPartnerRelationship = function getUserPartnerRelationship(request, callback) {
            return this.rpcCall(getUserPartnerRelationship, $root.user.UserPartnerRelationshipRequest, $root.user.UserPartnerRelationship, request, callback);
        }, "name", { value: "GetUserPartnerRelationship" });

        /**
         * Calls GetUserPartnerRelationship.
         * @function getUserPartnerRelationship
         * @memberof user.UserService
         * @instance
         * @param {user.IUserPartnerRelationshipRequest} request UserPartnerRelationshipRequest message or plain object
         * @returns {Promise<user.UserPartnerRelationship>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#getUserRelationships}.
         * @memberof user.UserService
         * @typedef GetUserRelationshipsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.UserPartnerRelationships} [response] UserPartnerRelationships
         */

        /**
         * Calls GetUserRelationships.
         * @function getUserRelationships
         * @memberof user.UserService
         * @instance
         * @param {user.IGetUserRequest} request GetUserRequest message or plain object
         * @param {user.UserService.GetUserRelationshipsCallback} callback Node-style callback called with the error, if any, and UserPartnerRelationships
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.getUserRelationships = function getUserRelationships(request, callback) {
            return this.rpcCall(getUserRelationships, $root.user.GetUserRequest, $root.user.UserPartnerRelationships, request, callback);
        }, "name", { value: "GetUserRelationships" });

        /**
         * Calls GetUserRelationships.
         * @function getUserRelationships
         * @memberof user.UserService
         * @instance
         * @param {user.IGetUserRequest} request GetUserRequest message or plain object
         * @returns {Promise<user.UserPartnerRelationships>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#getPartnerRelationships}.
         * @memberof user.UserService
         * @typedef GetPartnerRelationshipsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.UserPartnerRelationships} [response] UserPartnerRelationships
         */

        /**
         * Calls GetPartnerRelationships.
         * @function getPartnerRelationships
         * @memberof user.UserService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @param {user.UserService.GetPartnerRelationshipsCallback} callback Node-style callback called with the error, if any, and UserPartnerRelationships
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.getPartnerRelationships = function getPartnerRelationships(request, callback) {
            return this.rpcCall(getPartnerRelationships, $root.base.PartnerRequest, $root.user.UserPartnerRelationships, request, callback);
        }, "name", { value: "GetPartnerRelationships" });

        /**
         * Calls GetPartnerRelationships.
         * @function getPartnerRelationships
         * @memberof user.UserService
         * @instance
         * @param {base.IPartnerRequest} request PartnerRequest message or plain object
         * @returns {Promise<user.UserPartnerRelationships>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#updateUserPartnerRelationship}.
         * @memberof user.UserService
         * @typedef UpdateUserPartnerRelationshipCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.UserPartnerRelationship} [response] UserPartnerRelationship
         */

        /**
         * Calls UpdateUserPartnerRelationship.
         * @function updateUserPartnerRelationship
         * @memberof user.UserService
         * @instance
         * @param {user.IUserPartnerRelationship} request UserPartnerRelationship message or plain object
         * @param {user.UserService.UpdateUserPartnerRelationshipCallback} callback Node-style callback called with the error, if any, and UserPartnerRelationship
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.updateUserPartnerRelationship = function updateUserPartnerRelationship(request, callback) {
            return this.rpcCall(updateUserPartnerRelationship, $root.user.UserPartnerRelationship, $root.user.UserPartnerRelationship, request, callback);
        }, "name", { value: "UpdateUserPartnerRelationship" });

        /**
         * Calls UpdateUserPartnerRelationship.
         * @function updateUserPartnerRelationship
         * @memberof user.UserService
         * @instance
         * @param {user.IUserPartnerRelationship} request UserPartnerRelationship message or plain object
         * @returns {Promise<user.UserPartnerRelationship>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#deleteUserPartnerRelationship}.
         * @memberof user.UserService
         * @typedef DeleteUserPartnerRelationshipCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {google.protobuf.Empty} [response] Empty
         */

        /**
         * Calls DeleteUserPartnerRelationship.
         * @function deleteUserPartnerRelationship
         * @memberof user.UserService
         * @instance
         * @param {user.IUserPartnerRelationshipRequest} request UserPartnerRelationshipRequest message or plain object
         * @param {user.UserService.DeleteUserPartnerRelationshipCallback} callback Node-style callback called with the error, if any, and Empty
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.deleteUserPartnerRelationship = function deleteUserPartnerRelationship(request, callback) {
            return this.rpcCall(deleteUserPartnerRelationship, $root.user.UserPartnerRelationshipRequest, $root.google.protobuf.Empty, request, callback);
        }, "name", { value: "DeleteUserPartnerRelationship" });

        /**
         * Calls DeleteUserPartnerRelationship.
         * @function deleteUserPartnerRelationship
         * @memberof user.UserService
         * @instance
         * @param {user.IUserPartnerRelationshipRequest} request UserPartnerRelationshipRequest message or plain object
         * @returns {Promise<google.protobuf.Empty>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#createMarketPartnerAndAdmin}.
         * @memberof user.UserService
         * @typedef CreateMarketPartnerAndAdminCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.CreateMarketPartnerAndAdminResponse} [response] CreateMarketPartnerAndAdminResponse
         */

        /**
         * Calls CreateMarketPartnerAndAdmin.
         * @function createMarketPartnerAndAdmin
         * @memberof user.UserService
         * @instance
         * @param {user.ICreateMarketPartnerAndAdminRequest} request CreateMarketPartnerAndAdminRequest message or plain object
         * @param {user.UserService.CreateMarketPartnerAndAdminCallback} callback Node-style callback called with the error, if any, and CreateMarketPartnerAndAdminResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.createMarketPartnerAndAdmin = function createMarketPartnerAndAdmin(request, callback) {
            return this.rpcCall(createMarketPartnerAndAdmin, $root.user.CreateMarketPartnerAndAdminRequest, $root.user.CreateMarketPartnerAndAdminResponse, request, callback);
        }, "name", { value: "CreateMarketPartnerAndAdmin" });

        /**
         * Calls CreateMarketPartnerAndAdmin.
         * @function createMarketPartnerAndAdmin
         * @memberof user.UserService
         * @instance
         * @param {user.ICreateMarketPartnerAndAdminRequest} request CreateMarketPartnerAndAdminRequest message or plain object
         * @returns {Promise<user.CreateMarketPartnerAndAdminResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#createCompanyPartnerAndAdmin}.
         * @memberof user.UserService
         * @typedef CreateCompanyPartnerAndAdminCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.CreateCompanyPartnerAndAdminResponse} [response] CreateCompanyPartnerAndAdminResponse
         */

        /**
         * Calls CreateCompanyPartnerAndAdmin.
         * @function createCompanyPartnerAndAdmin
         * @memberof user.UserService
         * @instance
         * @param {user.ICreateCompanyPartnerAndAdminRequest} request CreateCompanyPartnerAndAdminRequest message or plain object
         * @param {user.UserService.CreateCompanyPartnerAndAdminCallback} callback Node-style callback called with the error, if any, and CreateCompanyPartnerAndAdminResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.createCompanyPartnerAndAdmin = function createCompanyPartnerAndAdmin(request, callback) {
            return this.rpcCall(createCompanyPartnerAndAdmin, $root.user.CreateCompanyPartnerAndAdminRequest, $root.user.CreateCompanyPartnerAndAdminResponse, request, callback);
        }, "name", { value: "CreateCompanyPartnerAndAdmin" });

        /**
         * Calls CreateCompanyPartnerAndAdmin.
         * @function createCompanyPartnerAndAdmin
         * @memberof user.UserService
         * @instance
         * @param {user.ICreateCompanyPartnerAndAdminRequest} request CreateCompanyPartnerAndAdminRequest message or plain object
         * @returns {Promise<user.CreateCompanyPartnerAndAdminResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link user.UserService#getUserByEmail}.
         * @memberof user.UserService
         * @typedef GetUserByEmailCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {user.User} [response] User
         */

        /**
         * Calls GetUserByEmail.
         * @function getUserByEmail
         * @memberof user.UserService
         * @instance
         * @param {user.IGetUserRequest} request GetUserRequest message or plain object
         * @param {user.UserService.GetUserByEmailCallback} callback Node-style callback called with the error, if any, and User
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(UserService.prototype.getUserByEmail = function getUserByEmail(request, callback) {
            return this.rpcCall(getUserByEmail, $root.user.GetUserRequest, $root.user.User, request, callback);
        }, "name", { value: "GetUserByEmail" });

        /**
         * Calls GetUserByEmail.
         * @function getUserByEmail
         * @memberof user.UserService
         * @instance
         * @param {user.IGetUserRequest} request GetUserRequest message or plain object
         * @returns {Promise<user.User>} Promise
         * @variation 2
         */

        return UserService;
    })();

    user.InvitePartnerUserRequest = (function() {

        /**
         * Properties of an InvitePartnerUserRequest.
         * @memberof user
         * @interface IInvitePartnerUserRequest
         * @property {google.protobuf.IStringValue|null} [email] InvitePartnerUserRequest email
         * @property {user.UserRole|null} [role] InvitePartnerUserRequest role
         */

        /**
         * Constructs a new InvitePartnerUserRequest.
         * @memberof user
         * @classdesc Represents an InvitePartnerUserRequest.
         * @implements IInvitePartnerUserRequest
         * @constructor
         * @param {user.IInvitePartnerUserRequest=} [properties] Properties to set
         */
        function InvitePartnerUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InvitePartnerUserRequest email.
         * @member {google.protobuf.IStringValue|null|undefined} email
         * @memberof user.InvitePartnerUserRequest
         * @instance
         */
        InvitePartnerUserRequest.prototype.email = null;

        /**
         * InvitePartnerUserRequest role.
         * @member {user.UserRole} role
         * @memberof user.InvitePartnerUserRequest
         * @instance
         */
        InvitePartnerUserRequest.prototype.role = 0;

        /**
         * Gets the default type url for InvitePartnerUserRequest
         * @function getTypeUrl
         * @memberof user.InvitePartnerUserRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        InvitePartnerUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.InvitePartnerUserRequest";
        };

        return InvitePartnerUserRequest;
    })();

    user.CreateOperatorUserRequest = (function() {

        /**
         * Properties of a CreateOperatorUserRequest.
         * @memberof user
         * @interface ICreateOperatorUserRequest
         * @property {user.IUserProfile|null} [profile] CreateOperatorUserRequest profile
         * @property {user.UserType|null} [userType] CreateOperatorUserRequest userType
         */

        /**
         * Constructs a new CreateOperatorUserRequest.
         * @memberof user
         * @classdesc Represents a CreateOperatorUserRequest.
         * @implements ICreateOperatorUserRequest
         * @constructor
         * @param {user.ICreateOperatorUserRequest=} [properties] Properties to set
         */
        function CreateOperatorUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateOperatorUserRequest profile.
         * @member {user.IUserProfile|null|undefined} profile
         * @memberof user.CreateOperatorUserRequest
         * @instance
         */
        CreateOperatorUserRequest.prototype.profile = null;

        /**
         * CreateOperatorUserRequest userType.
         * @member {user.UserType} userType
         * @memberof user.CreateOperatorUserRequest
         * @instance
         */
        CreateOperatorUserRequest.prototype.userType = 0;

        /**
         * Gets the default type url for CreateOperatorUserRequest
         * @function getTypeUrl
         * @memberof user.CreateOperatorUserRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateOperatorUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.CreateOperatorUserRequest";
        };

        return CreateOperatorUserRequest;
    })();

    user.GetUserRequest = (function() {

        /**
         * Properties of a GetUserRequest.
         * @memberof user
         * @interface IGetUserRequest
         * @property {google.protobuf.IInt32Value|null} [userId] GetUserRequest userId
         * @property {google.protobuf.IStringValue|null} [email] GetUserRequest email
         */

        /**
         * Constructs a new GetUserRequest.
         * @memberof user
         * @classdesc Represents a GetUserRequest.
         * @implements IGetUserRequest
         * @constructor
         * @param {user.IGetUserRequest=} [properties] Properties to set
         */
        function GetUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserRequest userId.
         * @member {google.protobuf.IInt32Value|null|undefined} userId
         * @memberof user.GetUserRequest
         * @instance
         */
        GetUserRequest.prototype.userId = null;

        /**
         * GetUserRequest email.
         * @member {google.protobuf.IStringValue|null|undefined} email
         * @memberof user.GetUserRequest
         * @instance
         */
        GetUserRequest.prototype.email = null;

        /**
         * Gets the default type url for GetUserRequest
         * @function getTypeUrl
         * @memberof user.GetUserRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.GetUserRequest";
        };

        return GetUserRequest;
    })();

    user.UpdateUserRequest = (function() {

        /**
         * Properties of an UpdateUserRequest.
         * @memberof user
         * @interface IUpdateUserRequest
         * @property {google.protobuf.IInt32Value|null} [userId] UpdateUserRequest userId
         * @property {user.IUserProfile|null} [profile] UpdateUserRequest profile
         */

        /**
         * Constructs a new UpdateUserRequest.
         * @memberof user
         * @classdesc Represents an UpdateUserRequest.
         * @implements IUpdateUserRequest
         * @constructor
         * @param {user.IUpdateUserRequest=} [properties] Properties to set
         */
        function UpdateUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateUserRequest userId.
         * @member {google.protobuf.IInt32Value|null|undefined} userId
         * @memberof user.UpdateUserRequest
         * @instance
         */
        UpdateUserRequest.prototype.userId = null;

        /**
         * UpdateUserRequest profile.
         * @member {user.IUserProfile|null|undefined} profile
         * @memberof user.UpdateUserRequest
         * @instance
         */
        UpdateUserRequest.prototype.profile = null;

        /**
         * Gets the default type url for UpdateUserRequest
         * @function getTypeUrl
         * @memberof user.UpdateUserRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UpdateUserRequest";
        };

        return UpdateUserRequest;
    })();

    user.DeleteUserRequest = (function() {

        /**
         * Properties of a DeleteUserRequest.
         * @memberof user
         * @interface IDeleteUserRequest
         * @property {google.protobuf.IInt32Value|null} [userId] DeleteUserRequest userId
         */

        /**
         * Constructs a new DeleteUserRequest.
         * @memberof user
         * @classdesc Represents a DeleteUserRequest.
         * @implements IDeleteUserRequest
         * @constructor
         * @param {user.IDeleteUserRequest=} [properties] Properties to set
         */
        function DeleteUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteUserRequest userId.
         * @member {google.protobuf.IInt32Value|null|undefined} userId
         * @memberof user.DeleteUserRequest
         * @instance
         */
        DeleteUserRequest.prototype.userId = null;

        /**
         * Gets the default type url for DeleteUserRequest
         * @function getTypeUrl
         * @memberof user.DeleteUserRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteUserRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.DeleteUserRequest";
        };

        return DeleteUserRequest;
    })();

    user.UpdateUserPasswordRequest = (function() {

        /**
         * Properties of an UpdateUserPasswordRequest.
         * @memberof user
         * @interface IUpdateUserPasswordRequest
         * @property {google.protobuf.IStringValue|null} [oldPassword] UpdateUserPasswordRequest oldPassword
         * @property {google.protobuf.IStringValue|null} [newPassword] UpdateUserPasswordRequest newPassword
         * @property {google.protobuf.IStringValue|null} [otp] UpdateUserPasswordRequest otp
         */

        /**
         * Constructs a new UpdateUserPasswordRequest.
         * @memberof user
         * @classdesc Represents an UpdateUserPasswordRequest.
         * @implements IUpdateUserPasswordRequest
         * @constructor
         * @param {user.IUpdateUserPasswordRequest=} [properties] Properties to set
         */
        function UpdateUserPasswordRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateUserPasswordRequest oldPassword.
         * @member {google.protobuf.IStringValue|null|undefined} oldPassword
         * @memberof user.UpdateUserPasswordRequest
         * @instance
         */
        UpdateUserPasswordRequest.prototype.oldPassword = null;

        /**
         * UpdateUserPasswordRequest newPassword.
         * @member {google.protobuf.IStringValue|null|undefined} newPassword
         * @memberof user.UpdateUserPasswordRequest
         * @instance
         */
        UpdateUserPasswordRequest.prototype.newPassword = null;

        /**
         * UpdateUserPasswordRequest otp.
         * @member {google.protobuf.IStringValue|null|undefined} otp
         * @memberof user.UpdateUserPasswordRequest
         * @instance
         */
        UpdateUserPasswordRequest.prototype.otp = null;

        /**
         * Gets the default type url for UpdateUserPasswordRequest
         * @function getTypeUrl
         * @memberof user.UpdateUserPasswordRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateUserPasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UpdateUserPasswordRequest";
        };

        return UpdateUserPasswordRequest;
    })();

    user.UpdateUserPhoneNumberRequest = (function() {

        /**
         * Properties of an UpdateUserPhoneNumberRequest.
         * @memberof user
         * @interface IUpdateUserPhoneNumberRequest
         * @property {google.protobuf.IStringValue|null} [newPhoneNumber] UpdateUserPhoneNumberRequest newPhoneNumber
         */

        /**
         * Constructs a new UpdateUserPhoneNumberRequest.
         * @memberof user
         * @classdesc Represents an UpdateUserPhoneNumberRequest.
         * @implements IUpdateUserPhoneNumberRequest
         * @constructor
         * @param {user.IUpdateUserPhoneNumberRequest=} [properties] Properties to set
         */
        function UpdateUserPhoneNumberRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateUserPhoneNumberRequest newPhoneNumber.
         * @member {google.protobuf.IStringValue|null|undefined} newPhoneNumber
         * @memberof user.UpdateUserPhoneNumberRequest
         * @instance
         */
        UpdateUserPhoneNumberRequest.prototype.newPhoneNumber = null;

        /**
         * Gets the default type url for UpdateUserPhoneNumberRequest
         * @function getTypeUrl
         * @memberof user.UpdateUserPhoneNumberRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpdateUserPhoneNumberRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UpdateUserPhoneNumberRequest";
        };

        return UpdateUserPhoneNumberRequest;
    })();

    user.ResetUserPasswordRequest = (function() {

        /**
         * Properties of a ResetUserPasswordRequest.
         * @memberof user
         * @interface IResetUserPasswordRequest
         * @property {google.protobuf.IStringValue|null} [email] ResetUserPasswordRequest email
         */

        /**
         * Constructs a new ResetUserPasswordRequest.
         * @memberof user
         * @classdesc Represents a ResetUserPasswordRequest.
         * @implements IResetUserPasswordRequest
         * @constructor
         * @param {user.IResetUserPasswordRequest=} [properties] Properties to set
         */
        function ResetUserPasswordRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResetUserPasswordRequest email.
         * @member {google.protobuf.IStringValue|null|undefined} email
         * @memberof user.ResetUserPasswordRequest
         * @instance
         */
        ResetUserPasswordRequest.prototype.email = null;

        /**
         * Gets the default type url for ResetUserPasswordRequest
         * @function getTypeUrl
         * @memberof user.ResetUserPasswordRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResetUserPasswordRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.ResetUserPasswordRequest";
        };

        return ResetUserPasswordRequest;
    })();

    user.CompleteUserInvitationRequest = (function() {

        /**
         * Properties of a CompleteUserInvitationRequest.
         * @memberof user
         * @interface ICompleteUserInvitationRequest
         * @property {google.protobuf.IStringValue|null} [firstname] CompleteUserInvitationRequest firstname
         * @property {google.protobuf.IStringValue|null} [lastname] CompleteUserInvitationRequest lastname
         * @property {google.protobuf.IStringValue|null} [salutation] CompleteUserInvitationRequest salutation
         * @property {google.protobuf.IStringValue|null} [phoneNumber] CompleteUserInvitationRequest phoneNumber
         * @property {google.protobuf.IStringValue|null} [password] CompleteUserInvitationRequest password
         */

        /**
         * Constructs a new CompleteUserInvitationRequest.
         * @memberof user
         * @classdesc Represents a CompleteUserInvitationRequest.
         * @implements ICompleteUserInvitationRequest
         * @constructor
         * @param {user.ICompleteUserInvitationRequest=} [properties] Properties to set
         */
        function CompleteUserInvitationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CompleteUserInvitationRequest firstname.
         * @member {google.protobuf.IStringValue|null|undefined} firstname
         * @memberof user.CompleteUserInvitationRequest
         * @instance
         */
        CompleteUserInvitationRequest.prototype.firstname = null;

        /**
         * CompleteUserInvitationRequest lastname.
         * @member {google.protobuf.IStringValue|null|undefined} lastname
         * @memberof user.CompleteUserInvitationRequest
         * @instance
         */
        CompleteUserInvitationRequest.prototype.lastname = null;

        /**
         * CompleteUserInvitationRequest salutation.
         * @member {google.protobuf.IStringValue|null|undefined} salutation
         * @memberof user.CompleteUserInvitationRequest
         * @instance
         */
        CompleteUserInvitationRequest.prototype.salutation = null;

        /**
         * CompleteUserInvitationRequest phoneNumber.
         * @member {google.protobuf.IStringValue|null|undefined} phoneNumber
         * @memberof user.CompleteUserInvitationRequest
         * @instance
         */
        CompleteUserInvitationRequest.prototype.phoneNumber = null;

        /**
         * CompleteUserInvitationRequest password.
         * @member {google.protobuf.IStringValue|null|undefined} password
         * @memberof user.CompleteUserInvitationRequest
         * @instance
         */
        CompleteUserInvitationRequest.prototype.password = null;

        /**
         * Gets the default type url for CompleteUserInvitationRequest
         * @function getTypeUrl
         * @memberof user.CompleteUserInvitationRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CompleteUserInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.CompleteUserInvitationRequest";
        };

        return CompleteUserInvitationRequest;
    })();

    user.User = (function() {

        /**
         * Properties of a User.
         * @memberof user
         * @interface IUser
         * @property {google.protobuf.IInt32Value|null} [userId] User userId
         * @property {user.IUserProfile|null} [profile] User profile
         * @property {google.protobuf.IBoolValue|null} [enabled] User enabled
         * @property {user.UserType|null} [userType] User userType
         */

        /**
         * Constructs a new User.
         * @memberof user
         * @classdesc Represents a User.
         * @implements IUser
         * @constructor
         * @param {user.IUser=} [properties] Properties to set
         */
        function User(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * User userId.
         * @member {google.protobuf.IInt32Value|null|undefined} userId
         * @memberof user.User
         * @instance
         */
        User.prototype.userId = null;

        /**
         * User profile.
         * @member {user.IUserProfile|null|undefined} profile
         * @memberof user.User
         * @instance
         */
        User.prototype.profile = null;

        /**
         * User enabled.
         * @member {google.protobuf.IBoolValue|null|undefined} enabled
         * @memberof user.User
         * @instance
         */
        User.prototype.enabled = null;

        /**
         * User userType.
         * @member {user.UserType} userType
         * @memberof user.User
         * @instance
         */
        User.prototype.userType = 0;

        /**
         * Gets the default type url for User
         * @function getTypeUrl
         * @memberof user.User
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        User.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.User";
        };

        return User;
    })();

    user.UserProfile = (function() {

        /**
         * Properties of a UserProfile.
         * @memberof user
         * @interface IUserProfile
         * @property {google.protobuf.IStringValue|null} [email] UserProfile email
         * @property {google.protobuf.IStringValue|null} [firstname] UserProfile firstname
         * @property {google.protobuf.IStringValue|null} [lastname] UserProfile lastname
         * @property {google.protobuf.IStringValue|null} [salutation] UserProfile salutation
         * @property {google.protobuf.IStringValue|null} [phoneNumber] UserProfile phoneNumber
         * @property {google.protobuf.IStringValue|null} [isoLanguageCode] UserProfile isoLanguageCode
         */

        /**
         * Constructs a new UserProfile.
         * @memberof user
         * @classdesc Represents a UserProfile.
         * @implements IUserProfile
         * @constructor
         * @param {user.IUserProfile=} [properties] Properties to set
         */
        function UserProfile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserProfile email.
         * @member {google.protobuf.IStringValue|null|undefined} email
         * @memberof user.UserProfile
         * @instance
         */
        UserProfile.prototype.email = null;

        /**
         * UserProfile firstname.
         * @member {google.protobuf.IStringValue|null|undefined} firstname
         * @memberof user.UserProfile
         * @instance
         */
        UserProfile.prototype.firstname = null;

        /**
         * UserProfile lastname.
         * @member {google.protobuf.IStringValue|null|undefined} lastname
         * @memberof user.UserProfile
         * @instance
         */
        UserProfile.prototype.lastname = null;

        /**
         * UserProfile salutation.
         * @member {google.protobuf.IStringValue|null|undefined} salutation
         * @memberof user.UserProfile
         * @instance
         */
        UserProfile.prototype.salutation = null;

        /**
         * UserProfile phoneNumber.
         * @member {google.protobuf.IStringValue|null|undefined} phoneNumber
         * @memberof user.UserProfile
         * @instance
         */
        UserProfile.prototype.phoneNumber = null;

        /**
         * UserProfile isoLanguageCode.
         * @member {google.protobuf.IStringValue|null|undefined} isoLanguageCode
         * @memberof user.UserProfile
         * @instance
         */
        UserProfile.prototype.isoLanguageCode = null;

        /**
         * Gets the default type url for UserProfile
         * @function getTypeUrl
         * @memberof user.UserProfile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserProfile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserProfile";
        };

        return UserProfile;
    })();

    /**
     * UserType enum.
     * @name user.UserType
     * @enum {number}
     * @property {number} MARKETPARTNER=0 MARKETPARTNER value
     * @property {number} OPERATORADMIN=1 OPERATORADMIN value
     * @property {number} OPERATORUSER=2 OPERATORUSER value
     * @property {number} COMPANYPARTNER=3 COMPANYPARTNER value
     */
    user.UserType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "MARKETPARTNER"] = 0;
        values[valuesById[1] = "OPERATORADMIN"] = 1;
        values[valuesById[2] = "OPERATORUSER"] = 2;
        values[valuesById[3] = "COMPANYPARTNER"] = 3;
        return values;
    })();

    user.Users = (function() {

        /**
         * Properties of a Users.
         * @memberof user
         * @interface IUsers
         * @property {Array.<user.IUser>|null} [users] Users users
         */

        /**
         * Constructs a new Users.
         * @memberof user
         * @classdesc Represents a Users.
         * @implements IUsers
         * @constructor
         * @param {user.IUsers=} [properties] Properties to set
         */
        function Users(properties) {
            this.users = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Users users.
         * @member {Array.<user.IUser>} users
         * @memberof user.Users
         * @instance
         */
        Users.prototype.users = $util.emptyArray;

        /**
         * Gets the default type url for Users
         * @function getTypeUrl
         * @memberof user.Users
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Users.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.Users";
        };

        return Users;
    })();

    user.UserPartnerRelationships = (function() {

        /**
         * Properties of a UserPartnerRelationships.
         * @memberof user
         * @interface IUserPartnerRelationships
         * @property {Array.<user.IUserPartnerRelationship>|null} [relationships] UserPartnerRelationships relationships
         */

        /**
         * Constructs a new UserPartnerRelationships.
         * @memberof user
         * @classdesc Represents a UserPartnerRelationships.
         * @implements IUserPartnerRelationships
         * @constructor
         * @param {user.IUserPartnerRelationships=} [properties] Properties to set
         */
        function UserPartnerRelationships(properties) {
            this.relationships = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserPartnerRelationships relationships.
         * @member {Array.<user.IUserPartnerRelationship>} relationships
         * @memberof user.UserPartnerRelationships
         * @instance
         */
        UserPartnerRelationships.prototype.relationships = $util.emptyArray;

        /**
         * Gets the default type url for UserPartnerRelationships
         * @function getTypeUrl
         * @memberof user.UserPartnerRelationships
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserPartnerRelationships.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserPartnerRelationships";
        };

        return UserPartnerRelationships;
    })();

    user.UserPartnerRelationship = (function() {

        /**
         * Properties of a UserPartnerRelationship.
         * @memberof user
         * @interface IUserPartnerRelationship
         * @property {google.protobuf.IInt32Value|null} [userId] UserPartnerRelationship userId
         * @property {google.protobuf.IInt32Value|null} [partnerId] UserPartnerRelationship partnerId
         * @property {user.UserRole|null} [role] UserPartnerRelationship role
         * @property {google.protobuf.IStringValue|null} [partnerName] UserPartnerRelationship partnerName
         * @property {user.UserType|null} [userType] UserPartnerRelationship userType
         */

        /**
         * Constructs a new UserPartnerRelationship.
         * @memberof user
         * @classdesc Represents a UserPartnerRelationship.
         * @implements IUserPartnerRelationship
         * @constructor
         * @param {user.IUserPartnerRelationship=} [properties] Properties to set
         */
        function UserPartnerRelationship(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserPartnerRelationship userId.
         * @member {google.protobuf.IInt32Value|null|undefined} userId
         * @memberof user.UserPartnerRelationship
         * @instance
         */
        UserPartnerRelationship.prototype.userId = null;

        /**
         * UserPartnerRelationship partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof user.UserPartnerRelationship
         * @instance
         */
        UserPartnerRelationship.prototype.partnerId = null;

        /**
         * UserPartnerRelationship role.
         * @member {user.UserRole} role
         * @memberof user.UserPartnerRelationship
         * @instance
         */
        UserPartnerRelationship.prototype.role = 0;

        /**
         * UserPartnerRelationship partnerName.
         * @member {google.protobuf.IStringValue|null|undefined} partnerName
         * @memberof user.UserPartnerRelationship
         * @instance
         */
        UserPartnerRelationship.prototype.partnerName = null;

        /**
         * UserPartnerRelationship userType.
         * @member {user.UserType} userType
         * @memberof user.UserPartnerRelationship
         * @instance
         */
        UserPartnerRelationship.prototype.userType = 0;

        /**
         * Gets the default type url for UserPartnerRelationship
         * @function getTypeUrl
         * @memberof user.UserPartnerRelationship
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserPartnerRelationship.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserPartnerRelationship";
        };

        return UserPartnerRelationship;
    })();

    /**
     * UserRole enum.
     * @name user.UserRole
     * @enum {number}
     * @property {number} USER_ROLE_UNSPECIFIED=0 USER_ROLE_UNSPECIFIED value
     * @property {number} ADMIN=1 ADMIN value
     * @property {number} USER=2 USER value
     */
    user.UserRole = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "USER_ROLE_UNSPECIFIED"] = 0;
        values[valuesById[1] = "ADMIN"] = 1;
        values[valuesById[2] = "USER"] = 2;
        return values;
    })();

    user.UserPartnerRelationshipRequest = (function() {

        /**
         * Properties of a UserPartnerRelationshipRequest.
         * @memberof user
         * @interface IUserPartnerRelationshipRequest
         * @property {google.protobuf.IInt32Value|null} [userId] UserPartnerRelationshipRequest userId
         * @property {google.protobuf.IInt32Value|null} [partnerId] UserPartnerRelationshipRequest partnerId
         */

        /**
         * Constructs a new UserPartnerRelationshipRequest.
         * @memberof user
         * @classdesc Represents a UserPartnerRelationshipRequest.
         * @implements IUserPartnerRelationshipRequest
         * @constructor
         * @param {user.IUserPartnerRelationshipRequest=} [properties] Properties to set
         */
        function UserPartnerRelationshipRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserPartnerRelationshipRequest userId.
         * @member {google.protobuf.IInt32Value|null|undefined} userId
         * @memberof user.UserPartnerRelationshipRequest
         * @instance
         */
        UserPartnerRelationshipRequest.prototype.userId = null;

        /**
         * UserPartnerRelationshipRequest partnerId.
         * @member {google.protobuf.IInt32Value|null|undefined} partnerId
         * @memberof user.UserPartnerRelationshipRequest
         * @instance
         */
        UserPartnerRelationshipRequest.prototype.partnerId = null;

        /**
         * Gets the default type url for UserPartnerRelationshipRequest
         * @function getTypeUrl
         * @memberof user.UserPartnerRelationshipRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserPartnerRelationshipRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.UserPartnerRelationshipRequest";
        };

        return UserPartnerRelationshipRequest;
    })();

    user.CreateMarketPartnerAndAdminRequest = (function() {

        /**
         * Properties of a CreateMarketPartnerAndAdminRequest.
         * @memberof user
         * @interface ICreateMarketPartnerAndAdminRequest
         * @property {partner.IPartnerSettings|null} [partnerSettings] CreateMarketPartnerAndAdminRequest partnerSettings
         * @property {user.IUserProfile|null} [initialAdmin] CreateMarketPartnerAndAdminRequest initialAdmin
         */

        /**
         * Constructs a new CreateMarketPartnerAndAdminRequest.
         * @memberof user
         * @classdesc Represents a CreateMarketPartnerAndAdminRequest.
         * @implements ICreateMarketPartnerAndAdminRequest
         * @constructor
         * @param {user.ICreateMarketPartnerAndAdminRequest=} [properties] Properties to set
         */
        function CreateMarketPartnerAndAdminRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateMarketPartnerAndAdminRequest partnerSettings.
         * @member {partner.IPartnerSettings|null|undefined} partnerSettings
         * @memberof user.CreateMarketPartnerAndAdminRequest
         * @instance
         */
        CreateMarketPartnerAndAdminRequest.prototype.partnerSettings = null;

        /**
         * CreateMarketPartnerAndAdminRequest initialAdmin.
         * @member {user.IUserProfile|null|undefined} initialAdmin
         * @memberof user.CreateMarketPartnerAndAdminRequest
         * @instance
         */
        CreateMarketPartnerAndAdminRequest.prototype.initialAdmin = null;

        /**
         * Gets the default type url for CreateMarketPartnerAndAdminRequest
         * @function getTypeUrl
         * @memberof user.CreateMarketPartnerAndAdminRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateMarketPartnerAndAdminRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.CreateMarketPartnerAndAdminRequest";
        };

        return CreateMarketPartnerAndAdminRequest;
    })();

    user.CreateMarketPartnerAndAdminResponse = (function() {

        /**
         * Properties of a CreateMarketPartnerAndAdminResponse.
         * @memberof user
         * @interface ICreateMarketPartnerAndAdminResponse
         * @property {partner.IPartnerResponse|null} [partner] CreateMarketPartnerAndAdminResponse partner
         * @property {user.IUser|null} [user] CreateMarketPartnerAndAdminResponse user
         * @property {user.IUserPartnerRelationship|null} [relationship] CreateMarketPartnerAndAdminResponse relationship
         */

        /**
         * Constructs a new CreateMarketPartnerAndAdminResponse.
         * @memberof user
         * @classdesc Represents a CreateMarketPartnerAndAdminResponse.
         * @implements ICreateMarketPartnerAndAdminResponse
         * @constructor
         * @param {user.ICreateMarketPartnerAndAdminResponse=} [properties] Properties to set
         */
        function CreateMarketPartnerAndAdminResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateMarketPartnerAndAdminResponse partner.
         * @member {partner.IPartnerResponse|null|undefined} partner
         * @memberof user.CreateMarketPartnerAndAdminResponse
         * @instance
         */
        CreateMarketPartnerAndAdminResponse.prototype.partner = null;

        /**
         * CreateMarketPartnerAndAdminResponse user.
         * @member {user.IUser|null|undefined} user
         * @memberof user.CreateMarketPartnerAndAdminResponse
         * @instance
         */
        CreateMarketPartnerAndAdminResponse.prototype.user = null;

        /**
         * CreateMarketPartnerAndAdminResponse relationship.
         * @member {user.IUserPartnerRelationship|null|undefined} relationship
         * @memberof user.CreateMarketPartnerAndAdminResponse
         * @instance
         */
        CreateMarketPartnerAndAdminResponse.prototype.relationship = null;

        /**
         * Gets the default type url for CreateMarketPartnerAndAdminResponse
         * @function getTypeUrl
         * @memberof user.CreateMarketPartnerAndAdminResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateMarketPartnerAndAdminResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.CreateMarketPartnerAndAdminResponse";
        };

        return CreateMarketPartnerAndAdminResponse;
    })();

    user.CreateCompanyPartnerAndAdminRequest = (function() {

        /**
         * Properties of a CreateCompanyPartnerAndAdminRequest.
         * @memberof user
         * @interface ICreateCompanyPartnerAndAdminRequest
         * @property {companypartner.ICompanyPartnerSettings|null} [companyPartnerSettings] CreateCompanyPartnerAndAdminRequest companyPartnerSettings
         * @property {user.IUserProfile|null} [initialAdmin] CreateCompanyPartnerAndAdminRequest initialAdmin
         * @property {google.protobuf.IStringValue|null} [password] CreateCompanyPartnerAndAdminRequest password
         */

        /**
         * Constructs a new CreateCompanyPartnerAndAdminRequest.
         * @memberof user
         * @classdesc Represents a CreateCompanyPartnerAndAdminRequest.
         * @implements ICreateCompanyPartnerAndAdminRequest
         * @constructor
         * @param {user.ICreateCompanyPartnerAndAdminRequest=} [properties] Properties to set
         */
        function CreateCompanyPartnerAndAdminRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateCompanyPartnerAndAdminRequest companyPartnerSettings.
         * @member {companypartner.ICompanyPartnerSettings|null|undefined} companyPartnerSettings
         * @memberof user.CreateCompanyPartnerAndAdminRequest
         * @instance
         */
        CreateCompanyPartnerAndAdminRequest.prototype.companyPartnerSettings = null;

        /**
         * CreateCompanyPartnerAndAdminRequest initialAdmin.
         * @member {user.IUserProfile|null|undefined} initialAdmin
         * @memberof user.CreateCompanyPartnerAndAdminRequest
         * @instance
         */
        CreateCompanyPartnerAndAdminRequest.prototype.initialAdmin = null;

        /**
         * CreateCompanyPartnerAndAdminRequest password.
         * @member {google.protobuf.IStringValue|null|undefined} password
         * @memberof user.CreateCompanyPartnerAndAdminRequest
         * @instance
         */
        CreateCompanyPartnerAndAdminRequest.prototype.password = null;

        /**
         * Gets the default type url for CreateCompanyPartnerAndAdminRequest
         * @function getTypeUrl
         * @memberof user.CreateCompanyPartnerAndAdminRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateCompanyPartnerAndAdminRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.CreateCompanyPartnerAndAdminRequest";
        };

        return CreateCompanyPartnerAndAdminRequest;
    })();

    user.CreateCompanyPartnerAndAdminResponse = (function() {

        /**
         * Properties of a CreateCompanyPartnerAndAdminResponse.
         * @memberof user
         * @interface ICreateCompanyPartnerAndAdminResponse
         * @property {companypartner.ICompanyPartnerSettings|null} [companyPartner] CreateCompanyPartnerAndAdminResponse companyPartner
         * @property {user.IUser|null} [user] CreateCompanyPartnerAndAdminResponse user
         * @property {user.IUserPartnerRelationship|null} [relationship] CreateCompanyPartnerAndAdminResponse relationship
         */

        /**
         * Constructs a new CreateCompanyPartnerAndAdminResponse.
         * @memberof user
         * @classdesc Represents a CreateCompanyPartnerAndAdminResponse.
         * @implements ICreateCompanyPartnerAndAdminResponse
         * @constructor
         * @param {user.ICreateCompanyPartnerAndAdminResponse=} [properties] Properties to set
         */
        function CreateCompanyPartnerAndAdminResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateCompanyPartnerAndAdminResponse companyPartner.
         * @member {companypartner.ICompanyPartnerSettings|null|undefined} companyPartner
         * @memberof user.CreateCompanyPartnerAndAdminResponse
         * @instance
         */
        CreateCompanyPartnerAndAdminResponse.prototype.companyPartner = null;

        /**
         * CreateCompanyPartnerAndAdminResponse user.
         * @member {user.IUser|null|undefined} user
         * @memberof user.CreateCompanyPartnerAndAdminResponse
         * @instance
         */
        CreateCompanyPartnerAndAdminResponse.prototype.user = null;

        /**
         * CreateCompanyPartnerAndAdminResponse relationship.
         * @member {user.IUserPartnerRelationship|null|undefined} relationship
         * @memberof user.CreateCompanyPartnerAndAdminResponse
         * @instance
         */
        CreateCompanyPartnerAndAdminResponse.prototype.relationship = null;

        /**
         * Gets the default type url for CreateCompanyPartnerAndAdminResponse
         * @function getTypeUrl
         * @memberof user.CreateCompanyPartnerAndAdminResponse
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CreateCompanyPartnerAndAdminResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/user.CreateCompanyPartnerAndAdminResponse";
        };

        return CreateCompanyPartnerAndAdminResponse;
    })();

    return user;
})();

export { $root as default };
