/* eslint-disable @angular-eslint/component-selector */
import { Component, ViewChild } from '@angular/core';
import { SessionService } from '../../shared/session/session.service';
import { MarketPartnerService } from '../../shared/services/marketpartner.service';

import { ActivatedRoute, Router } from '@angular/router';

import { MarketpartnerViewComponent } from '../shared/marketpartner-view.component';
import { ApiRequestService } from '../../shared/api-request/service/api-request.service';
import { ApiRequestBuilder } from '../../shared/api-request/builder/api-request.builder';
import { USERS_API_ENDPOINTS_LIST } from '../requests/api-endpoints-list';

import { SmartModalService } from '../../shared/marketpartner-components/smart-forms/smart-modal/service/smart-modal.service';
import { AppConfig } from '../../config/app.config';
import { ActionEvent } from '../../shared/marketpartner-components/smart-forms/smart-shared/classes/ActionEvent';
import { SmartTableBuilder } from '../../shared/marketpartner-components/smart-forms/smart-table/builder/smart-table.builder';
import { SmartTableConfigDefinition } from '../../shared/marketpartner-components/smart-forms/smart-table/classes/SmartTableConfigDefinition';

import { SmartTableComponent } from '../../shared/marketpartner-components/smart-forms/smart-table/component/smart-table.component';
import { ChangeProcess } from '../classes/ChangeProcess';
import { SdatRequestDialogsService } from '../shared/sdat-request-dialogs.service';
import { MarketPartnerInfo } from '../../shared/session/MarketPartnerInfo';
import { ColumnFilterOperator } from '../../shared/marketpartner-components/smart-forms/smart-table/classes/TableColumnFilter';

interface ButtonSpec {
  label: string;
  fnVisible?: ((mpi: MarketPartnerInfo) => boolean);
  fnClicked?: (() => void);
}

@Component({
  selector: 'page-users-change-processes-content',
  templateUrl: './users-change-processes.component.html',
  styleUrls: ['../users-common.scss', './users-change-processes.component.scss']
})

export class ChangeProcessesComponent extends MarketpartnerViewComponent  {

  initialized = false;

  tableAllChangeProcessesConfig?: SmartTableConfigDefinition;
  tableArchivedChangeProcessesConfig?: SmartTableConfigDefinition;

  @ViewChild('tableAllChangeProcesses') tableAllChangeProcesses?: SmartTableComponent;
  @ViewChild('tableArchivedChangeProcesses') tableArchivedChangeProcesses?: SmartTableComponent;

  createButtons: ButtonSpec[] = [{
    label: 'SDATButtons.SupplyNewPointOrModify',
    fnVisible: mpi => mpi.partnerSettings.role === 'LF',
    fnClicked: async () => { await this.sdatRequestDialogs.modifySupply(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.SupplyEnd',
    fnVisible: mpi => mpi.partnerSettings.role === 'LF',
    fnClicked: async () => { await this.sdatRequestDialogs.endSupply(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.RegisterBaseSupply',
    fnVisible: mpi => mpi.partnerSettings.role === 'VNB',
    fnClicked: async () => {
      if (!this.marketPartnerInfo) return;
      await this.sdatRequestDialogs.registerBaseSupply(undefined);
      this.onListChanged();
    }
  }, {
    label: 'SDATButtons.RegisterSubstituteSupply',
    fnVisible: mpi => mpi.partnerSettings.role === 'VNB',
    fnClicked: async () => {
      if (!this.marketPartnerInfo) return;
      await this.sdatRequestDialogs.registerSubstituteSupply(undefined);
      this.onListChanged();
    }
  }, {
    label: 'SDATButtons.PartyConnectedToGridChange',
    fnVisible: mpi => mpi.partnerSettings.role === 'LF',
    fnClicked: async () => { await this.sdatRequestDialogs.requestConsumerChange(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.PartyConnectedToGridEnd',
    fnVisible: mpi => mpi.partnerSettings.role === 'LF',
    fnClicked: async () => { await this.sdatRequestDialogs.requestConsumerEnd(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.ASPRegistration',
    fnVisible: mpi => mpi.partnerSettings.role === 'SDV',
    fnClicked: async () => { await this.sdatRequestDialogs.registerASP(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.ASPDeregistration',
    fnVisible: mpi => mpi.partnerSettings.role === 'SDV',
    fnClicked: async () => { await this.sdatRequestDialogs.deregisterASP(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.ChangeConsumerMasterData',
    fnVisible: mpi => mpi.partnerSettings.role === 'LF',
    fnClicked: async () => { await this.sdatRequestDialogs.changeConsumerMasterData(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.ChangeMPMasterData',
    fnVisible: mpi => mpi.partnerSettings.role === 'VNB',
    fnClicked: async () => { await this.sdatRequestDialogs.changeMpMasterData(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.QuerySwitchInformation',
    fnVisible: mpi => mpi.partnerSettings.role === 'LF',
    fnClicked: async () => { await this.sdatRequestDialogs.querySwitchInformation(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.QueryMpInformation',
    fnVisible: mpi => ['LF'].includes(mpi.partnerSettings.role),
    fnClicked: async () => { await this.sdatRequestDialogs.queryMpInformation(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.QueryMeasurements',
    fnVisible: mpi => ['LF', 'VNB', 'UNB', 'EV'].includes(mpi.partnerSettings.role),
    fnClicked: async () => { await this.sdatRequestDialogs.queryMeasurements(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.QueryMeasurementAggregates',
    fnVisible: mpi => ['LF', 'BGV', 'UNB'].includes(mpi.partnerSettings.role),
    fnClicked: async () => { await this.sdatRequestDialogs.queryMeasurementAggregates(); this.onListChanged(); }
  }, {
    label: 'SDATButtons.Cancellation',
    fnVisible: mpi => mpi.partnerSettings.role === 'LF' || mpi.partnerSettings.role === 'SDV',
    fnClicked: async () => { await this.sdatRequestDialogs.cancel(); this.onListChanged(); }
  }];

  createButtonsVisible: ButtonSpec[] = [];

  constructor(public sessionService: SessionService,
              public marketPartnerService: MarketPartnerService,
              public activatedRoute: ActivatedRoute,
              public apiRequestService: ApiRequestService,
              public smartModalService: SmartModalService,
              public sdatRequestDialogs: SdatRequestDialogsService,
              public router: Router) {
    super(activatedRoute, router, sessionService, marketPartnerService, apiRequestService);
  }

  async onParamsUrlInitialized(params: any) {
    await super.onParamsUrlInitialized(params);
    this.tableAllChangeProcessesConfig = this.initTableMessages();
    this.tableArchivedChangeProcessesConfig = this.initTableMessages(true);
    this.updateCounts();

    if (!this.marketPartnerInfo) return;
    this.createButtonsVisible = this.createButtons.filter(
      bs => bs.fnVisible ? this.marketPartnerInfo && bs.fnVisible(this.marketPartnerInfo) : true
    );

    this.setInitialized(true);
  }

  async onListChanged() {
    if (this.tableAllChangeProcesses) { this.tableAllChangeProcesses.reload(); }
    if (this.tableArchivedChangeProcesses) { this.tableArchivedChangeProcesses.reload(); }
    this.updateCounts();
  }

  private updateCounts() {
    this.marketPartnerService.updateUnreadProcessesCount();
    this.marketPartnerService.updateUnsuppliedCount();
  }

  async onActionPerformed(event: ActionEvent) {
    const self = this;
    const entitySelected = event.data.length ? event.data[0] : null;
    console.log('entitySelected:', entitySelected);
    switch (event.actionId) {
      case 'process_view':
        self.viewProcess(entitySelected);
        break;
      case 'process_read':
        self.setProcessRead(entitySelected, true);
        break;
      case 'process_unread':
        self.setProcessRead(entitySelected, false);
        break;
      case 'archive':
        self.archive(entitySelected, true);
        break;
      case 'dearchive':
        self.archive(entitySelected, false);
        break;
    }
  }

  initTableMessages(archived = false): SmartTableConfigDefinition {
    let tableChangeProcessesBuilder = new SmartTableBuilder()
      .addColumnFromInfo({id: 'processUuid', title: '', visible: false})
      .setColumnNameForId('processUuid')
      .addColumnFromInfo({id: 'businessReason', title: 'ColumnChangeProcess',
                          enum: 'sdatbusinessreason', visible: true, filterable: true});
//      .addColumnFromInfo({id: 'direction', title: 'ColumnChangeProcessDirection',
//                          enum: 'sdatprocessdirection', visible: true, filterable: true});

    tableChangeProcessesBuilder = tableChangeProcessesBuilder
      .addFixedColumnFilter('archive', {
        operator: ColumnFilterOperator.Equals, value: archived
      });

    tableChangeProcessesBuilder = tableChangeProcessesBuilder
      // yes, the 'processState' field has type messageClass (reuses same enum)
      .addColumnFromInfo({
        id: 'processState', title: 'ColumnProcessState',
        enum: 'messageclass', visible: true, filterable: true
      });

    return tableChangeProcessesBuilder
      .addColumnFromInfo({id: 'lastModificationTime', title: 'ColumnLastActivity',
                          fieldType: 'date', displayAs: 'localedatetimeLss',
                          sortable: true, filterable: true})
      .addColumnFromInfo({id: 'sdatProcessId', title: 'ColumnProcessId',
                          sortable: true, filterable: true})
      .addRowActionFromInfo({
        id: 'process_view', title: 'ActionViewChangeProcess',
        icon: 'pli-eye', className: 'btn-mint'
      })
      .addRowActionFromInfo({
        id: 'process_read', title: 'ActionProcessRead',
        icon: 'pli-mail-read', className: 'btn-success',
        fnVisible: (data: any) => data.read === false && !this.isOperatorMode
      })
      .addRowActionFromInfo({
        id: 'process_unread', title: 'ActionProcessUnread',
        icon: 'pli-mail-unread', className: 'btn-warning',
        fnVisible: (data: any) => data.read === true && !this.isOperatorMode
      })
      .addRowActionFromInfo({
        id: 'archive', title: 'ActionProcessArchive',
        icon: 'pli-folder-archive', className: 'btn-info',
        fnVisible: (data: any) => !data.archive
      })
      .addRowActionFromInfo({
        id: 'dearchive', title: 'ActionProcessDearchive',
        icon: 'pli-folder-archive', className: 'btn-info',
        fnVisible: (data: any) => data.archive
      })
      .addRowClassReadTag()
      .setApiRequestConfigInfoFromInfo(USERS_API_ENDPOINTS_LIST.changeProcessesList,
        {
          partnerId: this.partnerId
        })
      .showFilters(true)
      .showSearchField(false)
      .build();
  }

  viewProcess(changeProcessInfo: ChangeProcess) {
    this.navigateTo({
      'route': AppConfig.routes.change_processes_info,
      'replace': {processUuid: changeProcessInfo.processUuid}
    });
  }

  async setProcessRead(changeProcessInfo: ChangeProcess, read: boolean) {
    const apiRequestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.setProcessRead)
      .setBodyInfo({
        read,
        id: {
          uuid: changeProcessInfo.processUuid,
          partnerId: this.partnerId
        }
      })
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
    if (resultApiRequest.status === 'success') {
      this.onListChanged();
    }
  }

  async archive(changeProcessInfo: ChangeProcess, archive: boolean) {
    const apiRequestConfig = new ApiRequestBuilder()
      .setHandleLoading(true)
      .setHandleErrorNotification(true)
      .setEndpointInfo(USERS_API_ENDPOINTS_LIST.archiveProcess)
      .setBodyInfo({
        archive,
        id: {
          uuid: changeProcessInfo.processUuid,
          partnerId: this.partnerId
        }
      })
      .build();
    const resultApiRequest = await this.apiRequestService.callApi(apiRequestConfig);
    if (resultApiRequest.status === 'success') {
      this.onListChanged();
    }
  }
}
