import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import {FlatTreeControl} from "@angular/cdk/tree";

export interface TreeNode {
  label: string;
  data: any;
  children?: TreeNode[];
  selected: boolean | null;
}

interface FlatNode {
  expandable: boolean;
  label: string;
  data: any;
  selected: boolean | null;
  level: number;
}
@Component({
  selector: 'filter-tree',
  templateUrl: './filter-tree.component.html',
  styleUrls: []
})
export class FilterTreeComponent {
  private _transformer = (node: TreeNode, level: number): FlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      label: node.label,
      data: node.data,
      selected: node.selected,
      level: level
    };
  };

  @Input() set treeData(value: TreeNode[] | undefined) {
    if(value != undefined) {
      this.dataSource.data = value;
      this.treeControl.expandAll();
    }
  };

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
  );

  @Output() selectedNodes = new EventEmitter<any[]>();

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: FlatNode) => node.expandable;

  setAllSubnodes(node: FlatNode, checked: boolean) {
    node.selected = checked;
    this.treeControl.getDescendants(node).forEach(c => c.selected = node.selected);
    this.emitSelection();
  }

  setParentNode(node: FlatNode, checked: boolean) {
    node.selected = checked;
    let parentNode = this.getParentNode(node);
    parentNode.selected = this.treeControl
      .getDescendants(parentNode)
      .map(c => c.selected)
      .reduce((p, c) => p == c? c : null);
    this.emitSelection();
  }

  emitSelection() {
    this.selectedNodes.emit(this.treeControl.dataNodes.filter(n => !n.expandable && n.selected).map(n => n.data));
  }

  getParentNode(node: FlatNode): FlatNode {
    const currentLevel = node.level;

    if (currentLevel < 1) {
      return node;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return node;
  }
}
