import {NavigationMenuDefinition} from "../shared/nav/NavigationMenuDefinition";

export const COMPANYPARTNERS_NAVIGATION_LIST: NavigationMenuDefinition[] = [
  {
    fullPath: '/company/contingents',
    title: 'companyClient.navigation.contingents',
    icon: 'gauge',
    subRoutes: [
      {
        fullPath: '/company/contingents/consumption',
        title: 'companyClient.navigation.consumption',
      },
      {
        fullPath: '/company/contingents/meteringpoints',
        title: 'companyClient.navigation.meteringpoints',
      }/*,
      {
        fullPath: '/company/contingents/reports',
        title: 'companyClient.navigation.reports',
      }*/
    ],
    fnAllowed: sessionService => sessionService.isCompanyPartner()
  },
  {
    fullPath: '/company/usermanagement',
    title: 'companyClient.navigation.userManagement',
    icon: 'users',
    subRoutes: [],
    fnAllowed: sessionService => sessionService.getCurrentScopeForDomain() === 'ADMIN' && sessionService.isCompanyPartner()
  },
  {
    fullPath: '/company/settings',
    title: 'companyClient.navigation.companySettings',
    icon: 'cog',
    subRoutes: [],
    fnAllowed: sessionService => sessionService.getCurrentScopeForDomain() === 'ADMIN' && sessionService.isCompanyPartner()
  }

];
