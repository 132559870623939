import {ApiRequestConfigInfoDefinitionMap} from '../../shared/api-request/classes/ApiRequestConfigInfoDefinition';

export const USERS_API_ENDPOINTS_LIST: ApiRequestConfigInfoDefinitionMap = {
  'globalUsers': {
    endpoint: '/api/global/users',
    method: 'GET'
  },
  'globalRegisterUser': {
    endpoint: '/api/global/users',
    method: 'POST'
  },
  'globalDeleteUser': {
    endpoint: '/api/global/users/{userId}',
    method: 'DELETE'
  },
  'globalGetProfileUser': {
    endpoint: '/api/global/users/{userId}/profile/info',
    method: 'GET'
  },
  'globalEdiOperatorRoles': {
    endpoint: '/api/global/users/{userId}/operator/roles',
    method: 'PUT'
  },
  'globalEdiMarketPartnerRoles': {
    endpoint: '/api/global/users/{userId}/marketpartners/{partnerId}/roles',
    method: 'PUT'
  },
  'globalDeleteMarketPartnerUser': {
    endpoint: '/api/global/users/{userId}/marketpartners/{partnerId}',
    method: 'DELETE'
  },

  'globalLinkToMarketPartner': {
    endpoint: '/api/global/users/{userId}/marketpartners/{partnerId}/roles',
    method: 'POST'
  },
  'operatorAllMarketPartnerUsers': {
    endpoint: '/api/partners/all/users',
    method: 'GET'
  },
  'forgotResetUser': {
    endpoint: '/api/forgot/reset_user',
    method: 'POST'
  },

  'marketpartnerUsers': {
    endpoint: '/api/marketpartner/users',
    method: 'GET'
  },
  'marketpartnerInviteUser': {
    endpoint: '/api/marketpartner/invite',
    method: 'POST'
  },
  'marketpartnerDeleteUser': {
    endpoint: '/api/marketpartner/users/{userId}',
    method: 'DELETE'
  },
  'marketpartnerEditUserRoles': {
    endpoint: '/api/marketpartner/users/{userId}/roles',
    method: 'PUT'
  },

  'marketpartnersShortList': {
    endpoint: '/api/marketpartnersdirectory',
    method: 'GET'
  },

  'marketpartnerDirectorySearch': {
    endpoint: '/api/marketpartnersdirectory/search',
    method: 'POST'
  },
  'marketpartnerDirectoryContactSearch': {
    endpoint: '/api/marketpartnersdirectory/contact/search',
    method: 'POST'
  },
  'marketpartnerDirectoryMarketPartnerInfo': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}',
    method: 'GET'
  },
  'marketpartnerDirectoryMeteringPointsDelete': {
    endpoint: '/api/meteringPoints/delete/{partnerId}',
    method: 'DELETE'
  },
  'marketpartnerDirectoryMarketPartnerDelete': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}',
    method: 'DELETE'
  },
  'marketpartnerDirectoryMeteringDataDeleteForPartner': {
    endpoint: '/api/meteringdataservice/{partnerId}',
    method: 'DELETE'
  },
  'marketpartnerDirectoryMeteringDataDeleteForMeteringPoint': {
    endpoint: '/api/meteringdataservice/deleteMeteringData', // body MeteringPointSelector (id, adminEIC)
    method: 'POST'
  },
  'marketpartnersCreate': {
    endpoint: '/api/marketpartners',
    method: 'POST'
  },
  'marketpartnerDirectoryMarketPartnerSettingsUpdate': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}/partner-settings',
    method: 'PUT'
  },
  'marketpartnerDirectoryMarketPartnerSdatWebClientSettingsUpdate': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}/sdat-web-client-settings',
    method: 'PUT'
  },
  'marketpartnerDirectoryMarketPartnerMeteringPointsDirectorySettingsUpdate': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}/metering-points-directory-settings',
    method: 'PUT'
  },
  'marketpartnerDirectoryMarketPartnerReceiveMessageLocationUpdate': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}/receive-message-location',
    method: 'PUT'
  },

  'marketpartnerDirectoryMarketPartnerExternalAccountUpdate': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}/external-ftp-account/{id}',
    method: 'PUT'
  },
  'marketpartnerDirectoryMarketPartnerExternalAccountDelete': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}/external-ftp-account/{id}',
    method: 'DELETE'
  },
  'marketpartnerDirectoryMarketPartnerExternalAccountCreate': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}/external-ftp-account',
    method: 'POST'
  },

  'marketpartnerDirectoryMarketPartnerInternalAccountCreate': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}/internal-ftp-account',
    method: 'POST'
  },
  'marketpartnerDirectoryMarketPartnerInternalAccountDelete': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}/internal-ftp-account/{ftpUsername}',
    method: 'DELETE'
  },
  'marketpartnerIsFtpAccountShared': {
    endpoint: '/api/marketpartnersdirectory/is-ftp-account-shared',
    method: 'POST'
  },
  'marketpartnerDirectoryMarketPartnerFeatures': {
    endpoint: '/api/marketpartnersdirectory/{partnerId}/features',
    method: 'POST'
  },
  'marketpartnerDirectoryFeaturesForAll': {
    endpoint: '/api/marketpartnersdirectory/features',
    method: 'POST'
  },
  'changeProcessesList': {
    endpoint: '/api/sdatWebClient/listProcesses',
    method: 'POST'
  },
  'changeProcessInfo': {
    endpoint: '/api/sdatWebClient/getProcessDocumentDetails',
    method: 'POST'
  },
  'setProcessRead': {
    endpoint: '/api/sdatWebClient/setProcessRead',
    method: 'POST',
  },
  'archiveProcess': {
    endpoint: '/api/sdatWebClient/archiveProcess',
    method: 'POST',
  },
  'unreadProcess': {
    endpoint: '/api/sdatWebClient/hasUnread/partner/{partnerId}',
    method: 'GET',
  },
  'downloadProcessMessage': {
    endpoint: '/api/sdatWebClient/{messageUuid}/download',
    method: 'POST',
  },
  'messagesLogList': {
    endpoint: '/api/messagelog/search',
    method: 'GET',
    useMockup: true
  },
  'getReport': {
    endpoint: '/api/reporting',
    method: 'POST'
  },
  'requestSupplierChange': {
    endpoint: '/api/processingCore/requestSupplierChange',
    method: 'POST'
  },
  'requestSupplierEnd': {
    endpoint: '/api/processingCore/requestSupplierEnd',
    method: 'POST'
  },
  'requestConsumerChange': {
    endpoint: '/api/processingCore/requestConsumerChange',
    method: 'POST'
  },
  'requestConsumerEnd': {
    endpoint: '/api/processingCore/requestConsumerEnd',
    method: 'POST'
  },
  'changeConsumerMasterData': {
    endpoint: '/api/processingCore/changeConsumerMasterData',
    method: 'POST'
  },
  'registerASP': {
    endpoint: '/api/processingCore/registerASP',
    method: 'POST'
  },
  'deregisterASP': {
    endpoint: '/api/processingCore/deregisterASP',
    method: 'POST'
  },
  'registerBaseSupply': {
    endpoint: '/api/processingCore/registerBaseSupply',
    method: 'POST'
  },
  'registerSubstituteSupply': {
    endpoint: '/api/processingCore/registerSubstituteSupply',
    method: 'POST'
  },
  'changeMPMasterData': {
    endpoint: '/api/processingCore/changeMPMasterData',
    method: 'POST'
  },
  'queryMPInfo': {
    endpoint: '/api/processingCore/queryMPInfo',
    method: 'POST'
  },
  'querySwitchInfo': {
    endpoint: '/api/processingCore/querySwitchInfo',
    method: 'POST'
  },
  'queryMeasurements': {
    endpoint: '/api/processingCore/queryMeasurements',
    method: 'POST'
  },
  'queryMeasurementAggregates': {
    endpoint: '/api/processingCore/queryMeasurementAggregates',
    method: 'POST'
  },
  'requestProcessCancellation': {
    endpoint: '/api/processingCore/requestProcessCancellation',
    method: 'POST'
  },
  'confirmSupplierChange': {
    endpoint: '/api/processingCore/confirmSupplierChange',
    method: 'POST'  // SupplierChangeConfirmation
  },
  'confirmSupplierEnd': {
    endpoint: '/api/processingCore/confirmSupplierEnd',
    method: 'POST'  // SupplierEndConfirmation
  },
  'confirmConsumerChange': {
    endpoint: '/api/processingCore/confirmConsumerChange',
    method: 'POST'  // ConsumerChangeConfirmation
  },
  'confirmConsumerEnd': {
    endpoint: '/api/processingCore/confirmConsumerEnd',
    method: 'POST'  // ConsumerEndConfirmation
  },
  'confirmASPStart': {
    endpoint: '/api/processingCore/confirmASPStart',
    method: 'POST'  // ASPStartConfirmation
  },
  'confirmASPEnd': {
    endpoint: '/api/processingCore/confirmASPEnd',
    method: 'POST'  // ASPEndConfirmation
  },
  'answerMPInfoQuery': {
    endpoint: '/api/processingCore/answerMPInfoQuery',
    method: 'POST'  // MPInfoQueryAnswer
  },
  'answerSwitchInfoQuery': {
    endpoint: '/api/processingCore/answerSwitchInfoQuery',
    method: 'POST'  // SwitchInfoResponse
  },
  'confirmProcessCancellation': {
    endpoint: '/api/processingCore/confirmProcessCancellation',
    method: 'POST'  // ProcessCancellationConfirmation
  },
  'rejectRequest': {
    endpoint: '/api/processingCore/rejectRequest',
    method: 'POST'  // RequestRejection
  },
  'cancelDocument': {
    endpoint: '/api/processingCore/cancelDocument',
    method: 'POST'  // DocumentCancellation
  },
  'downloadData': {
    endpoint: '/api/processingCore/downloadData',
    method: 'POST'  // DocumentCancellation
  },
  'retrievePreviousConfirmation': {
    endpoint: '/api/processingCore/retrievePreviousConfirmation',
    method: 'POST'  // DocumentCancellation
  },
  'notificationsList': {
    endpoint: '/api/errorNotifications/search',
    method: 'POST'
  },
  'notificationInfo': {
    endpoint: '/api/errorNotifications/{messageUuid}/partner/{partnerId}',
    method: 'GET',
  },
  'notificationDownload': {
    endpoint: '/api/errorNotifications/{messageUuid}/download',
    method: 'POST',
  },
  'notificationMarkRead': {
    endpoint: '/api/errorNotifications/{messageUuid}/read?read={read}',
    method: 'PUT'
  },
  'notificationDelete': {
    endpoint: '/api/errorNotifications/{messageUuid}/partner/{partnerId}',
    method: 'DELETE'
  },
  'notificationsUnread': {
    endpoint: '/api/errorNotifications/hasUnread/partner/{partnerId}',
    method: 'GET'
  },
  'notificationsDeleteAll': {
    endpoint: '/api/errorNotifications/deleteAll',
    method: 'POST'
  },
  'meteringPointsList': {
    endpoint: '/api/meteringPoints/search',
    method: 'POST'
  },
  'meteringPointsUpload': {
    endpoint: '/api/meteringPoints/upload',
    method: 'POST'
  },
  'meteringPointsUploadStatus': {
    endpoint: '/api/meteringPoints/upload/status',
    method: 'POST'
  },
  'meteringPointsUploadAcknowledge': {
    endpoint: '/api/meteringPoints/upload/acknowledge',
    method: 'POST'
  },
  'meteringPointsDownload': {
    endpoint: '/api/meteringPoints/download',
    method: 'POST'
  },
  'meteringPointsExport': {
    endpoint: '/api/meteringPoints/export',
    method: 'POST'
  },
  'meteringPointsDownloadStatus': {
    endpoint: '/api/meteringPoints/download/status',
    method: 'POST'
  },
  'meteringPointsDownloadCancel': {
    endpoint: '/api/meteringPoints/download/cancel',
    method: 'POST'
  },
  'meteringPointsDetails': {
    endpoint: '/api/meteringPoints/details',
    method: 'POST'
  },
  'meteringPointsInvalidateEvent': {
    endpoint: '/api/meteringPoints/invalidateEvent',
    method: 'POST'
  },
  'meteringPointsDeleteMeteringPoint': {
    endpoint: '/api/meteringPoints/deleteMeteringPoint',
    method: 'POST'
  },
  'unsuppliedMeteringPoints': {
    endpoint: '/api/meteringPoints/getUnsuppliedCount/{partnerId}',
    method: 'GET',
  },
  'listBusinessPartners': {
    endpoint: '/api/companyservice/listBusinessPartnersPaginated',
    method: 'POST'
  },
  'uploadBusinessPartnerData': {
    endpoint: '/api/companyservice/uploadBusinessPartnerData',
    method: 'POST'
  },
  'downloadRegistrationCode': {
    endpoint: '/api/companyservice/downloadRegistrationCodes',
    method: 'POST'
  },
  'deleteBusinessPartner': {
    endpoint: '/api/companyservice/deleteBusinessPartner',
    method: 'POST'
  },
  'getBusinessPartner': {
    endpoint: '/api/companyservice/getBusinessPartner', // body: partnerId, businessPartnerId, returnLinkedCompanyPartner
    method: 'POST'
  },
  'operatorReport': {
    endpoint: '/api/marketpartnersdirectory/export',
    method: 'POST'
  },
  'operatorEmailSendAnnouncement': {
    endpoint: '/api/email/sendAnnouncement',
    method: 'POST'
  },
  'operatorExportUsers': {
    endpoint: '/api/users/download',
    method: 'POST'
  },
  'listLocalFtpAccountsAvailableForSharing': {
    endpoint: '/api/marketpartnersdirectory/list-local-ftp-accounts-available-for-sharing',
    method: 'GET'
  },
  'shareLocalFtpAccount': {
    endpoint: '/api/marketpartnersdirectory/share-local-ftp-account',
    method: 'POST'
  },
  'listSharedFtpAccounts': {
    endpoint: '/api/marketpartnersdirectory/list-shared-ftp-accounts',
    method: 'POST'
  },
  'unshareSharedFtpAccount': {
    endpoint: '/api/marketpartnersdirectory/unshare-shared-ftp-account',
    method: 'POST'
  },
  'getConsumptionData': {
    endpoint: 'api/companyservice/getConsumptionDataBusinessPartnersWithDailySdat',
    //body: partnerId, businessPartnerId, returnLinkedCompanyPartner, yearOfDailyValues -1 | 0 | YYYY
    method: 'POST'
  },
  'listMeteringPoints': {
    endpoint: '/api/companyservice/listMeteringPointsBusinessPartners',
    method: 'POST'
  },
  'getMeteringPointMonthlyDetails': {
    endpoint: 'api/companyservice/getMeteringPointMonthlyDetails',
    // MPSel: id (=meteringPointId), administratorId
    // body MeteringPointMonthlyRequest: selector: MPSel, businessPartner: businesspartnerId, partnerId
    method: 'POST'
  },
  'listCompanyPartners': {
    endpoint: '/api/companypartner/search',
    method: 'POST'
  },
  'getCompanyPartner': {
    endpoint: '/api/companypartner/{partnerId}',
    method: 'GET'
  },
  'operatorReportCompanyPartners': {
    endpoint: '/api/companypartner/export',
    method: 'POST'
  },
  'deleteCompanyPartner': {
    endpoint: '/api/companypartner/{partnerId}',
    method: 'DELETE'
  },
  'getContingentRates': {
    endpoint: '/api/companyservice/getAllContingentRates',
    method: 'POST'
  },
  'setContingentRate': {
    endpoint: '/api/companyservice/setContingentRate',
    method: 'POST'
  },
  'deleteContingentRate': {
    endpoint: '/api/companyservice/deleteContingentRate',
    method: 'POST'
  },
  'generateContingentLetters': {
    endpoint: '/api/companyservice/downloadDataForContingencyLetters',
    method: 'POST'
  },
  'generateContingentLettersByBusinessPartners': {
    endpoint: '/api/companyservice/downloadDataForContingencyLettersByBusinessPartners',
    method: 'POST'
  }
};
