import {ApiRequestConfigInfoDefinitionMap} from '../shared/api-request/classes/ApiRequestConfigInfoDefinition';

export const COMPANYPARTNERS_API_ENDPOINTS_LIST: ApiRequestConfigInfoDefinitionMap = {
  'getCompanyPartner': {
    endpoint: '/api/companyservice/getCompanyPartner',
    // body partnerId
    method: 'POST'
  },
  'updateCompanyPartnerAllServices': {
    endpoint: '/api/companypartner/{partnerId}/updateCompanyPartnerAllServices',
    method: 'PUT'
  },
  'getCompanyByRegistrationCode': {
    endpoint: '/api/companyservice/getCompanyByRegistrationCode',
    method: 'POST'
  },
  'initiateCompanyPartnerTransfer': {
    endpoint: '/api/companyservice/initiateCompanyPartnerTransfer',
    // body {sourcePartnerId, targetEmail}
    method: 'POST'
  },
  'uniteCompanyPartner': {
    endpoint: '/api/companyservice/uniteCompanyPartner',
    // body {partnerid: currentPartnerId, registrationCode: this.regCodeFormField.value}
    method: 'POST'
  },
  // unused?
  'listBusinessPartnersOfCompanyPartner': { // body: partnerid
    endpoint: '/api/companyservice/listAllBusinessPartnersOfOneCompanyPartner',
    method: 'POST'
  },
  'listUsers': {
    endpoint: '/api/companypartner/users',
    method: 'GET'
  },
  'inviteUser': {
    endpoint: '/api/companypartner/invite',
    method: 'POST'
  },
  'deleteUser': {
    endpoint: '/api/companypartner/users/{userId}',
    method: 'DELETE'
  },
  'editUserRoles': {
    endpoint: '/api/companypartner/users/{userId}/roles',
    method: 'PUT'
  },
  'getConsumptionData': {
    endpoint: 'api/companyservice/getConsumptionDataCompanyPartner',
    // body partnerId (of logged in companypartner)
    method: 'POST'
  },
  'listMeteringPoints': {
    endpoint: 'api/companyservice/listMeteringPointsCompanyPartner',
    // body partnerId
    method: 'POST'
  },
  'getMeteringPointMonthlyDetails': {
    endpoint: 'api/companyservice/getMeteringPointMonthlyDetails',
    // MPSel: id (=meteringPointId), administratorId
    // body MeteringPointMonthlyRequest: selector: MPSel, businessPartner: businesspartnerId, partnerId
    method: 'POST'
  }
};
