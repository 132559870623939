<page-loading [showLoadingText]="true" *ngIf="!initialized"></page-loading>
<div id="page-content" *ngIf="initialized">
  <div class="before-panel-info">
    <div class="row">
      <div class="col-sm-8">
        <div (click)="goBack()" class="before-panel-title go-back-nav-button">
          <i class="fa fa-chevron-circle-left"></i>
          <div>{{gpInfo.name}} ({{'Ostral.DetailTitle' | translate}})</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="showInfoMultipleVnb">
    <div class="col-sm-12">
      <div class="tab-base">
        <div class="tab-content text-info">
          <i class="pli-information modal-notification-icon"></i>
          {{ 'Ostral.MultipleVnb' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="tab-base no-botton-margin">
        <!--Nav tabs-->
        <ul class="nav nav-tabs tabs-left">
          <li *ngIf="!isOperatorMode" class="active">
            <a data-toggle="tab" href="#ostral-tab-1" >
              {{'Ostral.TabConsumption' | translate}}
            </a>
          </li>
          <li [class.active]="isOperatorMode">
            <a data-toggle="tab" href="#ostral-tab-2" >
              {{'Ostral.TabMeteringPoints' | translate}}
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#ostral-tab-3" >
              {{'Ostral.TabInfo' | translate}}
            </a>
          </li>
        </ul>

        <!--Tabs Content-->
        <div class="tab-content">

          <div *ngIf="!isOperatorMode" id="ostral-tab-1" class="tab-pane fade active in" style="height: 700px">
            <charts-wrapper [consumptionData]="consumptionData"></charts-wrapper>
          </div>

          <div id="ostral-tab-2" class="tab-pane fade" [class.active]="isOperatorMode" [class.in]="isOperatorMode">
            <table mat-table [dataSource]="meteringPoints" class="table-striped">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'id' | translate}}</th>
                <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.id}} </td>
              </ng-container>

              <ng-container matColumnDef="mpa">
                <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'vnb' | translate}}</th>
                <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.mpa.name}} </td>
              </ng-container>

              <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'address' | translate}}</th>
                <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.address.street}} {{meteringPoint.address.houseNumber}}, {{meteringPoint.address.zipCode}} {{meteringPoint.address.town}}</td>
              </ng-container>

              <ng-container matColumnDef="businessPartnerName">
                <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'company' | translate}}</th>
                <td mat-cell *matCellDef="let meteringPoint"> {{meteringPoint.businessPartnerName}} </td>
              </ng-container>

              <ng-container matColumnDef="validFrom">
                <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'validFrom' | translate}}</th>
                <td mat-cell *matCellDef="let meteringPoint"> {{renderDate(meteringPoint.valid.from)}} </td>
              </ng-container>

              <ng-container matColumnDef="validTo">
                <th mat-header-cell *matHeaderCellDef>{{mpPrefix+'validTo' | translate}}</th>
                <td mat-cell *matCellDef="let meteringPoint"> {{renderDate(meteringPoint.valid.to)}} </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let meteringPoint">
                  <button *ngIf="!this.isOperatorMode" mat-icon-button (click)="showMeteringPointDetails(meteringPoint.id)">
                    <mat-icon svgIcon="chevron-right"></mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

          <div id="ostral-tab-3" class="tab-pane fade">
            <div class="details-list">

              <div class="row">
                <div class="col-sm-6">
                  <h4>{{'Ostral.DataFromImport' | translate}}</h4>
                  <ng-container *ngIf="gpInfo != undefined">
                    <div class="details-item">
                      <label class="details-label">{{'Ostral.CompanyDetail.CompanyName' |translate}}</label>
                      <div class="details-value">{{gpInfo.name}}</div>
                    </div>
                    <div class="details-item">
                      <label class="details-label">{{'Ostral.CompanyDetail.Address' |translate}}</label>
                      <div class="details-value">{{gpInfo.address}}</div>
                    </div>
                    <div class="details-item">
                      <label class="details-label">{{'Ostral.CompanyDetail.RegistrationStatus' |translate}}</label>
                      <div class="details-value">{{'Ostral.Company.'+gpInfo.status | translate}}</div>
                    </div>
                    <div class="details-item">
                      <label class="details-label">{{'Ostral.CompanyDetail.Contact' |translate}}</label>
                      <div class="details-value">{{gpInfo.contact}}</div>
                    </div>
                  </ng-container>
                </div>
                <div class="col-sm-6">
                  <h4>{{'Ostral.DataFromCompany' | translate}}</h4>
                  <ng-container *ngIf="upInfo == undefined">
                    <br><p>{{'Ostral.NoCompanyData' |translate}}</p>
                  </ng-container>
                  <ng-container *ngIf="upInfo != undefined">
                    <div class="details-item">
                      <label class="details-label">{{'Ostral.CompanyDetail.CompanyName' |translate}}</label>
                      <div class="details-value">{{upInfo.name}}</div>
                    </div>
                    <div class="details-item">
                      <label class="details-label">{{'Ostral.CompanyDetail.Address' |translate}}</label>
                      <div class="details-value">{{upInfo.address}}</div>
                    </div>
                    <div class="details-item">
                      <label class="details-label">{{'Ostral.CompanyDetail.Contact' |translate}}</label>
                      <div class="details-value">{{upInfo.contact}}</div>
                    </div>
                    <div class="details-item">
                      <label class="details-label">{{'Ostral.CompanyDetail.Email' |translate}}</label>
                      <div class="details-value">{{upInfo.email}}</div>
                    </div>
                    <div class="details-item">
                      <label class="details-label">{{'Ostral.CompanyDetail.Phone' |translate}}</label>
                      <div class="details-value">{{upInfo.phone}}</div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
