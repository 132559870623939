/* eslint-disable quotes, @typescript-eslint/quotes */
import {NameValueMap} from '../../smart-shared/classes/KeyValueMap';

export const CUSTOM_REGEXPS_DEFS: NameValueMap<string> = {
  'email': "^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])$", // taken from emailregex.com
  'partnername': "^[a-zA-Z][a-zA-Z0-9 _-]{2,63}$",
  'firstname': "^.{1,100}$",
  'lastname': "^.{1,100}$",
  'password': "^.{8,64}$",
  'text': "^.{1,2500}$",
  'intid': "^[1-9][0-9]{0,13}$",
  'metering_point_id': "^CH\\d{11}[A-Z0-9\\-]{20}$",
  'date': "^[0-9]{1,4}-[0-9]{0,2}-[0-3][0-9]$",
  'code2FA': "^.{6,6}$",
  'ftphostname': "^[^ ]{1,200}$",
  'ftpdirectory': "^.{1,200}$",
  'ftpusername': "^.{1,50}$",
  'ftppassword': "^.{1,50}$",
  'positivenumber': "^(0|[1-9][0-9]{0,9})$",
  'sdateic': "^.{1,50}$",
  'internet': "http[s]?://.*",
  'time': '^([0-1]?[0-9]{1}|20|21|22|23):[0-5]{1}[0-9]{1}:[0-5]{1}[0-9]$',
  // 'phone_sms': "^(([+]41|0)7[0-9]{8}|[+]4237[0-9]{6}|[+]423664[0-9]{4})$",   // mobile switzerland and lichtenstein(unverified)
  'phone_sms': "^((\\+41|0)7[4-9][0-9]{7}|((\\+|00)([12356789][0-9]|4[023456789])[0-9]{6,28}))$",   // mobile switzerland and any other
  //'phone_sms': "^[0-9 /\\-+]{10,31}$",
  'phone_any': "^([0-9 /\\-+]{4,31})?$",
  'phone_ech': "^([0-9]{10,20})?$",   // xml definition: \d{10,20} (plus maxLength=20 and whiteSpace=collapse)
  'swisszipcode': "^([1-9][0-9][0-9][0-9])$",
};
