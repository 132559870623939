export class MeteringPoint {
  id: string;
  mpa: {name: string, id: number};
  address: string;
  businessPartnerName: string;
  businessPartnerId: number;
  valid: {
    from: Date | null,
    to: Date | null
  };

  constructor(properties: any) {
    this.id = properties.id;
    this.mpa = properties.mpa;
    this.address = properties.address;
    this.businessPartnerName = properties.businessPartnerName;
    this.businessPartnerId = properties.businessPartnerId;
    this.valid = {
      from: properties.valid.from ? new Date(properties.valid.from.year, properties.valid.from.month-1, properties.valid.from.day) : null,
      to: properties.valid.to ? new Date(properties.valid.to.year,properties.valid.to.month-1,properties.valid.to.day) : null
    };
  }
}
