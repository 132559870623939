import {Component, Input, OnInit} from '@angular/core';
import {MeteringPoint} from "../../../companypartners/metering-points/metering-point";
import {DateTimeService} from "../../services/date-time.service";
import {MatIconComponent} from "../mat-icon-component/mat-icon.component";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'metering-points-table',
  templateUrl: './metering-points-table.component.html',
  styleUrls: []
})
export class MeteringPointsTableComponent extends MatIconComponent implements OnInit {
  @Input() meteringPoints: MeteringPoint[] = [];
  @Input() showAction = false;

  constructor(private dateTimeService: DateTimeService,
              protected matIconRegistry: MatIconRegistry,
              protected domSanitizer: DomSanitizer) {
    super(matIconRegistry, domSanitizer);
  }

  prefix = 'companyClient.meteringPoints.';
  mpPrefix = 'companyClient.objectProperties.meteringPoint.';

  displayedColumns = ['id', 'mpa', 'address', 'businessPartnerName', 'validFrom', 'validTo'];

  renderDate(date: Date) {
    return this.dateTimeService.getDateAsLocaleFormatLinux(date.getTime());
  }

  ngOnInit() {
    if(this.showAction) {
      this.displayedColumns.push('action');
    }
  }
}
